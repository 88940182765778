// --------------------------------------------------------------
// Created On: 2023-09-29
// Author: Zachary Thomas
//
// Last Modified: 2024-01-04
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useState } from "react";
import { API } from "../../constants/miscellaneous";
import Spinner from "../../components/Spinner/Spinner";
import Error500Page from "../Error500Page/Error500Page";
import useApi from "../../hooks/useApi";
import Card from "../../components/Card/Card";
import styles from "./AdminDeviceAlertPage.module.scss";

// Page for viewing application wide asset alert activity.
// Used for finding the "chattiest" assets to trim down on communication costs.
export default function AdminDeviceAlertPage(): Component {
  const [hours, setHours] = useState<number>(48);
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [assets, setAssets] = useState<ChattyAsset[]>([]);
  const assetsReturnedCount = 25;

  // Get asset information.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/asset/alertlog/top/${assetsReturnedCount}/hour/${hours}`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setAssets(responseBody.assets);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [assetsReturnedCount, hours]
  );

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      <Card title="25 Chattiest Assets">
        <table className="table table-hover mb-0 pb-0">
          <thead>
            <tr>
              <th>Asset ID</th>
              <th>Asset Name</th>
              <th>Asset Nickname</th>
              <th>Company Name</th>
              <th>Alert Count</th>
              <th>
                <select className="form-select" value={hours} onChange={(e) => setHours(parseInt(e.target.value))}>
                  <option value="1">Last hour</option>
                  <option value="6">Last 6 hours</option>
                  <option value="12">Last 12 hours</option>
                  <option value="24">Last day</option>
                  <option value="48">Last 2 days</option>
                  <option value="72">Last 3 days</option>
                  <option value="168">Last week</option>
                  <option value="730">Last month</option>
                  <option value="2190">Last 3 months</option>
                  <option value="4380">Last 6 months</option>
                  <option value="8760">Last year</option>
                </select>
              </th>
            </tr>
          </thead>
          <tbody>
            {assets.map((asset) => (
              <tr key={asset.assetId}>
                <td>{asset.assetId}</td>
                <td>{asset.assetName}</td>
                <td>{asset.assetNickname === "" ? "N/A" : asset.assetNickname}</td>
                <td>{asset.companyName}</td>
                <td>{asset.alertLogCount}</td>
                <td />
              </tr>
            ))}
          </tbody>
        </table>

        <Fragment>
          {assets.length === 0 && (
            <div className={`text-center my-5 ${styles.notFound}`}>
              <span>No assets reporting alerts found.</span>
            </div>
          )}
        </Fragment>
      </Card>
    </div>
  );
}

interface ResponseBody {
  assets: ChattyAsset[];
}

interface ChattyAsset {
  assetId: number;
  assetName: string;
  assetNickname: string;
  companyName: string;
  alertLogCount: number;
}
