// --------------------------------------------------------------
// Created On: 2022-01-21
// Author: Zachary Thomas
//
// Last Modified: 2024-04-05
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import { MIN_LATITUDE, MAX_LATITUDE, MIN_LONGITUDE, MAX_LONGITUDE } from "../constants/miscellaneous";
import { GOOGLE_MAPS_DEFAULT_ZOOM, GOOGLE_MAPS_DEFAULT_LAT, GOOGLE_MAPS_DEFAULT_LNG } from "../constants/googleMaps";

// Given a set of points, calculate the center and level of zoom for a map.
export default function calculateCenterZoom(points: Point[], map: google.maps.Map): MapCenter {
  if (points.length > 1) {
    let maxLat = MIN_LATITUDE;
    let minLat = MAX_LATITUDE;
    let maxLng = MIN_LONGITUDE;
    let minLng = MAX_LONGITUDE;

    // Get the four sides of the bounding box that contains markers.
    points.forEach((point) => {
      if (point.latitude !== undefined && point.longitude !== undefined) {
        if (point.latitude > maxLat) {
          maxLat = point.latitude;
        }
        if (point.latitude < minLat) {
          minLat = point.latitude;
        }
        if (point.longitude > maxLng) {
          maxLng = point.longitude;
        }
        if (point.longitude < minLng) {
          minLng = point.longitude;
        }
      }
    });

    // If the latitude or longitude is exactly the same min and max, add a small buffer.
    if (minLat === maxLat) {
      if (minLat > MIN_LATITUDE + 0.00001) {
        minLat = minLat - 0.00001;
      } else {
        maxLat = maxLat + 0.00001;
      }
    }

    if (minLng === maxLng) {
      if (minLng > MIN_LONGITUDE + 0.00001) {
        minLng = minLng - 0.00001;
      } else {
        maxLng = maxLng + 0.00001;
      }
    }

    // Find the new bounding box that fits all markers.
    const bounds = new google.maps.LatLngBounds({ east: maxLng, north: maxLat, south: minLat, west: minLng });
    map.fitBounds(bounds);
    const mapCenter = map.getCenter();
    const mapZoom = map.getZoom();

    if (mapCenter !== undefined && mapZoom !== undefined) {
      return {
        center: { lat: mapCenter.lat(), lng: mapCenter.lng() },
        zoom: mapZoom,
      };
    } else {
      return {
        center: { lat: GOOGLE_MAPS_DEFAULT_LAT, lng: GOOGLE_MAPS_DEFAULT_LNG },
        zoom: GOOGLE_MAPS_DEFAULT_ZOOM,
      };
    }
  } else if (points.length === 1 && points[0].latitude !== undefined && points[0].longitude !== undefined) {
    return {
      center: { lat: points[0].latitude, lng: points[0].longitude },
      zoom: GOOGLE_MAPS_DEFAULT_ZOOM,
    };
  } else {
    return {
      center: { lat: GOOGLE_MAPS_DEFAULT_LAT, lng: GOOGLE_MAPS_DEFAULT_LNG },
      zoom: GOOGLE_MAPS_DEFAULT_ZOOM,
    };
  }
}

interface ShortPoint {
  lat: number;
  lng: number;
}

interface MapCenter {
  center: ShortPoint;
  zoom: number;
}
