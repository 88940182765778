// --------------------------------------------------------------
// Created On: 2021-11-23
// Author: Zachary Thomas
//
// Last Modified: 2024-05-14
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment } from "react";
import AssociationRole from "./AssociationRole/AssociationRole";
import validateNickname from "../../../utilities/validateNickname";
import PropTypes from "prop-types";
import styles from "./AssociationContainer.module.scss";

// Container for making selections and associations.
export default function AssociationContainer<Type>(props: Props<Type>): Component {
  const [controlDown, setControlDown] = useState<boolean>(false);
  const [unassociatedFilter, setUnassociatedFilter] = useState<string>("");
  const [associatedFilter, setAssociatedFilter] = useState<string>("");
  const [unassociatedItems, setUnassociatedItems] = useState<Item[]>([]);
  const [associatedItems, setAssociatedItems] = useState<Item[]>([]);

  // Filter out associated items that don't match the current filter.
  useEffect(() => {
    if (associatedFilter.length > 0) {
      const matchedItems = (props.associatedItems as Item[]).filter(
        (item: Item) => item.name.toLowerCase().indexOf(associatedFilter.toLowerCase()) >= 0
      );
      setAssociatedItems(matchedItems);
    } else {
      setAssociatedItems(props.associatedItems as Item[]);
    }
  }, [associatedFilter, JSON.stringify(props.associatedItems)]);

  // Filter out unassociated items that don't match the current filter.
  useEffect(() => {
    if (unassociatedFilter.length > 0) {
      const matchedItems = (props.unassociatedItems as Item[]).filter(
        (item: Item) => item.name.toLowerCase().indexOf(unassociatedFilter.toLowerCase()) >= 0
      );
      setUnassociatedItems(matchedItems);
    } else {
      setUnassociatedItems(props.unassociatedItems as Item[]);
    }
  }, [unassociatedFilter, JSON.stringify(props.unassociatedItems)]);

  // Add event listener for holding down the control key.
  useEffect(() => {
    function keyPress(e: KeyboardEvent) {
      if (e.key === "Control") {
        setControlDown(true);
      }
    }

    function keyRelease(e: KeyboardEvent) {
      if (e.key === "Control") {
        setControlDown(false);
      }
    }

    document.addEventListener("keydown", (e) => keyPress(e));
    document.addEventListener("keyup", (e) => keyRelease(e));
    return () => {
      document.removeEventListener("keydown", (e) => keyPress(e));
      document.removeEventListener("keyup", (e) => keyRelease(e));
    };
  }, []);

  // Select an item.
  function selectItem(itemId: number, selectionType: string): void {
    if (props.selectionType !== selectionType || !controlDown) {
      props.onSelectItems([itemId], selectionType);
    } else {
      if (!props.selectedItemIds.includes(itemId)) {
        props.onSelectItems([itemId, ...props.selectedItemIds], selectionType);
      }
    }
  }

  // Get ID from item.
  function getItemId(item: Item): number {
    switch (props.type) {
      case "asset":
        if (item.assetId !== undefined) {
          return item.assetId;
        }
        break;
      case "user":
        if (item.userId !== undefined) {
          return item.userId;
        }
        break;
      case "assetgroup":
        if (item.assetgroupId !== undefined) {
          return item.assetgroupId;
        }
        break;
      case "usergroup":
        if (item.usergroupId !== undefined) {
          return item.usergroupId;
        }
        break;
      default:
        return 0;
    }
    return 0;
  }

  return (
    <div className="row" data-test="association-container">
      {/* List of associated items. */}
      <div className="col-12 col-lg-5" data-test="associated-items-container">
        <div className={`${styles.header} py-3`}>
          <p className={styles.title}>{props.associatedItemsTitle}</p>

          <div className="filter mx-3">
            <input
              data-test="associated-items-search-input"
              type="search"
              className="form-control rounded"
              placeholder="Filter results..."
              value={associatedFilter}
              disabled={props.disabled}
              onChange={(e) => setAssociatedFilter(e.target.value)}
            />
          </div>
        </div>

        <div className={styles.list} data-test="associated-items-list">
          {associatedItems.map((item: Item) => (
            <div
              data-test="associated-items-list-item"
              key={getItemId(item)}
              className={
                `${styles.item} px-3 py-2` +
                ` ${
                  props.selectedItemIds.includes(item[props.itemIdKey as keyof Item] as number) ? styles.selected : ""
                }`
              }
              onClick={() => selectItem(getItemId(item), "associated")}
            >
              <div className="row align-items-center">
                <div className="col">
                  <span>{item.name}</span>
                  <span>{validateNickname(item.nickname, item.name) ? ` (${item.nickname})` : ""}</span>
                  <span>
                    {item.emailAddress !== null && item.emailAddress !== undefined && item.emailAddress.length > 0
                      ? ` (${item.emailAddress})`
                      : ""}
                  </span>
                </div>

                {props.type === "usergroup" && (
                  <AssociationRole
                    usergroupId={getItemId(item)}
                    operateAccessPermission={item.operateAccessPermission || false}
                    onClick={() =>
                      props.onConfirmRoleChange(
                        item[props.itemIdKey as keyof Item] as number,
                        item.operateAccessPermission as boolean
                      )
                    }
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Buttons for changing the association status of items. */}
      <div className={`${styles.btnDivider} col-12 col-lg-2 my-auto justify-content-center align-items-center`}>
        <button
          data-test="disassociate-single-button"
          className={
            `${styles.btn} my-2 pb-2 px-0` +
            ` ${
              props.selectedItemIds.length === 0 || props.selectionType !== "associated" || props.disabled
                ? styles.disabled
                : ""
            }`
          }
          disabled={props.disabled}
          onClick={() => props.onDisassociateItems(props.selectedItemIds)}
        >
          <span className={styles.text}>&gt;</span>
          <i className={`${styles.smallText} fa fa-fw fa-angle-down`} />
        </button>

        <button
          data-test="associate-single-item"
          className={
            `${styles.btn} my-2 pb-2 px-0` +
            ` ${
              props.selectedItemIds.length === 0 || props.selectionType !== "unassociated" || props.disabled
                ? styles.disabled
                : ""
            }`
          }
          disabled={props.disabled}
          onClick={() => props.onAssociateItems(props.selectedItemIds)}
        >
          <span className={styles.text}>&lt;</span>
          <i className={`${styles.smallText} fa fa-fw fa-angle-up`} />
        </button>

        <button
          data-test="disassociate-all-items-button"
          className={
            `${styles.btn} my-2 pb-2 px-0` +
            ` ${props.associatedItems.length === 0 || props.disabled ? styles.disabled : ""}`
          }
          disabled={props.disabled}
          onClick={() => props.onDisassociateAllItems()}
        >
          <span className={styles.text}>&gt;&gt;</span>
          <i className={`${styles.smallText} fa fa-fw fa-angle-double-down`} />
        </button>

        <button
          data-test="associate-all-items-button"
          className={
            `${styles.btn} my-2 pb-2 px-0` +
            ` ${props.unassociatedItems.length === 0 || props.disabled ? styles.disabled : ""}`
          }
          disabled={props.disabled}
          onClick={() => props.onAssociateAllItems()}
        >
          <span className={styles.text}>&lt;&lt;</span>
          <i className={`${styles.smallText} fa fa-fw fa-angle-double-up`} />
        </button>
      </div>

      {/* List of unassociated items. */}
      <div className="col-12 col-lg-5">
        <div className={`${styles.header} py-3`}>
          <p className={styles.title}>{props.unassociatedItemsTitle}</p>

          <div className="filter mx-3">
            <input
              data-test="unassociated-items-search-input"
              type="search"
              className="form-control rounded"
              placeholder="Filter results..."
              value={unassociatedFilter}
              disabled={props.disabled}
              onChange={(e) => setUnassociatedFilter(e.target.value)}
            />
          </div>
        </div>

        <div className={styles.list} data-test="unassociated-items-list">
          {unassociatedItems.map((item: Item) => (
            <Fragment key={getItemId(item)}>
              {item.name !== props.parentName && (
                <div
                  data-test="unassociated-items-list-item"
                  className={
                    `${styles.item} px-3 py-2` +
                    ` ${props.selectedItemIds.includes(getItemId(item)) ? styles.selected : ""}`
                  }
                  onClick={() => selectItem(getItemId(item), "unassociated")}
                >
                  <span>{item.name}</span>
                  <span>{validateNickname(item.nickname, item.name) ? ` (${item.nickname})` : ""}</span>
                  <span>
                    {item.emailAddress !== null && item.emailAddress !== undefined && item.emailAddress.length > 0
                      ? ` (${item.emailAddress})`
                      : ""}
                  </span>
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

AssociationContainer.propTypes = {
  type: PropTypes.oneOf(["asset", "assetgroup", "user", "usergroup"]).isRequired,
  associatedItemsTitle: PropTypes.string.isRequired,
  unassociatedItemsTitle: PropTypes.string.isRequired,
  associatedItems: PropTypes.array.isRequired,
  unassociatedItems: PropTypes.array.isRequired,
  itemIdKey: PropTypes.string.isRequired,
  parentName: PropTypes.string,
  selectedItemIds: PropTypes.array.isRequired,
  selectionType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSelectItems: PropTypes.func.isRequired,
  onAssociateItems: PropTypes.func.isRequired,
  onDisassociateItems: PropTypes.func.isRequired,
  onAssociateAllItems: PropTypes.func.isRequired,
  onDisassociateAllItems: PropTypes.func.isRequired,
  onConfirmRoleChange: PropTypes.func.isRequired,
};

interface Props<Type> {
  type: string;
  associatedItemsTitle: string;
  unassociatedItemsTitle: string;
  associatedItems: Type[];
  unassociatedItems: Type[];
  itemIdKey: string;
  parentName?: string;
  selectedItemIds: number[];
  selectionType: string;
  disabled: boolean;
  onSelectItems: (itemIds: number[], selectionType: string) => void;
  onAssociateItems: (selectedItemIds: number[]) => void;
  onDisassociateItems: (selectedItemIds: number[]) => void;
  onAssociateAllItems: () => void;
  onDisassociateAllItems: () => void;
  onConfirmRoleChange: (usergroupId: number, operateAccessPermission: boolean) => void;
}

interface Item {
  itemId: number;
  assetId?: number;
  userId?: number;
  assetgroupId?: number;
  usergroupId?: number;
  name: string;
  operateAccessPermission?: boolean;
  emailAddress?: string;
  nickname?: string;
  productModel?: string;
  productManufacturer?: string;
  deviceLog?: DeviceLog;
}

type DeviceLog = {
  lastUpdated: DeviceLogUnit;
} & {
  [key: string]: DeviceLogUnit;
};

interface DeviceLogUnit {
  code: string;
  name: string;
  value: string | number | boolean;
  latitude?: number;
  longitude?: number;
  unitLong: string;
  unitShort: string;
  unitSymbol: string;
  icon: string;
  isHistorical: boolean;
  sensorConnected?: boolean;
  mostRecentUtc?: string;
}
