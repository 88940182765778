// --------------------------------------------------------------
// Created On: 2024-09-12
// Author: Zachary Thomas
//
// Last Modified: 2024-09-15
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import IconTooltip from "../../../../components/IconTooltip/IconTooltip";
import PropTypes from "prop-types";
import { MAX_REMOTE_OPERATION_ATTRIBUTES } from "../../../../constants/templates";
import { Active, DndContext, Over } from "@dnd-kit/core";
import DroppableArea from "../../../../components/DroppableArea/DroppableArea";
import TemplateAttribute from "../TemplateAttribute/TemplateAttribute";
import styles from "./OperationAttributePage.module.scss";

// Page in wizard for defining operation attributes.
export default function OperationAttributePage(props: Props): Component {
  return (
    <div data-test="operation-attribute-page">
      <div className="mx-1 my-2">
        <label className={styles.title}>
          <span className="ms-3 me-2">Remote Operation Attributes</span>
          <IconTooltip
            id="operation-attribute-tooltip"
            icon="info-circle"
            message={`When viewing an asset's remote operation page, these attributes will appear in a list on the page.
                      There is a limit of ${MAX_REMOTE_OPERATION_ATTRIBUTES} remote operation attributes.`}
            color="var(--info-tooltip)"
          />
          <span className="me-2" />
          <IconTooltip
            id="remote-operation-attribute-image-tooltip"
            icon="picture-o"
            imageUrl="/images/template_operation_example.png"
            imageAlt="Remote operation attributes example."
            imageMaxWidth={1000}
            color="var(--info-tooltip-image)"
          />
        </label>

        <button
          data-test="add-operation-attribute-button"
          type="submit"
          className={`${styles.addButton} btn btn-success float-end`}
          onClick={() => props.onCreate()}
          disabled={props.disabled || props.operationAttributes.length >= MAX_REMOTE_OPERATION_ATTRIBUTES}
        >
          <span className="d-none d-sm-inline">Add Attribute</span>
          <i className="d-inline d-sm-none fa fa-fw fa-plus fa-xs" />
        </button>
      </div>

      {props.operationAttributes.length > 0 && (
        <DndContext onDragEnd={(e) => props.onDragEnd(e.over, e.active)}>
          <DroppableArea id={0} disabled={props.disabled} isSlim />
          {props.operationAttributes.map((attribute, i) => (
            <Fragment key={attribute.formId}>
              <TemplateAttribute
                key={attribute.formId}
                formIndex={i}
                formId={attribute.formId}
                name={attribute.attributeName}
                disabled={props.disabled}
                onChange={(name) => {
                  props.onChange(attribute.formId, name);
                }}
                onDelete={() => props.onDelete(attribute.formId)}
                onDragEnd={(over, active) => props.onDragEnd(over, active)}
              />
              <DroppableArea id={attribute.formId} disabled={props.disabled} isSlim />
            </Fragment>
          ))}
        </DndContext>
      )}
    </div>
  );
}

OperationAttributePage.propTypes = {
  operationAttributes: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onCreate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
};

interface Props {
  operationAttributes: Attribute[];
  disabled?: boolean;
  onCreate: () => void;
  onChange: (formId: number, name: string) => void;
  onDelete: (formId: number) => void;
  onDragEnd: (over: Over | null, active: Active) => void;
}

interface Attribute {
  formId: number;
  regAttributeId: number;
  attributeName: string;
  unitId: number | null;
  unitShortName: string | null;
  unitLongName: string | null;
}
