// --------------------------------------------------------------
// Created On: 2022-09-20
// Author: Zachary Thomas
//
// Last Modified: 2022-09-20
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";

// Explains what a user is.
export default function HelpUser(): Component {
  return (
    <div>
      <p className="px-3">
        Each user has an email address associated with their account, a name, and a role. The
        user&apos;s role determines what data they can see and what actions they can perform.
      </p>
    </div>
  );
}
