// --------------------------------------------------------------
// Created On: 2024-11-05
// Author: Zachary Thomas
//
// Last Modified: 2024-11-05
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import styles from "./FlowSkidVisual.module.scss";

// A data visualization for a flow skid.
export default function FlowSkidVisual(): Component {
  return (
    <div className="row g-0">
      <div className={`${styles.svgContent} col-12 col-lg-6`}>
        <img className={styles.base} src="/productVisuals/flowSkid/flowSkid.svg" alt="Flow Skid Data Visualization" />
      </div>
    </div>
  );
}
