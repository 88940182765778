// --------------------------------------------------------------
// Created On: 2022-06-16
// Author: Zachary Thomas
//
// Last Modified: 2022-09-01
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import { MS_PER_MINUTE, MS_PER_DAY } from "../../constants/miscellaneous";

// Get the current time (plus an optional offset) and return an ISO string.
export default function nowToLocalIso(daysOffset: number): string {
  const currentDate = new Date();
  const timeZoneOffset = currentDate.getTimezoneOffset();
  const localCurrentDate = new Date(
    currentDate.getTime() - timeZoneOffset * MS_PER_MINUTE + daysOffset * MS_PER_DAY
  );
  return localCurrentDate.toISOString();
}
