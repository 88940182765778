// --------------------------------------------------------------
// Created On: 2021-09-01
// Author: Zachary Thomas
//
// Last Modified: 2024-05-08
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import styles from "./CsvDownloadButton.module.scss";

// Button that downloads a CSV file.
export default function CsvDownloadButton(props: Props): Component {
  return (
    <CSVLink data={props.data} className={styles.body} filename={props.fileName}>
      {props.children}
    </CSVLink>
  );
}

CsvDownloadButton.propTypes = {
  fileName: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  children: PropTypes.any,
};

interface Props {
  fileName: string;
  data: string[][];
  children: string;
}
