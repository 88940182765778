// --------------------------------------------------------------
// Created On: 2021-09-30
// Author: Zachary Thomas
//
// Last Modified: 2023-03-12
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./Warning.module.scss";

// Warning message.
export default function Warning(props: Props): Component {
  return props.message.length > 0 ? (
    <div className={`${styles.body} alert alert-warning`} role="alert">
      {props.message}
    </div>
  ) : null;
}

Warning.propTypes = {
  message: PropTypes.string.isRequired,
};

interface Props {
  message: string;
}
