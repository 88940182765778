// --------------------------------------------------------------
// Created On: 2022-09-14
// Author: Zachary Thomas
//
// Last Modified: 2024-09-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import deepCopy from "./deepCopy";

// Smooth time data series.
export default function smoothTimeDataSeries(timeDataSeries: TimeDataSeries): TimeDataSeries {
  const timeDataSeriesDeepCopy = deepCopy(timeDataSeries);

  // Get the values from the time data series.
  const values: number[] = [];
  timeDataSeriesDeepCopy.data.forEach((tuple) => {
    values.push(Number(tuple[1]));
  });

  // Use a moving average to smooth the data. The length of each average is based on the length of the overall data.
  if (values.length >= 10) {
    // Get the moving average points. This number should always be odd and greater than one.
    let points = Math.ceil(values.length * 0.02);
    if (points % 2 === 0) {
      points++;
    }
    if (points <= 1) {
      points = 3;
    }

    const halfPoints = (points - 1) / 2;
    const smoothedValues: number[] = [];

    // Get all of the values that we can average (middle values).
    for (let i = halfPoints; i >= halfPoints && i < values.length - halfPoints; i++) {
      let mean = values[i];

      // Calculate the lower mean.
      for (let j = 1; j <= halfPoints; j++) {
        mean += values[i - j];
      }

      // Calculate the upper mean.
      for (let j = 1; j <= halfPoints; j++) {
        mean += values[i + j];
      }

      mean /= points;
      smoothedValues.push(parseFloat(mean.toFixed(4)));
    }

    // Set starting values as first mean.
    const firstMean = smoothedValues[0];
    for (let i = 0; i < halfPoints; i++) {
      smoothedValues.unshift(firstMean);
    }

    // Set ending values as last mean.
    const lastMean = smoothedValues[smoothedValues.length - 1];
    for (let i = values.length - halfPoints; i < values.length; i++) {
      smoothedValues.push(lastMean);
    }

    // Update time data series with new smoothed values.
    timeDataSeriesDeepCopy.data.forEach((tuple, i) => {
      tuple[1] = smoothedValues[i];
    });
  }

  return timeDataSeriesDeepCopy;
}

interface TimeDataSeries {
  name: string;
  unitName: string;
  unitShortName: string;
  data: [number, number][];
}
