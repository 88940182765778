// --------------------------------------------------------------
// Created On: 2023-02-09
// Author: Zachary Thomas
//
// Last Modified: 2024-04-17
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./ReportAttributeForm.module.scss";

// Form for assigning attributes to a report.
export default function ReportAttributeForm(props: Props): Component {
  return (
    <div className="my-3 mx-1">
      <div className="row align-items-center">
        <div className="col-4 col-lg-3 col-xl-2">
          <label className={styles.title}>Attribute #{props.formIndex + 1}:</label>
        </div>
        <div className="col-5 col-sm-6 col-lg-8 col-xl-9">
          <input
            data-test="report-attribute-form-input"
            className="form-control mx-auto"
            type="text"
            list="datalist-attributes"
            autoComplete="off"
            value={props.name}
            disabled={props.disabled}
            onChange={(e) => props.onChange(props.formId, e.target.value)}
          />
        </div>

        <div className="col-3 col-sm-2 col-lg-1">
          <button
            type="button"
            className={`${styles.button} btn btn-danger float-end`}
            disabled={props.disabled}
            onClick={() => props.onDelete(props.formId)}
          >
            <i className="fa fa-fw fa-times fa-xs" />
          </button>
        </div>
      </div>
    </div>
  );
}

ReportAttributeForm.propTypes = {
  formIndex: PropTypes.number.isRequired,
  formId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  attributes: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

interface Props {
  formIndex: number;
  formId: number;
  name: string;
  attributes: SimpleAttribute[];
  disabled: boolean;
  onChange: (formId: number, name: string) => void;
  onDelete: (formId: number) => void;
}
