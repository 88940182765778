// --------------------------------------------------------------
// Created On: 2021-12-03
// Author: Zachary Thomas
//
// Last Modified: 2024-12-03
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import styles from "./ValidateAssetButton.module.scss";
import ValidateAssetModal from "./ValidateAssetModal/ValidateAssetModal";

// When clicked, this button pulls up the validation modal for the specified asset.
export default function ValidateAssetButton(props: Props): Component {
  const [showValidationModal, setShowValidationModal] = useState<boolean>(false);
  return (
    <Fragment>
      <button
        data-test="validate-asset-button"
        className={`${props.smallButton ? styles.smallBody : styles.body} btn btn-primary ${props.className}`}
        type="button"
        disabled={props.disabled || props.assetId === null}
        onClick={() => setShowValidationModal(true)}
      >
        Validate
      </button>
      {showValidationModal && (
        <ValidateAssetModal assetId={props.assetId} onClose={() => setShowValidationModal(false)} />
      )}
    </Fragment>
  );
}

ValidateAssetButton.propTypes = {
  assetId: PropTypes.number,
  smallButton: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

interface Props {
  assetId: number | null;
  smallButton?: boolean;
  disabled?: boolean;
  className?: string;
}
