// --------------------------------------------------------------
// Created On: 2024-08-21
// Author: Hannah Vaughan
//
// Last Modified: 2024-10-21
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

export const CONFIG_DATA_TYPES = [
  {
    name: "Byte",
    value: 1,
  },
  {
    name: "Word",
    value: 2,
  },
  {
    name: "Inverted Word",
    value: 3,
  },
  {
    name: "Long",
    value: 4,
  },
  {
    name: "Float",
    value: 5,
  },
  {
    name: "Coil",
    value: 6,
  },
  {
    name: "Calc Field",
    value: 7,
  },
  {
    name: "Word Func04",
    value: 8,
  },
  {
    name: "Long Func04",
    value: 9,
  },
  {
    name: "Coil Func02",
    value: 10,
  },
  {
    name: "Float Func04",
    value: 11,
  },
  {
    name: "Equation String",
    value: 253,
  },
  {
    name: "Equation",
    value: 254,
  },
  {
    name: "String",
    value: 255,
  },
];
export const CONFIG_BYTE_ORDERS = [
  {
    name: "Standard",
    value: 0,
  },
  {
    name: "Word Inverted",
    value: 1,
  },
  {
    name: "Byte Inverted",
    value: 2,
  },
  {
    name: "All Inverted",
    value: 3,
  },
];
export const GENERAL_CONFIG_PAGE = "General Config";
export const PUMP_SAFETY_CONFIG_PAGE = "Pump Safety Config";
export const AUTO_CONFIG_PAGE = "Auto Config";
export const SYSTEM_SETTINGS_CATEGORY = "System Settings";
export const PUMP_PARAMETERS_CATEGORY = "Pump Parameters";
export const THROTTLE_SETTINGS_CATEGORY = "Throttle Settings";
export const CAN_SETTINGS_CATEGORY = "CAN Settings";
export const MODBUS_SETTINGS_CATEGORY = "MODBUS Settings";
export const PUMP_SAFETY_PARAMETERS_CATEGORY = "Pump Safety Parameters";
export const AUTO_OPERATION_PARAMETERS_CATEGORY = "Auto Operation Parameters";
export const AUTO_START_PARAMETERS_CATEGORY = "Auto Start Parameters";
