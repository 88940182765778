// --------------------------------------------------------------
// Created On: 2022-05-06
// Author: Zachary Thomas
//
// Last Modified: 2024-08-20
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import { DOMAIN } from "../../../constants/miscellaneous";
import styles from "./Document.module.scss";

// A single viewable and downloadable document.
export default function Document(props: Props): Component {
  return (
    <div className={`${styles.document} row text-start py-2 mx-2`}>
      <div className="col my-auto">{props.name}</div>

      <div className="col-auto">
        <a href={`${DOMAIN}/${props.endpoint}`} download>
          <button type="button" className={`${styles.btn} btn btn-info pull-right`}>
            <span>Download PDF</span>
            <i className="fa fa-fw fa-arrow-down" />
          </button>
        </a>
      </div>
    </div>
  );
}

Document.propTypes = {
  name: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
};

interface Props {
  name: string;
  endpoint: string;
}
