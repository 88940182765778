// --------------------------------------------------------------
// Created On: 2023-02-09
// Author: Zachary Thomas
//
// Last Modified: 2024-04-18
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./ReportAssetgroupForm.module.scss";

// Form for assigning asset groups to a report.
export default function ReportAssetgroupForm(props: Props): Component {
  return (
    <div className="my-3 mx-1">
      <div className="row align-items-center my-2">
        <div className="col-4 col-lg-3 col-xl-2">
          <label className={styles.title}>Asset Group #{props.formIndex + 1}:</label>
        </div>
        <div className="col-5 col-sm-6 col-lg-8 col-xl-9">
          <input
            data-test="report-asset-group-input"
            className="form-control mx-auto"
            type="text"
            list="datalist-assetgroups"
            autoComplete="off"
            value={props.name}
            disabled={props.disabled}
            onChange={(e) => props.onChange(props.formId, e.target.value)}
          />
        </div>

        <div className="col-3 col-sm-2 col-lg-1">
          <button
            type="button"
            className={`${styles.button} btn btn-danger float-end`}
            disabled={props.disabled}
            onClick={() => props.onDelete(props.formId)}
          >
            <i className="fa fa-fw fa-times fa-xs" />
          </button>
        </div>
      </div>
    </div>
  );
}

ReportAssetgroupForm.propTypes = {
  formIndex: PropTypes.number.isRequired,
  formId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  assetgroups: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

interface Props {
  formIndex: number;
  formId: number;
  name: string;
  assetgroups: ReportAssetgroup[];
  disabled: boolean;
  onChange: (formId: number, name: string) => void;
  onDelete: (formId: number) => void;
}
