// --------------------------------------------------------------
// Created On: 2024-08-22
// Author: Hannah Vaughan
//
// Last Modified: 2024-10-29
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useMemo } from "react";
import ControllerConfigurationsTable from "./ControllerConfigurationsTable/ControllerConfigurationsTable";
import CategoryContainer from "./CategoryContainer/CategoryContainer";
import PropTypes from "prop-types";

// The container that holds all of the different categories that exist for a specific configuration page.
export default function ControllerPageWrapper(props: Props): Component {
  const [multipleCategoriesPresent, categorySplitIntoNewColumnIndex] = useMemo(
    () => getCategoryArrangementDetails(),
    [JSON.stringify(props.categories)]
  );

  // Gets information that can be used to properly arrange categories.
  function getCategoryArrangementDetails(): [boolean, number | null] {
    const categoryCount = props.categories.length;
    let multipleCategoriesPresent = false;
    let categorySplitIntoNewColumnIndex: number | null = null;
    if (categoryCount > 1) {
      multipleCategoriesPresent = true;
    }
    if (multipleCategoriesPresent) {
      categorySplitIntoNewColumnIndex = Math.ceil(categoryCount / 2);
    }
    return [multipleCategoriesPresent, categorySplitIntoNewColumnIndex];
  }

  // Checks if the provided array of controller configurations have units.
  function controllerConfigurationHasUnits(controllerConfigurations: ControllerConfiguration[]): boolean {
    return controllerConfigurations.filter((controllerConfig) => controllerConfig.units === "").length === 0;
  }

  /// Gets information that can be used to properly arrange controller configurations.
  function getConfigSplitIntoNewColumnIndex(
    category: Category | null,
    multipleCategoriesPresent: boolean
  ): number | null {
    let controllerConfigurationsSplitIntoNewColumnIndex: number | null = null;
    if (!multipleCategoriesPresent && category !== null && category.controllerConfigurations.length > 1) {
      controllerConfigurationsSplitIntoNewColumnIndex = Math.ceil(category.controllerConfigurations.length / 2);
    }
    return controllerConfigurationsSplitIntoNewColumnIndex;
  }

  // Filter a category's controller configs based on what column they should belong to.
  function getAllConfigsInSpecificColumn(
    category: Category | null,
    multipleCategoriesPresent: boolean,
    isInFirstColumn: boolean
  ): ControllerConfiguration[] {
    let filteredControllerConfigurations: ControllerConfiguration[] = [];
    if (category !== null) {
      filteredControllerConfigurations = category.controllerConfigurations.filter((controllerConfiguration, i) => {
        const splitIntoNewColumnIndex = getConfigSplitIntoNewColumnIndex(category, multipleCategoriesPresent);
        if (isInFirstColumn) {
          return splitIntoNewColumnIndex === null || i < splitIntoNewColumnIndex;
        } else {
          return splitIntoNewColumnIndex !== null && i >= splitIntoNewColumnIndex;
        }
      });
    }
    return filteredControllerConfigurations;
  }

  return (
    <div className="row">
      {/* This first column of categories is always displayed. */}
      <div className="col">
        {props.categories.map((category, i) => (
          <Fragment key={category.categoryName.replace(/\s/g, "")}>
            {(categorySplitIntoNewColumnIndex === null || i < categorySplitIntoNewColumnIndex) && (
              <CategoryContainer
                category={category.categoryName}
                tooltip={category.tooltip}
                loading={props.loading}
                onReadCategory={(categoryName) => props.onReadCategory(categoryName)}
              >
                <div className="row g-0">
                  {/*
                    If we only have one category and multiple controller configurations, split them into two columns.
                  */}
                  <div
                    className={
                      getConfigSplitIntoNewColumnIndex(category, multipleCategoriesPresent) === null
                        ? "col-12"
                        : "col-12 col-lg-6"
                    }
                  >
                    <ControllerConfigurationsTable
                      hasUnits={controllerConfigurationHasUnits(category.controllerConfigurations)}
                      controllerConfigurations={getAllConfigsInSpecificColumn(
                        category,
                        multipleCategoriesPresent,
                        true
                      )}
                      loading={props.loading}
                      onChangeControllerConfig={(controllerConfigurationId, value) =>
                        props.onChangeControllerConfig(category.categoryName, controllerConfigurationId, value)
                      }
                      onWriteControllerConfig={(controllerConfigurationId, value) =>
                        props.onWriteControllerConfig(category.categoryName, controllerConfigurationId, value)
                      }
                    />
                  </div>
                  {getConfigSplitIntoNewColumnIndex(category, multipleCategoriesPresent) !== null && (
                    <div className="col-12 col-lg-6">
                      <ControllerConfigurationsTable
                        hasUnits={controllerConfigurationHasUnits(category.controllerConfigurations)}
                        controllerConfigurations={getAllConfigsInSpecificColumn(
                          category,
                          multipleCategoriesPresent,
                          false
                        )}
                        loading={props.loading}
                        hideHeaderBreakpoint="lg"
                        onChangeControllerConfig={(controllerConfigurationId, value) =>
                          props.onChangeControllerConfig(category.categoryName, controllerConfigurationId, value)
                        }
                        onWriteControllerConfig={(controllerConfigurationId, value) =>
                          props.onWriteControllerConfig(category.categoryName, controllerConfigurationId, value)
                        }
                      />
                    </div>
                  )}
                </div>
              </CategoryContainer>
            )}
          </Fragment>
        ))}
      </div>
      {/* This second column of categories is only displayed if we have enough categories to show in two columns. */}
      {multipleCategoriesPresent && (
        <div className="col">
          {props.categories.map((category, i) => (
            <Fragment key={category.categoryName.replace(/\s/g, "")}>
              {categorySplitIntoNewColumnIndex !== null && i >= categorySplitIntoNewColumnIndex && (
                <CategoryContainer
                  key={category.categoryName.replace(/\s/g, "")}
                  category={category.categoryName}
                  tooltip={category.tooltip}
                  loading={props.loading}
                  onReadCategory={(categoryName) => props.onReadCategory(categoryName)}
                >
                  <ControllerConfigurationsTable
                    hasUnits={controllerConfigurationHasUnits(category.controllerConfigurations)}
                    controllerConfigurations={category.controllerConfigurations}
                    loading={props.loading}
                    onChangeControllerConfig={(controllerConfigurationId, value) =>
                      props.onChangeControllerConfig(category.categoryName, controllerConfigurationId, value)
                    }
                    onWriteControllerConfig={(controllerConfigurationId, value) =>
                      props.onWriteControllerConfig(category.categoryName, controllerConfigurationId, value)
                    }
                  />
                </CategoryContainer>
              )}
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
}

ControllerPageWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onReadCategory: PropTypes.func.isRequired,
  onChangeControllerConfig: PropTypes.func.isRequired,
  onWriteControllerConfig: PropTypes.func.isRequired,
};

interface Props {
  id: string; // Lower camel case naming of current page for distinguishing page accordions.
  page: string; // Name of config page.
  categories: Category[]; // All categories present in the current config page.
  loading: boolean;
  onReadCategory: (categoryName: string) => void;
  onChangeControllerConfig: (categoryName: string, controllerConfigurationId: number, value: string) => void;
  onWriteControllerConfig: (categoryName: string, controllerConfigurationId: number, value: string) => void;
}

interface Category {
  categoryName: string;
  tooltip: string;
  controllerConfigurations: ControllerConfiguration[];
}

interface ControllerConfiguration {
  controllerConfigurationId: number;
  name: string;
  tooltip: string;
  startAddress: string;
  units: string | null;
  value: string | number | null;
  previousValue: string | number | null;
}
