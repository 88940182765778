// --------------------------------------------------------------
// Created On: 2022-09-20
// Author: Zachary Thomas
//
// Last Modified: 2023-12-26
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";

// Explains what a pending transfer request is.
export default function HelpTransferReceive(): Component {
  return (
    <div>
      <p className="px-3 my-2">
        These are assets that are actively being transferred to your company. You will have the option to accept or
        reject the transfer. If you accept the request your company will gain complete ownership of the asset and any
        attached monitoring device.
      </p>
    </div>
  );
}
