// --------------------------------------------------------------
// Created On: 2024-03-18
// Author: Zachary Thomas
//
// Last Modified: 2024-03-18
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import { useEffect, useState } from "react";

// Hook for checking the size of the current window.
export default function useWindowDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  // Listen for changes in the windows size.
  useEffect(() => {
    function handleResize(): void {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

// Get the current width and height from the window.
function getWindowDimensions(): WindowDimensions {
  const { innerWidth: width, innerHeight: height } = window;
  return { width: width, height: height };
}

interface WindowDimensions {
  width: number;
  height: number;
}
