// --------------------------------------------------------------
// Created On: 2023-02-21
// Author: Zachary Thomas
//
// Last Modified: 2024-07-22
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useMemo, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./MapHighlightingRuleForm.module.scss";

// Form for assigning a map highlighting rule (attribute and conditions, not color).
export default function MapHighlightingRuleForm(props: Props): Component {
  const [primaryValue, setPrimaryValue] = useState<string>("");
  const [secondaryValue, setSecondaryValue] = useState<string>("");
  const selectedAttribute = useMemo(
    () => getAttribute(props.name, props.attributes),
    [props.name, JSON.stringify(props.attributes)]
  );
  const units = selectedAttribute !== null && selectedAttribute.units !== null ? selectedAttribute.units : "";
  const attributeIsBoolean = selectedAttribute !== null ? selectedAttribute.isBoolean : false;

  // When a new value is passed in, split it between the primary and secondary value if needed.
  useEffect(() => {
    const values = props.value.split(",");
    if (values.length === 1) {
      setPrimaryValue(values[0]);
      setSecondaryValue("");
    } else if (values.length > 1) {
      setPrimaryValue(values[0]);
      setSecondaryValue(values[1]);
    }
  }, [props.value]);

  // Returns the named attribute, if it exists.
  function getAttribute(name: string, attributes: ViewAttribute[]): ViewAttribute | null {
    const selectedAttribute = attributes.find((attribute) => attribute.name.toLowerCase() === name.toLowerCase());
    // An attribute has to support highlighting (float, integer, or binary) to be selected.
    if (selectedAttribute === undefined || !selectedAttribute.isHighlightable) {
      return null;
    } else {
      return selectedAttribute;
    }
  }

  // Send updated settings to the parent component.
  function updateSettings(
    name: string,
    nameChanged: boolean,
    comparator: ViewComparator,
    valueType: ViewValueType,
    primaryValue: string,
    secondaryValue: string
  ): void {
    // If there is a valid secondary value, combine it into a single string with the primary value.
    let value = primaryValue;
    if (secondaryValue.length > 0) {
      value = `${primaryValue},${secondaryValue}`;
    }
    // Send changes to parent component.
    props.onChange(name, nameChanged, comparator, valueType, value);
  }

  return (
    <div data-test="view-map-highlight-rule-block" className="mx-0 px-0 my-3">
      <div data-test="highlighting-rule-form-container" className={`${styles.body} row align-items-center`}>
        <div className="col-auto">{props.formIndex === 0 ? "if" : "&"} </div>

        <div className="col-auto">
          <input
            data-test="map-attribute-select-input"
            className={`${styles.attributeInput} form-control`}
            type="text"
            list="datalist-attributes"
            autoComplete="off"
            value={props.name}
            disabled={props.disabled}
            onChange={(e) =>
              updateSettings(e.target.value, true, props.comparator, props.valueType, primaryValue, secondaryValue)
            }
          />
        </div>

        {attributeIsBoolean ? (
          <div className="col-auto py-2 mx-0">{units.length === 0 ? "" : units.toLowerCase()} is equal to </div>
        ) : (
          <Fragment>
            {props.valueType === "MAX" || props.valueType === "MIN" ? (
              <div className="col-auto py-2 mx-0">
                <label className={styles.title}>{units.length === 0 ? "" : units.toLowerCase()} is equal to the </label>
              </div>
            ) : (
              <Fragment>
                <div className="col-auto py-2 mx-0">
                  <label className={styles.title}>{units.length === 0 ? "" : units.toLowerCase()} is </label>
                </div>

                <div className="col-auto py-2 mx-0">
                  <select
                    className="form-select"
                    value={props.comparator}
                    disabled={props.disabled}
                    onChange={(e) =>
                      updateSettings(
                        props.name,
                        false,
                        e.target.value as ViewComparator,
                        props.valueType,
                        primaryValue,
                        secondaryValue
                      )
                    }
                  >
                    <option value=">">
                      {" "}
                      {props.valueType === "STANDARD_DEVIATION" ? "outside of" : "greater than"}{" "}
                    </option>
                    <option value="<"> {props.valueType === "STANDARD_DEVIATION" ? "inside of" : "less than"} </option>
                    {props.valueType === "NUMBER" && <option value="<>"> between </option>}
                    {props.valueType !== "STANDARD_DEVIATION" && <option value="="> equal to </option>}
                  </select>
                </div>

                <div className="col-auto py-2 mx-0">
                  <label className={styles.title}> the </label>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}

        {props.valueType === "STANDARD_DEVIATION" && (
          <div className="col-auto py-2 mx-0">
            <select
              className="form-select"
              value={primaryValue}
              disabled={props.disabled}
              onChange={(e) =>
                updateSettings(props.name, false, props.comparator, props.valueType, e.target.value, secondaryValue)
              }
            >
              <option value="1"> first </option>
              <option value="2"> second </option>
              <option value="3"> third </option>
            </select>
          </div>
        )}

        {!attributeIsBoolean && (
          <div className="col-auto py-2 mx-0">
            <select
              className="form-select"
              value={props.valueType}
              disabled={props.disabled}
              onChange={(e) =>
                updateSettings(
                  props.name,
                  false,
                  props.comparator,
                  e.target.value as ViewValueType,
                  primaryValue,
                  secondaryValue
                )
              }
            >
              <option value="NUMBER">number</option>
              <option value="MEDIAN">median</option>
              <option value="MEAN">mean</option>
              <option value="STANDARD_DEVIATION">standard deviation</option>
              <option value="MAX">maximum</option>
              <option value="MIN">minimum</option>
            </select>
          </div>
        )}

        {props.valueType === "NUMBER" && (
          <Fragment>
            {attributeIsBoolean ? (
              <div className="col-2 py-2 mx-0">
                <select
                  className="form-select"
                  value={primaryValue}
                  disabled={props.disabled}
                  onChange={(e) =>
                    updateSettings(props.name, false, props.comparator, props.valueType, e.target.value, secondaryValue)
                  }
                >
                  <option value="0">False</option>
                  <option value="1">True</option>
                </select>
              </div>
            ) : (
              <div className="col-3 col-lg-1 py-2 mx-0">
                <input
                  className="form-control"
                  type="text"
                  value={primaryValue}
                  disabled={props.disabled}
                  onChange={(e) =>
                    updateSettings(props.name, false, props.comparator, props.valueType, e.target.value, secondaryValue)
                  }
                />
              </div>
            )}
          </Fragment>
        )}

        {props.valueType === "NUMBER" && props.comparator === "<>" && (
          <Fragment>
            <div className="col-auto py-2 mx-0">
              <label className={styles.title}> and </label>
            </div>
            <div className="col-3 col-lg-1 py-2 mx-0">
              <input
                className="form-control"
                type="text"
                value={secondaryValue}
                disabled={props.disabled}
                onChange={(e) =>
                  updateSettings(props.name, false, props.comparator, props.valueType, primaryValue, e.target.value)
                }
              />
            </div>
          </Fragment>
        )}

        {props.allowDelete && (
          <div className="col">
            <button
              type="button"
              className={`${styles.button} btn btn-danger mx-0 float-end`}
              disabled={props.disabled}
              onClick={() => props.onDelete()}
            >
              <i className="fa fa-fw fa-times fa-xs" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

MapHighlightingRuleForm.propTypes = {
  formIndex: PropTypes.number.isRequired,
  formId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  comparator: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  valueType: PropTypes.string.isRequired,
  attributes: PropTypes.array.isRequired,
  allowDelete: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

interface Props {
  formIndex: number;
  formId: number;
  attributeId: number;
  name: string;
  comparator: ViewComparator;
  value: string;
  valueType: ViewValueType;
  attributes: ViewAttribute[];
  allowDelete: boolean;
  disabled?: boolean;
  onChange: (
    name: string,
    nameChanged: boolean,
    comparator: ViewComparator,
    valueType: ViewValueType,
    value: string
  ) => void;
  onDelete: () => void;
}

interface ViewAttribute {
  attributeId: number;
  name: string;
  code: string;
  units: string | null;
  isBoolean: boolean;
  isHighlightable: boolean;
}

type ViewValueType = "NUMBER" | "MEDIAN" | "MEAN" | "STANDARD_DEVIATION" | "MAX" | "MIN";

type ViewComparator = "=" | "!=" | "<" | ">" | "<>";
