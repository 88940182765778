// --------------------------------------------------------------
// Created On: 2024-09-14
// Author: Zachary Thomas
//
// Last Modified: 2024-09-17
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import { Active, Over, useDraggable } from "@dnd-kit/core";
import styles from "./Gauge.module.scss";

// Form for a single template attribute.
export default function Gauge(props: Props): Component {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.formId,
  });

  return (
    <div className="row align-items-center">
      <div className="col-auto ge-0 pe-0">{props.formIndex + 1}.</div>
      <div className="col g-0">
        <div
          data-test="gauge-attribute-container"
          className="container m-0 py-0"
          ref={setNodeRef}
          style={
            transform
              ? {
                  transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
                }
              : undefined
          }
        >
          <div className={`${styles.body} row p-0 m-0`}>
            <div className={`${styles.draggable} col-auto p-0 m-0`} {...attributes} {...listeners} />
            <div className="col py-2 pe-2">
              <div className="row align-items-center mb-2">
                {/* Input field for selecting attribute. */}
                <div className="col-auto">
                  <label className={styles.title}>Attribute</label>
                </div>
                <div className="col">
                  <input
                    data-test="gauge-attribute-select-input"
                    className="form-control mx-auto"
                    type="text"
                    list="datalist-attributes-gauges"
                    autoComplete="off"
                    value={props.name}
                    disabled={props.disabled}
                    onChange={(e) => props.onChange(e.target.value, String(props.min), String(props.max))}
                  />
                </div>
                {/* Button for deleting this gauge. */}
                <div className="col-auto">
                  <button
                    data-test="delete-attribute-button"
                    type="button"
                    className={`${styles.button} btn btn-danger float-end`}
                    disabled={props.disabled}
                    onClick={() => props.onDelete()}
                  >
                    <i className="fa fa-fw fa-times fa-xs" />
                  </button>
                </div>
              </div>
              <div className="row align-items-center">
                {/* Min gauge value. */}
                <div className="col-auto">
                  <label className={styles.title}>
                    Min {props.unitShortName === "" || props.unitShortName === null ? "" : `(${props.unitShortName}) `}
                  </label>
                </div>
                <div className="col">
                  <input
                    data-test="gauge-min-input"
                    className="form-control mx-auto"
                    type="text"
                    value={props.min}
                    disabled={props.disabled}
                    onChange={(e) => props.onChange(props.name, e.target.value, String(props.max))}
                  />
                </div>
                {/* Max gauge value. */}
                <div className="col-auto">
                  <label className={styles.title}>
                    Max {props.unitShortName === "" || props.unitShortName === null ? "" : `(${props.unitShortName}) `}
                  </label>
                </div>
                <div className="col">
                  <input
                    data-test="gauge-max-input"
                    className="form-control mx-auto"
                    type="text"
                    value={props.max}
                    disabled={props.disabled}
                    onChange={(e) => props.onChange(props.name, String(props.min), e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Gauge.propTypes = {
  formIndex: PropTypes.number.isRequired,
  formId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  unitShortName: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
};

interface Props {
  formIndex: number;
  formId: number;
  name: string;
  min: string | number;
  max: string | number;
  unitShortName: string | null;
  disabled?: boolean;
  onChange: (name: string, min: string, max: string) => void;
  onDelete: () => void;
  onDragEnd: (over: Over | null, active: Active) => void;
}
