// --------------------------------------------------------------
// Created On: 2022-10-31
// Author: Zachary Thomas
//
// Last Modified: 2023-11-09
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import isoToLocalDate from "./isoToLocalDate";
import { MS_PER_MINUTE, MAX_MINUTES_FOR_ACCURATE_READING } from "../../constants/miscellaneous";

// Check if an ISO UTC string is considered stale. We consider a value stale when
// it is older than our constant MAX_MINUTES_FOR_ACCURATE_READING.
export default function isoUtcIsStale(isoUtcTime: string | null): boolean {
  if (isoUtcTime === null) {
    return true;
  } else {
    const nowDate = new Date();
    const localDate = isoToLocalDate(isoUtcTime);
    return (
      (nowDate.getTime() - localDate.getTime()) / MS_PER_MINUTE > MAX_MINUTES_FOR_ACCURATE_READING
    );
  }
}
