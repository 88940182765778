// --------------------------------------------------------------
// Created On: 2024-08-21
// Author: KC Willard
//
// Last Modified: 2024-09-11
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./TemplateListRow.module.scss";

// A single template in a list of templates.
export default function TemplateListRow(props: Props): Component {
  return (
    <div className="p-2" data-test="template-list-row">
      <span>{props.name}</span>
      {props.isImmutable && <span className={styles.tag}>Default</span>}
    </div>
  );
}

TemplateListRow.propTypes = {
  assetTemplateId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isImmutable: PropTypes.bool.isRequired,
};

interface Props {
  assetTemplateId: number;
  name: string;
  isImmutable: boolean;
}
