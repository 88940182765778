// --------------------------------------------------------------
// Created On: 2022-09-20
// Author: Zachary Thomas
//
// Last Modified: 2024-02-02
// Modified By: Zachary Thomas
//
// Copyright 2022 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useEffect, useState } from "react";
import { NAVBAR_HOVER_DROPDOWN_DELAY } from "../../constants/miscellaneous";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./NavbarDropdown.module.scss";

// A dropdown set of clickable link on a navbar.
export default function NavbarDropdown(props: Props): Component {
  const [targetPage, setTargetPage] = useState<string | undefined | null>("");
  const [recentlyClicked, setRecentlyClicked] = useState<boolean>(false);

  // Treat partial matches as complete matches, unless exact is enabled.
  useEffect(() => {
    if (props.exactMatch) {
      setTargetPage(props.targetPage);
    } else {
      if (props.targetPage === undefined || props.targetPage === null) {
        setTargetPage(props.currentPage);
      } else if (props.targetPage !== null && props.targetPage !== undefined) {
        const targetPageArray = props.targetPage.split("/");
        const currentPageArray = props.currentPage.split("/");

        let pathMatch = true;
        currentPageArray.forEach((subdirectory, i) => {
          if (targetPageArray[i] !== subdirectory) {
            pathMatch = false;
          }
        });

        if (pathMatch) {
          setTargetPage(props.currentPage);
        } else {
          setTargetPage(props.targetPage);
        }
      }
    }
  }, [props.currentPage, props.targetPage, props.exactMatch]);

  // Update the recently clicked state. This allows us to close a hover activated dropdown when it is clicked.
  useEffect(() => {
    if (recentlyClicked) {
      const newTimerId = setTimeout(() => setRecentlyClicked(false), NAVBAR_HOVER_DROPDOWN_DELAY);

      return () => {
        clearTimeout(newTimerId);
      };
    }
  }, [recentlyClicked]);

  // Close the current dropdown.
  function closeDropdown(): void {
    setRecentlyClicked(true);
  }

  return (
    <div className={`${styles.dropdownContainer} dropdown`}>
      <li className={`${styles.item} mx-3 ${targetPage === props.currentPage ? styles.active : ""}`}>
        <Link to={targetPage ? targetPage : props.currentPage} className="router-link">
          <div className={styles.link} onClick={() => closeDropdown()}>
            <span>{props.name}</span>
          </div>
        </Link>
        <div
          className={`${styles.dropdownMenu} ${!recentlyClicked ? styles.ready : ""} dropdown-menu dropdown-menu-end`}
          onClick={() => setRecentlyClicked(true)}
        >
          {props.menuItems.map((menuItem) => (
            <Link key={menuItem.name} to={menuItem.targetPage} className="router-link">
              <div className={`${styles.dropdownItem} dropdown-item`} onClick={() => closeDropdown()}>
                {menuItem.name}
              </div>
            </Link>
          ))}
        </div>
      </li>
    </div>
  );
}

NavbarDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  currentPage: PropTypes.string.isRequired,
  targetPage: PropTypes.string,
  exactMatch: PropTypes.bool,
  menuItems: PropTypes.array.isRequired,
};

interface Props {
  name: string;
  currentPage: string;
  targetPage?: string;
  exactMatch?: boolean;
  menuItems: MenuItem[];
}

interface MenuItem {
  name: string;
  targetPage: string;
}
