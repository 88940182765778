// --------------------------------------------------------------
// Created On: 2021-10-27
// Author: Zachary Thomas
//
// Last Modified: 2024-08-05
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./NavigationDrawerCollection.module.scss";

// A single expandable collection in a navigation drawer.
export default function NavigationDrawerCollection(props: Props): Component {
  const [selected, setSelected] = useState(false);
  const [expanded, setExpanded] = useState(false);

  // Automatically set this collection as selected if this collection is already in use.
  useEffect(() => {
    const isValidSingleTarget = typeof props.targetPage === "string" && props.currentPage === props.targetPage;
    const isValidMultipleTargets = Array.isArray(props.targetPage) && props.targetPage.includes(props.currentPage);
    if (isValidSingleTarget || isValidMultipleTargets) {
      setSelected(true);
      setExpanded(true);
    } else {
      setSelected(false);
      setExpanded(false);
    }
  }, [props.targetPage, props.currentPage]);

  return (
    <div className={styles.body}>
      <div
        className={`${styles.link} ${selected ? styles.selected : ""} ${expanded ? styles.expanded : ""}`}
        onClick={() => setExpanded((previousExpanded) => !previousExpanded)}
        data-test="nav-drawer-collection"
      >
        <div className={styles.item}>
          <i className={`fa fa-fw fa-${props.icon} me-3`} />

          <span>{props.name}</span>

          {expanded ? (
            <span className="pull-right">
              <i className="fa fa-fw fa-caret-down" />
            </span>
          ) : (
            <span className="pull-right">
              <i className="fa fa-fw fa-caret-right" />
            </span>
          )}
        </div>
      </div>
      {expanded && <Fragment>{props.children}</Fragment>}
    </div>
  );
}

NavigationDrawerCollection.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  targetPage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  currentPage: PropTypes.string.isRequired,
  children: PropTypes.any,
};

interface Props {
  name: string;
  icon: string;
  targetPage: string | string[];
  currentPage: string;
  children: Component | Component[];
}
