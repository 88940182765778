// --------------------------------------------------------------
// Created On: 2024-01-16
// Author: Zachary Thomas
//
// Last Modified: 2024-01-16
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import Modal from "../Modal/Modal";
import ModalHeader from "../ModalHeader/ModalHeader";
import ModalBody from "../ModalBody/ModalBody";
import ModalFooter from "../ModalFooter/ModalFooter";
import PropTypes from "prop-types";
import styles from "./DoubleConfirmModal.module.scss";

// Modal for confirming a critical action that needs to be confirmed twice.
export default function DoubleConfirmModal(props: Props): Component {
  const [confirmationText, setConfirmationText] = useState<string>("");
  const [deletionReady, setDeletionReady] = useState<boolean>(false);

  // Check to see if the user is ready to delete.
  useEffect(() => {
    if (confirmationText.toUpperCase() === props.confirmPin.toUpperCase()) {
      setDeletionReady(true);
    } else {
      setDeletionReady(false);
    }
  }, [confirmationText]);

  // Attempt to delete.
  function attemptConfirm(): void {
    if (deletionReady) {
      setConfirmationText("");
      setDeletionReady(false);
      props.onConfirm();
    }
  }

  // Cancel delete.
  function cancelDelete(): void {
    setConfirmationText("");
    setDeletionReady(false);
    props.onCancel();
  }

  return (
    <div data-test="double-confirm-modal">
      <Modal
        show={props.showModal}
        onHide={() => cancelDelete()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--super-modal-z-index)" }}
        animation
        centered
      >
        <ModalHeader>
          <h5>{props.title}</h5>
        </ModalHeader>
        <ModalBody>
          <p>{props.content}</p>

          <br />

          <p>
            Enter &apos;
            <span className="font-weight-bold">{props.confirmPin.toUpperCase()}</span>&apos; in the box below to
            confirm.
          </p>
          <input
            data-test="confirm-permanent-delete-input"
            className="form-control"
            placeholder={props.confirmPin.toUpperCase()}
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value.toUpperCase())}
          />
        </ModalBody>
        <ModalFooter>
          <button
            data-test="double-confirm-button"
            type="button"
            className="btn btn-primary"
            data-dismiss="modal"
            onClick={() => attemptConfirm()}
            disabled={!deletionReady}
          >
            {props.confirmText}
          </button>
          <button
            data-test="cancel-permanent-delete-button"
            type="button"
            className="btn btn-secondary"
            onClick={() => cancelDelete()}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

DoubleConfirmModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  confirmPin: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

interface Props {
  showModal: boolean;
  title: string;
  content: string;
  confirmText: string;
  confirmPin: string;
  onConfirm: () => void;
  onCancel: () => void;
}
