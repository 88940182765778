// --------------------------------------------------------------
// Created On: 2021-08-23
// Author: Zachary Thomas
//
// Last Modified: 2023-11-17
// Modified By: Lilly Kane
//
// Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import Card from "../../components/Card/Card";
import TextBlurb from "../../components/TextBlurb/TextBlurb";
import styles from "./Error500Page.module.scss";

// Page for displaying 500 errors.
export default function Error500Page(): Component {
  return (
    <div className={`${styles.body} p-4`} data-test="500-error-card">
      <Card title="Internal Server Error">
        <div className="my-5">
          <TextBlurb
            title="Something went wrong!"
            paragraph="We were unable to display the page you were looking for. Please go back to the previous page and try again."
            icon="exclamation-triangle"
          />
        </div>
      </Card>
    </div>
  );
}
