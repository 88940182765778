// --------------------------------------------------------------
// Created On: 2021-09-21
// Author: Zachary Thomas
//
// Last Modified: 2022-09-19
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { CSSProperties } from "react";
import { Modal as ModalReact } from "react-bootstrap";
import PropTypes from "prop-types";

// Generic modal.
export default function Modal(props: Props): Component {
  return (
    <ModalReact
      dialogClassName={props.dialogClassName || undefined}
      backdropClassName={props.backdropClassName || undefined}
      show={props.show}
      onHide={props.onHide}
      size={props.size || undefined}
      animation={props.animation || undefined}
      centered={props.centered || undefined}
      style={props.style || undefined}
    >
      {props.children}
    </ModalReact>
  );
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  dialogClassName: PropTypes.string,
  backdropClassName: PropTypes.string,
  size: PropTypes.oneOf(["sm", "lg", "xl"]),
  animation: PropTypes.bool,
  centered: PropTypes.bool,
  children: PropTypes.any,
};

interface Props {
  show: boolean;
  onHide: () => void;
  dialogClassName?: string;
  backdropClassName?: string;
  size?: "sm" | "lg" | "xl";
  animation?: boolean;
  centered?: boolean;
  children?: Component | Component[];
  style?: CSSProperties;
}
