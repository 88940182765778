// --------------------------------------------------------------
// Created On: 2023-05-27
// Author: Zachary Thomas
//
// Last Modified: 2024-12-03
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, useMemo } from "react";
import { API, MS_PER_MINUTE, MS_PER_SECOND, MAX_MINUTES_FOR_ACCURATE_READING } from "../../constants/miscellaneous";
import { POWER_TO_PANEL_ATTRIBUTE, ENGINE_RUNNING_ATTRIBUTE, RPM_ATTRIBUTE } from "../../constants/attributes";
import { ASSET_DETAILS_MAX_INACTIVE_USER_SECONDS, ASSET_DETAILS_POLLING_DELAY_SECONDS } from "../../constants/polling";
import isValueBooleanString from "../../utilities/isValueBooleanString";
import formatDateLocal from "../../utilities/time/formatDateLocal";
import useApi from "../../hooks/useApi";
import Spinner from "../../components/Spinner/Spinner";
import Error500Page from "../Error500Page/Error500Page";
import CompleteAttributeList from "./CompleteAttributeList/CompleteAttributeList";
import AssetAttributeCard from "./AssetAttributeCard/AssetAttributeCard";
import isoToLocalDate from "../../utilities/time/isoToLocalDate";
import isoToTimePassed from "../../utilities/time/isoToTimePassed";
import formatDateShortLocal from "../../utilities/time/formatDateShortLocal";
import AssetDetails from "../../components/AssetDetails/AssetDetails";
import isoUtcIsStale from "../../utilities/time/isoUtcIsStale";
import InfoBanner from "../../components/InfoBanner/InfoBanner";
import userIsSuperAdmin from "../../utilities/userIsSuperAdmin";
import isoUtcToIsoLocal from "../../utilities/time/isoUtcToIsoLocal";
import DoubleConfirmModal from "../../components/DoubleConfirmModal/DoubleConfirmModal";
import DataHistoryModal from "./DataHistoryModal/DataHistoryModal";
import { MIGRATE_ASSETS_PERMISSION } from "../../constants/permissions";
import Error from "../../components/Error/Error";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import userHasPermission from "../../utilities/userHasPermission";
import ProductDataVisual from "./ProductDataVisual/ProductDataVisual";
import apiRequest from "../../utilities/api/apiRequest";
import useLastActive from "../../hooks/useLastActive";

// Page for viewing attribute data for the current asset.
export default function AssetDataPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [controllerModel, setControllerModel] = useState<string | null>(null);
  const [deviceId, setDeviceId] = useState<number | null>(null);
  const [deviceType, setDeviceType] = useState<string | null>(null);
  const [deviceTypeName, setDeviceTypeName] = useState<string | null>(null);
  const [deviceIdentifier, setDeviceIdentifier] = useState<string | null>(null);
  const [productType, setProductType] = useState<string>("");
  const [nickname, setNickname] = useState<string>("");
  const [productModel, setProduct] = useState<string>("");
  const [productIdentifier, setProductIdentifier] = useState<string>("");
  const [productManufacturer, setProductManufacturer] = useState<string>("");
  const [isRented, setIsRented] = useState<boolean>(false);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [attributeMap, setAttributeMap] = useState<AttributesById | null>(null);
  const [cards, setCards] = useState<AssetAttributeCard[]>([]);
  const [highlightAttributes, setHighlightAttributes] = useState<Attribute[]>([]);
  const [lastComm, setLastComm] = useState<string | null>(null);
  const [isMigrating, setIsMigrating] = useState<boolean>(false);
  const [showMigrateAsset, setShowMigrateAsset] = useState<boolean>(false);
  const [migrationCompleted, setMigrationCompleted] = useState<boolean>(false);
  const [lastConfigSuccessful, setLastConfigSuccessful] = useState<boolean>(true);
  const [lastConfigAttemptUtc, setLastConfigAttemptUtc] = useState<string>("");
  const [attemptedToReconfigure, setAttemptedToReconfigure] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [timerCounter, setTimerCounter] = useState<number>(0);
  const [dataPollCounter, setDataPollCounter] = useState<number>(0);
  const [selectedHistoryAttributeId, setSelectedHistoryAttributeId] = useState<number | null>(null);
  const [selectedHistoryStartingDate, setSelectedHistoryStartingDate] = useState<string | null>(null);
  const userInactiveSeconds = useLastActive(ASSET_DETAILS_POLLING_DELAY_SECONDS);
  const currentUser = useSelector(getCurrentUser);
  const { assetId } = useParams();
  const isStale = useMemo(() => isoUtcIsStale(lastComm), [lastComm]);

  // Get asset data from API.
  useApi(
    () => {
      if (dataPollCounter === 0) {
        setLoading(true);
      }
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/${assetId}`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setName(responseBody.assetName);
        setNickname(responseBody.assetNickname);
        setProduct(responseBody.productModel);
        setProductIdentifier(responseBody.productIdentifier);
        setProductManufacturer(responseBody.productManufacturer);
        setIsRented(responseBody.isRented);
        setControllerModel(responseBody.controllerModel);
        setDeviceId(responseBody.deviceId);
        setDeviceType(responseBody.deviceModel);
        setDeviceTypeName(responseBody.deviceTypeName);
        setDeviceIdentifier(responseBody.deviceIdentifier);
        setProductType(responseBody.productType);
        setAttributeMap(responseBody.attributesById);
        const attributeIds = getAttributeIdsFromAttributeMap(responseBody.attributesById);
        const attributes = getAttributesByIds(attributeIds, responseBody.attributesById);
        markStaleAttributesInPlace(attributes);
        setAttributes(sortAttributes(attributes));
        const cards = addAttributesToCards(responseBody.cards, responseBody.attributesById);
        setCards(cards);
        setHighlightAttributes(getAttributesByIds(responseBody.attributeHighlightIds, responseBody.attributesById));
        setLastComm(responseBody.lastComm);
        setIsMigrating(responseBody.isMigrating);
        if (responseBody.lastConfigAttemptUtc !== undefined && responseBody.lastConfigAttemptUtc !== null) {
          setLastConfigAttemptUtc(responseBody.lastConfigAttemptUtc);
        }
        if (responseBody.lastConfigSuccessful !== undefined && responseBody.lastConfigSuccessful !== null) {
          setLastConfigSuccessful(responseBody.lastConfigSuccessful);
        }
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [JSON.stringify(currentUser), assetId, migrationCompleted, dataPollCounter]
  );

  // Periodically poll data to give users a live feed.
  useEffect(() => {
    const newTimerId = setTimeout(() => {
      setTimerCounter((prev) => prev + 1);
      // Only poll data if the user is active.
      console.log(
        `Looking to see if data should be polled` +
          ` (inactive for ${userInactiveSeconds} seconds of ${ASSET_DETAILS_MAX_INACTIVE_USER_SECONDS} second limit).`
      );
      if (userInactiveSeconds < ASSET_DETAILS_MAX_INACTIVE_USER_SECONDS) {
        setDataPollCounter((prev) => prev + 1);
      } else {
        console.log("User is inactive. Data will not be polled.");
      }
    }, ASSET_DETAILS_POLLING_DELAY_SECONDS * MS_PER_SECOND);
    return () => {
      clearTimeout(newTimerId);
    };
  }, [timerCounter]);

  // Sort attributes alphabetically.
  function sortAttributes(attributes: Attribute[]): Attribute[] {
    return attributes.sort((a, b) => {
      if (a.attributeName < b.attributeName) {
        return -1;
      } else if (a.attributeName > b.attributeName) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // Get an array of attributes by passing in an array of attribute IDs.
  function getAttributesByIds(attributeIds: number[], attributeMap: AttributesById): Attribute[] {
    const attributes = attributeIds.map((attributeId) => attributeMap[attributeId]);
    // If any attributes have connected attributes, get their information.
    attributes.forEach((attribute) => {
      if (attribute.connectedRegAttributeId === null || attributeMap[attribute.connectedRegAttributeId] === undefined) {
        attribute.connectedAttribute = null;
      } else {
        attribute.connectedAttribute = attributeMap[attribute.connectedRegAttributeId];
      }
    });
    return attributes;
  }

  // Get all valid attribute IDs from attribute map.
  function getAttributeIdsFromAttributeMap(attributeMap: AttributesById): number[] {
    const keys = Object.keys(attributeMap);
    const attributeIds: number[] = [];
    keys.forEach((key) => {
      const keyIntegerValue = parseInt(key, 10);
      if (!isNaN(keyIntegerValue)) {
        attributeIds.push(keyIntegerValue);
      }
    });
    return attributeIds;
  }

  // Perform an asset migration for the current asset.
  async function migrateAsset(): Promise<void> {
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${assetId}/migrate`,
      "PUT",
      null
    )) as [Response, MigrateResponseBody];
    setLoading(false);
    if (response.ok) {
      setErrorMessage("");
      setMigrationCompleted(true);
      setShowMigrateAsset(false);
      setDataPollCounter(0);
    } else {
      setErrorMessage("Internal server error. Unable to migrate asset.");
      setShowMigrateAsset(false);
    }
  }

  // Attempts to reconfigure the monitoring device of the current asset.
  async function retryConfiguration(): Promise<void> {
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${assetId}/configretry`,
      "POST",
      null
    )) as [Response, MigrateResponseBody];
    setLoading(false);
    if (response.ok) {
      setErrorMessage("");
      setAttemptedToReconfigure(true);
    } else {
      setErrorMessage("Internal server error. Unable to configure asset.");
    }
  }

  // Converts an array of cards that contains attribute IDs to have an array of cards that contain attributes.
  function addAttributesToCards(cards: AssetCard[], attributeMap: AttributesById): AssetAttributeCard[] {
    const assetAttributeCards: AssetAttributeCard[] = [];
    cards.forEach((card) => {
      const attributes = getAttributesByIds(card.attributeIds, attributeMap);
      markStaleAttributesInPlace(attributes);
      assetAttributeCards.push({
        assetTemplateCardId: card.assetTemplateCardId,
        cardTypeName: card.cardTypeName,
        name: card.name,
        attributes: attributes,
      });
    });
    return assetAttributeCards;
  }

  // Modifies an array of attributes to set their status on whether the current data is stale or not.
  function markStaleAttributesInPlace(attributes: Attribute[]): void {
    let engineRunningIsStale = false;
    let rpmIsStale = false;
    const nowDate = new Date();

    // Mark attributes as stale if they haven't reported a new reading in a certain amount of time.
    // We do not mark boolean attributes as stale in this way, due to them only sending new readings on state change.
    attributes.forEach((attribute) => {
      if (attribute.currentValue === null || !isValueBooleanString(attribute.currentValue)) {
        attribute.isStale = attribute.currentValue === null || attribute.currentValueUtc === null;
        if (!attribute.isStale) {
          const attributeDate = isoToLocalDate(attribute.currentValueUtc as string);
          attribute.isStale =
            (nowDate.getTime() - attributeDate.getTime()) / MS_PER_MINUTE > MAX_MINUTES_FOR_ACCURATE_READING;
        }
        if (attribute.isStale && attribute.attributeCode === ENGINE_RUNNING_ATTRIBUTE) {
          engineRunningIsStale = true;
        }
        if (attribute.isStale && attribute.attributeCode === RPM_ATTRIBUTE) {
          rpmIsStale = true;
        }
      } else {
        attribute.isStale = false;
      }
    });

    // Some attributes values indicate that other attributes may not have a reliable value.
    // In these cases we will mark attributes as stale if they cannot be trusted.
    const powerToPanelAttribute = attributes.find((attribute) => attribute.attributeCode === POWER_TO_PANEL_ATTRIBUTE);
    const engineRunningAttribute = attributes.find((attribute) => attribute.attributeCode === ENGINE_RUNNING_ATTRIBUTE);
    const rpmAttribute = attributes.find((attribute) => attribute.attributeCode === RPM_ATTRIBUTE);

    if (
      !engineRunningIsStale &&
      powerToPanelAttribute !== undefined &&
      engineRunningAttribute !== undefined &&
      isValueBooleanString(powerToPanelAttribute.currentValue) &&
      (powerToPanelAttribute.currentValue === null || powerToPanelAttribute.currentValue.toLowerCase() === "false")
    ) {
      engineRunningAttribute.isStale = true;
    }

    if (
      !rpmIsStale &&
      rpmAttribute !== undefined &&
      ((powerToPanelAttribute !== undefined &&
        isValueBooleanString(powerToPanelAttribute.currentValue) &&
        (powerToPanelAttribute.currentValue === null ||
          powerToPanelAttribute.currentValue.toLowerCase() === "false")) ||
        (engineRunningAttribute !== undefined &&
          isValueBooleanString(engineRunningAttribute.currentValue) &&
          (engineRunningAttribute.currentValue === null ||
            engineRunningAttribute.currentValue.toLowerCase() === "false")))
    ) {
      rpmAttribute.isStale = true;
    }
  }

  // Select an attribute so that we an open the historical modal and show the historical attribute data.
  function handleSelectedAttribute(attributeId: number, attributeMap: AttributesById | null): void {
    if (attributeMap === null) {
      setSelectedHistoryAttributeId(null);
      setSelectedHistoryStartingDate(null);
    } else {
      const selectedAttribute = attributeMap[attributeId];
      if (selectedAttribute !== undefined) {
        setSelectedHistoryAttributeId(selectedAttribute.regAttributeId);
        // We will want to get the most recent reading date, without the time of day, in the local time zone
        // to act as a default starting date for the history modal.
        if (typeof selectedAttribute.currentValueUtc === "string") {
          const mostRecentLocal = isoUtcToIsoLocal(selectedAttribute.currentValueUtc);
          const mostRecentSections = mostRecentLocal.split("T");
          setSelectedHistoryStartingDate(mostRecentSections[0]);
        } else {
          setSelectedHistoryAttributeId(null);
          setSelectedHistoryStartingDate(null);
        }
      }
    }
  }

  // Get an attribute by ID.
  function getAttributeById(attributeId: number, attributeMap: AttributesById | null): Attribute | undefined {
    if (attributeMap === null) {
      return undefined;
    } else {
      return attributeMap[attributeId];
    }
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div>
      <Spinner loading={loading} />

      <Spinner loading={loading} />

      <div className="pt-1" />

      {/* Shows a product specific image with attribute highlights. */}
      <ProductDataVisual
        assetName={name}
        productType={productType}
        highlightAttributes={highlightAttributes}
        allAttributes={attributes}
        onSelectAttribute={(attributeId) => handleSelectedAttribute(attributeId, attributeMap)}
      />

      {/* Banner information, such as last reported time. */}
      <div className="row justify-content-center mx-0 mb-3">
        <div className="col-12 col-xl-8 px-3">
          {lastComm !== null && (
            <InfoBanner
              message={`This asset reported new data ${isoToTimePassed(lastComm)} ago (${formatDateShortLocal(
                lastComm
              )}).`}
            />
          )}

          {isMigrating && (
            <div className={isStale ? "mt-3" : ""}>
              <InfoBanner
                message={
                  "This asset has not completed migration. " +
                  "There are some limitations on configuration, alerting, and remote operation until this migration is complete."
                }
              >
                {userIsSuperAdmin() || (userHasPermission([[MIGRATE_ASSETS_PERMISSION]]) && !isRented) ? (
                  <div className="text-center">
                    <button className="btn btn-primary mt-2" type="button" onClick={() => setShowMigrateAsset(true)}>
                      Migrate Asset
                    </button>
                  </div>
                ) : null}
              </InfoBanner>
            </div>
          )}

          {!isMigrating && !lastConfigSuccessful && (
            <div className={isStale ? "mt-3" : ""}>
              <InfoBanner
                message={
                  `This asset failed to update its monitoring device configuration settings on ${formatDateLocal(
                    lastConfigAttemptUtc
                  )}. ` +
                  "This could possibly be due to not being able to establish a stable connection with the physical device. " +
                  "You can attempt to reapply the configuration settings. You will get a notification when the attempt is complete."
                }
              >
                <div className="text-center">
                  <button
                    className="btn btn-primary mx-auto mt-2"
                    type="button"
                    onClick={() => retryConfiguration()}
                    disabled={attemptedToReconfigure}
                  >
                    Retry Configuration
                  </button>
                </div>
              </InfoBanner>
            </div>
          )}

          {errorMessage.length > 0 && (
            <div className={isStale || isMigrating ? "mt-3" : ""}>
              <Error message={errorMessage} />
            </div>
          )}
        </div>
      </div>

      {/* Dynamically render cards for asset. */}
      {cards.length > 0 && (
        <div className="row justify-content-center mx-0 mb-4">
          <div className="col-12 col-xl-8">
            <div className="row">
              {cards.map((card) => (
                <AssetAttributeCard
                  key={card.assetTemplateCardId}
                  assetTemplateCardId={card.assetTemplateCardId}
                  name={card.name}
                  cardTypeName={card.cardTypeName}
                  attributes={card.attributes}
                  onSelectAttribute={(attributeId) => handleSelectedAttribute(attributeId, attributeMap)}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Accordion that shows all attribute data about this asset, regardless of template. */}
      <CompleteAttributeList
        attributes={attributes}
        onSelectAttribute={(attributeId) => handleSelectedAttribute(attributeId, attributeMap)}
      />

      {/* Extra non-attribute related information about the asset. */}
      <AssetDetails
        assetId={assetId !== undefined ? parseInt(assetId, 10) : assetId}
        name={name}
        nickname={nickname}
        productType={productType}
        productModel={productModel}
        productIdentifier={productIdentifier}
        productManufacturer={productManufacturer}
        deviceId={deviceId}
        deviceType={deviceType}
        deviceTypeName={deviceTypeName}
        deviceIdentifier={deviceIdentifier}
        controllerModel={controllerModel}
        loading={loading}
        isRented={isRented}
        showAssociatedAssetgroups={true}
        allowDeviceRead={true}
        allowAssetValidate={true}
        onDeviceRead={() => {
          setDataPollCounter(0);
        }}
      />

      {/* Modal for showing historical data. */}
      <DataHistoryModal
        showModal={
          selectedHistoryAttributeId !== null &&
          getAttributeById(selectedHistoryAttributeId, attributeMap) !== undefined
        }
        isGraphable={
          selectedHistoryAttributeId !== null &&
          getAttributeById(selectedHistoryAttributeId, attributeMap) !== undefined &&
          (getAttributeById(selectedHistoryAttributeId, attributeMap) as Attribute).isGraphable === true
        }
        name={
          selectedHistoryAttributeId !== null &&
          getAttributeById(selectedHistoryAttributeId, attributeMap) !== undefined
            ? (getAttributeById(selectedHistoryAttributeId, attributeMap) as Attribute).attributeName
            : ""
        }
        code={
          selectedHistoryAttributeId !== null &&
          getAttributeById(selectedHistoryAttributeId, attributeMap) !== undefined
            ? (getAttributeById(selectedHistoryAttributeId, attributeMap) as Attribute).attributeCode
            : ""
        }
        date={selectedHistoryStartingDate}
        onClose={() => setSelectedHistoryAttributeId(null)}
      />

      {/* Modal that supports migrating asset from legacy software. */}
      <DoubleConfirmModal
        showModal={showMigrateAsset}
        title="Migrate Asset?"
        content={
          "If migrated, this asset will no longer be available in its current capacity in legacy software. " +
          "Alert thresholds from legacy software will not be transferred. This action in not reversible! " +
          "Do you still want to migrate this asset?"
        }
        confirmPin="MIGRATE ASSET"
        confirmText="Migrate Asset"
        onConfirm={() => migrateAsset()}
        onCancel={() => setShowMigrateAsset(false)}
      />
    </div>
  );
}

interface ResponseBody {
  operationIsAllowed: boolean;
  assetId: number;
  assetName: string;
  assetNickname: string;
  productManufacturer: string;
  productType: string;
  productModel: string;
  productIdentifier: string;
  controllerModel: string | null;
  isRented: boolean;
  deviceId: number | null;
  deviceModel: string | null;
  deviceTypeName: string | null;
  deviceIdentifier: string | null;
  lastComm: string | null;
  isLockedOut: boolean;
  canUnlock: boolean;
  isMigrating: boolean;
  lastConfigAttemptUtc: string;
  lastConfigSuccessful: boolean;
  attributesById: AttributesById;
  attributeHighlightIds: number[];
  cards: AssetCard[];
}

interface AttributesById {
  [key: string]: Attribute;
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  connectedRegAttributeId: number | null;
  connectedAttribute?: Attribute | null;
  unitShortName: string | null;
  unitLongName: string | null;
  icon: string;
  currentValue: string | null;
  currentValueUtc: string | null;
  isHistorical: boolean;
  isGraphable: boolean;
  isStale: boolean;
}

interface AssetCard {
  assetTemplateCardId: number;
  cardTypeName: string | null;
  name: string;
  attributeIds: number[];
}

interface AssetAttributeCard {
  assetTemplateCardId: number;
  cardTypeName: string | null;
  name: string;
  attributes: Attribute[];
}

interface MigrateResponseBody {
  message: string;
}
