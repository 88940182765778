// --------------------------------------------------------------
// Created On: 2023-10-10
// Author: Zachary Thomas
//
// Last Modified: 2024-03-12
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import formatDateShortLocal from "../../../utilities/time/formatDateShortLocal";
import styles from "./RentalLogList.module.scss";

// A list of rental operation logs.
export default function RentalLogList(props: Props): Component {
  // Generate log message.
  function generateLogMessage(transferLog: TransferLog): string {
    switch (transferLog.transferCode) {
      case "SEND_REQUEST":
        return `User '${transferLog.userName}' offered asset rental to company '${
          transferLog.companyName
        }' for asset '${transferLog.assetName}' at ${formatDateShortLocal(transferLog.createdUtc)}`;

      case "RECEIVE_REQUEST":
        return `User '${transferLog.userName}' from company '${
          transferLog.companyName
        }' offered asset rental request for asset '${transferLog.assetName}' at ${formatDateShortLocal(
          transferLog.createdUtc
        )}`;

      case "CANCEL_REQUEST":
        return `User '${transferLog.userName}' canceled the offered asset rental request to company '${
          transferLog.companyName
        }' for asset '${transferLog.assetName}' at ${formatDateShortLocal(transferLog.createdUtc)}`;

      case "ACCEPT_REQUEST":
        return `User '${transferLog.userName}' accepted the asset rental offer from company '${
          transferLog.companyName
        }' for asset '${transferLog.assetName}' at ${formatDateShortLocal(transferLog.createdUtc)}`;

      case "REJECT_REQUEST":
        return `User '${transferLog.userName}' rejected the asset rental offer from company '${
          transferLog.companyName
        }' for asset '${transferLog.assetName}' at ${formatDateShortLocal(transferLog.createdUtc)}`;

      case "REQUEST_CANCELLED":
        return `User '${transferLog.userName}' from company '${
          transferLog.companyName
        }' cancelled the rental request for asset '${transferLog.assetName}' at ${formatDateShortLocal(
          transferLog.createdUtc
        )}`;

      case "REQUEST_ACCEPTED":
        return `User '${transferLog.userName}' from company '${
          transferLog.companyName
        }' accepted the rental request for asset '${transferLog.assetName}' at ${formatDateShortLocal(
          transferLog.createdUtc
        )}`;

      case "REQUEST_REJECTED":
        return `User '${transferLog.userName}' from company '${
          transferLog.companyName
        }' rejected the rental request for asset '${transferLog.assetName}' at ${formatDateShortLocal(
          transferLog.createdUtc
        )}`;

      case "REVOKE_ASSET":
        return `User '${transferLog.userName}' revoked the asset rental from company '${
          transferLog.companyName
        }' for asset '${transferLog.assetName}' at ${formatDateShortLocal(transferLog.createdUtc)}`;

      case "RETURN_ASSET":
        return `User '${transferLog.userName}' returned the asset rental to company '${
          transferLog.companyName
        }' for asset '${transferLog.assetName}' at ${formatDateShortLocal(transferLog.createdUtc)}`;

      case "ASSET_RETURNED":
        return `User '${transferLog.userName}' from company '${transferLog.companyName}' returned the rental asset '${
          transferLog.assetName
        }' at ${formatDateShortLocal(transferLog.createdUtc)}`;

      case "ASSET_REVOKED":
        if (transferLog.userName === null) {
          return `Company '${transferLog.companyName}' revoked the rental asset '${
            transferLog.assetName
          }' at ${formatDateShortLocal(transferLog.createdUtc)}`;
        } else {
          return `User '${transferLog.userName}' from company '${transferLog.companyName}' revoked the rental asset '${
            transferLog.assetName
          }' at ${formatDateShortLocal(transferLog.createdUtc)}`;
        }

      default:
        return "Unknown transfer operation";
    }
  }

  return (
    <div>
      <div className={styles.titleBar}>
        <label className={styles.title}>Rental History Logs</label>
      </div>
      <div className={styles.body}>
        {props.transferLogs.map((transferLog) => (
          <div key={transferLog.assetTransferLogId} className={styles.item}>
            {generateLogMessage(transferLog)}
          </div>
        ))}

        {props.transferLogs.length === 0 && <div className={styles.item}>No rental history exists</div>}
      </div>
    </div>
  );
}

RentalLogList.propTypes = {
  transferLogs: PropTypes.array.isRequired,
};

interface Props {
  transferLogs: TransferLog[];
}

interface TransferLog {
  assetTransferLogId: number;
  userName: string | null;
  assetName: string;
  companyName: string;
  deviceType: string | null;
  deviceIdentifier: string | null;
  transferCode: string;
  createdUtc: string;
}
