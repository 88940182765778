// --------------------------------------------------------------
// Created On: 2023-11-27
// Author: Zachary Thomas
//
// Last Modified: 2023-11-30
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./GeofenceThresholdControl.module.scss";

// Control for updating or deleting a geo-fence threshold.
export default function GeofenceThresholdControl(props: Props): Component {
  return (
    <div className={styles.body}>
      <div className="mt-2 mb-3">
        <i
          className={`${styles.deleteButton} fa fa-fw fa-times float-end`}
          onClick={() => props.onRemoveThreshold()}
        />
      </div>

      <div>
        <label className={styles.label}>
          Assets {props.mode}ing the geo-fence will trigger an alert.
        </label>

        <div className="my-3">
          <button className="btn btn-primary" type="button" onClick={() => props.onSelectUsers()}>
            Manage Subscribed Users
          </button>
        </div>
      </div>
    </div>
  );
}

GeofenceThresholdControl.propTypes = {
  mode: PropTypes.string.isRequired,
  onRemoveThreshold: PropTypes.func.isRequired,
  onSelectUsers: PropTypes.func.isRequired,
};

interface Props {
  mode: "exit" | "enter";
  onRemoveThreshold: () => void;
  onSelectUsers: () => void;
}
