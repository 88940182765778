// --------------------------------------------------------------
// Created On: 2022-06-21
// Author: Zachary Thomas
//
// Last Modified: 2024-03-12
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Convert UTC timestamp, ISO 8601 string, or date object to short human readable local time.
export default function formatDateShortLocal(timestamp: string): string {
  if (!timestamp.includes("Z") && !timestamp.includes("+")) {
    timestamp += "Z";
  }
  return new Date(Date.parse(timestamp)).toLocaleDateString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
}
