// --------------------------------------------------------------
// Created On: 2021-09-30
// Author: Zachary Thomas
//
// Last Modified: 2024-04-16
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import styles from "./AssociatedUsergroupItem.module.scss";

// A single user group in the asset group modal.
export default function AssociatedUsergroupItem(props: Props): Component {
  return (
    <Fragment>
      <div
        data-test="-assetgroup-associated-usergroup-item"
        className={`${styles.body} px-4 py-2`}
        data-tip
        data-for={`user-group-${props.usergroupId}`}
      >
        <span>
          {props.name} {props.operateAccessPermission && <i className="fa fa-fw fa-cog fa-lg" />}
        </span>
      </div>

      {/* Show a tool tip describing the user group. */}
      <ReactTooltip id={`user-group-${props.usergroupId}`} place="top" effect="float">
        {props.operateAccessPermission
          ? "This user group has viewing and operating permissions."
          : "This user group has viewing permissions."}
      </ReactTooltip>
    </Fragment>
  );
}

AssociatedUsergroupItem.propTypes = {
  usergroupId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  operateAccessPermission: PropTypes.bool.isRequired,
};

interface Props {
  usergroupId: number;
  name: string;
  operateAccessPermission: boolean;
}
