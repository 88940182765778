// --------------------------------------------------------------
// Created On: 2021-06-08
// Author: Zachary Thomas
//
// Last Modified: 2024-05-16
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import Card from "../../../components/Card/Card";
import Error from "../../../components/Error/Error";
import { Link } from "react-router-dom";
import PasswordInput from "../../../components/PasswordInput/PasswordInput";
import PropTypes from "prop-types";
import styles from "./LoginForm.module.scss";

// Login form for users.
export default function LoginForm(props: Props): Component {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  // Check if the user pressed enter, if they did we attempt to login.
  function checkPressEnter(e: React.KeyboardEvent) {
    if (e.key === "Enter") {
      props.onSubmit(email, password);
    }
  }

  return (
    <form
      className={`${styles.body} col-12 col-xl-6 offset-xl-3`}
      onSubmit={(e) => e.preventDefault()}
      onKeyDown={(e) => checkPressEnter(e)}
    >
      <Card title="User Login">
        <div className="px-5 mb-4">
          <label className="mt-4">Email</label>

          <input
            data-test="login-email-input"
            type="email"
            className="form-control mt-2 mb-4"
            id="email-text"
            aria-describedby="Email"
            placeholder="Enter email"
            autoComplete="username"
            onChange={(e) => setEmail(e.target.value)}
          />

          <label className="mt-2">{props.isForgotPasswordRedirect && "Temporary"} Password</label>

          <PasswordInput password={password} onChangePassword={(password: string) => setPassword(password)} />

          {!props.isForgotPasswordRedirect && (
            <div className="my-2">
              <Link to="/forgot-password">Forgot your password?</Link>
            </div>
          )}

          {!props.isForgotPasswordRedirect && (
            <div className="my-2">
              <Link to="/register">Create a new account</Link>
            </div>
          )}

          <div className="text-center my-4">
            <button
              data-test="login-button"
              type="submit"
              id="submit-login"
              className={`${styles.btn} btn btn-primary`}
              onClick={() => props.onSubmit(email, password)}
            >
              Login
            </button>
          </div>

          <Error message={props.errorMessage} />
        </div>
      </Card>
    </form>
  );
}

LoginForm.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  isForgotPasswordRedirect: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

interface Props {
  errorMessage: string;
  isForgotPasswordRedirect: boolean;
  onSubmit: (email: string, password: string) => void;
}
