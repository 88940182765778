// --------------------------------------------------------------
// Created On: 2021-10-12
// Author: Zachary Thomas
//
// Last Modified: 2023-12-27
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import { API } from "../../constants/miscellaneous";
import useApi from "../../hooks/useApi";
import Spinner from "../../components/Spinner/Spinner";
import Error500Page from "../Error500Page/Error500Page";
import CompanyList from "./CompanyList/CompanyList";

// Admin dashboard, used for selecting a company to dive into.
export default function DashboardAdminPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [companies, setCompanies] = useState<Company[]>([]);

  // Get company data from API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      url: `${API}/company`,
      method: "GET",
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setCompanies(responseBody.companies);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="page-rpm-dashboard p-4">
      <Spinner loading={loading} />

      {/* List of companies. */}
      <div className="company-view-container mx-auto">
        <CompanyList companies={companies} />
      </div>
    </div>
  );
}

interface ResponseBody {
  companies: Company[];
}

interface Company {
  companyId: number;
  name: string;
  code: string;
  isPackager: boolean;
}
