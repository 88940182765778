// --------------------------------------------------------------
// Created On: 2023-12-28
// Author: Zachary Thomas
//
// Last Modified: 2024-04-10
// Modified By: Lilly Kane
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import formatDateShortLocal from "../../../utilities/time/formatDateShortLocal";
import styles from "./AlertThresholdModifyLogList.module.scss";

// A list of logs for alert threshold modifications.
export default function AlertThresholdModifyLogList(props: Props): Component {
  return props.modificationLogs.length > 0 ? (
    <div>
      <div>
        <label className={styles.title}>Threshold Modification History</label>
      </div>
      <div className={styles.body} data-test="threshold-modification-log-list-container">
        {props.modificationLogs.map((modificationLog, i) => (
          <div data-test="threshold-modification-log-single-log" key={i} className={styles.item}>
            {modificationLog.alertOnAssetExit === undefined ? (
              <span>
                {modificationLog.username} ({modificationLog.emailAddress}) modified alert thresholds on{" "}
                {formatDateShortLocal(modificationLog.lastModifiedUtc)}.
              </span>
            ) : (
              <span>
                {modificationLog.username} ({modificationLog.emailAddress}) modified alert thresholds for &apos;
                {modificationLog.alertOnAssetExit ? "assets entering" : "assets exiting"}&apos; on{" "}
                {formatDateShortLocal(modificationLog.lastModifiedUtc)}.
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  ) : null;
}

AlertThresholdModifyLogList.propTypes = {
  modificationLogs: PropTypes.array.isRequired,
};

interface Props {
  modificationLogs: ModificationLog[];
}

interface ModificationLog {
  username: string;
  emailAddress: string;
  lastModifiedUtc: string;
  alertOnAssetExit?: boolean;
}
