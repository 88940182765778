// --------------------------------------------------------------
// Created On: 2022-05-19
// Author: Zachary Thomas
//
// Last Modified: 2022-11-04
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./Widget.module.scss";

// Stylized wrapper for content.
export default function Widget(props: Props): Component {
  return <div className={`${styles.body} shadow-sm ${props.extendClass}`}>{props.children}</div>;
}

Widget.propTypes = {
  extendClass: PropTypes.string,
  children: PropTypes.any,
};

interface Props {
  extendClass?: string;
  children?: Component | Component[] | string;
}
