// --------------------------------------------------------------
// Created On: 2024-09-05
// Author: Zachary Thomas
//
// Last Modified: 2024-09-13
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Constants related to the asset template feature.
export const MONITORING_DEVICE_SENSOR_CARD_TYPE = "Monitoring Device Sensors";
export const ACTIVE_DTC_CARD_TYPE = "Active DTCs";
export const MIN_TEMPLATE_NAME_LENGTH = 5;
export const MAX_TEMPLATE_NAME_LENGTH = 50;
export const MAX_TEMPLATE_DESCRIPTION_LENGTH = 500;
export const MAX_HIGHLIGHT_ATTRIBUTES = 6;
export const MAX_CARDS = 10;
export const MAX_CARD_ATTRIBUTES = 15;
export const MIN_CARD_NAME_LENGTH = 5;
export const MAX_CARD_NAME_LENGTH = 50;
export const MAX_GAUGES = 5;
export const GAUGE_LOWEST_MIN_VALUE = -99999;
export const GAUGE_HIGHEST_MAX_VALUE = 99999;
export const MAX_REMOTE_OPERATION_ATTRIBUTES = 15;
