// --------------------------------------------------------------
// Created On: 2022-01-04
// Author: Zachary Thomas
//
// Last Modified: 2024-08-26
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import { MAX_MAINTENANCE_LENGTH, MAX_MAINTENANCE_NOTES_LENGTH } from "../../../../constants/miscellaneous";
import { UPDATE_SERVICE_HOURS_PERMISSION } from "../../../../constants/permissions";
import userHasPermission from "../../../../utilities/userHasPermission";
import styles from "./MaintenanceForm.module.scss";

// Form for writing up a maintenance log.
export default function MaintenanceForm(props: Props): Component {
  return (
    <div className="maintenance-form">
      <div className="mx-3 mb-3">
        <label className="mb-3">Service Description</label>
        <input
          data-test="maintenance-description-input"
          className="form-control mx-auto"
          type="text"
          maxLength={MAX_MAINTENANCE_LENGTH}
          value={props.workPerformed}
          disabled={!props.formIsEditable}
          onChange={(e) => props.onChangeWorkPerformed(e.target.value)}
        />
      </div>

      <div className="mx-3 mb-3">
        <label className="mb-3">Service Performed By</label>
        <input
          data-test="maintenance-performer-input"
          className="form-control mx-auto"
          type="text"
          maxLength={MAX_MAINTENANCE_LENGTH}
          value={props.workPerformedBy}
          disabled={!props.formIsEditable}
          onChange={(e) => props.onChangeWorkPerformedBy(e.target.value)}
        />
      </div>

      <div className="row mx-3 mb-3">
        <div className="col ps-0">
          <label className="mb-3">Date of Service</label>
          <input
            data-test="maintenance-date-input"
            className="form-control mx-auto"
            type="date"
            maxLength={MAX_MAINTENANCE_LENGTH}
            value={props.date}
            disabled={!props.formIsEditable}
            onChange={(e) => props.onChangeDate(e.target.value)}
          />
        </div>

        <div className="col">
          <label className="mb-3">Time of Service</label>
          <input
            data-test="maintenance-time-input"
            className="form-control mx-auto"
            type="time"
            maxLength={MAX_MAINTENANCE_LENGTH}
            value={props.time}
            disabled={!props.formIsEditable}
            onChange={(e) => props.onChangeTime(e.target.value)}
          />
        </div>

        <div className="col pe-0">
          <label className="mb-3">Cost</label>

          <input
            data-test="maintenance-cost-input"
            className="form-control mx-auto"
            type="text"
            maxLength={MAX_MAINTENANCE_LENGTH}
            value={props.cost}
            disabled={!props.formIsEditable}
            onChange={(e) => props.onChangeCost(e.target.value)}
          />
        </div>
      </div>

      <div className="row mx-3 mb-3">
        <div className="col ps-0">
          <label className="mb-3">Vibration Based Hours</label>
          <input
            data-test="vibration-runtime"
            className="form-control mx-auto"
            type="text"
            maxLength={MAX_MAINTENANCE_LENGTH}
            value={props.runtimeVibration}
            disabled={!props.formIsEditable}
            onChange={(e) => props.onChangeRuntimeVibration(e.target.value)}
          />
        </div>

        <div className="col">
          <label className="mb-3">Asset Hours</label>
          <input
            data-test="asset-runtime"
            className="form-control mx-auto"
            type="text"
            maxLength={MAX_MAINTENANCE_LENGTH}
            value={props.runtimeAsset}
            disabled={!props.formIsEditable}
            onChange={(e) => props.onChangeRuntimeAsset(e.target.value)}
          />
        </div>

        <div className="col pe-0">
          <label className="mb-3">Hours Since Last Serviced</label>
          <input
            data-test="maintenance-hours-since-service-input"
            className="form-control mx-auto"
            type="text"
            maxLength={MAX_MAINTENANCE_LENGTH}
            value={props.runtimeService}
            disabled={!props.formIsEditable}
            onChange={(e) => props.onChangeRuntimeService(e.target.value)}
          />
        </div>
      </div>

      <div className="mb-3 mx-3">
        <label className="mb-3">Additional Notes</label>
        <textarea
          data-test="maintenance-notes-input"
          className="form-control"
          rows={3}
          maxLength={MAX_MAINTENANCE_NOTES_LENGTH}
          value={props.note}
          disabled={!props.formIsEditable}
          onChange={(e) => props.onChangeNote(e.target.value)}
        />
      </div>

      {!props.isRented && userHasPermission([[UPDATE_SERVICE_HOURS_PERMISSION]]) && (
        <div className="mb-3 mx-3">
          <input
            className="form-check-input form-check-big"
            type="checkbox"
            checked={props.clearServiceHours}
            onChange={() => props.onClearServiceHours(!props.clearServiceHours)}
          />
          <label
            className={styles.clearServiceLabel}
            onClick={() => props.onClearServiceHours(!props.clearServiceHours)}
          >
            Reset hours since last serviced on {props.isNewMaintenanceLog ? "create" : "save"}.
          </label>
        </div>
      )}
    </div>
  );
}

MaintenanceForm.propTypes = {
  isRented: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  workPerformedBy: PropTypes.string.isRequired,
  workPerformed: PropTypes.string.isRequired,
  cost: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  formIsEditable: PropTypes.bool.isRequired,
  runtimeVibration: PropTypes.string.isRequired,
  runtimeAsset: PropTypes.string.isRequired,
  runtimeService: PropTypes.string.isRequired,
  isNewMaintenanceLog: PropTypes.bool.isRequired,
  clearServiceHours: PropTypes.bool.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onChangeTime: PropTypes.func.isRequired,
  onChangeWorkPerformedBy: PropTypes.func.isRequired,
  onChangeWorkPerformed: PropTypes.func.isRequired,
  onChangeCost: PropTypes.func.isRequired,
  onChangeNote: PropTypes.func.isRequired,
  onChangeRuntimeVibration: PropTypes.func.isRequired,
  onChangeRuntimeAsset: PropTypes.func.isRequired,
  onChangeRuntimeService: PropTypes.func.isRequired,
  onClearServiceHours: PropTypes.func.isRequired,
};

interface Props {
  isRented: boolean;
  date: string;
  time: string;
  workPerformedBy: string;
  workPerformed: string;
  cost: string;
  note: string;
  formIsEditable: boolean;
  runtimeVibration: string;
  runtimeAsset: string;
  runtimeService: string;
  isNewMaintenanceLog: boolean;
  clearServiceHours: boolean;
  onChangeDate: (date: string) => void;
  onChangeTime: (time: string) => void;
  onChangeWorkPerformedBy: (workPerformedBy: string) => void;
  onChangeWorkPerformed: (workPerformed: string) => void;
  onChangeCost: (cost: string) => void;
  onChangeNote: (note: string) => void;
  onChangeRuntimeVibration: (runtimeVibration: string) => void;
  onChangeRuntimeAsset: (runtimeAsset: string) => void;
  onChangeRuntimeService: (runtimeService: string) => void;
  onClearServiceHours: (clearServiceHours: boolean) => void;
}
