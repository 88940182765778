// --------------------------------------------------------------
// Created On: 2021-05-14
// Author: Zachary Thomas
//
// Last Modified: 2024-10-21
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./TitleBar.module.scss";

// Simple bar with title.
export default function TitleBar(props: Props): Component {
  return (
    <div data-test="title-bar" className={`${styles.body} ${props.isLeftAligned ? styles.leftAligned : ""} py-3`}>
      <span>{props.title}</span>
      <span>{props.children}</span>
    </div>
  );
}

TitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  isLeftAligned: PropTypes.bool,
  children: PropTypes.any,
};

interface Props {
  title: string;
  isLeftAligned?: boolean;
  children?: Component | Component[];
}
