// --------------------------------------------------------------
// Created On: 2021-09-01
// Author: Zachary Thomas
//
// Last Modified: 2024-09-17
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import { POWER_TO_PANEL_ATTRIBUTE, COMMON_ALARM_ATTRIBUTE } from "../../../constants/attributes";
import { MONITORING_DEVICE_SENSOR_CARD_TYPE, ACTIVE_DTC_CARD_TYPE } from "../../../constants/templates";
import SensorConnectedIcon from "../../../components/SensorConnectedIcon/SensorConnectedIcon";
import formatDateShortLocal from "../../../utilities/time/formatDateShortLocal";
import isValueBooleanString from "../../../utilities/isValueBooleanString";
import LightIcon from "../../../components/LightIcon/LightIcon";
import ActiveDtcCardBody from "./ActiveDtcCardBody/ActiveDtcCardBody";
import PropTypes from "prop-types";
import styles from "./AssetAttributeCard.module.scss";

// Asset card that contains a list of attributes.
export default function AssetAttributeCard(props: Props): Component {
  // Determines if a light should be shown as on or off based on the attribute code and value.
  function lightIsOn(attributeCode: string, value: string | null, isStale: boolean): boolean {
    if (isStale || value === null) {
      return false;
    } else if (attributeCode === POWER_TO_PANEL_ATTRIBUTE) {
      return true;
    } else if (isValueBooleanString(value) && value.toLowerCase() === "true") {
      return true;
    } else {
      return false;
    }
  }

  // Determines what color a light should be based on the attribute code and value.
  function lightColor(attributeCode: string, value: string | null): "red" | "green" {
    if (value === null) {
      return "red";
    } else if (
      attributeCode === POWER_TO_PANEL_ATTRIBUTE &&
      isValueBooleanString(value) &&
      value.toLowerCase() === "false"
    ) {
      return "red";
    } else if (
      attributeCode === COMMON_ALARM_ATTRIBUTE &&
      isValueBooleanString(value) &&
      value.toLowerCase() === "true"
    ) {
      return "red";
    } else {
      return "green";
    }
  }

  // Checks if the current card is an empty dynamic monitoring device sensors card.
  // This check is important since this is a card that dynamically hides itself if there are no attributes in the card.
  if (props.cardTypeName === MONITORING_DEVICE_SENSOR_CARD_TYPE && props.attributes.length === 0) {
    return <Fragment />;
  } else {
    return (
      <div data-test="asset-attribute-card" className="col-12 col-lg-6 my-3">
        <div className={styles.body}>
          <div className={styles.titleBar}>
            <label className={styles.title}>{props.cardTypeName === null ? props.name : props.cardTypeName}</label>
          </div>
          {props.cardTypeName === ACTIVE_DTC_CARD_TYPE ? (
            <ActiveDtcCardBody attributes={props.attributes} />
          ) : (
            <div>
              {props.attributes.length > 0 ? (
                <table className={`${styles.table} table mb-0 pb-0`}>
                  <thead>
                    <tr>
                      <th className={styles.th}>Name</th>
                      <th className={styles.th}>Value</th>
                      <th className={styles.th}></th>
                      <th className={styles.th}>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.attributes.map((attribute, i) => (
                      <tr
                        key={i}
                        className={attribute.isStale ? styles.staleData : styles.activeData}
                        onClick={() => props.onSelectAttribute(attribute.regAttributeId)}
                      >
                        <td>{attribute.attributeName}</td>
                        <td>
                          {attribute.currentValue === null
                            ? "N/A"
                            : `${attribute.currentValue} ${
                                attribute.unitShortName === null ? "" : attribute.unitShortName
                              }`}
                        </td>
                        <td>
                          {attribute.connectedAttribute !== null &&
                            attribute.connectedAttribute !== undefined &&
                            attribute.connectedAttribute.attributeCode !== null && (
                              <SensorConnectedIcon
                                attributeCode={attribute.connectedAttribute.attributeCode}
                                isConnected={attribute.connectedAttribute.currentValue === "True"}
                              />
                            )}

                          {isValueBooleanString(attribute.currentValue) && (
                            <LightIcon
                              isOn={lightIsOn(attribute.attributeCode, attribute.currentValue, attribute.isStale)}
                              color={lightColor(attribute.attributeCode, attribute.currentValue)}
                            />
                          )}
                        </td>
                        <td>
                          {attribute.currentValueUtc === null ? "N/A" : formatDateShortLocal(attribute.currentValueUtc)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className={styles.cardMessage}>
                  {props.cardTypeName === MONITORING_DEVICE_SENSOR_CARD_TYPE
                    ? "This asset does not have any monitoring device analog or digital sensors configured."
                    : "No attributes exist for this card."}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

AssetAttributeCard.propTypes = {
  assetTemplateCardId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  cardTypeName: PropTypes.string,
  attributes: PropTypes.array.isRequired,
  onSelectAttribute: PropTypes.func.isRequired,
};

interface Props {
  assetTemplateCardId: number;
  name: string;
  cardTypeName: string | null;
  attributes: Attribute[];
  onSelectAttribute: (attributeId: number) => void;
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  connectedRegAttributeId: number | null;
  connectedAttribute?: Attribute | null;
  unitShortName: string | null;
  unitLongName: string | null;
  icon: string;
  currentValue: string | null;
  currentValueUtc: string | null;
  isHistorical: boolean;
  isGraphable: boolean;
  isStale: boolean;
}
