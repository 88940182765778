// --------------------------------------------------------------
// Created On: 2022-09-20
// Author: Zachary Thomas
//
// Last Modified: 2022-09-20
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import styles from "./HelpAsset.module.scss";

// Explains what an asset is.
export default function HelpAsset(): Component {
  return (
    <div>
      <p className="px-3">
        An asset represents anything that you can track and manage. An asset may be comprised of the
        following three parts.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Product:</b> Each asset has a product. A product can be a pump, generator, or anything
        else that can be tracked and managed.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Controller:</b> Some assets may have a controller. A controller at minimum turns on or
        off the product, though it can also return information about the product.
      </p>

      <p className="px-3 mt-3 mb-5">
        <b>Monitoring Device:</b> Some assets may have a monitoring device. A monitoring device can
        retrieve information such as location, temperature, and vibration.
      </p>

      <div className="row justify-content-center">
        <div className="col-12 col-lg-6 p-2">
          <img
            className={styles.image}
            src="/images/asset_info_1.png"
            alt="Asset with only a product."
          />
        </div>

        <div className="col-12 col-lg-6 p-2">
          <img
            className={styles.image}
            src="/images/asset_info_2.png"
            alt="Asset with a product, controller, and monitoring device."
          />
        </div>
      </div>
    </div>
  );
}
