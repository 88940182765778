// --------------------------------------------------------------
// Created On: 2023-02-08
// Author: Zachary Thomas
//
// Last Modified: 2024-04-17
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import { MIN_EMAIL_LENGTH, MAX_EMAIL_LENGTH } from "../../../../constants/miscellaneous";
import PropTypes from "prop-types";
import styles from "./ReportEmailForm.module.scss";

// Form for assigning emails to a report.
export default function ReportEmailForm(props: Props): Component {
  return (
    <div className="my-3 mx-1">
      <div className="row align-items-center">
        <div className="col-4 col-lg-3 col-xl-2">
          <label className={`${styles.title} d-none d-md-inline`}>
            Email Address #{props.formIndex + 1}:
          </label>
          <label className={`${styles.title} d-inline d-md-none`}>
            Email #{props.formIndex + 1}:
          </label>
        </div>
        <div className="col-5 col-sm-6 col-lg-8 col-xl-9">
          <input
            data-test="report-email-form-input"
            className="form-control"
            type="text"
            minLength={MIN_EMAIL_LENGTH}
            maxLength={MAX_EMAIL_LENGTH}
            value={props.value}
            disabled={props.disabled}
            onChange={(e) => props.onChange(props.formId, e.target.value)}
          />
        </div>

        <div className="col-3 col-sm-2 col-lg-1">
          <button
            type="button"
            className={`${styles.button} btn btn-danger float-end`}
            disabled={props.disabled}
            onClick={() => props.onDelete(props.formId)}
          >
            <i className="fa fa-fw fa-times fa-xs" />
          </button>
        </div>
      </div>
    </div>
  );
}

ReportEmailForm.propTypes = {
  formIndex: PropTypes.number.isRequired,
  formId: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

interface Props {
  formIndex: number;
  formId: number;
  value: string;
  disabled: boolean;
  onChange: (formId: number, value: string) => void;
  onDelete: (formId: number) => void;
}
