// --------------------------------------------------------------
// Created On: 2022-01-14
// Author: Zachary Thomas
//
// Last Modified: 2024-08-05
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useEffect, useState } from "react";
import { API, CHECK_ALERTS_MILLISECONDS, MAX_NAVBAR_ALERTS } from "../../../constants/miscellaneous";
import useApi from "../../../hooks/useApi";
import apiRequest from "../../../utilities/api/apiRequest";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAlerts } from "../../../redux/selectors";
import { setAlerts, deleteAlert } from "../../../redux/actions";
import styles from "./AlertIndicator.module.scss";

// Periodically updating list of alerts.
export default function AlertIndicator() {
  const [counter, setCounter] = useState<number>(0);
  const [unreadAlertCount, setUnreadAlertCount] = useState<number>(0);
  const [shake, setShake] = useState<boolean>(false);
  const alerts = useSelector(getAlerts);
  const dispatch = useDispatch();

  // Get alert data from API.
  useApi(
    () => {
      return true;
    },
    {
      method: "GET",
      url: `${API}/alertmessage/all`,
      hideLog: true,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        dispatch(setAlerts(responseBody.alertMessages));
      } else {
        console.error("Internal server error. Unable to retrieve alert messages.");
      }
    },
    [counter]
  );

  // When the number of unread alert messages change, update the counter.
  useEffect(() => {
    const newUnreadAlertCount = alerts.filter((alert) => alert.unread).length;
    if (newUnreadAlertCount > unreadAlertCount) {
      setUnreadAlertCount(newUnreadAlertCount);
      setShake(true);
    } else if (newUnreadAlertCount < unreadAlertCount) {
      setUnreadAlertCount(newUnreadAlertCount);
    } else if (newUnreadAlertCount === 0) {
      setShake(false);
    }
  }, [JSON.stringify(alerts), unreadAlertCount]);

  // Periodically poll alert data.
  useEffect(() => {
    const newTimerId = setTimeout(
      () => setCounter((previousCounter) => previousCounter + 1),
      CHECK_ALERTS_MILLISECONDS
    );

    return () => {
      clearTimeout(newTimerId);
    };
  }, [counter]);

  // Delete an alert message.
  async function deleteAlertMessage(alertMessageId: number): Promise<void> {
    dispatch(deleteAlert(alertMessageId));
    const requestBody = { alertMessageIds: [alertMessageId] };
    apiRequest(`${API}/alertmessage`, "DELETE", requestBody);
  }

  return (
    <div className={`${styles.container} dropdown mx-3`}>
      <button
        className={`${styles.btn} btn btn-primary ml-4 h-100`}
        type="button"
        onClick={() => setShake(false)}
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-test="alert-indicator"
      >
        {unreadAlertCount > 0 && (
          <span className={styles.badge} data-test="alert-count">
            {unreadAlertCount < 100 ? unreadAlertCount : "99+"}
          </span>
        )}

        <i className={`${styles.bell} fa fa-fw fa-bell ${shake ? styles.shake : ""}`} />
      </button>

      <div className="dropdown-menu dropdown-menu-end" data-test="alerts-dropdown">
        {alerts
          .filter((alert) => alert.unread)
          .map((alert, i) => (
            <Fragment key={alert.alertMessageId}>
              {i < MAX_NAVBAR_ALERTS && (
                <div
                  className={`${styles.item} d-flex align-items-top dropdown-item pb-3`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div>
                    <i
                      className={
                        `${styles.icon} fa fa-fw me-2` +
                        ` fa-${alert.urgency.toLowerCase() === "info" ? "info-circle" : "exclamation-circle"}`
                      }
                      style={{
                        color: `var(--alert-${alert.urgency.toLowerCase()})`,
                      }}
                    />
                  </div>

                  <p
                    className={
                      `${styles.text} mb-0` +
                      ` ${alert.urgency.toLowerCase() === "read" ? styles.read : ""}` +
                      ` ${alert.urgency.toLowerCase() === "emergency" ? styles.emergency : ""}`
                    }
                  >
                    <span key={alert.alertMessageId}>{alert.message}</span>
                  </p>

                  <div className={styles.btnContainer}>
                    <button
                      className={`${styles.closeBtn} m-0 p-0 pull-right`}
                      type="button"
                      onClick={() => deleteAlertMessage(alert.alertMessageId)}
                    >
                      <i className="small-btn-text fa fa-fw fa-times" data-test="dismiss-alert-button" />
                    </button>
                  </div>
                </div>
              )}
            </Fragment>
          ))}
        <Link to="/alert/overview" className="router-link">
          <button className={`${styles.viewAll} ${styles.item} dropdown-item`}>View All Alerts</button>
        </Link>
      </div>
    </div>
  );
}

interface AlertMessage {
  alertMessageId: number;
  alertCode: string;
  date: string;
  message: string;
  unread: boolean;
  urgency: string;
}

interface ResponseBody {
  alertMessages: AlertMessage[];
}
