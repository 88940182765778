// --------------------------------------------------------------
// Created On: 2024-08-21
// Author: KC Willard
//
// Last Modified: 2024-09-19
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useReducer } from "react";
import Spinner from "../../components/Spinner/Spinner";
import Error500Page from "../Error500Page/Error500Page";
import deepCopy from "../../utilities/deepCopy";
import useApi from "../../hooks/useApi";
import ResourceList from "../../components/ResourceList/ResourceList";
import TemplateManagementWizard from "./TemplateManagementWizard/TemplateManagementWizard";
import { API } from "../../constants/miscellaneous";
import { TEMPLATE_TYPES } from "../../constants/reducerActions";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import { CREATE_TEMPLATES_PERMISSION } from "../../constants/permissions";
import TemplateListRow from "./TemplateListRow/TemplateListRow";
import HelpTemplate from "../../components/HelpTemplate/HelpTemplate";
import styles from "./ManageTemplatesPage.module.scss";

// Page for creating, editing, and deleting templates.
export default function ManageTemplatesPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null);
  const [attributeMap, setAttributeMap] = useState<AttributesById>({});
  const [templateCardTypes, setTemplateCardTypes] = useState<TemplateCardType[]>([]);
  const [templates, dispatch] = useReducer(templateReducer, []);
  const currentUser = useSelector(getCurrentUser);

  // Get all templates and supporting information from the API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset-template`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        dispatch({
          type: TEMPLATE_TYPES.SET_TEMPLATES,
          payload: {
            templates: responseBody.assetTemplates,
          },
        });
        setTemplateCardTypes(responseBody.templateCardTypes);
        setAttributeMap(responseBody.attributesById);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // Template reducer.
  function templateReducer(state: AssetTemplate[], action: Action): AssetTemplate[] {
    switch (action.type) {
      case TEMPLATE_TYPES.SET_TEMPLATES: {
        if (action.payload.templates !== undefined) {
          return sortTemplates(action.payload.templates);
        } else {
          return [];
        }
      }

      case TEMPLATE_TYPES.CREATE_TEMPLATE: {
        let stateDeepCopy = deepCopy(state);
        const newTemplate = action.payload.template;
        if (newTemplate !== undefined) {
          // Check if the template already exists before creating them.
          const templateExists = stateDeepCopy.some(
            (template) => template.assetTemplateId === newTemplate.assetTemplateId
          );
          if (!templateExists) {
            stateDeepCopy.push(newTemplate);
            stateDeepCopy = sortTemplates(stateDeepCopy);
          }
        }
        return stateDeepCopy;
      }

      case TEMPLATE_TYPES.UPDATE_TEMPLATE: {
        let stateDeepCopy = deepCopy(state);
        const updatedTemplate = action.payload.template;
        if (updatedTemplate !== undefined) {
          // Find the index of the template.
          const templateIndex = stateDeepCopy.findIndex(
            (template) => template.assetTemplateId === updatedTemplate.assetTemplateId
          );

          // Don't continue if we couldn't find the template.
          if (templateIndex === -1) {
            return stateDeepCopy;
          }

          // Replace the current template with the updated one.
          stateDeepCopy.splice(templateIndex, 1, updatedTemplate);

          // Sort the templates.
          stateDeepCopy = sortTemplates(stateDeepCopy);
        }
        return stateDeepCopy;
      }

      case TEMPLATE_TYPES.DELETE_TEMPLATE: {
        const stateDeepCopy = deepCopy(state);
        const templateId = action.payload.templateId;

        if (templateId !== undefined) {
          // Find the index of the template.
          const templateIndex = stateDeepCopy.findIndex((template) => template.assetTemplateId === templateId);

          // Don't continue if we couldn't find the template.
          if (templateIndex === -1) {
            return stateDeepCopy;
          }

          // Delete the current template.
          stateDeepCopy.splice(templateIndex, 1);
        }
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  // Sort templates.
  function sortTemplates(templates: AssetTemplate[]): AssetTemplate[] {
    return templates.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // Get an array of attributes by passing in an array of attribute IDs.
  function getAttributesByIds(attributeIds: number[], attributeMap: AttributesById): Attribute[] {
    return attributeIds.map((attributeId) => attributeMap[attributeId]);
  }

  // Get an array of attributes by passing in an array of attribute IDs and filter out attributes that do not have units.
  function getAttributesByIdFilterOutAttributesWithoutUnits(
    attributeIds: number[],
    attributeMap: AttributesById
  ): Attribute[] {
    let attributes = attributeIds.map((attributeId) => attributeMap[attributeId]);
    attributes = attributes.filter((attribute) => attribute.unitId !== null);
    return attributes;
  }

  // Get all valid attribute IDs from attribute map.
  function getAttributeIdsFromAttributeMap(attributeMap: AttributesById): number[] {
    const keys = Object.keys(attributeMap);
    const attributeIds: number[] = [];
    keys.forEach((key) => {
      const keyIntegerValue = parseInt(key, 10);
      if (!isNaN(keyIntegerValue)) {
        attributeIds.push(keyIntegerValue);
      }
    });
    return attributeIds;
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      <div className="row">
        <div className={`${styles.list} col-12 col-md mx-0`}>
          <ResourceList
            resourceNameSingular="Template"
            resourceNamePlural="Templates"
            resourceArticle="a"
            headerButtonLabel="Create Template"
            headerButtonLabelSmall="Create"
            headerButtonUserPermissions={[[CREATE_TEMPLATES_PERMISSION]]}
            resourceIdKey="assetTemplateId"
            resources={templates}
            resourcePriorityKey="isImmutable"
            resourceRow={TemplateListRow}
            helpModal={<HelpTemplate />}
            filterKeys={["name"]}
            loading={loading}
            onClickHeaderButton={() => setSelectedTemplateId(0)}
            onSelect={(assetTemplateId) => setSelectedTemplateId(assetTemplateId)}
          />

          {selectedTemplateId !== null && (
            <TemplateManagementWizard
              isCreatingNewTemplate={selectedTemplateId === 0}
              assetTemplateId={selectedTemplateId}
              attributeMap={attributeMap}
              templateCardTypes={templateCardTypes}
              onClose={() => setSelectedTemplateId(null)}
              onAction={(action) => dispatch(action)}
            />
          )}

          <datalist id="datalist-attributes">
            {getAttributesByIds(getAttributeIdsFromAttributeMap(attributeMap), attributeMap).map((attribute) => (
              <option key={attribute.regAttributeId} value={attribute.attributeName} />
            ))}
          </datalist>

          <datalist id="datalist-attributes-gauges">
            {getAttributesByIdFilterOutAttributesWithoutUnits(
              getAttributeIdsFromAttributeMap(attributeMap),
              attributeMap
            ).map((attribute) => (
              <option key={attribute.regAttributeId} value={attribute.attributeName} />
            ))}
          </datalist>
        </div>
      </div>
    </div>
  );
}

interface ResponseBody {
  assetTemplates: AssetTemplate[];
  templateCardTypes: TemplateCardType[];
  attributesById: AttributesById;
}

interface AttributesById {
  [key: string]: Attribute;
}

interface Attribute {
  formId: number;
  regAttributeId: number;
  attributeName: string;
  unitId: number | null;
  unitShortName: string | null;
  unitLongName: string | null;
}

interface AssetTemplate {
  assetTemplateId: number;
  name: string;
  isImmutable: boolean;
}

interface TemplateCardType {
  templateCardTypeId: number;
  name: string;
  description: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  templates?: AssetTemplate[];
  template?: AssetTemplate;
  templateId?: number;
}
