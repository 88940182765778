// --------------------------------------------------------------
// Created On: 2024-07-23
// Author: Hannah Vaughan
//
// Last Modified: 2024-11-04
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./CategoryContainer.module.scss";
import IconTooltip from "../../../../components/IconTooltip/IconTooltip";

// A container for a single category and all of its controller configurations.
export default function CategoryContainer(props: Props): Component {
  return (
    <div className={styles.body}>
      <div className={styles.titleBar}>
        <div className="row justify-content-between">
          <div className="col-auto">
            <label className={styles.title}>
              {props.category}&nbsp;
              <span className={styles.tooltip}>
                <IconTooltip
                  id={props.category.replace(/\s/g, "")}
                  icon="info-circle"
                  message={props.tooltip}
                  color="var(--link-bright)"
                />
              </span>
            </label>
          </div>
          <div className={`${styles.readConfigButtonContainer} col d-flex justify-content-end`}>
            <button
              type="button"
              className={`${styles.readConfigButton} btn btn-primary`}
              disabled={props.loading}
              onClick={() => props.onReadCategory(props.category)}
            >
              Read Config
            </button>
          </div>
        </div>
      </div>
      {props.children}
    </div>
  );
}

CategoryContainer.propTypes = {
  category: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.any,
  onReadCategory: PropTypes.func.isRequired,
};

interface Props {
  category: string;
  tooltip: string;
  loading: boolean;
  children: Component | Component[];
  onReadCategory: (categoryName: string) => void;
}
