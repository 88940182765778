// --------------------------------------------------------------
// Created On: 2024-09-25
// Author: Zachary Thomas
//
// Last Modified: 2024-09-25
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Get a string for a data object that describes the current day.
export default function formatDateObjectDailyLocal(dateObject: Date): string {
  return dateObject.toLocaleDateString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });
}
