// --------------------------------------------------------------
// Created On: 2021-10-06
// Author: Zachary Thomas
//
// Last Modified: 2023-03-07
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./HamburgerButton.module.scss";

// A hamburger menu button for opening up a navigation drawer.
export default function HamburgerButton(props: Props): Component {
  return (
    <button
      type="button"
      className={`${styles.body} btn btn-secondary d-inline-block d-xl-none me-3`}
      onClick={() => props.onClick()}
    >
      <i className="fa fa-fw fa-bars fa-2x" data-test="hamburger-menu-button" />
    </button>
  );
}

HamburgerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

interface Props {
  onClick: () => void;
}
