// --------------------------------------------------------------
// Created On: 2022-09-16
// Author: Zachary Thomas
//
// Last Modified: 2023-12-26
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, Fragment } from "react";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import DeviceStatusValue from "./DeviceStatusValue/DeviceStatusValue";
import PropTypes from "prop-types";
import useApi from "../../../hooks/useApi";
import Spinner from "../../../components/Spinner/Spinner";
import Error from "../../../components/Error/Error";
import { COPILOT_DEVICE_TYPE, API } from "../../../constants/miscellaneous";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import styles from "./DeviceStatusModal.module.scss";

// Modal for status information for a single device.
export default function DeviceStatusModal(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const currentUser = useSelector(getCurrentUser);

  // Get the most up to date status information if the device supports on demand status queries.
  useApi(
    () => {
      setErrorMessage("");
      if (props.deviceId > 0 && props.deviceType === COPILOT_DEVICE_TYPE) {
        setLoading(true);
        return true;
      } else {
        return false;
      }
    },
    {
      method: "POST",
      url: `${API}/company/${currentUser.companyId}/device/${props.deviceId}/status`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody && responseBody.monitoringDevices.length === 1) {
        props.onUpdateStatus(responseBody.monitoringDevices[0]);
        setErrorMessage("");
      } else {
        setErrorMessage("Unable to query live status information. Please try again later.");
      }
      setLoading(false);
    },
    [props.deviceId]
  );

  return (
    <Fragment>
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => props.onClose()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--super-modal-z-index)" }}
        size="lg"
        animation
        centered
      >
        <ModalHeader>
          <h5 className="font-weight-bold">Device Status</h5>
        </ModalHeader>

        <ModalBody>
          <div className={styles.deviceInfo}>
            {props.deviceType} {props.deviceIdentifier}
          </div>
          {props.statuses.length > 0 ? (
            <table className={`${styles.table} table mb-0 pb-0`}>
              <thead className={styles.thead}>
                <tr>
                  <th className={styles.th}>Name</th>
                  <th className={styles.th}>Value</th>
                  <th className={styles.th}>Info</th>
                </tr>
              </thead>
              <tbody>
                {props.statuses.map((deviceStatus) => (
                  <DeviceStatusValue
                    key={deviceStatus.name}
                    name={deviceStatus.name}
                    description={deviceStatus.description}
                    value={deviceStatus.value}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className={styles.noStatus}>This device is not reporting any status information.</div>
          )}
          <div className="my-3 mb-0">
            <Error message={errorMessage} />
          </div>
        </ModalBody>

        <ModalFooter>
          <button className="btn btn-secondary" type="button" onClick={() => props.onClose()}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

DeviceStatusModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  deviceId: PropTypes.number.isRequired,
  deviceIdentifier: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  statuses: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
};

interface Props {
  showModal: boolean;
  deviceId: number;
  deviceIdentifier: string;
  deviceType: string;
  statuses: Status[];
  onClose: () => void;
  onUpdateStatus: (monitoringDevice: Device) => void;
}

interface Status {
  code: string;
  description: string;
  deviceStatusId: number;
  name: string;
  value: string;
}

interface Device {
  deviceId: number;
  deviceType: string;
  deviceIdentifier: string;
  statuses: Status[];
}

interface ResponseBody {
  monitoringDevices: Device[];
}
