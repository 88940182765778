// --------------------------------------------------------------
// Created On: 2021-11-18
// Author: Zachary Thomas
//
// Last Modified: 2023-09-08
// Modified By: Zachary Thomas
//
// Copyright 2021 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import { MIN_PASSWORD_LENGTH } from "../../constants/miscellaneous";
import styles from "./PasswordRequirements.module.scss";

// Text describing the password requirements.
export default function PasswordRequirements(): Component {
  return (
    <div className={styles.body}>
      <h4>Password Requirements</h4>
      <ul>
        <div className={`${styles.row} row`}>
          <div className="col-12 col-md">
            <li>{`Minimum length is ${MIN_PASSWORD_LENGTH} characters long`}</li>
            <li>At least one number is required</li>
            <li>At least one lower case letter is required</li>
          </div>
          <div className="col-12 col-md">
            <li>At least one upper case letter is required</li>
            <li>At least one special character is required</li>
          </div>
        </div>
      </ul>
    </div>
  );
}
