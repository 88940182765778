// --------------------------------------------------------------
// Created On: 2021-06-07
// Author: Zachary Thomas
//
// Last Modified: 2024-01-25
// Modified By: Zachary Thomas
//
// Copyright 2022 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import Modal from "../Modal/Modal";
import ModalHeader from "../ModalHeader/ModalHeader";
import ModalBody from "../ModalBody/ModalBody";
import ModalFooter from "../ModalFooter/ModalFooter";
import PropTypes from "prop-types";
import styles from "./ConfirmModal.module.scss";

// Modal for confirming or declining an action.
export default function ConfirmModal(props: Props): Component {
  return (
    <div data-test="confirmation-modal">
      <Modal
        show={props.showModal}
        onHide={() => props.onClose()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--super-modal-z-index)" }}
        animation
        centered
      >
        <ModalHeader>
          <h5>{props.title}</h5>
        </ModalHeader>
        <ModalBody>
          {Array.isArray(props.content) ? (
            <Fragment>
              {props.content.map((section, i) => (
                <p key={i} className="my-3">
                  {section}
                </p>
              ))}
            </Fragment>
          ) : (
            <p>{props.content}</p>
          )}
        </ModalBody>
        <ModalFooter>
          {props.yesText !== undefined && (
            <button
              data-test="confirm-modal-confirm-button"
              type="button"
              className={`btn ${props.danger ? "btn-danger" : "btn-primary"}`}
              data-dismiss="modal"
              onClick={() => props.onYes()}
            >
              {props.yesText}
            </button>
          )}
          {props.noText !== undefined && (
            <button
              data-test="confirm-modal-cancel-button"
              type="button"
              className="btn btn-secondary"
              onClick={() => props.onNo()}
            >
              {props.noText}
            </button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}

ConfirmModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired || PropTypes.array.isRequired,
  yesText: PropTypes.string,
  noText: PropTypes.string,
  danger: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
};

interface Props {
  showModal: boolean;
  title: string;
  content: string | string[];
  yesText?: string;
  noText?: string;
  danger?: boolean;
  onClose: () => void;
  onYes: () => void;
  onNo: () => void;
}
