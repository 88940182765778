// --------------------------------------------------------------
// Created On: 2022-06-16
// Author: Zachary Thomas
//
// Last Modified: 2024-09-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import useApi from "../../hooks/useApi";
import LocationDateSelector from "./LocationDateSelector/LocationDateSelector";
import LocationMap from "./LocationMap/LocationMap";
import nowToLocalIso from "../../utilities/time/nowToLocalIso";
import Spinner from "../../components/Spinner/Spinner";
import { useParams } from "react-router-dom";
import {
  LOCATION_STARTING_DATE_OFFSET,
  LOCATION_ENDING_DATE_OFFSET,
  API,
  MS_PER_MINUTE,
} from "../../constants/miscellaneous";
import { LOCATION_ATTRIBUTE, GPS_TRAVEL_SPEED_ATTRIBUTE } from "../../constants/attributes";
import isoAddDays from "../../utilities/time/isoAddDays";
import isoUtcToIsoLocal from "../../utilities/time/isoUtcToIsoLocal";
import cleanLocationHistory from "../../utilities/cleanLocationHistory";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import deepCopy from "../../utilities/deepCopy";

// Page for viewing location history data for the current asset.
export default function AssetLocationHistoryPage(): Component {
  const [assetName, setAssetName] = useState<string>("");
  const [startDate, setStartDate] = useState<string>(calculateStartDate(null));
  const [endDate, setEndDate] = useState<string>(nowToLocalIso(LOCATION_ENDING_DATE_OFFSET).split("T")[0]);
  const [startDateOffset, setStartDateOffset] = useState<string>("");
  const [endDateOffset, setEndDateOffset] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [frequencyMinutes, setFrequencyMinutes] = useState<number>(0);
  const [locations, setLocations] = useState<LocationHistoryPoint[]>([]);
  const [speedDataPoints, setSpeedDataPoints] = useState<HistoricalDataPoint[]>([]);
  const [speedUnitName, setSpeedUnitName] = useState<string>("");
  const [currentLocation, setCurrentLocation] = useState<string | null>(null);
  const currentUser = useSelector(getCurrentUser);
  const { assetId } = useParams();

  // Get the most recent location log and asset name from API.
  useApi(
    () => {
      if (assetId !== undefined && parseInt(assetId, 10) > 0) {
        return true;
      } else {
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/${assetId}/location`,
      body: null,
    },
    async (response: Response, responseBody: ResponseBodyCurrentLocation) => {
      if (response.ok && responseBody) {
        setAssetName(responseBody.assetName);
        setCurrentLocation(responseBody.currentValue);
        setStartDate(
          calculateStartDate({ currentValue: responseBody.currentValue, currentValueUtc: responseBody.currentValueUtc })
        );
        setFailedToLoad(false);
      } else if (response.status === 404) {
        // If there isn't any location logs, we will get a 404. Hide any previous location data if this is the case.
        // Though don't treat it as an error, since not having location data is possible.
        setAssetName("");
        setCurrentLocation(null);
      } else {
        setAssetName("");
        setCurrentLocation(null);
        setFailedToLoad(true);
      }
    },
    [assetId]
  );

  // Get historical location data from API.
  useApi(
    () => {
      if (assetId !== undefined && parseInt(assetId, 10) > 0 && startDateOffset !== "" && endDateOffset !== "") {
        setLoading(true);
        return true;
      } else {
        setLoading(false);
        return false;
      }
    },
    {
      method: "POST",
      url: `${API}/company/${currentUser.companyId}/asset/${assetId}/history`,
      body: {
        startDate: startDateOffset,
        endDate: endDateOffset,
        attributeCode: LOCATION_ATTRIBUTE,
      },
    },
    async (response: Response, responseBody: ResponseBodyHistoricalData) => {
      if (response.ok && responseBody) {
        const cleanedResponseBody = cleanLocationHistory(responseBody);
        setLocations(cleanedResponseBody.dataPoints);
        setFailedToLoad(false);
      } else {
        setLocations([]);
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [assetId, startDateOffset, endDateOffset]
  );

  // Get historical travel speed data from API.
  useApi(
    () => {
      if (assetId !== undefined && parseInt(assetId, 10) > 0 && startDateOffset !== "" && endDateOffset !== "") {
        setLoading(true);
        return true;
      } else {
        return false;
      }
    },
    {
      method: "POST",
      url: `${API}/company/${currentUser.companyId}/asset/${assetId}/history`,
      body: {
        startDate: startDateOffset,
        endDate: endDateOffset,
        attributeCode: GPS_TRAVEL_SPEED_ATTRIBUTE,
      },
    },
    async (response: Response, responseBody: ResponseBodyHistoricalData) => {
      if (response.ok && responseBody) {
        setSpeedDataPoints(responseBody.dataPoints);
        setSpeedUnitName(responseBody.unitShortName);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [assetId, startDateOffset, endDateOffset]
  );

  // Combine location data and GPS speed into one array of objects.
  function getCombinedLocationAndSpeedDataPoints(
    locations: LocationHistoryPoint[],
    speedDataPoints: HistoricalDataPoint[]
  ): LocationHistoryPoint[] {
    const locationsWithSpeed: LocationHistoryPoint[] = [];
    const locationSpeedMap: LocationTimeMap = {};

    // Use a hash table to match times between location timestamps and speed.
    locations.forEach((location) => {
      locationSpeedMap[location.changeDate] = deepCopy(location);
      const speedDataPoint = speedDataPoints.find((speedDataPoint) => {
        const timeDifference = Math.abs(
          new Date(location.changeDate).getTime() - new Date(speedDataPoint.dateTimeUtc).getTime()
        );
        return timeDifference < MS_PER_MINUTE;
      });
      if (speedDataPoint !== undefined) {
        locationSpeedMap[location.changeDate].speed = speedDataPoint.value;
      }
    });

    // Now that all of the values are mapped to the hash table, create an array of the resulting objects.
    Object.keys(locationSpeedMap).forEach((locationSpeedMapKey) =>
      locationsWithSpeed.push(locationSpeedMap[locationSpeedMapKey])
    );

    return locationsWithSpeed;
  }

  // When start or end date is changed, update the offset date that is used by the API.
  useEffect(() => {
    if (startDate !== null && startDate.length > 0 && endDate !== null && endDate.length > 0) {
      setStartDateOffset(isoAddDays(`${startDate}T00:00:00`, -1).split(".")[0]);
      setEndDateOffset(isoAddDays(`${endDate}T00:00:00`, 1).split(".")[0]);
    }
  }, [startDate, endDate]);

  // Compare the last time a reading was taken and the default starting date, select the one that is oldest.
  function calculateStartDate(currentLocation: CurrentLocation | null): string {
    const defaultStartDate = nowToLocalIso(LOCATION_STARTING_DATE_OFFSET).split("T")[0];
    if (currentLocation !== null) {
      const locationDateTime = isoUtcToIsoLocal(currentLocation.currentValueUtc);
      const locationDate = locationDateTime.split("T")[0];
      if (locationDate < defaultStartDate) {
        return locationDate;
      }
    }
    return defaultStartDate;
  }

  return (
    <div>
      <Spinner loading={loading} />

      <LocationDateSelector
        start={startDate}
        end={endDate}
        frequencyMinutes={frequencyMinutes}
        currentLocation={currentLocation}
        onChangeStart={(startDate) => setStartDate(startDate)}
        onChangeEnd={(endDate) => setEndDate(endDate)}
        onChangeFrequencyMinutes={(frequencyMinutes) => setFrequencyMinutes(frequencyMinutes)}
      />
      <LocationMap
        locations={getCombinedLocationAndSpeedDataPoints(locations, speedDataPoints)}
        speedUnitName={speedUnitName}
        startDate={startDate}
        endDate={endDate}
        frequencyMinutes={frequencyMinutes}
        assetId={parseInt(assetId || "0", 10) || 0}
        assetName={assetName}
        failedToLoad={failedToLoad}
      />
    </div>
  );
}

interface ResponseBodyCurrentLocation {
  assetName: string;
  currentValue: string;
  currentValueUtc: string;
}

interface ResponseBodyHistoricalData {
  dataPoints: HistoricalDataPoint[];
  unitShortName: string;
}

interface LocationTimeMap {
  [key: string]: LocationHistoryPoint;
}

interface CurrentLocation {
  currentValue: string;
  currentValueUtc: string;
}
