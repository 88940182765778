// --------------------------------------------------------------
// Created On: 2021-09-15
// Author: Zachary Thomas
//
// Last Modified: 2023-02-13
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import Xarrow from "react-xarrows";
import PropTypes from "prop-types";
import { MAX_DIAGRAM_DEPTH, MAX_DIAGRAM_BREADTH } from "../../../../../constants/miscellaneous";
import styles from "./DiagramNode.module.scss";

// A node in a diagram and all of its children.
export default function DiagramNode(props: Props): Component {
  // Get parent node's unique path string.
  function getParentPath(path: string): string {
    const pathArray = path.split("-");
    if (pathArray.length > 1) {
      pathArray.splice(-1);
      return `${pathArray.join("-")}-out`;
    } else {
      return `${pathArray[0]}-out`;
    }
  }

  // Redirect to a new modal if this node allows it.
  function redirectNode(): void {
    if (props.node.linkable) {
      props.onClick(props.node.id, props.node.type);
    }
  }

  return (
    <div className={`${styles.nodeRow} row align-items-center mx-auto`}>
      <div className={`${styles.column} col-auto mx-auto`}>
        {/* Current node. */}
        <div className={styles.arrowConnector} id={`${props.node.path}-in`} />
        <div
          className={
            `${styles.node} my-1 p-1 d-inline-block` +
            ` ${
              props.node.type.toLowerCase() === "parent" ? styles.parentNode : styles.childNode
            }` +
            ` ${props.node.linkable ? "selectable-container" : ""}`
          }
          onClick={() => redirectNode()}
        >
          {props.node.depth < MAX_DIAGRAM_DEPTH && props.node.name.length > 0
            ? `${props.node.name}`
            : "..."}
        </div>
        <div className={styles.arrowConnector} id={`${props.node.path}-out`} />

        {/* Arrow from parent node to this node. */}
        {props.node.depth > 1 && (
          <Xarrow
            start={getParentPath(props.node.path)}
            end={`${props.node.path}-in`}
            headSize={4}
            color={"var(--diagram-arrows)"}
          />
        )}
      </div>

      {props.node.type === "parent" && props.node.depth < MAX_DIAGRAM_DEPTH && (
        <div className="col-auto mx-auto">
          {/* Child nodes. */}
          {props.node.children.map((child, i) => (
            <Fragment key={child.path}>
              {i < MAX_DIAGRAM_BREADTH && (
                <DiagramNode
                  node={child}
                  onClick={(selectedId, type) => props.onClick(selectedId, type)}
                />
              )}

              {i === MAX_DIAGRAM_BREADTH && (
                <DiagramNode
                  node={{
                    id: 0,
                    name: "...",
                    path: `${props.node.path}-0`,
                    depth: props.node.depth + 1,
                    type: "child",
                    linkable: false,
                    children: [],
                  }}
                  onClick={() => {
                    /* Do nothing. */
                  }}
                />
              )}
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
}

DiagramNode.propTypes = {
  node: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

interface Props {
  node: DiagramTree;
  onClick: (selectedId: number, type: string) => void;
}
