// --------------------------------------------------------------
// Created On: 2021-11-20
// Author: Zachary Thomas
//
// Last Modified: 2022-12-09
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./LocationHistoryWarning.module.scss";

// A warning about an asset missing location history.
export default function LocationHistoryWarning(props: Props): Component {
  return props.showWarning || props.failedToLoad ? (
    <div className={styles.assetWarningContainer}>
      {props.failedToLoad ? (
        <div className={`${styles.assetWarning} alert alert-danger`}>
          <span>Unable to load location data, please try again later.</span>
        </div>
      ) : (
        <div className={`${styles.assetWarning} alert alert-warning`}>
          <span>This asset does not have any location data.</span>
        </div>
      )}
    </div>
  ) : null;
}

LocationHistoryWarning.propTypes = {
  showWarning: PropTypes.bool.isRequired,
  failedToLoad: PropTypes.bool,
};

interface Props {
  showWarning: boolean;
  failedToLoad?: boolean;
}
