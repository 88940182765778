// --------------------------------------------------------------
// Created On: 2021-09-30
// Author: Zachary Thomas
//
// Last Modified: 2024-05-10
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./AccountInfo.module.scss";

// Information about the account that is about to be created.
export default function AccountInfo(props: Props): Component {
  return (
    <div className={styles.text}>
      {props.accountType === "NEW_COMPANY" &&
        "Your are creating a new company account. " +
          "Once your account is created you can invite other users to join your account."}

      {props.accountType === "EXISTING_COMPANY" &&
        "The company name you have entered already exists. " +
          "If you want to join an existing company, you will need an invite from an administrator of that company."}
    </div>
  );
}

AccountInfo.propTypes = {
  accountType: PropTypes.string.isRequired,
};

interface Props {
  accountType: "NEW_ACCOUNT" | "NEW_COMPANY" | "EXISTING_COMPANY";
}
