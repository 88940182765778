// --------------------------------------------------------------
// Created On: 2021-05-19
// Author: Zachary Thomas
//
// Last Modified: 2024-07-18
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useMemo, Fragment } from "react";
import { MAX_ASSETGROUP_ASSETS_TO_GRAPH } from "../../../constants/miscellaneous";
import PropTypes from "prop-types";
import calculateHighlightRuleValue from "../../../utilities/calculateHighlightRuleValue";
import calculateHighlightColor from "../../../utilities/calculateHighlightColor";
import Card from "../../../components/Card/Card";
import ColumnChart from "../../../components/ColumnChart/ColumnChart";
import deepCopy from "../../../utilities/deepCopy";
import stringIsValidNumber from "../../../utilities/stringIsValidNumber";
import isoUtcIsStale from "../../../utilities/time/isoUtcIsStale";
import styles from "./GroupGraphPage.module.scss";

// Displays graphs of the the current group's data.
export default function GroupGraphPage(props: Props) {
  const dataGraphs = useMemo(
    () => getDataGraphs(props.assets, props.attributes),
    [JSON.stringify(props.assets), JSON.stringify(props.attributes)]
  );

  // Constructs data graphs using assets and attributes.
  function getDataGraphs(assets: ViewAsset[], attributes: ViewAttribute[]): DataGraph[] {
    const dataGraphs: DataGraph[] = [];
    const assetsDeepCopy = deepCopy(assets);
    let attributesDeepCopy = deepCopy(attributes);

    // Filter out attributes that are not graphable.
    attributesDeepCopy = attributesDeepCopy.filter((attribute) => attribute.isGraphable);

    // Create a data graph for each attribute.
    attributesDeepCopy.forEach((attribute, i) => {
      const attributesReversed = attribute.highlightingRules.reverse();
      calculateRuleValues(attributesReversed, assetsDeepCopy, i);
      const dataGraph = createHighlightedDataGraph(
        attribute.name,
        attribute.units || "",
        attributesReversed,
        assetsDeepCopy,
        i
      );
      if (dataGraph.assetValues.length > 0) {
        dataGraphs.push(dataGraph);
      }
    });

    return dataGraphs;
  }

  // Calculate highlighting values for dynamic rules that require viewing a set of data.
  function calculateRuleValues(
    highlightingRules: ViewHighlightingRule[],
    assets: ViewAsset[],
    attributeIndex: number
  ): void {
    // Don't bother calculating anything if there are no highlighting rules for this attribute.
    if (highlightingRules.length === 0) {
      return;
    }

    // Get data points from each asset for the current attribute.
    const dataPoints: number[] = [];
    assets.forEach((asset) => {
      if (
        asset.attributes.length > attributeIndex &&
        asset.attributes[attributeIndex] !== null &&
        asset.attributes[attributeIndex].value !== null
      ) {
        // Handle boolean values.
        let stringValue: string = asset.attributes[attributeIndex].value;
        if (stringValue.toLowerCase() === "true") {
          stringValue = "1";
        } else if (stringValue.toLowerCase() === "false") {
          stringValue = "0";
        }

        if (stringIsValidNumber(stringValue)) {
          dataPoints.push(parseFloat(stringValue));
        }
      }
    });
    dataPoints.sort((a, b) => a - b);

    // Use data points to calculate dynamic highlighting rules.
    highlightingRules.forEach((highlightingRule) => {
      highlightingRule.value = calculateHighlightRuleValue(highlightingRule, dataPoints);
    });
  }

  // Create a data graph with custom view highlighting for columns.
  function createHighlightedDataGraph(
    name: string,
    units: string,
    highlightingRules: ViewHighlightingRule[],
    assets: ViewAsset[],
    attributeIndex: number
  ): DataGraph {
    const assetIds: number[] = [];
    const assetNames: string[] = [];
    const assetValues: number[] = [];
    const assetColors: string[] = [];
    const assetStaleStatus: boolean[] = [];

    assets.forEach((asset) => {
      if (
        asset.attributes.length > attributeIndex &&
        asset.attributes[attributeIndex] !== null &&
        asset.attributes[attributeIndex].value !== null
      ) {
        // Handle boolean values.
        let stringValue: string = asset.attributes[attributeIndex].value;
        if (stringValue.toLowerCase() === "true") {
          stringValue = "1";
        } else if (stringValue.toLowerCase() === "false") {
          stringValue = "0";
        }

        if (stringIsValidNumber(stringValue)) {
          const value = parseFloat(stringValue);
          let barColor = calculateHighlightColor(highlightingRules, value);
          if (barColor === null) {
            barColor = "#007bff";
          }
          const isStale = isoUtcIsStale(asset.lastComm);
          // If the current value is stale, make the bar slightly transparent.
          if (isStale) {
            barColor += "75";
          }
          assetIds.push(asset.assetId);
          assetNames.push(asset.name);
          assetValues.push(value);
          assetColors.push(barColor);
          assetStaleStatus.push(isoUtcIsStale(asset.lastComm));
        }
      }
    });

    return {
      name: name,
      units: units,
      assetIds: assetIds,
      assetNames: assetNames,
      assetValues: assetValues,
      assetColors: assetColors,
      assetStaleStatus: assetStaleStatus,
    };
  }

  return (
    <div className="px-4 pb-3">
      {props.assets.length <= MAX_ASSETGROUP_ASSETS_TO_GRAPH && dataGraphs.length > 0 && (
        <Fragment>
          {dataGraphs.map((dataGraph, i) => (
            <div className={`${styles.widget} shadow-sm mb-4`} key={i}>
              <p className={`${styles.title} px-4 pt-3`}>{dataGraph.name}</p>
              <div className="ms-2 me-3">
                <ColumnChart
                  name={dataGraph.name}
                  units={dataGraph.units}
                  assetIds={dataGraph.assetIds}
                  assetNames={dataGraph.assetNames}
                  assetValues={dataGraph.assetValues}
                  assetColors={dataGraph.assetColors}
                  assetStaleStatus={dataGraph.assetStaleStatus}
                />
              </div>
            </div>
          ))}
        </Fragment>
      )}

      {!props.loading && props.assets.length <= MAX_ASSETGROUP_ASSETS_TO_GRAPH && dataGraphs.length === 0 && (
        <Card title="Assets">
          <div className={`${styles.emptyList} my-5`}>
            {props.errorMessage.length > 0 && <span>{props.errorMessage}</span>}
            {props.errorMessage.length === 0 && props.viewId > 0 && (
              <span>
                None of the assets in this asset group have current values that match the selected view&apos;s
                attributes.
              </span>
            )}
            {props.errorMessage.length === 0 && props.viewId <= 0 && (
              <span>No view selected. Create a new view to see asset data.</span>
            )}
          </div>
        </Card>
      )}

      {!props.loading && props.assets.length > MAX_ASSETGROUP_ASSETS_TO_GRAPH && (
        <Card title="Assets">
          <div className={`${styles.emptyList} py-5`}>
            Graphing more than {MAX_ASSETGROUP_ASSETS_TO_GRAPH} assets in a single asset group is not supported.
          </div>
        </Card>
      )}
    </div>
  );
}

GroupGraphPage.propTypes = {
  assets: PropTypes.array.isRequired,
  attributes: PropTypes.array.isRequired,
  viewId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

interface Props {
  assets: ViewAsset[];
  attributes: ViewAttribute[];
  viewId: number;
  loading: boolean;
  errorMessage: string;
}

interface ViewAsset {
  assetId: number;
  name: string;
  nickname: string;
  deviceType: string;
  deviceIdentifier: string;
  attributes: ViewAssetAttribute[];
  lastComm: string | null;
}

interface ViewAssetAttribute {
  attributeId: number;
  value: string;
  colorHexCode?: string;
}

interface ViewAttribute {
  attributeId: number;
  name: string;
  units: string | null;
  highlightingRules: ViewHighlightingRule[];
  isGraphable: boolean;
}

interface ViewHighlightingRule {
  comparator: string;
  valueType: string;
  value: string;
  colorHexCode: string;
}

interface DataGraph {
  name: string;
  units: string;
  assetIds: number[];
  assetNames: string[];
  assetValues: number[];
  assetColors: string[];
  assetStaleStatus: boolean[];
}
