// --------------------------------------------------------------
// Created On: 2022-05-02
// Author: Zachary Thomas
//
// Last Modified: 2024-09-10
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, Fragment, useEffect } from "react";
import apiRequest from "../../utilities/api/apiRequest";
import Error500Page from "../Error500Page/Error500Page";
import Spinner from "../../components/Spinner/Spinner";
import useApi from "../../hooks/useApi";
import Card from "../../components/Card/Card";
import Error from "../../components/Error/Error";
import Success from "../../components/Success/Success";
import AssetDetails from "../../components/AssetDetails/AssetDetails";
import { useSelector } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getCurrentUser } from "../../redux/selectors";
import AssetModal from "../../components/AssetModal/AssetModal";
import { API, COPILOT_DEVICE_TYPE, CREATE_MODE } from "../../constants/miscellaneous";
import { CREATE_ASSETS_PERMISSION } from "../../constants/permissions";
import getApiError from "../../utilities/api/getApiError";
import userHasPermission from "../../utilities/userHasPermission";
import styles from "./ScanAssetPage.module.scss";

// Page that performs a search for a specific asset by scanned serial number.
export default function ScanAssetPage(): Component {
  const initialAsset: AssetConfiguration = {
    assetId: 0,
    name: "",
    nickname: "",
    assetTemplateId: null,
    productType: "Pump",
    productModel: "",
    productIdentifier: "",
    productManufacturer: "CORNELL",
    controllerModel: null,
    controllerManufacturer: null,
    deviceType: null,
    isRented: false,
    deviceIdentifier: null,
    analogSensors: [],
    digitalSensors: [],
  };
  const [asset, setAsset] = useState<AssetConfiguration>(initialAsset);
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [controllers, setControllers] = useState<Controller[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [information, setInformation] = useState<string[]>([]);
  const [allowNewAsset, setAllowNewAsset] = useState<boolean>(false);
  const [editAsset, setEditAsset] = useState<boolean>(false);
  const [analogSensors, setAnalogSensors] = useState<AnalogSensor[]>([]);
  const [digitalSensors, setDigitalSensors] = useState<DigitalSensor[]>([]);
  const [monitoringDevices, setMonitoringDevices] = useState<MonitoringDevice[]>([]);
  const { partNumber, serialNumber, deviceIdentifier } = useParams<Params>();
  const [productManufacturers, setProductManufacturers] = useState<string[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);
  const currentUser = useSelector(getCurrentUser);
  const navigate = useNavigate();

  // Check if the scanned asset exists.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/product/CORNELL/${serialNumber}/scan`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        navigate(`/asset/${responseBody.assetId}/data`);
      } else {
        if (response.status === 404) {
          setAllowNewAsset(true);
          if (userHasPermission([[CREATE_ASSETS_PERMISSION]])) {
            setInformation([
              "This asset is not currently part of this company.",
              " Would you like to create the following asset?",
            ]);
          } else {
            setInformation([
              "This asset is not currently part of this company.",
              " You do not have permission to create this asset. Please contact an admin to create this asset.",
            ]);
          }
        } else if (response.status === 405) {
          setInformation([
            "You have not been given permission to view this asset.",
            " Contact your admin if you believe this is an error.",
          ]);
          setAllowNewAsset(false);
          setLoading(false);
        } else {
          setAllowNewAsset(false);
          setFailedToLoad(true);
          setLoading(false);
        }
      }
    },
    []
  );

  // If the scanned asset does not exist, then get supporting data to create a new asset.
  useApi(
    () => {
      setLoading(allowNewAsset);
      return allowNewAsset;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/device`,
    },
    async (response: Response, responseBody: MapResponseBody) => {
      if (response.ok && responseBody) {
        setFailedToLoad(false);
        setControllers(responseBody.controllers);
        setDeviceTypes(responseBody.deviceTypes);
        setProductManufacturers(responseBody.productManufacturers);
        setProductTypes(responseBody.productTypes);
        setAnalogSensors(responseBody.analogSensors);
        setDigitalSensors(responseBody.digitalSensors);
        setMonitoringDevices(responseBody.monitoringDevices);
        setTemplates(responseBody.templates);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [allowNewAsset]
  );

  // Get the initial details for the new asset.
  useEffect(() => {
    let newDeviceType = null;
    let newDeviceIdentifier = null;

    if (typeof deviceIdentifier === "string") {
      newDeviceType = COPILOT_DEVICE_TYPE;
      newDeviceIdentifier = deviceIdentifier;
    }

    setAsset({
      assetId: 0,
      name: `Cornell_${serialNumber}`,
      nickname: `Cornell Pump ${serialNumber}`,
      assetTemplateId: null,
      productType: "Pump",
      productModel: partNumber || "",
      productIdentifier: serialNumber || "",
      productManufacturer: "CORNELL",
      controllerModel: null,
      controllerManufacturer: null,
      deviceType: newDeviceType,
      deviceIdentifier: newDeviceIdentifier,
      isRented: false,
      analogSensors: [],
      digitalSensors: [],
    });
  }, [partNumber, serialNumber, deviceIdentifier]);

  // Create a new asset based on the information that was scanned from a QR code.
  async function createScannedAsset(): Promise<void> {
    const defaultAssetTemplate = templates.find(
      (template) => template.name === "Default Template" && template.isImmutable
    );

    if (asset.assetTemplateId === null) {
      if (defaultAssetTemplate === undefined) {
        setErrorMessage("Internal server error. Unable to find default template to apply to asset.");
        return;
      } else {
        asset.assetTemplateId = defaultAssetTemplate.assetTemplateId;
      }
    }

    const requestBody = {
      assetName: asset.name,
      assetNickname: asset.nickname,
      assetTemplateId: asset.assetTemplateId,
      productType: asset.productType,
      productModel: asset.productModel,
      productIdentifier: asset.productIdentifier,
      productManufacturer: asset.productManufacturer,
      controllerModel: asset.controllerModel,
      controllerManufacturerCode: asset.controllerManufacturer,
      deviceType: asset.deviceType,
      deviceIdentifier: asset.deviceIdentifier,
      analogSensors: [],
      digitalSensors: [],
      updateSensors: false,
      updateTemplate: false,
    };

    setLoading(true);
    const [response, responseBody] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset`,
      "POST",
      requestBody
    )) as [Response, ResponseBody];
    setLoading(false);

    if (response.ok) {
      setAsset({
        assetId: responseBody.assetId,
        name: asset.name,
        nickname: asset.nickname,
        assetTemplateId: asset.assetTemplateId,
        productType: asset.productType,
        productModel: asset.productModel,
        productIdentifier: asset.productIdentifier,
        productManufacturer: asset.productManufacturer,
        controllerModel: asset.controllerModel,
        controllerManufacturer: asset.controllerManufacturer,
        deviceType: asset.deviceType,
        deviceIdentifier: asset.deviceIdentifier,
        isRented: false,
        analogSensors: [],
        digitalSensors: [],
      });
      setErrorMessage("");
      setSuccessMessage("The new asset has been created.");
    } else {
      setSuccessMessage("");
      setErrorMessage(await getApiError(response, "Unable to create asset."));
    }
  }

  // Create a new asset based on the information that was scanned from a QR code with additional user modifications.
  function createCustomScannedAsset(action: Action): void {
    if (action.payload.asset !== undefined && action.payload.asset !== undefined) {
      setAsset(action.payload.asset);
      setErrorMessage("");
      setSuccessMessage("The new asset has been created.");
    }
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      <div className="col-12 col-xl-8 offset-xl-2">
        <Card title="Create Asset">
          <div className={`${styles.assetInfo} mt-3 mb-1 p-5`}>
            {information.map((message: string, i: number) => (
              <p key={i}>{message}</p>
            ))}
          </div>

          <Fragment>
            {allowNewAsset && (
              <Fragment>
                <AssetDetails
                  name={asset.name}
                  nickname={asset.nickname}
                  productModel={asset.productModel}
                  productIdentifier={asset.productIdentifier}
                  productManufacturer={asset.productManufacturer}
                  deviceType={asset.deviceType}
                  deviceTypeName={asset.deviceType}
                  deviceIdentifier={asset.deviceIdentifier}
                  controllerModel={asset.controllerModel}
                  loading={false}
                  isRented={false}
                />

                {successMessage.length === 0 && (
                  <div className="row justify-content-center mt-5">
                    <div className="col-sm-1" />

                    <div className="col-12 col-sm-3">
                      <button
                        className={`${styles.assetBtn} btn btn-success d-flex mx-auto mb-5`}
                        type="button"
                        onClick={() => createScannedAsset()}
                        disabled={loading}
                      >
                        Create Asset
                      </button>
                    </div>

                    <div className="col-12 col-sm-3">
                      <button
                        className={`${styles.assetBtn} btn btn-primary d-flex mx-auto mb-5`}
                        type="button"
                        onClick={() => setEditAsset(true)}
                        disabled={loading}
                      >
                        Edit Asset
                      </button>
                    </div>

                    <div className="col-12 col-sm-3">
                      <Link to="/" className="router-link">
                        <button
                          className={`${styles.assetBtn} btn btn-secondary d-flex mx-auto mb-5`}
                          type="button"
                          disabled={loading}
                        >
                          Cancel
                        </button>
                      </Link>
                    </div>

                    <div className="col-sm-1" />
                  </div>
                )}
              </Fragment>
            )}

            <div className="col-12 col-lg-6 offset-lg-3">
              <div className="status-messages mt-3 mx-4">
                <Error message={errorMessage} />
                <Success message={successMessage} />
              </div>
            </div>

            {successMessage.length > 0 && (
              <Fragment>
                {userHasPermission([[CREATE_ASSETS_PERMISSION]]) ? (
                  <Link to={`/asset/${asset.assetId}/data`} className="router-link">
                    <button
                      className={`${styles.assetBtn} btn btn-primary d-flex mx-auto mt-4 mb-5`}
                      type="button"
                      disabled={loading}
                    >
                      View Asset Info
                    </button>
                  </Link>
                ) : (
                  <Link to="/" className="router-link">
                    <button
                      className={`${styles.assetBtn} btn btn-primary d-flex mx-auto mt-4 mb-5`}
                      type="button"
                      disabled={loading}
                    >
                      Go to Dashboard
                    </button>
                  </Link>
                )}
              </Fragment>
            )}
          </Fragment>
        </Card>
      </div>

      <AssetModal
        assetId={0}
        mode={CREATE_MODE}
        showModal={editAsset}
        initialAsset={asset}
        deviceTypes={deviceTypes}
        controllers={controllers}
        productTypes={productTypes}
        productManufacturers={productManufacturers}
        analogSensors={analogSensors}
        monitoringDevices={monitoringDevices}
        digitalSensors={digitalSensors}
        templates={templates}
        onClose={() => setEditAsset(false)}
        onAction={(action: Action) => createCustomScannedAsset(action)}
      />
    </div>
  );
}

type Params = {
  partNumber: string;
  serialNumber: string;
  deviceIdentifier: string;
};

interface AssetConfiguration {
  assetId: number;
  name: string;
  nickname: string;
  assetTemplateId: number | null;
  productType: string;
  productModel: string;
  productIdentifier: string;
  productManufacturer: string;
  controllerModel: string | null;
  controllerManufacturer: string | null;
  deviceType: string | null;
  deviceIdentifier: string | null;
  isRented: boolean;
  analogSensors: AnalogSensorSelection[];
  digitalSensors: DigitalSensorSelection[];
}

interface AnalogSensorSelection {
  analogSensorId: number;
  attributeCode: string;
  attributeConnectedCode: string;
  attributeModeCode: string;
  attributeMinCode: string;
  attributeSpanCode: string;
  mappedAttributeId: number;
  mappedAttributeConnectedId: number;
}

interface DigitalSensorSelection {
  digitalSensorId: number;
  attributeStateCode: string;
  attributeFlowCode: string;
  attributeAccumulationCode: string;
  attributeModeCode: string;
  attributeKFactorCode: string;
  attributeOffsetCode: string;
  attributeDebounceCode: string;
  attributeUnitsPerPulseCode: string;
  mappedAttributeStateId: number;
  mappedAttributeFlowId: number;
  mappedAttributeAccumulationId: number;
  reportingDescription: string;
}

interface ResponseBody {
  assetId: number;
}

interface MapResponseBody {
  controllers: Controller[];
  deviceTypes: DeviceType[];
  productManufacturers: string[];
  productTypes: ProductType[];
  analogSensors: AnalogSensor[];
  digitalSensors: DigitalSensor[];
  monitoringDevices: MonitoringDevice[];
  templates: Template[];
}

interface Template {
  assetTemplateId: number;
  name: string;
  isImmutable: boolean;
}

interface Controller {
  controllerId: number;
  controllerModel: string | null;
  manufacturerCompanyCode: string | null;
  name: string;
}

interface DeviceType {
  deviceTypeId: number;
  model: string;
  name: string;
}

interface AnalogSensor {
  analogSensorId: number;
  name: string;
  applications: AnalogApplication[];
}

interface AnalogApplication {
  attributeId: number;
  attributeConnectedId: number;
  name: string;
}

interface DigitalSensor {
  digitalSensorId: number;
  name: string;
  applications: DigitalApplication[];
}

interface DigitalApplication {
  attributeStateId: number;
  attributeFlowId: number;
  attributeAccumulationId: number;
  reportingDescription: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  asset?: AssetConfiguration;
}
