// --------------------------------------------------------------
// Created On: 2023-11-27
// Author: Zachary Thomas
//
// Last Modified: 2024-04-10
// Modified By: Lilly Kane
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./AddAssetThreshold.module.scss";

// Panel for adding a new asset threshold.
export default function AddAssetThreshold(props: Props): Component {
  return (
    <div className={styles.body} data-test="asset-add-threshold-container">
      <span>Add new alert threshold</span>
      <button
        data-test="asset-add-threshold-button"
        className="btn btn-success m-3"
        type="button"
        onClick={() => props.onAddThreshold()}
      >
        <i className="fa fa-fw fa-plus fa-xs" />
      </button>
    </div>
  );
}

AddAssetThreshold.propTypes = {
  onAddThreshold: PropTypes.func.isRequired,
};

interface Props {
  onAddThreshold: () => void;
}
