// --------------------------------------------------------------
// Created On: 2023-07-11
// Author: Zachary Thomas
//
// Last Modified: 2024-02-01
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// This is a temporary function until we can improve the manufacturer table structure in the database.
// This function attempts to translate manufacture codes into human readable names.
export default function translateManufacturer(name: string): string {
  switch (name) {
    case "ATLASCOPCO":
      return "Atlas Copco";
    case "BBAPUMPS":
      return "BBA Pumps";
    case "CORNELL":
      return "Cornell Pump Company";
    case "GORMANRUPP":
      return "Gorman-Rupp";
    case "GRUNDFOS":
      return "Grundfos";
    case "HYDRATECH":
      return "HydraTech";
    case "PIONEER":
      return "Pioneer Pump";
    case "PREMIER":
      return "Premier Pump";
    case "PRIMAX":
      return "Primax";
    case "PVR":
      return "PVR";
    case "SYKES":
      return "Sykes Group";
    default:
      return name;
  }
}
