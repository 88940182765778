// --------------------------------------------------------------
// Created On: 2023-02-02
// Author: Zachary Thomas
//
// Last Modified: 2024-02-28
// Modified By: Lilly Kane
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";

// A single asset group in an asset group list.
export default function AssetgroupListRow(props: Props): Component {
  return (
    <div className="p-2" data-test="assetgroup-list-row">
      <span data-test="assetgroup-title">{props.name}</span>
    </div>
  );
}

AssetgroupListRow.propTypes = {
  assetgroupId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

interface Props {
  assetgroupId: number;
  name: string;
}
