// --------------------------------------------------------------
// Created On: 2021-09-21
// Author: Zachary Thomas
//
// Last Modified: 2024-03-26
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes, { InferProps } from "prop-types";

// Generic modal footer.
export default function ModalFooter(props: Props): Component {
  return <Modal.Footer data-test="modal-footer" className={props.className || undefined}>{props.children}</Modal.Footer>;
}

ModalFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

type Props = InferProps<typeof ModalFooter.propTypes>;
