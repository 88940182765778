// --------------------------------------------------------------
// Created On: 2022-09-20
// Author: Zachary Thomas
//
// Last Modified: 2023-11-07
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";

// Explains what an alert log is.
export default function HelpAlertLog(): Component {
  return (
    <div>
      <p className="px-3">
        Each alert log describes a specific instance of an alert threshold being crossed. For
        example, if we set an alert threshold for when &apos;Internal Temperature&apos; is above 200
        degrees fahrenheit, we would expect to see an alert log when &apos;Internal
        Temperature&apos; changes from 190 degrees to 210 degrees.
      </p>
      <p className="px-3 mt-3 mb-2">
        <b>Acknowledged:</b> If an alert is acknowledged, this means that the alert will stop
        escalating to users in the escalation chain for the alert threshold. Additionally, once an
        alert is acknowledged a new alert will not be sent unless the alerting value has reached a
        value that is considered &apos;not in alert&apos; by the threshold, at this point new alerts
        can be sent if the value once again crosses the threshold.
      </p>
      <p className="px-3 mt-3 mb-2">
        <b>Timed Out:</b> An alert log is considered &apos;timed out&apos; if no one acknowledged
        the alert in the time defined by the alert threshold.
      </p>
    </div>
  );
}
