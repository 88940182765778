// --------------------------------------------------------------
// Created On: 2023-01-26
// Author: Zachary Thomas
//
// Last Modified: 2024-04-01
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useReducer } from "react";
import Spinner from "../../components/Spinner/Spinner";
import Error500Page from "../Error500Page/Error500Page";
import deepCopy from "../../utilities/deepCopy";
import useApi from "../../hooks/useApi";
import ResourceList from "../../components/ResourceList/ResourceList";
import ReportModal from "./ReportModal/ReportModal";
import { API } from "../../constants/miscellaneous";
import { REPORT_TYPES } from "../../constants/reducerActions";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import { CREATE_REPORTS_PERMISSION } from "../../constants/permissions";
import ReportListRow from "./ReportListRow/ReportListRow";
import HelpReport from "../../components/HelpReport/HelpReport";
import styles from "./ManageReportsPage.module.scss";

// Page for creating, editing, and deleting reports.
export default function ManageReportsPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [attributes, setAttributes] = useState<SimpleAttribute[]>([]);
  const [assetgroups, setAssetgroups] = useState<ReportAssetgroup[]>([]);
  const [reports, dispatch] = useReducer(reportReducer, []);
  const currentUser = useSelector(getCurrentUser);

  // Get a list of reports from the API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/report`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        dispatch({
          type: REPORT_TYPES.SET_REPORTS,
          payload: {
            reports: responseBody.reports,
          },
        });
        setAssetgroups(responseBody.assetgroups);
        setAttributes(responseBody.attributes);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // Report reducer.
  function reportReducer(state: AssetReport[], action: Action): AssetReport[] {
    switch (action.type) {
      case REPORT_TYPES.SET_REPORTS: {
        if (action.payload.reports !== undefined) {
          return sortReports(action.payload.reports);
        } else {
          return [];
        }
      }

      case REPORT_TYPES.CREATE_REPORT: {
        let stateDeepCopy = deepCopy(state);
        const newReport = action.payload.report;
        if (newReport !== undefined) {
          // Check if the report already exists before creating them.
          const reportExists = stateDeepCopy.some((report) => report.reportId === newReport.reportId);
          if (!reportExists) {
            stateDeepCopy.push(newReport);
            stateDeepCopy = sortReports(stateDeepCopy);
          }
        }
        return stateDeepCopy;
      }

      case REPORT_TYPES.UPDATE_REPORT: {
        let stateDeepCopy = deepCopy(state);
        const updatedReport = action.payload.report;
        if (updatedReport !== undefined) {
          // Find the index of the report.
          const reportIndex = stateDeepCopy.findIndex((report) => report.reportId === updatedReport.reportId);

          // Don't continue if we couldn't find the report.
          if (reportIndex === -1) {
            return stateDeepCopy;
          }

          // Replace the current report with the updated one.
          stateDeepCopy.splice(reportIndex, 1, updatedReport);

          // Sort the reports.
          stateDeepCopy = sortReports(stateDeepCopy);
        }
        return stateDeepCopy;
      }

      case REPORT_TYPES.DELETE_REPORT: {
        const stateDeepCopy = deepCopy(state);
        const reportId = action.payload.reportId;

        if (reportId !== undefined) {
          // Find the index of the report.
          const reportIndex = stateDeepCopy.findIndex((report) => report.reportId === reportId);

          // Don't continue if we couldn't find the report.
          if (reportIndex === -1) {
            return stateDeepCopy;
          }

          // Delete the current report.
          stateDeepCopy.splice(reportIndex, 1);
        }
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  // Sort reports.
  function sortReports(reports: AssetReport[]): AssetReport[] {
    return reports.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      <div className="row">
        {/* Report management. */}
        <div className={`${styles.list} col-12 col-md mx-0`}>
          <ResourceList
            resourceNameSingular="Report"
            resourceNamePlural="Reports"
            resourceArticle="a"
            headerButtonLabel="Create Report"
            headerButtonLabelSmall="Create"
            headerButtonUserPermissions={[[CREATE_REPORTS_PERMISSION]]}
            resourceIdKey="reportId"
            resources={reports}
            resourceRow={ReportListRow}
            helpModal={<HelpReport />}
            filterKeys={["name"]}
            loading={loading}
            onClickHeaderButton={() => setSelectedId(0)}
            onSelect={(selectedId) => setSelectedId(selectedId)}
          />

          {/* Report modal for editing reports. */}
          {selectedId >= 0 && (
            <ReportModal
              isCreatingNewRecord={selectedId === 0}
              reportId={selectedId}
              assetgroups={assetgroups}
              attributes={attributes}
              onClose={() => setSelectedId(-1)}
              onAction={(action) => dispatch(action)}
            />
          )}

          <datalist id="datalist-attributes">
            {attributes.map((attribute) => (
              <option key={attribute.attributeId} value={attribute.name} />
            ))}
          </datalist>

          <datalist id="datalist-assetgroups">
            {assetgroups.map((assetgroup) => (
              <option key={assetgroup.assetgroupId} value={assetgroup.name} />
            ))}
          </datalist>
        </div>
      </div>
    </div>
  );
}

interface ResponseBody {
  reports: AssetReport[];
  assetgroups: ReportAssetgroup[];
  attributes: Attribute[];
}

interface AssetReport {
  reportId: number;
  name: string;
  enabled: boolean;
  type: string;
  hours: number;
  attributes: SimpleAttribute[];
  emailAddresses: string[];
  scheduleSettings: ReportSchedule[];
  assetgroups: ReportAssetgroup[];
}

interface Attribute {
  attributeId: number;
  code: string;
  name: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  reports?: AssetReport[];
  report?: AssetReport;
  reportId?: number;
}
