// --------------------------------------------------------------
// Created On: 2023-11-27
// Author: Zachary Thomas
//
// Last Modified: 2024-01-29
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./SelectAssetgroupsControl.module.scss";

// Button for selecting asset groups.
export default function SelectAssetgroupsControl(props: Props): Component {
  return (
    <div className={styles.body}>
      <span>Update all assets inside of selected asset groups</span>
      <br />
      <button className="btn btn-primary m-3" type="button" onClick={() => props.onSelect()}>
        Select Asset Groups
      </button>
    </div>
  );
}

SelectAssetgroupsControl.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

interface Props {
  onSelect: () => void;
}
