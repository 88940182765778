// --------------------------------------------------------------
// Created On: 2023-08-14
// Author: Zachary Thomas
//
// Last Modified: 2024-04-01
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import InfoModal from "../InfoModal/InfoModal";
import formatTitleCase from "../../utilities/formatTitleCase";
import userHasPermission from "../../utilities/userHasPermission";
import styles from "./ResourceList.module.scss";

// A wrapper for styling lists of resources that support management methods.
export default function ResourceList<Type>(props: Props<Type>): Component {
  const [filter, setFilter] = useState<string>("");
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const filteredResources = useMemo(
    () => getFilteredResources(props.resources, props.filterKeys, props.resourcePriorityKey, filter),
    [JSON.stringify(props.resources), JSON.stringify(props.filterKeys), props.resourcePriorityKey, filter]
  );

  // Filter resources.
  // 'filter' represents the user input used for filtering 'resources'.
  // 'filterKeys' represents the keys in the resource object that we want to filter on. Multiple keys are treated as a logical 'OR'.
  function getFilteredResources<Type>(
    resources: Type[],
    filterKeys: string[] | undefined,
    resourcePriorityKey: string | undefined,
    filter: string
  ): Type[] {
    if (filterKeys === undefined || filter.length === 0) {
      return sortResources(resources, resourcePriorityKey);
    } else {
      // Check each filter key for a substring match. If any filter key matches, we consider it a valid resource.
      const filteredResources = resources.filter((resource) => {
        return (filterKeys as string[]).some((filterKey) => {
          if (typeof resource === "object" && resource !== null && filterKey in resource) {
            const value = resource[filterKey as keyof Type] as string;
            return value.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
          } else {
            return false;
          }
        });
      });
      return sortResources(filteredResources, resourcePriorityKey);
    }
  }

  // Sort resources by name.
  // If a resourcePriorityKey is included, then also add values with a 'true' value on the key to the top of the list.
  function sortResources<Type>(resources: Type[], resourcePriorityKey: string | undefined): Type[] {
    return resources.sort((a, b) => {
      if (
        a !== null &&
        typeof a === "object" &&
        typeof (a as Record<string, string>)["name"] === "string" &&
        b !== null &&
        typeof b === "object" &&
        typeof (b as Record<string, string>)["name"] === "string"
      ) {
        const resourceNameA = a["name" as keyof Type];
        const resourceNameB = b["name" as keyof Type];
        if (resourcePriorityKey !== undefined) {
          const resourcePriorityA = a[resourcePriorityKey as keyof Type];
          const resourcePriorityB = b[resourcePriorityKey as keyof Type];
          if (resourcePriorityA > resourcePriorityB) {
            return -1;
          } else if (resourcePriorityA < resourcePriorityB) {
            return 1;
          }
        }
        if (resourceNameA < resourceNameB) {
          return -1;
        } else if (resourceNameA > resourceNameB) {
          return 1;
        }
      }
      return 0;
    });
  }

  // Returns whether a header button exists based on the input to this component.
  function hasHeaderButton(): boolean {
    return (
      props.headerButtonLabel !== undefined &&
      props.onClickHeaderButton !== undefined &&
      (props.headerButtonUserPermissions === undefined || userHasPermission(props.headerButtonUserPermissions))
    );
  }

  return (
    <div>
      <div data-test="resource-list-header" className={`${styles.header} p-4`}>
        <div className="row align-items-top">
          {/* Modal for explaining the purpose of the resource. */}
          <div className={`${hasHeaderButton() ? "col-6 col-lg-8" : "col-12"} mb-4`}>
            <span className={styles.title} data-test="resource-list-title">
              {props.titleOverride === undefined ? formatTitleCase(props.resourceNamePlural) : props.titleOverride}
              &nbsp;({filteredResources.length})
            </span>
            <InfoModal
              showModal={showHelpModal}
              title={`What is ${props.resourceArticle.toLowerCase()} ${formatTitleCase(props.resourceNameSingular)}?`}
              darkBackground={true}
              onClick={() => setShowHelpModal(true)}
              onClose={() => setShowHelpModal(false)}
            >
              {props.helpModal}
            </InfoModal>
          </div>

          {/* Header button that performs an action based on the passed in onClick handler. */}
          {hasHeaderButton() && (
            <div className="col-6 col-lg-4">
              <button
                data-test="resource-list-create-resource-button"
                className={`${styles.createButton} btn btn-light float-end`}
                onClick={() => props.onClickHeaderButton()}
              >
                <span className={props.headerButtonLabelSmall !== undefined ? "d-none d-xl-inline" : ""}>
                  {props.headerButtonLabel}
                </span>

                {props.headerButtonLabelSmall !== undefined && (
                  <span className="d-inline d-xl-none">{props.headerButtonLabelSmall}</span>
                )}
              </button>
            </div>
          )}
        </div>

        {/* Filter bar. */}
        {props.filterKeys !== undefined && (
          <div className="input-group rounded">
            <input
              data-test="resource-list-filter-input"
              type="search"
              className="form-control rounded"
              placeholder="Filter results..."
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
        )}
      </div>

      <div className={styles.body} data-test="resource-list-body">
        {/* List of individual interactive resources. */}
        {!props.loading &&
          filteredResources.map((resource: Type) => (
            <div
              data-test="resource-list-row"
              key={resource[props.resourceIdKey as keyof Type] as number}
              className={`${styles.item} selectable-container`}
              onClick={() => props.onSelect(resource[props.resourceIdKey as keyof Type] as number)}
            >
              {<props.resourceRow {...(resource as Type & Record<string, unknown>)} />}
            </div>
          ))}
      </div>

      {filteredResources.length === 0 && !props.loading && filter.length === 0 && (
        <div className={`${styles.info} py-5 px-4`}>
          <h5 className="mb-4">
            <span>No {props.resourceNamePlural.toLowerCase()} exist</span>
          </h5>
        </div>
      )}

      {filteredResources.length === 0 && !props.loading && filter.length > 0 && (
        <div className={`${styles.info} py-5 px-4`}>
          <h5 className="mb-4">No {props.resourceNamePlural.toLowerCase()} match the filter</h5>
        </div>
      )}
    </div>
  );
}

ResourceList.propTypes = {
  resourceNameSingular: PropTypes.string.isRequired,
  resourceNamePlural: PropTypes.string.isRequired,
  resourceArticle: PropTypes.string.isRequired,
  helpModal: PropTypes.object.isRequired,
  filterKeys: PropTypes.array,
  resourceIdKey: PropTypes.string.isRequired,
  resourcePriorityKey: PropTypes.string,
  resources: PropTypes.array.isRequired,
  resourceRow: PropTypes.elementType.isRequired,
  loading: PropTypes.bool.isRequired,
  headerButtonLabel: PropTypes.string,
  headerButtonLabelSmall: PropTypes.string,
  headerButtonUserPermissions: PropTypes.array,
  titleOverride: PropTypes.string,
  linkTitleOverride: PropTypes.string,
  onClickHeaderButton: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
};

interface Props<Type> {
  resourceNameSingular: string;
  resourceNamePlural: string;
  resourceArticle: string;
  helpModal: Component;
  filterKeys?: string[];
  resourceIdKey: string;
  resourcePriorityKey?: string;
  resources: Type[];
  resourceRow: React.ComponentType<Type>;
  loading: boolean;
  headerButtonLabel?: string;
  headerButtonLabelSmall?: string;
  headerButtonUserPermissions?: string[][];
  titleOverride?: string;
  linkTitleOverride?: string;
  onClickHeaderButton: () => void;
  onSelect: (resourceId: number) => void;
}
