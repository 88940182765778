// --------------------------------------------------------------
// Created On: 2023-08-15
// Author: Zachary Thomas
//
// Last Modified: 2023-08-16
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";

// Explains what a role is.
export default function HelpRole(): Component {
  return (
    <div>
      <p className="px-3 my-2">
        Roles are used to control what users can view and modify. Roles can be applied directly to
        users, or to user groups. If a user has one role assigned directly to them and a second role
        assigned to them through a user group, then they have the permissions of the combination of
        roles. For example, if a user group role gives a user access to creating assets and a user
        specific role gives the user access to inviting users, then that user can create assets and
        invite users.
      </p>
      <p className="px-3 mt-3 mb-2">
        <b>Immutable Roles:</b> Immutable roles are roles that cannot be modified. The &apos;Basic
        User&apos; role is given to new users by default, this role has no permissions. The
        &apos;Admin&apos; role is given to the user who created the account, this role has every
        permission.
      </p>
      <p className="px-3 mt-3 mb-2">
        <b>Permissions:</b> A role is made up of permissions. Each permission describes a specific
        action or actions that can be performed by a user who has this role.
      </p>
    </div>
  );
}
