// --------------------------------------------------------------
// Created On: 2022-10-14
// Author: Zachary Thomas
//
// Last Modified: 2024-08-20
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useMemo, Fragment } from "react";
import { API } from "../../constants/miscellaneous";
import Spinner from "../../components/Spinner/Spinner";
import Error500Page from "../Error500Page/Error500Page";
import useApi from "../../hooks/useApi";
import AttributeAlias from "./AttributeAlias/AttributeAlias";
import deepCopy from "../../utilities/deepCopy";
import apiRequest from "../../utilities/api/apiRequest";
import getApiError from "../../utilities/api/getApiError";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import Toast from "../../components/Toast/Toast";
import FilteredTableContainer from "../../components/FilteredTableContainer/FilteredTableContainer";
import SortedTableHeader from "../../components/SortedTableHeader/SortedTableHeader";
import styles from "./DeviceAttributesPage.module.scss";

// Page for modifying the names and units of device attributes.
export default function DeviceAttributesPage(): Component {
  const currentUser = useSelector(getCurrentUser);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [sortColumnIndex, setSortColumnIndex] = useState<number>(1);
  const [attributes, setAttributes] = useState<AttributeAlias[]>([]);
  const organizedAttributes = useMemo(
    () => getFilteredAndSortedAttributes(attributes),
    [JSON.stringify(attributes), filter, sortAscending, sortColumnIndex]
  );

  // Get attributes info for the current company.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/attribute`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setAttributes(responseBody.attributes);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // Given a list of attributes, filter and sort the attributes.
  function getFilteredAndSortedAttributes(attributes: AttributeAlias[]): AttributeAlias[] {
    let attributesDeepCopy = deepCopy(attributes);
    if (filter.length > 0) {
      attributesDeepCopy = attributesDeepCopy.filter(
        (attribute) => attribute.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
      );
    }
    return sortAttributes(attributesDeepCopy);
  }

  // Sort attributes based on the current column and ascension rule.
  function sortAttributes(attributes: AttributeAlias[]): AttributeAlias[] {
    return attributes.sort((a, b) => {
      let aValue = "";
      let bValue = "";

      switch (sortColumnIndex) {
        case 1:
          aValue = a.name;
          bValue = b.name;
          break;
        case 2:
          aValue = a.aliasName || "";
          bValue = b.aliasName || "";
          break;
        case 3:
          aValue = getUnitName(a.selectableUnits, a.unitId || 0) || "N/A";
          bValue = getUnitName(b.selectableUnits, b.unitId || 0) || "N/A";
          break;
        default:
          aValue = a.name;
          bValue = b.name;
      }

      if (aValue < bValue) {
        if (sortAscending) {
          return -1;
        } else {
          return 1;
        }
      } else if (aValue > bValue) {
        if (sortAscending) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return 0;
      }
    });
  }

  // Save the changes that were made made to an attribute.
  async function saveAttribute(attributeId: number, aliasName: string, unitId: number | null): Promise<void> {
    let updatedAliasName: string | null = aliasName;
    if (aliasName === "") {
      updatedAliasName = null;
    }

    const requestBody = {
      attributeId: attributeId,
      attributeAliasName: updatedAliasName,
      aliasUnitId: unitId,
    };

    // If we set default values for the alias name and unit ID, then make a delete call to the API, instead of an update.
    let apiMethod = "PUT";
    if ((aliasName === undefined || aliasName === null) && (unitId === undefined || unitId === null)) {
      apiMethod = "DELETE";
    }

    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/attributealias/${attributeId}`,
      apiMethod,
      requestBody
    )) as [Response, ResponseBody];
    setLoading(false);

    if (response.ok) {
      const attributesDeepCopy = deepCopy(attributes);
      const attributeIndex = attributesDeepCopy.findIndex((attribute) => attribute.attributeId === attributeId);
      if (attributeIndex !== -1) {
        if (aliasName !== undefined) {
          attributesDeepCopy[attributeIndex].aliasName = aliasName;
        }
        if (unitId !== undefined) {
          attributesDeepCopy[attributeIndex].unitId = unitId;
        }
      }
      setAttributes(attributesDeepCopy);
      setErrorMessage("");
    } else {
      setErrorMessage(await getApiError(response, "Unable to save changes."));
    }
  }

  // Given a unit ID, get the matching unit name.
  function getUnitName(selectableUnits: UnitAlias[], unitId: number): string {
    const selectedUnit = selectableUnits.find((unit) => unit.unitId === unitId);
    if (selectedUnit !== undefined) {
      return selectedUnit.unitName;
    } else {
      return "";
    }
  }

  // Update sorting order.
  function updateSort(newIndex: number): void {
    if (newIndex === sortColumnIndex) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(false);
      setSortColumnIndex(newIndex);
    }
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      <Toast
        title="Error"
        message={errorMessage}
        show={errorMessage.length > 0}
        onClose={() => setErrorMessage("")}
        type="error"
      />

      {attributes.length > 0 ? (
        <FilteredTableContainer
          title={`Attribute Aliases (${organizedAttributes.length})`}
          filterPrompt="Filter by attribute name..."
          filter={filter}
          hasContent={attributes.length > 0}
          hasFilteredContent={organizedAttributes.length > 0}
          pluralContentType="attributes"
          onChangeFilter={(filter) => setFilter(filter)}
        >
          <table className="table table-hover mb-0 pb-0">
            <thead className={styles.header}>
              <tr>
                <SortedTableHeader
                  name="Attribute Name"
                  index={1}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
                <SortedTableHeader
                  name="Alias Name"
                  index={2}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
                <SortedTableHeader
                  name="Units"
                  index={3}
                  selectedIndex={sortColumnIndex}
                  selectedAscending={sortAscending}
                  onClick={(index) => updateSort(index)}
                />
                <th />
              </tr>
            </thead>
            <tbody>
              {organizedAttributes.map((attribute) => (
                <AttributeAlias
                  key={attribute.attributeId}
                  attributeId={attribute.attributeId}
                  code={attribute.code}
                  name={attribute.name}
                  aliasName={attribute.aliasName}
                  isAliasAllowed={attribute.isAliasAllowed}
                  unitId={attribute.unitId}
                  unitName={attribute.unitName}
                  selectableUnits={attribute.selectableUnits}
                  onSave={(attributeId, aliasName, unitId) => saveAttribute(attributeId, aliasName, unitId)}
                />
              ))}
            </tbody>
          </table>
        </FilteredTableContainer>
      ) : (
        <Fragment>{!loading && <Error500Page />}</Fragment>
      )}
    </div>
  );
}

interface ResponseBody {
  attributes: AttributeAlias[];
}

interface AttributeAlias {
  attributeId: number;
  code: string;
  name: string;
  aliasName: string | null;
  unitId: number | null;
  unitName: string | null;
  isAliasAllowed: boolean;
  selectableUnits: UnitAlias[];
}

interface UnitAlias {
  unitId: number;
  unitName: string;
}
