// --------------------------------------------------------------
// Created On: 2021-12-14
// Author: Zachary Thomas
//
// Last Modified: 2023-12-27
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducer";

// Load the Redux state from local storage.
function loadState(): ReduxStore {
  const state = {
    authorizerToken: "",
    accessToken: "",
    currentUser: {
      userId: 0,
      userName: "",
      emailAddress: "",
      companyId: 0,
      companyName: "",
      isCompanyHidden: true,
      permissions: [],
      isPackager: false,
    },
    alerts: [],
    deviceAttributes: [],
  };

  try {
    const authorizerToken = localStorage.getItem("reduxAuthorizerToken");
    const accessToken = localStorage.getItem("reduxAccessToken");
    const currentUser = localStorage.getItem("reduxCurrentUser");

    if (authorizerToken !== null) {
      state.authorizerToken = authorizerToken;
    }

    if (accessToken !== null) {
      state.accessToken = accessToken;
    }

    if (currentUser !== null) {
      state.currentUser = JSON.parse(currentUser);
    }
  } catch (e) {
    console.error("Redux state was unable to load.");
  }

  return state;
}

const previousState = loadState();
const store = configureStore({ reducer: rootReducer, preloadedState: previousState });
export default store;
