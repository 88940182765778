// --------------------------------------------------------------
// Created On: 2021-09-30
// Author: Zachary Thomas
//
// Last Modified: 2024-02-28
// Modified By: Lilly Kane
//
// Copyright 2021 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import validateNickname from "../../../utilities/validateNickname";

// A single asset in an asset list.
export default function AssetListRow(props: Props): Component {
  return (
    <div className="p-2" data-test="asset-list-row">
      {props.name} {validateNickname(props.nickname, props.name) ? `(${props.nickname})` : ""}
    </div>
  );
}

AssetListRow.propTypes = {
  assetId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
};

interface Props {
  assetId: number;
  name: string;
  nickname: string;
}
