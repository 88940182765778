// --------------------------------------------------------------
// Created On: 2022-07-20
// Author: Zachary Thomas
//
// Last Modified: 2024-07-03
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useReducer } from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import useApi from "../../hooks/useApi";
import { API } from "../../constants/miscellaneous";
import { LENT_ASSET_TYPES } from "../../constants/reducerActions";
import deepCopy from "../../utilities/deepCopy";
import Error500Page from "../Error500Page/Error500Page";
import Spinner from "../../components/Spinner/Spinner";
import ResourceList from "../../components/ResourceList/ResourceList";
import RentOutAssetModal from "./RentOutAssetModal/RentOutAssetModal";
import RentingAssetModal from "./RentingAssetModal/RentingAssetModal";
import RentalLogList from "./RentalLogList/RentalLogList";
import apiRequest from "../../utilities/api/apiRequest";
import {
  CREATE_ASSET_RENTALS_PERMISSION,
  UPDATE_ASSET_RENTALS_PERMISSION,
  REVOKE_ASSET_RENTALS_PERMISSION,
  RETURN_ASSET_RENTALS_PERMISSION,
  ACCEPT_ASSET_RENTALS_PERMISSION,
  DECLINE_ASSET_RENTALS_PERMISSION,
} from "../../constants/permissions";
import userHasPermission from "../../utilities/userHasPermission";
import RentOutAssetListRow from "./RentOutAssetListRow/RentOutAssetListRow";
import RentingAssetListRow from "./RentingAssetListRow/RentingAssetListRow";
import HelpRental from "../../components/HelpRental/HelpRental";
import HelpRentalPending from "../../components/HelpRentalPending/HelpRentalPending";

// Page for lending assets to other companies.
export default function ManageRentalAssetsPage(): Component {
  const currentUser = useSelector(getCurrentUser);
  const [loading, setLoading] = useState<boolean>(true);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] = useState<RentedOutAsset | RentalAsset | null>(null);
  const [selectionType, setSelectionType] = useState<"rentedOut" | "rental" | null>(null);
  const [assets, setAssets] = useState<OwnedAsset[]>([]);
  const [rentedOutAssets, dispatchRentedOut] = useReducer(rentedOutAssetReducer, []);
  const [rentalAssets, dispatchRental] = useReducer(rentalAssetReducer, []);
  const [transferLogs, setTransferLogs] = useState<TransferLog[]>([]);

  // Get borrowed and lent asset data from API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/rental/map`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setAssets(responseBody.ownedAssets);
        dispatchRentedOut({
          type: LENT_ASSET_TYPES.SET_ASSETS,
          payload: {
            assets: responseBody.rentedOutAssets,
          },
        });
        dispatchRental({
          type: LENT_ASSET_TYPES.SET_ASSETS,
          payload: {
            assets: responseBody.rentalAssets,
          },
        });
        setTransferLogs(responseBody.transferLogs);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // Reducer for rented out assets (current company owns asset).
  function rentedOutAssetReducer(state: RentedOutAsset[], action: RentedOutAction): RentedOutAsset[] {
    switch (action.type) {
      case LENT_ASSET_TYPES.SET_ASSETS: {
        if (action.payload.assets !== undefined) {
          return action.payload.assets;
        } else {
          return state;
        }
      }

      case LENT_ASSET_TYPES.CREATE_ASSET: {
        let stateDeepCopy = deepCopy(state);
        const newAsset = action.payload.asset;

        // Make sure the payload includes a valid asset.
        if (newAsset !== undefined) {
          const assetExists = stateDeepCopy.some((asset) => asset.assetId === newAsset.assetId);

          // Check if the asset already exists before creating it.
          if (!assetExists) {
            stateDeepCopy.push(newAsset);
            stateDeepCopy = sortAssets(stateDeepCopy) as RentedOutAsset[];
          }
        }

        refreshRentalHistoryLogs();

        return stateDeepCopy;
      }

      case LENT_ASSET_TYPES.DELETE_ASSET: {
        const stateDeepCopy = deepCopy(state);
        const assetId = action.payload.assetId;
        const companyId = action.payload.companyId;

        // Find the index of the asset.
        const assetIndex = stateDeepCopy.findIndex(
          (asset) => asset.assetId === assetId && asset.renteeCompanyId === companyId
        );
        // Don't continue if we couldn't find the asset.
        if (assetIndex === -1) {
          return state;
        }

        // Delete the current asset.
        stateDeepCopy.splice(assetIndex, 1);
        refreshRentalHistoryLogs();
        setSelectedAsset(null);
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  // Reducer for rented out assets (current company owns asset).
  function rentalAssetReducer(state: RentalAsset[], action: RentalAction): RentalAsset[] {
    switch (action.type) {
      case LENT_ASSET_TYPES.SET_ASSETS: {
        if (action.payload.assets !== undefined) {
          return action.payload.assets;
        } else {
          return state;
        }
      }

      case LENT_ASSET_TYPES.CREATE_ASSET: {
        let stateDeepCopy = deepCopy(state);
        const newAsset = action.payload.asset;

        // Make sure the payload includes a valid asset.
        if (newAsset !== undefined) {
          const assetExists = stateDeepCopy.some((asset) => asset.assetId === newAsset.assetId);

          // Check if the asset already exists before creating it.
          if (!assetExists) {
            stateDeepCopy.push(newAsset);
            stateDeepCopy = sortAssets(stateDeepCopy) as RentalAsset[];
          }
        }

        refreshRentalHistoryLogs();

        return stateDeepCopy;
      }

      case LENT_ASSET_TYPES.DELETE_ASSET: {
        const stateDeepCopy = deepCopy(state);
        const assetId = action.payload.assetId;
        const companyId = action.payload.companyId;

        // Find the index of the asset.
        const assetIndex = stateDeepCopy.findIndex(
          (asset) => asset.assetId === assetId && asset.renterCompanyId === companyId
        );

        // Don't continue if we couldn't find the asset.
        if (assetIndex === -1) {
          return state;
        }

        // Delete the current asset.
        stateDeepCopy.splice(assetIndex, 1);

        refreshRentalHistoryLogs();
        setSelectedAsset(null);
        return stateDeepCopy;
      }

      case LENT_ASSET_TYPES.APPROVE_ASSET: {
        const stateDeepCopy = deepCopy(state);
        const assetId = action.payload.assetId;

        // Find the index of the asset.
        const assetIndex = stateDeepCopy.findIndex((asset) => asset.assetId === assetId);

        // Don't continue if we couldn't find the asset.
        if (assetIndex === -1) {
          return state;
        }

        // Change the pending status of the current asset.
        stateDeepCopy[assetIndex].isPendingApproval = false;
        refreshRentalHistoryLogs();
        setSelectedAsset(null);

        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  // Sort assets.
  function sortAssets(assets: RentedOutAsset[] | RentalAsset[]): RentedOutAsset[] | RentalAsset[] {
    return assets.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // Select an asset so that it can be viewed in a modal.
  function selectAsset(assetId: number, selectionType: "rentedOut" | "rental" | null): void {
    setSelectionType(selectionType);

    let foundAsset: RentedOutAsset | RentalAsset | undefined;
    if (assetId === 0) {
      foundAsset = {
        assetId: 0,
        name: "",
        renteeCompanyId: 0,
        renteeCompanyName: "",
        operateAccessPermission: false,
        alertThresholdPermission: false,
        isPendingApproval: false,
      };
    } else {
      if (selectionType === "rentedOut") {
        foundAsset = rentedOutAssets.find((asset: RentedOutAsset) => asset.assetId === assetId);
      } else {
        foundAsset = rentalAssets.find((asset: RentalAsset) => asset.assetId === assetId);
      }
    }

    if (foundAsset === undefined) {
      setSelectedAsset(null);
    } else {
      setSelectedAsset(foundAsset);
    }
  }

  // Refresh the rental history logs.
  async function refreshRentalHistoryLogs(): Promise<void> {
    const [response, responseBody] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/transferlogs/1`,
      "GET",
      null
    )) as [Response, HistoryResponseBody];

    if (response.ok) {
      setTransferLogs(responseBody.transferLogs);
    } else {
      console.error("Unable to retrieve current transfer logs.");
    }
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      <div className="row">
        {userHasPermission([
          [CREATE_ASSET_RENTALS_PERMISSION],
          [UPDATE_ASSET_RENTALS_PERMISSION],
          [REVOKE_ASSET_RENTALS_PERMISSION],
        ]) && (
          <div className="col-12 col-xl mb-4" data-test="outgoing-asset-rentals">
            <ResourceList
              resourceNameSingular="Rented Asset"
              resourceNamePlural="Outgoing Asset Rentals"
              resourceArticle="a"
              headerButtonLabel="Rent Out Asset"
              headerButtonLabelSmall="Rent"
              headerButtonUserPermissions={[[CREATE_ASSET_RENTALS_PERMISSION]]}
              resourceIdKey="assetId"
              resources={rentedOutAssets}
              resourceRow={RentOutAssetListRow}
              helpModal={<HelpRental />}
              filterKeys={["name"]}
              loading={loading}
              onClickHeaderButton={() => selectAsset(0, "rentedOut")}
              onSelect={(assetId) => selectAsset(assetId, "rentedOut")}
            />
          </div>
        )}

        {userHasPermission([
          [ACCEPT_ASSET_RENTALS_PERMISSION],
          [DECLINE_ASSET_RENTALS_PERMISSION],
          [RETURN_ASSET_RENTALS_PERMISSION],
        ]) && (
          <div className="col-12 col-xl mb-4" data-test="incoming-asset-rentals">
            <ResourceList
              resourceNameSingular="Rented Asset"
              resourceNamePlural="Incoming Asset Rentals"
              resourceArticle="a"
              resourceIdKey="assetId"
              resources={rentalAssets}
              resourceRow={RentingAssetListRow}
              helpModal={<HelpRentalPending />}
              filterKeys={["name"]}
              loading={loading}
              onClickHeaderButton={() => {
                /* Do nothing. */
              }}
              onSelect={(assetId) => selectAsset(assetId, "rental")}
            />
          </div>
        )}
      </div>

      {transferLogs.length > 0 && (
        <div className="my-3">
          <RentalLogList transferLogs={transferLogs} />
        </div>
      )}

      {selectedAsset !== null && selectionType === "rentedOut" && (
        <RentOutAssetModal
          isCreatingNewRecord={selectedAsset.assetId === 0}
          asset={selectedAsset as RentedOutAsset}
          assets={assets}
          onClose={() => selectAsset(-1, null)}
          onAction={(action: RentedOutAction) => dispatchRentedOut(action)}
        />
      )}

      {selectedAsset !== null && selectionType === "rental" && (
        <RentingAssetModal
          asset={selectedAsset as RentalAsset}
          onClose={() => selectAsset(-1, null)}
          onAction={(action: RentalAction) => dispatchRental(action)}
        />
      )}
    </div>
  );
}

interface ResponseBody {
  ownedAssets: OwnedAsset[];
  rentedOutAssets: RentedOutAsset[];
  rentalAssets: RentalAsset[];
  transferLogs: TransferLog[];
}

interface HistoryResponseBody {
  transferLogs: TransferLog[];
}

interface OwnedAsset {
  assetId: number;
  name: string;
  operateAccessPermission: boolean;
  alertThresholdPermission: boolean;
}

interface RentedOutAsset {
  assetId: number;
  name: string;
  renteeCompanyId: number;
  renteeCompanyName: string;
  operateAccessPermission: boolean;
  alertThresholdPermission: boolean;
  isPendingApproval: boolean;
}

interface RentalAsset {
  assetId: number;
  name: string;
  renterCompanyId: number;
  renterCompanyName: string;
  operateAccessPermission: boolean;
  alertThresholdPermission: boolean;
  isPendingApproval: boolean;
}

interface TransferLog {
  assetTransferLogId: number;
  userName: string | null;
  assetName: string;
  companyName: string;
  deviceType: string | null;
  deviceIdentifier: string | null;
  transferCode: string;
  createdUtc: string;
}

interface RentedOutAction {
  type: string;
  payload: RentedOutPayload;
}

interface RentedOutPayload {
  assets?: RentedOutAsset[];
  asset?: RentedOutAsset;
  assetId?: number;
  companyId?: number;
}

interface RentalAction {
  type: string;
  payload: RentalPayload;
}

interface RentalPayload {
  assets?: RentalAsset[];
  asset?: RentalAsset;
  assetId?: number;
  companyId?: number;
}
