// --------------------------------------------------------------
// Created On: 2023-09-23
// Author: Zachary Thomas
//
// Last Modified: 2024-05-17
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Success from "../../../components/Success/Success";
import Warning from "../../../components/Warning/Warning";
import InviteResults from "./InviteResults/InviteResults";
import Spinner from "../../../components/Spinner/Spinner";
import apiRequest from "../../../utilities/api/apiRequest";
import getApiError from "../../../utilities/api/getApiError";
import { API, VALID_EMAIL_REGULAR_EXPRESSION } from "../../../constants/miscellaneous";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import { USER_TYPES } from "../../../constants/reducerActions";
import styles from "./InviteUsersModal.module.scss";

// Modal for inviting users to this company.
export default function InviteUsersModal(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [emails, setEmails] = useState<string>("");
  const [invitesSent, setInvitesSent] = useState<boolean>(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState<InvitationResponse[]>([]);
  const [failed, setFailed] = useState<InvitationResponse[]>([]);
  const [successful, setSuccessful] = useState<InvitationResponse[]>([]);
  const currentUser = useSelector(getCurrentUser);

  // Send email invitations to users.
  async function sendInvitations() {
    // Confirm that the email list is not empty.
    if (emails.length === 0) {
      setErrorMessage("You must list at least one Email to invite.");
      return;
    }

    // Remove all white spaces from email list, and create an array.
    const emailList = emails.replace(/\s/g, "").split(",");

    // Confirm that the email list includes valid email addresses.
    for (const email of emailList) {
      // Use a regular expression to test if an email is valid.
      const isValidEmail = VALID_EMAIL_REGULAR_EXPRESSION.test(email.toLowerCase());

      if (!isValidEmail) {
        setErrorMessage(`The email address '${email.trim()}' is not valid.`);
        return;
      }
    }

    // Send the emails.
    const requestBody = {
      emailAddresses: emailList,
      companyId: currentUser.companyId,
    };

    setLoading(true);
    const [response, responseBody] = (await apiRequest(
      `${API}/registration/company/user/invite`,
      "POST",
      requestBody
    )) as [Response, ResponseBody];
    setLoading(false);

    if (response.ok && responseBody) {
      setInvitesSent(true);
      setSuccessful(responseBody.successfulEmails);
      setAlreadyRegistered(responseBody.alreadyRegistered);
      setFailed(responseBody.failedEmails);
      if (responseBody.alreadyRegistered.length + responseBody.failedEmails.length === 0) {
        setErrorMessage("");
        setSuccessMessage("All invites sent successfully.");
      } else if (responseBody.successfulEmails.length === 0) {
        setErrorMessage("No invites were sent.");
      } else {
        setErrorMessage("");
        setWarningMessage("Some invites could not be sent.");
      }

      responseBody.successfulEmails.forEach((successfulEmail) => {
        if (responseBody.roleId !== null) {
          const newUser = {
            userId: successfulEmail.userId,
            name: successfulEmail.emailAddress.split("@")[0],
            emailAddress: successfulEmail.emailAddress,
            phoneNumberCountryCode: null,
            phoneNumber: null,
            roleId: responseBody.roleId,
          };

          props.onAction({
            type: USER_TYPES.CREATE_USER,
            payload: {
              user: newUser,
            },
          });
        }
      });
    } else {
      setErrorMessage(await getApiError(response, "Unable to send invitations."));
    }
  }

  // Clean up and exit the modal.
  function exitModal() {
    setErrorMessage("");
    setSuccessMessage("");
    setWarningMessage("");
    setEmails("");
    setInvitesSent(false);
    props.onClose();
  }

  return (
    <div className="user-modal-container">
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="lg"
        animation
      >
        <ModalHeader>
          <h5 className="modal-title font-weight-bold">
            <span>Invite Users to Company</span>
          </h5>
        </ModalHeader>

        <ModalBody>
          {invitesSent ? (
            <Fragment>
              {successful.length > 0 && (
                <InviteResults
                  title="Successfully sent invites:"
                  color="var(--bs-success)"
                  invitationResponses={successful}
                />
              )}

              {failed.length > 0 && (
                <InviteResults
                  title="Invites that failed to send:"
                  color="var(--bs-danger)"
                  invitationResponses={failed}
                />
              )}

              {alreadyRegistered.length > 0 && (
                <InviteResults
                  title="Invites to users who are already registered:"
                  color="var(--bs-danger)"
                  invitationResponses={alreadyRegistered}
                />
              )}
            </Fragment>
          ) : (
            <div className="form-user mx-2 mt-3">
              <label className="font-weight-bold mb-3">Users to Invite</label>
              <p className="font-italic mb-3">
                Enter a comma separated list of the Email addresses you would like to send invitations to.
              </p>
              <textarea
                data-test="invite-users-modal-textarea"
                className="form-control mx-auto"
                value={emails}
                onChange={(e) => setEmails(e.target.value)}
              />
            </div>
          )}

          {(errorMessage.length > 0 || successMessage.length > 0 || warningMessage.length > 0) && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={errorMessage} />
                <Success message={successMessage} />
                <Warning message={warningMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          {invitesSent ? (
            <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
              Close
            </button>
          ) : (
            <Fragment>
              <button
                data-test="invite-users-modal-send-invitation-button"
                className="btn btn-primary"
                type="button"
                onClick={() => sendInvitations()}
              >
                Send Invitations
              </button>

              <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
                Cancel
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}

InviteUsersModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

interface Props {
  showModal: boolean;
  onAction: (action: Action) => void;
  onClose: () => void;
}

interface InvitationResponse {
  userId: number;
  emailAddress: string;
}

interface ResponseBody {
  successfulEmails: InvitationResponse[];
  alreadyRegistered: InvitationResponse[];
  failedEmails: InvitationResponse[];
  roleId: number | null;
}

interface User {
  userId: number;
  name: string;
  emailAddress: string;
  phoneNumberCountryCode: string | null;
  phoneNumber: string | null;
  roleId: number;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  user: User;
}
