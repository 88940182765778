// --------------------------------------------------------------
// Created On: 2021-11-23
// Author: Zachary Thomas
//
// Last Modified: 2024-09-11
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Widget from "../../../components/Widget/Widget";
import Accordion from "../../../components/Accordion/Accordion";
import DataPoint from "./DataPoint/DataPoint";

// List of all attributes ever reported by the current asset.
export default function CompleteAttributeList(props: Props): Component {
  return (
    <div className="row justify-content-center mx-3 py-3">
      <div className="col-12 col-xl-8 px-0">
        <Accordion id="data-attributes" title="All Reported Attributes">
          <div data-test="asset-data-container" className="px-4 pb-4">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="row">
                  {props.attributes.map((attribute) => (
                    <Fragment key={attribute.attributeCode}>
                      {attribute.currentValue !== null && (
                        <div className={props.attributes.length > 1 ? "col-12 col-lg-6 p-2" : "col-12 p-2"}>
                          <Widget extendClass="w-100">
                            <DataPoint
                              icon={attribute.icon || "question"}
                              name={attribute.attributeName}
                              code={attribute.attributeCode}
                              value={attribute.currentValue}
                              unit={attribute.unitShortName || ""}
                              mostRecentUtc={attribute.currentValueUtc}
                              isHistorical={attribute.isHistorical || false}
                              isGraphable={attribute.isGraphable || false}
                              isStale={attribute.isStale}
                              onClick={() => props.onSelectAttribute(attribute.regAttributeId)}
                            />
                          </Widget>
                        </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
}

CompleteAttributeList.propTypes = {
  attributes: PropTypes.array.isRequired,
  onSelectAttribute: PropTypes.func.isRequired,
};

interface Props {
  attributes: Attribute[];
  onSelectAttribute: (attributeId: number) => void;
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  connectedRegAttributeId: number | null;
  unitShortName: string | null;
  unitLongName: string | null;
  icon: string;
  currentValue: string | null;
  currentValueUtc: string | null;
  isHistorical: boolean;
  isGraphable: boolean;
  isStale: boolean;
}
