// --------------------------------------------------------------
// Created On: 2023-06-19
// Author: Zachary Thomas
//
// Last Modified: 2024-09-12
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";

// A select for a type of digital sensor and its application (what it reports).
export default function DigitalSensorSelect(props: Props): Component {
  // Returns a digital sensor ID if it exists, otherwise an empty string.
  function getDigitalSensorId(attributeCode: string): string {
    if (
      props.digitalSensorMap[attributeCode] !== undefined &&
      props.digitalSensorMap[attributeCode] !== null &&
      props.digitalSensorMap[attributeCode].digitalSensorId !== undefined
    ) {
      return String(props.digitalSensorMap[attributeCode].digitalSensorId);
    } else {
      return "";
    }
  }

  // Returns a list of all of the applications supported by a selected digital sensor.
  function getDigitalSensorApplications(attributeCode: string): DigitalApplication[] {
    if (
      props.digitalSensorMap[attributeCode] !== undefined &&
      props.digitalSensorMap[attributeCode] !== null &&
      props.digitalSensorMap[attributeCode].digitalSensorId !== undefined
    ) {
      const selectedSensor = props.digitalSensors.find(
        (digitalSensor) => props.digitalSensorMap[attributeCode].digitalSensorId === digitalSensor.digitalSensorId
      );
      if (selectedSensor !== undefined) {
        return selectedSensor.applications;
      }
    }

    return [];
  }

  // Returns an digital sensor's currently selected reporting description, otherwise an empty string.
  function getSelectedDigitalSensorReportingDescription(attributeCode: string): string {
    if (
      props.digitalSensorMap[attributeCode] !== undefined &&
      props.digitalSensorMap[attributeCode] !== null &&
      props.digitalSensorMap[attributeCode].reportingDescription !== undefined
    ) {
      return String(props.digitalSensorMap[attributeCode].reportingDescription);
    } else {
      return "";
    }
  }

  return (
    <div>
      <label className="my-3 me-2">
        <span>Digital Sensor #{props.sensorNumber}</span>
      </label>
      <select
        className="form-select"
        value={getDigitalSensorId(props.sourceStateAttribute)}
        disabled={props.disabled}
        onChange={(e) =>
          props.onChange(
            e.target.value,
            props.sourceStateAttribute,
            props.sourceFlowAttribute,
            props.sourceAccumulationAttribute,
            props.sourceModeAttribute,
            props.sourceKFactorAttribute,
            props.sourceOffsetAttribute,
            props.sourceDebounceAttribute,
            props.sourceUnitsPerPulseAttribute,
            ""
          )
        }
      >
        <option value="">None</option>
        {props.digitalSensors.map((digitalSensor) => (
          <option key={digitalSensor.digitalSensorId} value={digitalSensor.digitalSensorId}>
            {digitalSensor.name}
          </option>
        ))}
      </select>

      {getDigitalSensorApplications(props.sourceStateAttribute).length > 0 && (
        <select
          className="form-select mt-2"
          value={getSelectedDigitalSensorReportingDescription(props.sourceStateAttribute)}
          disabled={props.disabled}
          onChange={(e) =>
            props.onChange(
              getDigitalSensorId(props.sourceStateAttribute),
              props.sourceStateAttribute,
              props.sourceFlowAttribute,
              props.sourceAccumulationAttribute,
              props.sourceModeAttribute,
              props.sourceKFactorAttribute,
              props.sourceOffsetAttribute,
              props.sourceDebounceAttribute,
              props.sourceUnitsPerPulseAttribute,
              e.target.value
            )
          }
        >
          {getDigitalSensorApplications(props.sourceStateAttribute).map((application) => (
            <option key={application.reportingDescription} value={application.reportingDescription}>
              Sensor reports {application.reportingDescription.toLowerCase()}
            </option>
          ))}
        </select>
      )}
    </div>
  );
}

DigitalSensorSelect.propTypes = {
  sensorNumber: PropTypes.number.isRequired,
  sourceStateAttribute: PropTypes.string.isRequired,
  sourceFlowAttribute: PropTypes.string.isRequired,
  sourceAccumulationAttribute: PropTypes.string.isRequired,
  sourceModeAttribute: PropTypes.string.isRequired,
  sourceKFactorAttribute: PropTypes.string.isRequired,
  sourceOffsetAttribute: PropTypes.string.isRequired,
  sourceDebounceAttribute: PropTypes.string.isRequired,
  sourceUnitsPerPulseAttribute: PropTypes.string.isRequired,
  digitalSensors: PropTypes.array.isRequired,
  digitalSensorMap: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

interface Props {
  sensorNumber: number;
  sourceStateAttribute: string;
  sourceFlowAttribute: string;
  sourceAccumulationAttribute: string;
  sourceModeAttribute: string;
  sourceKFactorAttribute: string;
  sourceOffsetAttribute: string;
  sourceDebounceAttribute: string;
  sourceUnitsPerPulseAttribute: string;
  digitalSensors: DigitalSensor[];
  digitalSensorMap: DigitalSensorMap;
  disabled: boolean;
  onChange: (
    digitalSensorId: string,
    attributeStateCode: string,
    attributeFlowCode: string,
    attributeAccumulationCode: string,
    attributeModeCode: string,
    attributeKFactorCode: string,
    attributeOffsetCode: string,
    attributeDebounceCode: string,
    attributeUnitsPerPulseCode: string,
    reportingDescription: string
  ) => void;
}

interface DigitalSensor {
  digitalSensorId: number;
  name: string;
  applications: DigitalApplication[];
}

interface DigitalApplication {
  attributeStateId: number;
  attributeFlowId: number;
  attributeAccumulationId: number;
  reportingDescription: string;
}

interface DigitalSensorMap {
  [key: string]: DigitalSensorSelection;
}

interface DigitalSensorSelection {
  digitalSensorId: number;
  attributeStateCode: string;
  attributeFlowCode: string;
  attributeAccumulationCode: string;
  attributeModeCode: string;
  attributeKFactorCode: string;
  attributeOffsetCode: string;
  attributeDebounceCode: string;
  attributeUnitsPerPulseCode: string;
  mappedAttributeStateId: number;
  mappedAttributeFlowId: number;
  mappedAttributeAccumulationId: number;
  reportingDescription: string;
}
