// --------------------------------------------------------------
// Created On: 2022-01-05
// Author: Zachary Thomas
//
// Last Modified: 2024-09-17
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import InfoModal from "../../../components/InfoModal/InfoModal";
import HelpMaintenanceLog from "../../../components/HelpMaintenanceLog/HelpMaintenanceLog";
import PropTypes from "prop-types";
import formatDateLocal from "../../../utilities/time/formatDateLocal";
import userHasPermission from "../../../utilities/userHasPermission";
import { CREATE_MAINTENANCE_LOGS_PERMISSION } from "../../../constants/permissions";
import styles from "./MaintenanceList.module.scss";

// A list of maintenance logs that support creation, updating, and deletion.
export default function MaintenanceList(props: Props): Component {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div className={`${styles.body} shadow-sm`}>
      <div className={`${styles.header} p-4`}>
        <div className="row align-items-top">
          <div className="col-6">
            <span className={styles.title}>
              {props.assetName}
              {props.assetName === "" ? "" : "'s"} Maintenance Logs ({props.maintenanceLogs.length})
            </span>
            <InfoModal
              showModal={showModal}
              title="What is a Maintenance Log?"
              darkBackground={true}
              onClick={() => setShowModal(true)}
              onClose={() => setShowModal(false)}
            >
              <HelpMaintenanceLog />
            </InfoModal>
          </div>

          {/* Button for creating new maintenance logs. */}
          {userHasPermission([[CREATE_MAINTENANCE_LOGS_PERMISSION]]) && (
            <div className="col-6">
              {userHasPermission([[CREATE_MAINTENANCE_LOGS_PERMISSION]]) && (
                <button
                  data-test="create-maintenance-log-button"
                  className={`${styles.button} btn btn-light float-end`}
                  onClick={() => props.onSelect(0)}
                >
                  Create Maintenance Log
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      <div className={styles.list}>
        {/* Table of interactive maintenance logs. */}
        {props.maintenanceLogs.length > 0 && (
          <div className="table-responsive">
            <table className={`${styles.table} table mb-0 pb-0`} data-test="maintenance-log-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Serviced By</th>
                  <th>Cost</th>
                </tr>
              </thead>
              <tbody>
                {props.maintenanceLogs.map((maintenanceLog) => (
                  <tr
                    data-test="maintenance-list-container"
                    className="selectable-container py-2"
                    key={maintenanceLog.assetMaintenanceLogId}
                    onClick={() => props.onSelect(maintenanceLog.assetMaintenanceLogId)}
                  >
                    <td>{formatDateLocal(maintenanceLog.datePerformed)}</td>
                    <td data-test="maintenance-log-desc">{maintenanceLog.workPerformed}</td>
                    <td>{maintenanceLog.workPerformedBy}</td>
                    <td>{maintenanceLog.cost}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {props.maintenanceLogs.length === 0 && (
          <p className="h4 my-5 text-center">No maintenance logs have been created</p>
        )}
      </div>
    </div>
  );
}

MaintenanceList.propTypes = {
  assetName: PropTypes.string.isRequired,
  maintenanceLogs: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

interface Props {
  assetName: string;
  maintenanceLogs: MaintenanceLog[];
  onSelect: (maintenanceLogId: number) => void;
}

interface MaintenanceLog {
  assetMaintenanceLogId: number;
  workPerformed: string;
  workPerformedBy: string;
  datePerformed: string;
  cost: string;
  note: string;
}
