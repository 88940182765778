// --------------------------------------------------------------
// Created On: 2024-09-12
// Author: Zachary Thomas
//
// Last Modified: 2024-09-15
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import IconTooltip from "../../../../components/IconTooltip/IconTooltip";
import PropTypes from "prop-types";
import { MAX_CARD_ATTRIBUTES, MAX_CARDS } from "../../../../constants/templates";
import { Active, DndContext, Over } from "@dnd-kit/core";
import DroppableArea from "../../../../components/DroppableArea/DroppableArea";
import TemplateCard from "./TemplateCard/TemplateCard";
import styles from "./CardPage.module.scss";

// Page in wizard for defining cards.
export default function CardPage(props: Props): Component {
  return (
    <div data-test="card-attribute-page">
      <div className="mx-1 my-2">
        <label className={styles.title}>
          <span className="ms-3 me-2">Cards</span>
          <IconTooltip
            id="card-attribute-tooltip"
            icon="info-circle"
            message={`When viewing an asset's data page, these cards will show general data about the asset beneath the
                      highlight attributes. You can either fill out your own custom attributes per card, or use a dynamic
                      card that auto populates based on the state of the current asset. There is a limit of ${MAX_CARDS} cards,
                      and ${MAX_CARD_ATTRIBUTES} attributes per card.
                    `}
            color="var(--info-tooltip)"
          />
          <span className="me-2" />
          <IconTooltip
            id="card-image-tooltip"
            icon="picture-o"
            imageUrl="/images/template_card_example.png"
            imageAlt="Card example."
            imageMaxWidth={1000}
            color="var(--info-tooltip-image)"
          />
        </label>

        <button
          data-test="add-card-attribute-button"
          type="submit"
          className={`${styles.addButton} btn btn-success float-end`}
          onClick={() => props.onCreate()}
          disabled={props.disabled || props.cards.length >= MAX_CARDS}
        >
          <span className="d-none d-sm-inline">Add Card</span>
          <i className="d-inline d-sm-none fa fa-fw fa-plus fa-xs" />
        </button>
      </div>

      {props.cards.length > 0 && (
        <DndContext onDragEnd={(e) => props.onDragEnd(e.over, e.active)}>
          <DroppableArea id={0} disabled={props.disabled} isSlim />
          {props.cards.map((card, i) => (
            <Fragment key={card.formId}>
              <TemplateCard
                formIndex={i}
                formId={card.formId}
                name={card.name}
                cardTypeId={card.cardTypeId}
                attributeMap={props.attributeMap}
                attributes={card.attributes}
                templateCardTypes={props.templateCardTypes}
                onChange={(name, cardTypeId, attributes) => props.onChange(card.formId, name, cardTypeId, attributes)}
                onDelete={() => props.onDelete(card.formId)}
                onDragEnd={(over, active) => props.onDragEnd(over, active)}
              />
              <DroppableArea id={card.formId} disabled={props.disabled} isSlim />
            </Fragment>
          ))}
        </DndContext>
      )}
    </div>
  );
}

CardPage.propTypes = {
  cards: PropTypes.array.isRequired,
  templateCardTypes: PropTypes.array.isRequired,
  attributeMap: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onCreate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
};

interface Props {
  cards: Card[];
  templateCardTypes: TemplateCardType[];
  attributeMap: AttributesById;
  disabled?: boolean;
  onCreate: () => void;
  onChange: (formId: number, name: string, cardTypeId: number | null, attributes: Attribute[]) => void;
  onDelete: (formId: number) => void;
  onDragEnd: (over: Over | null, active: Active) => void;
}

interface Card {
  formId: number;
  name: string;
  cardTypeId: number | null;
  attributes: Attribute[];
}

interface Attribute {
  formId: number;
  regAttributeId: number;
  attributeName: string;
  unitId: number | null;
  unitShortName: string | null;
  unitLongName: string | null;
}

interface TemplateCardType {
  templateCardTypeId: number;
  name: string;
  description: string;
}

interface AttributesById {
  [key: string]: Attribute;
}
