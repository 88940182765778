// --------------------------------------------------------------
// Created On: 2021-10-14
// Author: Zachary Thomas
//
// Last Modified: 2024-02-02
// Modified By: Zachary Thomas
//
// Copyright 2022 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import AlertMessageModal from "./AlertMessageModal/AlertMessageModal";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import { setCurrentUser, setAuthorizerToken } from "../../../redux/actions";
import { Link } from "react-router-dom";
import { RPM_COMPANY } from "../../../constants/miscellaneous";
import userHasPermission from "../../../utilities/userHasPermission";
import userIsSuperAdmin from "../../../utilities/userIsSuperAdmin";
import { SUPER_ADMIN_PERMISSION, CREATE_ALERT_MESSAGES_PERMISSION } from "../../../constants/permissions";
import styles from "./UserOptions.module.scss";

// User dropdown for managing settings and logging out.
export default function UserOptions(): Component {
  const [showModal, setShowModal] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Logout the current user.
  function logout(): void {
    dispatch(setAuthorizerToken(""));
    navigate("/login");
  }

  // Exit the current company and return to the RPM admin dashboard.
  function exitCompany(): void {
    dispatch(
      setCurrentUser(
        currentUser.userId,
        currentUser.userName,
        currentUser.emailAddress,
        0,
        RPM_COMPANY,
        false,
        currentUser.permissions,
        false
      )
    );
    navigate("/");
  }

  return (
    <div className={`${styles.dropdown} dropdown`}>
      {/* Button for selecting user options. */}
      <button
        className={`${styles.container} btn btn-primary py-0`}
        type="button"
        id="user-navbar-button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-test="navbar-user-options-button"
      >
        <div className="d-flex align-items-center" aria-labelledby="user-navbar-button">
          <div className={`${styles.userNavbarText}`}>
            <div className={styles.fullContent}>
              <div className={`${styles.bigUserNameText} my-2`}>{currentUser.userName}</div>
            </div>

            <div className={styles.smallContent}>
              <i className="fa fa-fw fa-cog" />
            </div>
          </div>
        </div>
      </button>

      {/* Dropdown menu of options */}
      <div
        className={`${styles.dropdownMenu} dropdown-menu dropdown-menu-end`}
        data-test="navbar-user-options-dropdown"
      >
        {userIsSuperAdmin() && currentUser.companyName !== RPM_COMPANY && (
          <button
            className={`${styles.dropdownItem} d-inline-block d-lg-none dropdown-item`}
            onClick={() => exitCompany()}
          >
            Exit &apos;{currentUser.companyName}&apos;
          </button>
        )}

        <Link to="/account/user-info" className="router-link">
          <button className={`${styles.dropdownItem} dropdown-item`} data-test="edit-user-account-info-button">
            Edit Account Info
          </button>
        </Link>

        <Link to="/account/password" className="router-link">
          <button className={`${styles.dropdownItem} dropdown-item`} data-test="change-password-button">
            Change Password
          </button>
        </Link>

        {userHasPermission([[SUPER_ADMIN_PERMISSION], [CREATE_ALERT_MESSAGES_PERMISSION]]) &&
          currentUser.companyName !== RPM_COMPANY && (
            <button className={`${styles.dropdownItem} dropdown-item`} onClick={() => setShowModal(true)}>
              Send Alert Message
            </button>
          )}

        <button className={`${styles.dropdownItem} dropdown-item`} data-test="logout-button" onClick={() => logout()}>
          Sign Out
        </button>
      </div>

      <AlertMessageModal showModal={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
}
