// --------------------------------------------------------------
// Created On: 2021-05-14
// Author: Zachary Thomas
//
// Last Modified: 2023-12-06
// Modified By: Lilly Kane
//
// Copyright 2021 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./Spinner.module.scss";

// Centered spinner that lets the user know something is loading.
export default function Spinner(props: Props): Component {
  return props.loading ? (
    <div data-test="spinner-container" className={styles.spinnerContainer}>
      <div role="status" className={`${styles.spinner} spinner-border text-primary mt-5`}>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  ) : null;
}

Spinner.propTypes = {
  loading: PropTypes.bool.isRequired,
};

interface Props {
  loading: boolean;
}
