// --------------------------------------------------------------
// Created On: 2024-08-21
// Author: KC Willard
//
// Last Modified: 2024-09-17
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import Spinner from "../../../components/Spinner/Spinner";
import deepCopy from "../../../utilities/deepCopy";
import TemplateNameForm from "./TemplateNameForm/TemplateNameForm";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import { API } from "../../../constants/miscellaneous";
import useApi from "../../../hooks/useApi";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import userHasPermission from "../../../utilities/userHasPermission";
import getApiError from "../../../utilities/api/getApiError";
import { Over, Active } from "@dnd-kit/core";
import HighlightAttributePage from "./HighlightAttributePage/HighlightAttributePage";
import OperationAttributePage from "./OperationAttributePage/OperationAttributePage";
import GaugePage from "./GaugePage/GaugePage";
import CardPage from "./CardPage/CardPage";
import { TEMPLATE_TYPES } from "../../../constants/reducerActions";
import {
  MIN_TEMPLATE_NAME_LENGTH,
  MAX_TEMPLATE_NAME_LENGTH,
  MAX_TEMPLATE_DESCRIPTION_LENGTH,
  MAX_HIGHLIGHT_ATTRIBUTES,
  MAX_REMOTE_OPERATION_ATTRIBUTES,
  MAX_CARDS,
  MAX_GAUGES,
  MIN_CARD_NAME_LENGTH,
  MAX_CARD_NAME_LENGTH,
  GAUGE_LOWEST_MIN_VALUE,
  GAUGE_HIGHEST_MAX_VALUE,
} from "../../../constants/templates";
import Error from "../../../components/Error/Error";
import apiRequest from "../../../utilities/api/apiRequest";
import {
  CREATE_TEMPLATES_PERMISSION,
  UPDATE_TEMPLATES_PERMISSION,
  DELETE_TEMPLATES_PERMISSION,
} from "../../../constants/permissions";
import styles from "./TemplateManagementWizard.module.scss";

// A wizard for creating, updating, and deleting templates.
export default function TemplateManagementWizard(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showConfirmExit, setShowConfirmExit] = useState<boolean>(false);
  const [activePageNumber, setActivePageNumber] = useState<number>(1);
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [templateName, setTemplateName] = useState<string>("");
  const [templateDescription, setTemplateDescription] = useState<string>("");
  const [highlightAttributes, setHighlightAttributes] = useState<Attribute[]>([]);
  const [operationAttributes, setOperationAttributes] = useState<Attribute[]>([]);
  const [gauges, setGauges] = useState<Gauge[]>([]);
  const [cards, setCards] = useState<Card[]>([]);
  const [previousTemplateName, setPreviousTemplateName] = useState<string>("");
  const [previousTemplateDescription, setPreviousTemplateDescription] = useState<string>("");
  const [previousHighlightAttributes, setPreviousHighlightAttributes] = useState<Attribute[]>([]);
  const [previousGauges, setPreviousGauges] = useState<Gauge[]>([]);
  const [previousCards, setPreviousCards] = useState<Card[]>([]);
  const [previousOperationAttributes, setPreviousOperationAttributes] = useState<Attribute[]>([]);
  const currentUser = useSelector(getCurrentUser);
  const formIsEditable =
    (props.isCreatingNewTemplate && userHasPermission([[CREATE_TEMPLATES_PERMISSION]])) ||
    (!props.isCreatingNewTemplate && userHasPermission([[UPDATE_TEMPLATES_PERMISSION]]));
  const templateManagementPages = [
    {
      title: "Template Name and Description",
      pageNumber: 1,
    },
    {
      title: "Highlight Attributes",
      pageNumber: 2,
    },
    {
      title: "Cards",
      pageNumber: 3,
    },
    {
      title: "Remote Operation Gauges",
      pageNumber: 4,
    },
    {
      title: "Remote Operation Attributes",
      pageNumber: 5,
    },
  ];

  // Get detailed template information from the API.
  useApi(
    () => {
      setLoading(props.assetTemplateId > 0);
      return props.assetTemplateId > 0;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset-template/${props.assetTemplateId}`,
    },
    async (response: Response, responseBody: GetResponseBody) => {
      if (response.ok && responseBody) {
        setIsDefault(responseBody.isImmutable);
        setTemplateName(responseBody.name);
        setTemplateDescription(responseBody.description);
        const highlightAttributes = getAttributesByIds(responseBody.highlightAttributeIds, props.attributeMap);
        setHighlightAttributes(highlightAttributes);
        const operationAttributes = getAttributesByIds(responseBody.operationAttributeIds, props.attributeMap);
        setOperationAttributes(operationAttributes);
        const gauges: Gauge[] = [];
        responseBody.gauges.forEach((gauge, i) => {
          const attribute = props.attributeMap[gauge.regAttributeId];
          if (attribute !== undefined) {
            gauges.push({
              formId: i + 1,
              regAttributeId: attribute.regAttributeId,
              attributeName: attribute.attributeName,
              unitId: attribute.unitId,
              unitShortName: attribute.unitShortName,
              unitLongName: attribute.unitLongName,
              min: gauge.min,
              max: gauge.max,
            });
          }
        });
        setGauges(gauges);
        const cards: Card[] = [];
        responseBody.cards.forEach((card, i) => {
          const cardAttributes = getAttributesByIds(card.attributeIds, props.attributeMap);
          cards.push({
            formId: i + 1,
            name: card.name,
            cardTypeId: card.cardTypeId,
            attributes: cardAttributes,
          });
        });
        setCards(cards);
        setPreviousTemplateName(responseBody.name);
        setPreviousTemplateDescription(responseBody.description);
        setPreviousHighlightAttributes(highlightAttributes);
        setPreviousOperationAttributes(operationAttributes);
        setPreviousGauges(gauges);
        setPreviousCards(cards);
        setErrorMessage("");
      } else {
        setErrorMessage(await getApiError(response, "Unable to get template information."));
      }
      setLoading(false);
    },
    [props.assetTemplateId]
  );

  // Clear error messages whenever the active page number changes.
  useEffect(() => {
    setErrorMessage("");
  }, [activePageNumber]);

  // Get an array of attributes by passing in an array of attribute IDs.
  function getAttributesByIds(attributeIds: number[], attributeMap: AttributesById): Attribute[] {
    const attributesDeepCopy = deepCopy(attributeIds.map((attributeId) => attributeMap[attributeId]));
    attributesDeepCopy.forEach((attribute, i) => {
      attribute.formId = i + 1;
    });
    return attributesDeepCopy;
  }

  // Save changes.
  function saveChanges(assetTemplateId: number): void {
    setShowConfirmExit(false);
    setErrorMessage("");
    if (props.isCreatingNewTemplate) {
      createTemplate();
    } else {
      editTemplate(assetTemplateId);
    }
  }

  // Exit without saving changes.
  function discardChanges(): void {
    props.onClose();
    setLoading(false);
    setIsDefault(false);
    setTemplateName("");
    setTemplateDescription("");
    setCards([]);
    setGauges([]);
    setHighlightAttributes([]);
    setOperationAttributes([]);
    setShowConfirmDelete(false);
    setShowConfirmExit(false);
    setActivePageNumber(1);
    setErrorMessage("");
  }

  // Exit modal if no changes have been made. Otherwise prompt the user.
  function exitModal(): void {
    if (
      templateName === previousTemplateName &&
      templateDescription === previousTemplateDescription &&
      JSON.stringify(highlightAttributes) === JSON.stringify(previousHighlightAttributes) &&
      JSON.stringify(gauges) === JSON.stringify(previousGauges) &&
      JSON.stringify(cards) === JSON.stringify(previousCards) &&
      JSON.stringify(operationAttributes) === JSON.stringify(previousOperationAttributes)
    ) {
      // Since there have been no changes we can safely exit.
      discardChanges();
    } else {
      // We have unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    }
  }

  // Add new attributes to the highlight section.
  function handleCreateHighlightAttribute() {
    const highlightAttributesDeepCopy = deepCopy(highlightAttributes);
    let formId = 1;
    highlightAttributesDeepCopy.forEach((highlightAttribute) => {
      if (highlightAttribute.formId >= formId) {
        formId = highlightAttribute.formId + 1;
      }
    });
    const highlightAttribute = {
      formId: formId,
      regAttributeId: 0,
      attributeName: "",
      unitId: null,
      unitShortName: null,
      unitLongName: null,
    };
    highlightAttributesDeepCopy.push(highlightAttribute);
    setHighlightAttributes(highlightAttributesDeepCopy);
  }

  // Update an attribute in the highlight section.
  function handleUpdateHighlightAttribute(formId: number, name: string, attributeMap: AttributesById): void {
    const highlightAttributesDeepCopy = deepCopy(highlightAttributes);
    const highlightAttribute = highlightAttributesDeepCopy.find((attribute) => attribute.formId === formId);
    if (highlightAttribute !== undefined) {
      const attributes = Object.keys(attributeMap).map((key) => attributeMap[key]);
      const lookUpAttribute = attributes.find((attribute) => attribute.attributeName === name);
      if (lookUpAttribute !== undefined) {
        highlightAttribute.regAttributeId = lookUpAttribute.regAttributeId;
      } else {
        highlightAttribute.regAttributeId = 0;
      }
      highlightAttribute.attributeName = name;
      setHighlightAttributes(highlightAttributesDeepCopy);
    }
  }

  // Delete an attribute in the highlight section.
  function handleDeleteHighlightAttribute(formId: number): void {
    const highlightAttributesDeepCopy = deepCopy(highlightAttributes);
    const selectedAttributeIndex = highlightAttributesDeepCopy.findIndex((attribute) => attribute.formId === formId);
    if (selectedAttributeIndex >= 0) {
      highlightAttributesDeepCopy.splice(selectedAttributeIndex, 1);
      setHighlightAttributes(highlightAttributesDeepCopy);
    }
  }

  // Handles changing the order of highlight attributes when dragged to a new position.
  function handleHighlightAttributeDragEnd(over: Over | null, active: Active): void {
    if (over !== null) {
      let attributesDeepCopy = deepCopy(highlightAttributes);
      const selectedAttributeIndex = attributesDeepCopy.findIndex((attribute) => attribute.formId === active.id);
      const selectedAttribute = attributesDeepCopy.find((attribute) => attribute.formId === active.id);
      if (selectedAttributeIndex > -1 && selectedAttribute !== undefined) {
        // Start by removing the attribute from the array before adding it to its new location.
        attributesDeepCopy.splice(selectedAttributeIndex, 1);
        // Figure out where the attribute gets added back into the array.
        if (over.id === 0) {
          attributesDeepCopy = [selectedAttribute, ...attributesDeepCopy];
          setHighlightAttributes(attributesDeepCopy);
        } else {
          const previousAttributeIndex = attributesDeepCopy.findIndex((attribute) => attribute.formId === over.id);
          if (previousAttributeIndex > -1) {
            attributesDeepCopy.splice(previousAttributeIndex + 1, 0, selectedAttribute);
            setHighlightAttributes(attributesDeepCopy);
          }
        }
      }
    }
  }

  // Add new attributes to the operation attributes section.
  function handleCreateOperationAttribute() {
    const operationAttributesDeepCopy = deepCopy(operationAttributes);
    let formId = 1;
    operationAttributesDeepCopy.forEach((operationAttribute) => {
      if (operationAttribute.formId >= formId) {
        formId = operationAttribute.formId + 1;
      }
    });
    const operationAttribute = {
      formId: formId,
      regAttributeId: 0,
      attributeName: "",
      unitId: null,
      unitShortName: null,
      unitLongName: null,
    };
    operationAttributesDeepCopy.push(operationAttribute);
    setOperationAttributes(operationAttributesDeepCopy);
  }

  // Update an attribute in the operation section.
  function handleUpdateOperationAttribute(formId: number, name: string, attributeMap: AttributesById): void {
    const operationAttributesDeepCopy = deepCopy(operationAttributes);
    const operationAttribute = operationAttributesDeepCopy.find((attribute) => attribute.formId === formId);
    if (operationAttribute !== undefined) {
      const attributes = Object.keys(attributeMap).map((key) => attributeMap[key]);
      const lookUpAttribute = attributes.find((attribute) => attribute.attributeName === name);
      if (lookUpAttribute !== undefined) {
        operationAttribute.regAttributeId = lookUpAttribute.regAttributeId;
      } else {
        operationAttribute.regAttributeId = 0;
      }
      operationAttribute.attributeName = name;
      setOperationAttributes(operationAttributesDeepCopy);
    }
  }

  // Delete an attribute in the operation section.
  function handleDeleteOperationAttribute(formId: number): void {
    const operationAttributesDeepCopy = deepCopy(operationAttributes);
    const selectedAttributeIndex = operationAttributesDeepCopy.findIndex((attribute) => attribute.formId === formId);
    if (selectedAttributeIndex >= 0) {
      operationAttributesDeepCopy.splice(selectedAttributeIndex, 1);
      setOperationAttributes(operationAttributesDeepCopy);
    }
  }

  // Handles changing the order of operation attributes when dragged to a new position.
  function handleOperationAttributeDragEnd(over: Over | null, active: Active): void {
    if (over !== null) {
      let attributesDeepCopy = deepCopy(operationAttributes);
      const selectedAttributeIndex = attributesDeepCopy.findIndex((attribute) => attribute.formId === active.id);
      const selectedAttribute = attributesDeepCopy.find((attribute) => attribute.formId === active.id);
      if (selectedAttributeIndex > -1 && selectedAttribute !== undefined) {
        // Start by removing the attribute from the array before adding it to its new location.
        attributesDeepCopy.splice(selectedAttributeIndex, 1);
        // Figure out where the attribute gets added back into the array.
        if (over.id === 0) {
          attributesDeepCopy = [selectedAttribute, ...attributesDeepCopy];
          setOperationAttributes(attributesDeepCopy);
        } else {
          const previousAttributeIndex = attributesDeepCopy.findIndex((attribute) => attribute.formId === over.id);
          if (previousAttributeIndex > -1) {
            attributesDeepCopy.splice(previousAttributeIndex + 1, 0, selectedAttribute);
            setOperationAttributes(attributesDeepCopy);
          }
        }
      }
    }
  }

  // Add new gauge to gauge section.
  function handleCreateGauge() {
    const gaugesDeepCopy = deepCopy(gauges);
    let formId = 1;
    gaugesDeepCopy.forEach((gauge) => {
      if (gauge.formId >= formId) {
        formId = gauge.formId + 1;
      }
    });
    const gauge = {
      formId: formId,
      regAttributeId: 0,
      attributeName: "",
      min: "",
      max: "",
      unitId: null,
      unitShortName: null,
      unitLongName: null,
    };
    gaugesDeepCopy.push(gauge);
    setGauges(gaugesDeepCopy);
  }

  // Update an attribute in the gauge section.
  function handleUpdateGauge(
    formId: number,
    name: string,
    min: string,
    max: string,
    attributeMap: AttributesById
  ): void {
    const gaugesDeepCopy = deepCopy(gauges);
    const gauge = gaugesDeepCopy.find((gauge) => gauge.formId === formId);
    if (gauge !== undefined) {
      const attributes = Object.keys(attributeMap).map((key) => attributeMap[key]);
      const lookUpAttribute = attributes.find(
        (attribute) => attribute.attributeName === name && attribute.unitId !== null
      );
      if (lookUpAttribute !== undefined) {
        gauge.regAttributeId = lookUpAttribute.regAttributeId;
        gauge.unitId = lookUpAttribute.unitId;
        gauge.unitLongName = lookUpAttribute.unitLongName;
        gauge.unitShortName = lookUpAttribute.unitShortName;
      } else {
        gauge.regAttributeId = 0;
        gauge.unitId = null;
        gauge.unitLongName = null;
        gauge.unitShortName = null;
      }
      gauge.attributeName = name;
      gauge.min = min;
      gauge.max = max;
      setGauges(gaugesDeepCopy);
    }
  }

  // Delete a gauge in the gauge section.
  function handleDeleteGauge(formId: number): void {
    const gaugesDeepCopy = deepCopy(gauges);
    const selectedGaugeIndex = gaugesDeepCopy.findIndex((gauge) => gauge.formId === formId);
    if (selectedGaugeIndex >= 0) {
      gaugesDeepCopy.splice(selectedGaugeIndex, 1);
      setGauges(gaugesDeepCopy);
    }
  }

  // Handles changing the order of gauge attributes when dragged to a new position.
  function handleGaugeDragEnd(over: Over | null, active: Active): void {
    if (over !== null) {
      let gaugesDeepCopy = deepCopy(gauges);
      const selectedGaugeIndex = gaugesDeepCopy.findIndex((gauge) => gauge.formId === active.id);
      const selectedGauge = gaugesDeepCopy.find((gauge) => gauge.formId === active.id);
      if (selectedGaugeIndex > -1 && selectedGauge !== undefined) {
        // Start by removing the gauge from the array before adding it to its new location.
        gaugesDeepCopy.splice(selectedGaugeIndex, 1);
        // Figure out where the gauge gets added back into the array.
        if (over.id === 0) {
          gaugesDeepCopy = [selectedGauge, ...gaugesDeepCopy];
          setGauges(gaugesDeepCopy);
        } else {
          const previousGaugeIndex = gaugesDeepCopy.findIndex((gauge) => gauge.formId === over.id);
          if (previousGaugeIndex > -1) {
            gaugesDeepCopy.splice(previousGaugeIndex + 1, 0, selectedGauge);
            setGauges(gaugesDeepCopy);
          }
        }
      }
    }
  }

  // Add new card to card section.
  function handleCreateCard() {
    const cardsDeepCopy = deepCopy(cards);
    let formId = 1;
    cardsDeepCopy.forEach((card) => {
      if (card.formId >= formId) {
        formId = card.formId + 1;
      }
    });
    const card = {
      formId: formId,
      name: "",
      cardTypeId: null,
      attributes: [],
    };
    cardsDeepCopy.push(card);
    setCards(cardsDeepCopy);
  }

  // Update an card in the card section.
  function handleUpdateCard(formId: number, name: string, cardTypeId: number | null, attributes: Attribute[]): void {
    const cardsDeepCopy = deepCopy(cards);
    const card = cardsDeepCopy.find((card) => card.formId === formId);
    if (card !== undefined) {
      if (cardTypeId === null) {
        card.name = name;
        card.cardTypeId = null;
        card.attributes = attributes;
      } else {
        card.name = "";
        card.cardTypeId = cardTypeId;
        card.attributes = [];
      }
      setCards(cardsDeepCopy);
    }
  }

  // Delete a card in the card section.
  function handleDeleteCard(formId: number): void {
    const cardsDeepCopy = deepCopy(cards);
    const selectedCardIndex = cardsDeepCopy.findIndex((card) => card.formId === formId);
    if (selectedCardIndex >= 0) {
      cardsDeepCopy.splice(selectedCardIndex, 1);
      setCards(cardsDeepCopy);
    }
  }

  // Handles changing the order of cards when dragged to a new position.
  function handleCardDragEnd(over: Over | null, active: Active): void {
    if (over !== null) {
      let cardsDeepCopy = deepCopy(cards);
      const selectedCardIndex = cardsDeepCopy.findIndex((card) => card.formId === active.id);
      const selectedCard = cardsDeepCopy.find((card) => card.formId === active.id);
      if (selectedCardIndex > -1 && selectedCard !== undefined) {
        // Start by removing the card from the array before adding it to its new location.
        cardsDeepCopy.splice(selectedCardIndex, 1);
        // Figure out where the card gets added back into the array.
        if (over.id === 0) {
          cardsDeepCopy = [selectedCard, ...cardsDeepCopy];
          setCards(cardsDeepCopy);
        } else {
          const previousCardIndex = cardsDeepCopy.findIndex((card) => card.formId === over.id);
          if (previousCardIndex > -1) {
            cardsDeepCopy.splice(previousCardIndex + 1, 0, selectedCard);
            setCards(cardsDeepCopy);
          }
        }
      }
    }
  }

  // Validate the template settings.
  function templateIsValid(): boolean {
    if (templateName.length < MIN_TEMPLATE_NAME_LENGTH) {
      setErrorMessage(`The template name must be at least ${MIN_TEMPLATE_NAME_LENGTH} characters long.`);
      return false;
    } else if (templateName.length > MAX_TEMPLATE_NAME_LENGTH) {
      setErrorMessage(`The template name must be less than ${MAX_TEMPLATE_NAME_LENGTH} characters long.`);
      return false;
    } else if (templateDescription.length > MAX_TEMPLATE_DESCRIPTION_LENGTH) {
      setErrorMessage(`The template description must be less than ${MAX_TEMPLATE_DESCRIPTION_LENGTH}.`);
      return false;
    } else if (
      !highlightAttributesAreValid() ||
      !cardsAreValid() ||
      !gaugesAreValid() ||
      !operationAttributesAreValid()
    ) {
      return false;
    } else {
      return true;
    }
  }

  // Checks if highlight attributes for a template are valid.
  function highlightAttributesAreValid(): boolean {
    let highlightAttributeIdsAreValid = true;
    let highlightAttributeIndex = 0;
    for (const [i, highlightAttribute] of highlightAttributes.entries()) {
      if (highlightAttribute.regAttributeId === 0) {
        highlightAttributeIdsAreValid = false;
        highlightAttributeIndex = i;
        break;
      }
    }
    if (highlightAttributes.length > MAX_HIGHLIGHT_ATTRIBUTES) {
      setErrorMessage(`The max number of highlight attributes allowed is ${MAX_HIGHLIGHT_ATTRIBUTES}.`);
      return false;
    } else if (!highlightAttributeIdsAreValid) {
      setErrorMessage(`Highlight attribute #${highlightAttributeIndex + 1} is an invalid attribute.`);
      return false;
    } else {
      return true;
    }
  }

  // Checks if gauges for a template are valid.
  function cardsAreValid(): boolean {
    let cardAttributesAreValid = true;
    let cardNameIsShort = false;
    let cardNameIsLong = false;
    let cardHasDuplicateAttribute = false;
    let cardIndex = 0;
    let cardAttributeIndex = 0;
    for (const [i, card] of cards.entries()) {
      if (card.name.length < MIN_CARD_NAME_LENGTH && card.cardTypeId === null) {
        cardNameIsShort = true;
        cardIndex = i;
        break;
      }
      if (card.name.length > MAX_CARD_NAME_LENGTH && card.cardTypeId === null) {
        cardNameIsLong = true;
        cardIndex = i;
        break;
      }
      const cardAttributeIds: number[] = [];
      for (const [j, attribute] of card.attributes.entries()) {
        if (cardAttributeIds.includes(attribute.regAttributeId)) {
          cardHasDuplicateAttribute = true;
          cardIndex = i;
          break;
        } else {
          cardAttributeIds.push(attribute.regAttributeId);
        }
        if (attribute.regAttributeId === 0) {
          cardAttributesAreValid = false;
          cardIndex = i;
          cardAttributeIndex = j;
          break;
        }
      }
    }
    if (cards.length > MAX_CARDS) {
      setErrorMessage(`The max number of cards allowed is ${MAX_CARDS}.`);
      return false;
    } else if (cardNameIsLong) {
      setErrorMessage(`Card #${cardIndex + 1}'s name must be at most ${MAX_CARD_NAME_LENGTH} characters long.`);
      return false;
    } else if (cardNameIsShort) {
      setErrorMessage(`Card #${cardIndex + 1}'s name must be at least ${MIN_CARD_NAME_LENGTH} characters long.`);
      return false;
    } else if (!cardAttributesAreValid) {
      setErrorMessage(`Card #${cardIndex + 1}, attribute #${cardAttributeIndex + 1} is an invalid attribute.`);
      return false;
    } else if (cardHasDuplicateAttribute) {
      setErrorMessage(
        `Card #${
          cardIndex + 1
        } includes the same attribute multiple times. An attribute may only appear once in a card.`
      );
      return false;
    } else {
      return true;
    }
  }

  // Checks if gauges for a template are valid.
  function gaugesAreValid(): boolean {
    const gaugeAttributeIds: number[] = [];
    let gaugeAttributesAreValid = true;
    let gaugeMinIsValid = true;
    let gaugeMaxIsValid = true;
    let gaugeMinIsSameAsMax = false;
    let gaugeHasDuplicateAttribute = false;
    let gaugeIndex = 0;
    for (const [i, gauge] of gauges.entries()) {
      if (gaugeAttributeIds.includes(gauge.regAttributeId)) {
        gaugeHasDuplicateAttribute = true;
        gaugeIndex = i;
        break;
      } else {
        gaugeAttributeIds.push(gauge.regAttributeId);
      }
      if (gauge.regAttributeId === 0) {
        gaugeAttributesAreValid = false;
        gaugeIndex = i;
        break;
      } else if (isNaN(parseFloat(String(gauge.min))) || parseFloat(String(gauge.min)) < GAUGE_LOWEST_MIN_VALUE) {
        gaugeMinIsValid = false;
        gaugeIndex = i;
        break;
      } else if (isNaN(parseFloat(String(gauge.max))) || parseFloat(String(gauge.max)) > GAUGE_HIGHEST_MAX_VALUE) {
        gaugeMaxIsValid = false;
        gaugeIndex = i;
        break;
      } else if (parseFloat(String(gauge.min)) === parseFloat(String(gauge.max))) {
        gaugeMinIsSameAsMax = true;
        gaugeIndex = i;
        break;
      }
    }
    if (gauges.length > MAX_GAUGES) {
      setErrorMessage(`The max number of gauges allowed is ${MAX_GAUGES}.`);
      return false;
    } else if (!gaugeAttributesAreValid) {
      setErrorMessage(`Gauge #${gaugeIndex + 1} is using an invalid attribute.`);
      return false;
    } else if (!gaugeMinIsValid) {
      setErrorMessage(
        `Gauge #${
          gaugeIndex + 1
        } has an invalid minimum value. Only whole numbers between ${GAUGE_LOWEST_MIN_VALUE} and ${GAUGE_HIGHEST_MAX_VALUE} are allowed.`
      );
      return false;
    } else if (!gaugeMaxIsValid) {
      setErrorMessage(
        `Gauge #${
          gaugeIndex + 1
        } has an invalid maximum value. Only whole numbers between ${GAUGE_LOWEST_MIN_VALUE} and ${GAUGE_HIGHEST_MAX_VALUE} are allowed.`
      );
      return false;
    } else if (gaugeMinIsSameAsMax) {
      setErrorMessage(
        `Gauge #${gaugeIndex + 1} has invalid min and max values. Min and max cannot be the same number.`
      );
      return false;
    } else if (gaugeHasDuplicateAttribute) {
      setErrorMessage(
        `Gauge #${
          gaugeIndex + 1
        } is using an attribute that is currently in use by another gauge. An attribute must be unique across gauges.`
      );
      return false;
    } else {
      return true;
    }
  }

  // Checks if operation attributes for a template are valid.
  function operationAttributesAreValid(): boolean {
    let operationAttributeIdsAreValid = true;
    let operationAttributeIndex = 0;
    for (const [i, operationAttribute] of operationAttributes.entries()) {
      if (operationAttribute.regAttributeId === 0) {
        operationAttributeIdsAreValid = false;
        operationAttributeIndex = i;
        break;
      }
    }
    if (operationAttributes.length > MAX_REMOTE_OPERATION_ATTRIBUTES) {
      setErrorMessage(`The max number of operation attributes allowed is ${MAX_REMOTE_OPERATION_ATTRIBUTES}.`);
      return false;
    } else if (!operationAttributeIdsAreValid) {
      setErrorMessage(`Operation attribute #${operationAttributeIndex + 1} is an invalid attribute.`);
      return false;
    } else {
      return true;
    }
  }

  // Create template.
  async function createTemplate(): Promise<void> {
    if (templateIsValid()) {
      // Structure data for request.
      const highlightAttributeIds = highlightAttributes.map((highlightAttribute) => highlightAttribute.regAttributeId);
      const operationAttributeIds = operationAttributes.map((operationAttribute) => operationAttribute.regAttributeId);
      const compressedCards: CompressedCard[] = [];
      cards.forEach((card) => {
        compressedCards.push({
          name: card.name,
          cardTypeId: card.cardTypeId,
          attributeIds: card.attributes.map((attribute) => attribute.regAttributeId),
        });
      });
      const compressedGauges: CompressedGauge[] = [];
      gauges.forEach((gauge) => {
        compressedGauges.push({
          regAttributeId: gauge.regAttributeId,
          unitId: gauge.unitId,
          min: parseFloat(String(gauge.min)),
          max: parseFloat(String(gauge.max)),
        });
      });

      const requestBody = {
        name: templateName,
        description: templateDescription,
        highlightAttributeIds: highlightAttributeIds,
        operationAttributeIds: operationAttributeIds,
        cards: compressedCards,
        gauges: compressedGauges,
      };

      setLoading(true);
      const [response, responseBody] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/asset-template`,
        "POST",
        requestBody
      )) as [Response, PostResponseBody];
      setLoading(false);

      if (response.ok) {
        props.onAction({
          type: TEMPLATE_TYPES.CREATE_TEMPLATE,
          payload: {
            template: {
              assetTemplateId: responseBody.assetTemplateId,
              name: templateName,
              isImmutable: isDefault,
            },
          },
        });
        discardChanges();
      } else {
        setErrorMessage(await getApiError(response, "Unable to create template."));
      }
    }
  }

  // Edit template.
  async function editTemplate(assetTemplateId: number): Promise<void> {
    if (templateIsValid()) {
      // Structure data for request.
      const highlightAttributeIds = highlightAttributes.map((highlightAttribute) => highlightAttribute.regAttributeId);
      const operationAttributeIds = operationAttributes.map((operationAttribute) => operationAttribute.regAttributeId);
      const compressedCards: CompressedCard[] = [];
      cards.forEach((card) => {
        compressedCards.push({
          name: card.name,
          cardTypeId: card.cardTypeId,
          attributeIds: card.attributes.map((attribute) => attribute.regAttributeId),
        });
      });
      const compressedGauges: CompressedGauge[] = [];
      gauges.forEach((gauge) => {
        compressedGauges.push({
          regAttributeId: gauge.regAttributeId,
          unitId: gauge.unitId,
          min: parseFloat(String(gauge.min)),
          max: parseFloat(String(gauge.max)),
        });
      });

      const requestBody = {
        name: templateName,
        description: templateDescription,
        highlightAttributeIds: highlightAttributeIds,
        operationAttributeIds: operationAttributeIds,
        cards: compressedCards,
        gauges: compressedGauges,
      };

      setLoading(true);
      const [response] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/asset-template/${assetTemplateId}`,
        "PUT",
        requestBody
      )) as [Response, PutResponseBody];
      setLoading(false);

      if (response.ok) {
        props.onAction({
          type: TEMPLATE_TYPES.UPDATE_TEMPLATE,
          payload: {
            template: {
              assetTemplateId: assetTemplateId,
              name: templateName,
              isImmutable: isDefault,
            },
          },
        });
        discardChanges();
      } else {
        setErrorMessage(await getApiError(response, "Unable to update template."));
      }
    }
  }

  // Delete template.
  async function deleteTemplate(assetTemplateId: number): Promise<void> {
    setErrorMessage("");
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset-template/${assetTemplateId}`,
      "DELETE",
      null
    )) as [Response, DeleteResponseBody];
    setLoading(false);

    if (response.ok) {
      discardChanges();
      props.onAction({
        type: TEMPLATE_TYPES.DELETE_TEMPLATE,
        payload: {
          templateId: assetTemplateId,
        },
      });
    } else {
      setShowConfirmDelete(false);
      setErrorMessage(await getApiError(response, "Unable to delete template."));
    }
  }

  return (
    <div>
      <Spinner loading={loading} />

      <Modal
        show={true}
        onHide={() => exitModal()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="xl"
        animation
      >
        <ModalHeader>
          <div>
            <div className="row">
              <h5 className="font-weight-bold">
                {props.isCreatingNewTemplate ? "Create Template" : "Update Template"}
              </h5>
            </div>
            <div className="row">
              <Breadcrumbs
                breadcrumbs={templateManagementPages}
                activePageNumber={activePageNumber}
                onClick={(pageNumber: number) => setActivePageNumber(pageNumber)}
              />
            </div>
          </div>
        </ModalHeader>

        <ModalBody>
          {activePageNumber === 1 && (
            <TemplateNameForm
              name={templateName}
              description={templateDescription}
              disabled={!formIsEditable || isDefault}
              onChangeDescription={(description: string) => setTemplateDescription(description)}
              onChangeName={(name: string) => setTemplateName(name)}
            />
          )}
          {activePageNumber === 2 && (
            <HighlightAttributePage
              highlightAttributes={highlightAttributes}
              onCreate={() => handleCreateHighlightAttribute()}
              onChange={(formId, name) => handleUpdateHighlightAttribute(formId, name, props.attributeMap)}
              onDelete={(formId) => handleDeleteHighlightAttribute(formId)}
              onDragEnd={(over, active) => handleHighlightAttributeDragEnd(over, active)}
            />
          )}
          {activePageNumber === 3 && (
            <CardPage
              cards={cards}
              templateCardTypes={props.templateCardTypes}
              attributeMap={props.attributeMap}
              onCreate={() => handleCreateCard()}
              onChange={(formId, name, cardTypeId, attributes) =>
                handleUpdateCard(formId, name, cardTypeId, attributes)
              }
              onDelete={(formId) => handleDeleteCard(formId)}
              onDragEnd={(over, active) => handleCardDragEnd(over, active)}
            />
          )}
          {activePageNumber === 4 && (
            <GaugePage
              gauges={gauges}
              onCreate={() => handleCreateGauge()}
              onChange={(formId, name, min, max) => handleUpdateGauge(formId, name, min, max, props.attributeMap)}
              onDelete={(formId) => handleDeleteGauge(formId)}
              onDragEnd={(over, active) => handleGaugeDragEnd(over, active)}
            />
          )}
          {activePageNumber === 5 && (
            <OperationAttributePage
              operationAttributes={operationAttributes}
              onCreate={() => handleCreateOperationAttribute()}
              onChange={(formId, name) => handleUpdateOperationAttribute(formId, name, props.attributeMap)}
              onDelete={(formId) => handleDeleteOperationAttribute(formId)}
              onDragEnd={(over, active) => handleOperationAttributeDragEnd(over, active)}
            />
          )}

          {errorMessage.length > 0 && (
            <div className="mt-4 mx-3">
              <Error message={errorMessage} />
            </div>
          )}
        </ModalBody>

        <ModalFooter className={styles.footer}>
          <Fragment>
            <div className="me-auto">
              {!props.isCreatingNewTemplate && !isDefault && userHasPermission([[DELETE_TEMPLATES_PERMISSION]]) && (
                <button
                  data-test="template-delete-button"
                  className={`${styles.button} btn btn-danger me-2`}
                  disabled={loading}
                  type="button"
                  onClick={() => setShowConfirmDelete(true)}
                >
                  Delete
                </button>
              )}
              {activePageNumber > 1 && (
                <button
                  data-test="template-previous-page-button"
                  className={`${styles.btn} btn btn-primary`}
                  type="button"
                  onClick={() => {
                    setActivePageNumber((prev) => prev - 1);
                  }}
                >
                  Previous Page
                </button>
              )}
            </div>
            {activePageNumber === templateManagementPages.length ? (
              <Fragment>
                <button
                  data-test="template-wizard-cancel-button"
                  className={`${styles.button} btn btn-secondary`}
                  type="button"
                  onClick={() => exitModal()}
                >
                  Cancel
                </button>
                {props.isCreatingNewTemplate && userHasPermission([[CREATE_TEMPLATES_PERMISSION]]) && (
                  <button
                    data-test="template-wizard-save-button"
                    className={`${styles.btn} btn btn-success`}
                    type="button"
                    onClick={() => createTemplate()}
                  >
                    Create Template
                  </button>
                )}
                {!props.isCreatingNewTemplate && userHasPermission([[UPDATE_TEMPLATES_PERMISSION]]) && (
                  <button
                    data-test="template-wizard-save-button"
                    className={`${styles.btn} btn btn-success`}
                    type="button"
                    disabled={loading}
                    onClick={() => editTemplate(props.assetTemplateId)}
                  >
                    Save Changes
                  </button>
                )}
              </Fragment>
            ) : (
              <button
                data-test="template-next-page-button"
                className={`${styles.btn} btn btn-primary`}
                type="button"
                onClick={() => setActivePageNumber((prev) => prev + 1)}
              >
                Next Page
              </button>
            )}
          </Fragment>
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={showConfirmDelete}
        title={`Delete '${templateName}'`}
        content={`Are you sure that you want to delete the template '${templateName}'?`}
        yesText="Delete Template"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteTemplate(props.assetTemplateId)}
        onNo={() => setShowConfirmDelete(false)}
      />

      <SaveChangesModal
        showModal={showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges(props.assetTemplateId)}
        onNoSave={() => discardChanges()}
      />
    </div>
  );
}

TemplateManagementWizard.propTypes = {
  isCreatingNewTemplate: PropTypes.bool.isRequired,
  assetTemplateId: PropTypes.number.isRequired,
  attributeMap: PropTypes.object.isRequired,
  templateCardTypes: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

interface Props {
  isCreatingNewTemplate: boolean;
  assetTemplateId: number;
  attributeMap: AttributesById;
  templateCardTypes: TemplateCardType[];
  onClose: () => void;
  onAction: (action: Action) => void;
}

interface GetResponseBody {
  name: string;
  description: string;
  isImmutable: boolean;
  highlightAttributeIds: number[];
  operationAttributeIds: number[];
  cards: CompressedCard[];
  gauges: CompressedGauge[];
}

interface PostResponseBody {
  assetTemplateId: number;
}

interface PutResponseBody {
  message: string;
}

interface DeleteResponseBody {
  message: string;
}

interface CompressedCard {
  name: string;
  cardTypeId: number | null;
  attributeIds: number[];
}

interface CompressedGauge {
  regAttributeId: number;
  unitId: number | null;
  min: string | number;
  max: string | number;
}

interface Card {
  formId: number;
  name: string;
  cardTypeId: number | null;
  attributes: Attribute[];
}

interface Gauge {
  formId: number;
  regAttributeId: number;
  attributeName: string;
  unitId: number | null;
  unitShortName: string | null;
  unitLongName: string | null;
  min: string | number;
  max: string | number;
}

interface Attribute {
  formId: number;
  regAttributeId: number;
  attributeName: string;
  unitId: number | null;
  unitShortName: string | null;
  unitLongName: string | null;
}

interface AttributesById {
  [key: string]: Attribute;
}

interface TemplateCardType {
  templateCardTypeId: number;
  name: string;
  description: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  template?: AssetTemplate;
  templateId?: number;
}

interface AssetTemplate {
  assetTemplateId: number;
  name: string;
  isImmutable: boolean;
}
