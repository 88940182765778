// --------------------------------------------------------------
// Created On: 2022-05-23
// Author: Formkit / Auto Animate Team
//
// Last Modified: 2023-08-15
// Modified By: Zachary Thomas
//
// Copyright 2022 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import { useEffect, useRef, RefObject } from "react";
import autoAnimate, { AutoAnimateOptions, AutoAnimationPlugin } from "@formkit/auto-animate";

// AutoAnimate hook for adding dead-simple transitions and animations to react.
export default function useAutoAnimate<Type extends Element>(
  options: Partial<AutoAnimateOptions> | AutoAnimationPlugin = {}
): [RefObject<Type>] {
  const element = useRef<Type>(null);
  useEffect(() => {
    if (element.current instanceof HTMLElement) {
      autoAnimate(element.current, options);
    }
  }, [element]);
  return [element];
}
