// --------------------------------------------------------------
// Created On: 2022-01-21
// Author: Zachary Thomas
//
// Last Modified: 2024-05-14
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import "./TabList.scss";

// A list of selectable tabs.
export default function TabList(props: Props): Component {
  return (
    <div className="tab-list-container container">
      <div className="row">
        {props.tabs.map((tab) => (
          <div key={tab.tabId} className={`px-0 ${tab.breakPoints}`}>
            <button
              data-test="tab-list-container"
              className={`container-tab btn btn-primary w-100 ${props.selectedTabId === tab.tabId ? "tab-active" : ""}`}
              onClick={() => props.onSelect(tab.tabId)}
            >
              <span className="tab-name">{tab.name}</span>
              <span className="tab-short-name">{tab.shortName}</span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

TabList.propTypes = {
  tabs: PropTypes.array.isRequired,
  selectedTabId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

interface Props {
  tabs: Tab[];
  selectedTabId: string;
  onSelect: (tabId: string) => void;
}

interface Tab {
  tabId: string;
  name: string;
  shortName: string;
  breakPoints: string;
}
