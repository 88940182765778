// --------------------------------------------------------------
// Created On: 2023-02-21
// Author: Zachary Thomas
//
// Last Modified: 2024-07-23
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import {
  MAX_VIEW_ATTRIBUTE_HIGHLIGHTING_RULES,
  MAX_VIEW_ATTRIBUTES,
  MAX_MAP_HIGHLIGHTING_COLORS,
  MAX_MAP_HIGHLIGHTING_RULES_PER_COLOR,
} from "../../constants/miscellaneous";

// Explains what a view is.
export default function HelpView(): Component {
  return (
    <div>
      <p className="px-3 my-2">
        Views allow users to control the data they want to focus on when looking at an asset group.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Shared View:</b> By default, views are only accessible by the user who created them. Shared views can be used
        by any user in the company.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>List & Graph Attributes:</b>
        The attributes selected will determine what data to show when looking at the dashboard asset list and dashboard
        asset graph pages. Attribute values can also be highlighted dynamically based on custom rules. If one value
        would be highlighted by two separate rules, the rule higher in the list will take priority. Some non-numerical
        attributes do not support highlighting. Up to {MAX_VIEW_ATTRIBUTES} attributes can be selected for a single
        view.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>List & Graph Highlighting Rules:</b> Attribute values can be highlighted dynamically based on custom rules.
        If one value would be highlighted by two separate rules, the rule higher in the list will take priority. Some
        non-numerical attributes do not support highlighting. Up to {MAX_VIEW_ATTRIBUTE_HIGHLIGHTING_RULES} highlighting
        rules can be applied to each list & graph attribute.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Map Highlighting Rules:</b> Asset map markers can have special highlighting rules applied with a view. Rules
        can be combined to ensure an asset is only highlighted a specific color if all rules pass. If one value would be
        highlighted by two separate rules, the rule higher in the list will take priority. If a rule causes an asset to
        be highlighted, then the map marker and the specified attributes for the rule will all be highlighted. Some
        non-numerical attributes cannot be used to enforce rules. Up to {MAX_MAP_HIGHLIGHTING_COLORS} rules with up to{" "}
        {MAX_MAP_HIGHLIGHTING_RULES_PER_COLOR} conditions each can be applied for a single view.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>NOTE:</b> When creating or updating a view, this textured area{" "}
        <img src="/images/view_drag_info.png" alt="Draggable area texture" /> allows you to click and drag a rule to
        rearrange it. This allows you to change which rules take priority when one or more rules pass.
      </p>
    </div>
  );
}
