// --------------------------------------------------------------
// Created On: 2022-01-17
// Author: Zachary Thomas
//
// Last Modified: 2023-05-01
// Modified By: Zachary Thomas
//
// Copyright 2022 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import Card from "../../../components/Card/Card";
import AlertCount from "./AlertCount/AlertCount";
import {
  ALERT_EMERGENCY,
  ALERT_HIGH,
  ALERT_MEDIUM,
  ALERT_LOW,
  ALERT_INFO,
} from "../../../constants/miscellaneous";
import { useSelector } from "react-redux";
import { getAlerts } from "../../../redux/selectors";

// An overview of the types of new alerts with a count of each type.
export default function AlertOverview(): Component {
  const [emergencyCount, setEmergencyCount] = useState<number>(0);
  const [highCount, setHighCount] = useState<number>(0);
  const [mediumCount, setMediumCount] = useState<number>(0);
  const [lowCount, setLowCount] = useState<number>(0);
  const [infoCount, setInfoCount] = useState<number>(0);
  const alerts = useSelector(getAlerts);

  // Update the new urgency counts when the alerts change.
  useEffect(() => {
    let emergencyCount = 0;
    let highCount = 0;
    let mediumCount = 0;
    let lowCount = 0;
    let infoCount = 0;

    alerts.forEach((alert) => {
      switch (alert.urgency) {
        case ALERT_EMERGENCY:
          if (alert.unread) {
            emergencyCount++;
          }
          break;

        case ALERT_HIGH:
          if (alert.unread) {
            highCount++;
          }
          break;

        case ALERT_MEDIUM:
          if (alert.unread) {
            mediumCount++;
          }
          break;

        case ALERT_LOW:
          if (alert.unread) {
            lowCount++;
          }
          break;

        case ALERT_INFO:
          if (alert.unread) {
            infoCount++;
          }
          break;
      }
    });

    setEmergencyCount(emergencyCount);
    setHighCount(highCount);
    setMediumCount(mediumCount);
    setLowCount(lowCount);
    setInfoCount(infoCount);
  }, [JSON.stringify(alerts)]);

  return (
    <div>
      <Card title="Alert Overview">
        <div className="row justify-content-center">
          <AlertCount
            type={"emergency"}
            count={emergencyCount}
            leftBorder={false}
            rightBorder={true}
          />

          <AlertCount type={"high"} count={highCount} leftBorder={true} rightBorder={true} />

          <AlertCount type={"medium"} count={mediumCount} leftBorder={true} rightBorder={true} />

          <AlertCount type={"low"} count={lowCount} leftBorder={true} rightBorder={true} />

          <AlertCount type={"info"} count={infoCount} leftBorder={true} rightBorder={false} />
        </div>
      </Card>
    </div>
  );
}
