// --------------------------------------------------------------
// Created On: 2021-09-29
// Author: Zachary Thomas
//
// Last Modified: 2024-08-05
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import { API, MIN_FULL_NAME_LENGTH, MAX_FULL_NAME_LENGTH } from "../../constants/miscellaneous";
import { SUPER_ADMIN_PERMISSION } from "../../constants/permissions";
import Spinner from "../../components/Spinner/Spinner";
import Card from "../../components/Card/Card";
import Error from "../../components/Error/Error";
import Success from "../../components/Success/Success";
import Error500Page from "../Error500Page/Error500Page";
import useApi from "../../hooks/useApi";
import getApiError from "../../utilities/api/getApiError";
import apiRequest from "../../utilities/api/apiRequest";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import { setCurrentUser, setAuthorizerToken } from "../../redux/actions";
import userHasPermission from "../../utilities/userHasPermission";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput";
import userIsSuperAdmin from "../../utilities/userIsSuperAdmin";
import PermanentlyDeleteModal from "../../components/PermanentlyDeleteModal/PermanentlyDeleteModal";
import { useNavigate } from "react-router-dom";
import styles from "./ChangeProfilePage.module.scss";

// Page for changing user profile settings.
export default function ChangeProfilePage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [showPermanentlyDeleteModal, setShowPermanentlyDeleteModal] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [newName, setNewName] = useState<string>("");
  const [previousCallingCode, setPreviousCallingCode] = useState<string>("1");
  const [previousPhoneNumber, setPreviousPhoneNumber] = useState<string>("");
  const [callingCode, setCallingCode] = useState<string>("1");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [userId, setUserId] = useState<number>(0);
  const currentUser = useSelector(getCurrentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Get account info.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${userIsSuperAdmin() ? "10" : currentUser.companyId}/user/${currentUser.userId}`,
    },
    async (response: Response, responseBody: GetResponseBody) => {
      if (response.ok && responseBody) {
        dispatch(
          setCurrentUser(
            currentUser.userId,
            responseBody.name,
            responseBody.emailAddress,
            currentUser.companyId,
            currentUser.companyName,
            currentUser.isCompanyHidden,
            currentUser.permissions,
            currentUser.isPackager
          )
        );
        setNewName(responseBody.name);
        setUserId(responseBody.userId);
        if (
          responseBody.phoneNumberCountryCode !== null &&
          responseBody.phoneNumberCountryCode !== undefined &&
          responseBody.phoneNumber !== null &&
          responseBody.phoneNumber !== undefined
        ) {
          setCallingCode(responseBody.phoneNumberCountryCode);
          setPreviousCallingCode(responseBody.phoneNumberCountryCode);
          setPreviousPhoneNumber(responseBody.phoneNumber);
          setPhoneNumber(responseBody.phoneNumber);
        } else {
          setCallingCode("1");
          setPhoneNumber("");
        }
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // If an input is being edited, remove any old messages.
  useEffect(() => {
    setErrorMessage("");
    setSuccessMessage("");
  }, [newName, phoneNumber, callingCode]);

  // Validate the account settings.
  function accountIsValid(): boolean {
    if (newName.trim().length < MIN_FULL_NAME_LENGTH || newName.trim().length > MAX_FULL_NAME_LENGTH) {
      setSuccessMessage("");
      setErrorMessage(`Your name must be between ${MIN_FULL_NAME_LENGTH} and ${MAX_FULL_NAME_LENGTH} characters long.`);
      return false;
    } else {
      return true;
    }
  }

  // Save the account settings.
  async function saveChanges(): Promise<void> {
    let requestPhoneNumber: string | null = null;
    let requestCallingCode: string | null = null;
    if (phoneNumber !== undefined && callingCode !== undefined && phoneNumber.length > 0) {
      // Remove the calling code in the phone number before sending it in the request body.
      const regexRemoveCodeFromStart = new RegExp(`^${callingCode}`);
      requestPhoneNumber = phoneNumber.replace(regexRemoveCodeFromStart, "");
      requestCallingCode = callingCode;
    }

    if (accountIsValid()) {
      const requestBody: RequestBody = {
        name: newName.trim(),
        phoneNumberCountryCode: requestCallingCode,
        phoneNumber: requestPhoneNumber,
      };

      setLoading(true);
      const [response] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/user/${userId}/profile`,
        "PUT",
        requestBody
      )) as [Response, PutResponseBody];
      setLoading(false);

      if (response.ok) {
        dispatch(
          setCurrentUser(
            currentUser.userId,
            newName,
            currentUser.emailAddress,
            currentUser.companyId,
            currentUser.companyName,
            currentUser.isCompanyHidden,
            currentUser.permissions,
            currentUser.isPackager
          )
        );
        setErrorMessage("");
        setSuccessMessage("Account settings have been saved.");
      } else {
        setSuccessMessage("");
        setErrorMessage(await getApiError(response, "Unable to update account."));
      }
    }
  }

  // Undo any changes that have been made since the page loaded.
  function undoChanges(): void {
    setNewName(currentUser.userName);
    setPhoneNumber(previousPhoneNumber);
    setCallingCode(previousCallingCode);
  }

  // Attempts to delete the current users account.
  async function deleteSelf(): Promise<void> {
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/user/${currentUser.userId}`,
      "DELETE",
      null
    )) as [Response, DeleteResponseBody];
    setLoading(false);

    if (response.ok) {
      dispatch(setAuthorizerToken(""));
      navigate("/login");
    } else {
      setShowPermanentlyDeleteModal(false);
      setErrorMessage("Internal server error. Unable to delete account.");
    }
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className={`${styles.body} p-4 col-12 col-xl-10 col-xxl-8 offset-xl-1 offset-xxl-2`}>
      <Spinner loading={loading} />

      <Card title="Account Information">
        <div className="mx-4 my-3">
          {/* Account info. */}
          <div className="row mb-3">
            <div className="col-12 col-lg-6 text-center">
              <p className={styles.profileInfo}>
                <i className="fa fa-fw fa-envelope-o" /> {currentUser.emailAddress}
              </p>
            </div>

            {!userHasPermission([[SUPER_ADMIN_PERMISSION]]) && (
              <div className="col-12 col-lg-6 text-center">
                <p className={styles.profileInfo}>
                  <i className="fa fa-fw fa-building-o" /> {currentUser.companyName}
                </p>
              </div>
            )}
          </div>

          {/* Name input. */}
          <div className="form-update-account mb-4">
            <label className="mb-3 font-weight-bold">Name</label>
            <input
              className="form-control mx-auto"
              type="text"
              maxLength={MAX_FULL_NAME_LENGTH}
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>

          {/* Phone number input. */}
          <div className="form-update-account mb-5">
            <PhoneNumberInput
              labelText="Phone Number"
              labelClassName="mb-3 font-weight-bold"
              phoneNumber={phoneNumber}
              callingCode={callingCode}
              onChangePhoneNumber={(phoneNumber) => setPhoneNumber(phoneNumber)}
              onChangeCallingCode={(callingCode) => setCallingCode(callingCode)}
            />
          </div>

          {(errorMessage.length > 0 || successMessage.length > 0) && (
            <div className="status-messages mt-3">
              <Error message={errorMessage} />
              <Success message={successMessage} />
            </div>
          )}

          {/* Hide save / undo button if a save was just attempted and nothing has changed since. */}
          {errorMessage.length === 0 && successMessage.length === 0 && (
            <div>
              <button
                className={`${styles.profileButton} btn btn-danger me-auto`}
                type="button"
                onClick={() => setShowPermanentlyDeleteModal(true)}
              >
                Delete Account
              </button>

              <button
                className={`${styles.profileButton} btn btn-primary mx-2 float-end`}
                type="button"
                onClick={() => saveChanges()}
              >
                Save Changes
              </button>

              <button
                className={`${styles.profileButton} btn btn-secondary mx-2 float-end`}
                type="button"
                onClick={() => undoChanges()}
              >
                Undo Changes
              </button>
            </div>
          )}
        </div>
      </Card>

      {/* Delete user account. */}
      <PermanentlyDeleteModal
        showModal={showPermanentlyDeleteModal}
        title="Delete account?"
        content="Are you sure that you want to delete your current user account? If you delete yourself you will not be able to login to this account again."
        onDelete={() => deleteSelf()}
        onCancel={() => setShowPermanentlyDeleteModal(false)}
      />
    </div>
  );
}

interface GetResponseBody {
  userId: number;
  name: string;
  emailAddress: string;
  roleId: number;
  companyId: number;
  companyName: string;
  isCompanyHidden: boolean;
  permissions: string[];
  phoneNumberCountryCode: string | null;
  phoneNumber: string | null;
}

interface PutResponseBody {
  message: string;
}

interface DeleteResponseBody {
  message: string;
}

interface RequestBody {
  name: string;
  phoneNumberCountryCode: string | null;
  phoneNumber: string | null;
}
