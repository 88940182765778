// --------------------------------------------------------------
// Created On: 2024-08-06
// Author: Dimitra Weinstein
//
// Last Modified: 2024-08-21
// Modified By: Dimitra Weinstein
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useMemo } from "react";
import { API } from "../../constants/miscellaneous";
import useApi from "../../hooks/useApi";
import Spinner from "../../components/Spinner/Spinner";
import Error500Page from "../Error500Page/Error500Page";
import FilteredTableContainer from "../../components/FilteredTableContainer/FilteredTableContainer";
import SortedTableHeader from "../../components/SortedTableHeader/SortedTableHeader";
import AttributeInformationRow from "./AttributeInformationRow/AttributeInformationRow";
import styles from "./AttributeInformationPage.module.scss";
import deepCopy from "../../utilities/deepCopy";

// Page for viewing attribute information.
export default function AttributeInformationPage(): Component {
    const [loading, setLoading] = useState<boolean>(false);
    const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>("");
    const [sortAscending, setSortAscending] = useState<boolean>(true);
    const [sortColumnIndex, setSortColumnIndex] = useState<number>(1);
    const [attributeInformation, setAttributeInformation] = useState<AttributeInformation[]>([])
    const organizedAttributeInformation = useMemo(
        () => getFilteredAndSortedAttributeInformation(attributeInformation),
        [JSON.stringify(attributeInformation), filter, sortAscending, sortColumnIndex]
    )

    // Make Api call for attribute information.
    useApi(() => {
        setLoading(true);
        return true;
    }, {
        method: "GET",
        url: `${API}/attribute`,
    }, async(response: Response, responseBody: ResponseBody) => {
        if (response.ok && responseBody) {
            setFailedToLoad(false);
            setAttributeInformation(responseBody.attributes)
        } else {
            setFailedToLoad(true);
        }
        setLoading(false);
    }, []);
    
    // Sort and filter attribute list.
    function getFilteredAndSortedAttributeInformation(attributeInformation: AttributeInformation[]): AttributeInformation[] {
        let attributeInfoDeepCopy = deepCopy(attributeInformation);
        if (filter.length > 0) {
            attributeInfoDeepCopy = attributeInfoDeepCopy.filter(
                (attributeInfo) => attributeInfo.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
            );
        }
        return sortAttributeInformation(attributeInfoDeepCopy)
    }

    // Update sorting order.
    function updateSort(newIndex: number): void {
        if (newIndex === sortColumnIndex) {
            setSortAscending((previous) => !previous);
        } else {
            setSortAscending(false);
            setSortColumnIndex(newIndex);
        }
    }

    // Sort attributes based on the current column and ascension rule.
    function sortAttributeInformation(attributeInformation: AttributeInformation[]): AttributeInformation[] {
        return attributeInformation.sort((a, b) => {
            let aValue = "";
            let bValue = "";

            switch (sortColumnIndex) {
                case 1:
                    aValue = a.name;
                    bValue = b.name;
                    break;
                case 2:
                    aValue = a.description;
                    bValue = b.description;
                    break;
                default:
                    aValue = a.name;
                    bValue = b.name;
            }

            if (aValue < bValue) {
                if (sortAscending) {
                    return -1;
                } else {
                    return 1;
                }
            } else if (aValue > bValue) {
                if (sortAscending) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                return 0;
            }
        })
    }
    
    return failedToLoad ? (
        <Error500Page />
    ) : (
        <div className="p-4">
            <Spinner loading={loading} />
            
            {attributeInformation.length > 0 ? (
                <FilteredTableContainer
                    title={`Attribute Information (${organizedAttributeInformation.length})`}
                    filterPrompt="Filter by name..."
                    filter={filter}
                    hasContent={attributeInformation.length > 0}
                    hasFilteredContent={organizedAttributeInformation.length > 0}
                    pluralContentType="attributes"
                    onChangeFilter={(filter) => setFilter(filter)}
                >
                <table className="table table-hover mb-0 pb-0">
                    <thead className={styles.header}>
                        <tr>
                            <SortedTableHeader 
                                name="Attribute Name"
                                index={1}
                                selectedIndex={sortColumnIndex}
                                selectedAscending={sortAscending}
                                onClick={(index) => updateSort(index)}
                            />
                            <SortedTableHeader 
                                name="Description"
                                index={2}
                                selectedIndex={sortColumnIndex}
                                selectedAscending={sortAscending}
                                onClick={(index) => updateSort(index)}
                            />
                        </tr>
                    </thead>
                    <tbody>
                       {organizedAttributeInformation.map((attribute) => (
                        <AttributeInformationRow
                            key={attribute.attributeId}
                            name={attribute.name}
                            description={attribute.description}
                        />
                        ))}
                    </tbody>
                </table>
            </FilteredTableContainer>
            ) : (
               !loading && <Error500Page />
            )}
        </div>
    );
}

interface ResponseBody {
    attributes: AttributeInformation[];
}

interface AttributeInformation {
    attributeId: number;
    name: string;
    description: string;
}