// --------------------------------------------------------------
// Created On: 2024-09-15
// Author: Zachary Thomas
//
// Last Modified: 2024-09-16
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useMemo } from "react";
import IconTooltip from "../../../../../../components/IconTooltip/IconTooltip";
import { MONITORING_DEVICE_SENSOR_CARD_TYPE, ACTIVE_DTC_CARD_TYPE } from "../../../../../../constants/templates";
import PropTypes from "prop-types";
import styles from "./DynamicCardInfo.module.scss";

// Displays information about the current dynamic card.
export default function DynamicCardInfo(props: Props): Component {
  const selectedCardType = useMemo(
    () => getCardType(props.cardTypeId, props.templateCardTypes),
    [props.cardTypeId, JSON.stringify(props.templateCardTypes)]
  );

  // Gets the current selected card type given a card type ID.
  function getCardType(cardTypeId: number | null, templateCardTypes: TemplateCardType[]): TemplateCardType | null {
    if (cardTypeId === null) {
      return null;
    } else {
      const selectedCardType = templateCardTypes.find(
        (templateCardType) => templateCardType.templateCardTypeId === cardTypeId
      );
      if (selectedCardType === undefined) {
        return null;
      } else {
        return selectedCardType;
      }
    }
  }

  if (selectedCardType === null) {
    return <Fragment />;
  } else {
    return (
      <div className={`${styles.body} col`}>
        <IconTooltip
          id={`card-type-description-tooltip-${props.cardTypeId}`}
          icon="info-circle"
          message={selectedCardType.description}
          color="var(--info-tooltip)"
        />
        <span className="me-2" />
        {selectedCardType.name === MONITORING_DEVICE_SENSOR_CARD_TYPE && (
          <IconTooltip
            id={`card-type-image-tooltip-${props.cardTypeId}`}
            icon="picture-o"
            imageUrl="/images/template_dynamic_sensors_example.png"
            imageAlt="Dynamic card example."
            imageMaxWidth={1000}
            color="var(--info-tooltip-image)"
          />
        )}
        {selectedCardType.name === ACTIVE_DTC_CARD_TYPE && (
          <IconTooltip
            id={`card-type-image-tooltip-${props.cardTypeId}`}
            icon="picture-o"
            imageUrl="/images/template_dynamic_dtc_example.png"
            imageAlt="Dynamic card example."
            imageMaxWidth={1000}
            color="var(--info-tooltip-image)"
          />
        )}
      </div>
    );
  }
}

DynamicCardInfo.propTypes = {
  formId: PropTypes.number,
  cardTypeId: PropTypes.number,
  templateCardTypes: PropTypes.array.isRequired,
};

interface Props {
  formId: number;
  cardTypeId: number | null;
  templateCardTypes: TemplateCardType[];
}

interface TemplateCardType {
  templateCardTypeId: number;
  name: string;
  description: string;
}
