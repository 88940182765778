// --------------------------------------------------------------
// Created On: 2023-10-10
// Author: Zachary Thomas
//
// Last Modified: 2024-03-12
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import formatDateShortLocal from "../../../utilities/time/formatDateShortLocal";
import styles from "./TransferLogList.module.scss";

// A list of transfer operation logs.
export default function TransferLogList(props: Props): Component {
  // Generate log message.
  function generateLogMessage(transferLog: TransferLog): string {
    switch (transferLog.transferCode) {
      case "SEND_REQUEST":
        return `User '${transferLog.userName}' offered a transfer to company '${transferLog.companyName}' for asset '${
          transferLog.assetName
        }' at ${formatDateShortLocal(transferLog.createdUtc)}`;

      case "RECEIVE_REQUEST":
        return `User '${transferLog.userName}' from company '${
          transferLog.companyName
        }' offered a transfer request for asset '${transferLog.assetName}' at ${formatDateShortLocal(
          transferLog.createdUtc
        )}`;

      case "CANCEL_REQUEST":
        return `User '${transferLog.userName}' canceled the offered asset transfer request to company '${
          transferLog.companyName
        }' for asset '${transferLog.assetName}' at ${formatDateShortLocal(transferLog.createdUtc)}`;

      case "ACCEPT_REQUEST":
        return `User '${transferLog.userName}' accepted the asset transfer offer from company '${
          transferLog.companyName
        }' for asset '${transferLog.assetName}' at ${formatDateShortLocal(transferLog.createdUtc)}`;

      case "REJECT_REQUEST":
        return `User '${transferLog.userName}' rejected the asset transfer offer from company '${
          transferLog.companyName
        }' for asset '${transferLog.assetName}' at ${formatDateShortLocal(transferLog.createdUtc)}`;

      case "REQUEST_CANCELLED":
        return `User '${transferLog.userName}' from company '${
          transferLog.companyName
        }' cancelled the transfer request for asset '${transferLog.assetName}' at ${formatDateShortLocal(
          transferLog.createdUtc
        )}`;

      case "REQUEST_ACCEPTED":
        return `User '${transferLog.userName}' from company '${
          transferLog.companyName
        }' accepted the transfer request for asset '${transferLog.assetName}' at ${formatDateShortLocal(
          transferLog.createdUtc
        )}`;

      case "REQUEST_REJECTED":
        return `User '${transferLog.userName}' from company '${
          transferLog.companyName
        }' rejected the transfer request for asset '${transferLog.assetName}' at ${formatDateShortLocal(
          transferLog.createdUtc
        )}`;

      default:
        return "Unknown transfer operation";
    }
  }

  return (
    <div>
      <div className={styles.titleBar}>
        <label className={styles.title}>Transfer History Logs</label>
      </div>
      <div className={styles.body}>
        {props.transferLogs.map((transferLog) => (
          <div key={transferLog.assetTransferLogId} className={styles.item}>
            {generateLogMessage(transferLog)}
          </div>
        ))}

        {props.transferLogs.length === 0 && <div className={styles.item}>No transfer history exists</div>}
      </div>
    </div>
  );
}

TransferLogList.propTypes = {
  transferLogs: PropTypes.array.isRequired,
};

interface Props {
  transferLogs: TransferLog[];
}

interface TransferLog {
  assetTransferLogId: number;
  userName: string | null;
  assetName: string;
  companyName: string;
  deviceType: string | null;
  deviceIdentifier: string | null;
  transferCode: string;
  createdUtc: string;
}
