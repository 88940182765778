// --------------------------------------------------------------
// Created On: 2023-08-23
// Author: Zachary Thomas
//
// Last Modified: 2024-04-09
// Modified By: Lilly Kane
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./UsergroupListRow.module.scss";

// A single user group in an user group list.
export default function UsergroupListRow(props: Props): Component {
  return (
    <div className="p-2" data-test="usergroup-list-row">
      <span>{props.name}</span>
      {props.isDefault && <span className={styles.tag}>Default</span>}
    </div>
  );
}

UsergroupListRow.propTypes = {
  usergroupId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
};

interface Props {
  usergroupId: number;
  name: string;
  isDefault: boolean;
}
