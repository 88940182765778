// --------------------------------------------------------------
// Created On: 2023-04-18
// Author: Zachary Thomas
//
// Last Modified: 2024-10-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Returns whether the string conforms to the format of {numbers only}{single period}{numbers only} or {numbers only}.
// No letters are allowed and only a single decimal point. This should be used for text input validation only.
export default function stringIsValidNumber(value: string): boolean {
  const pattern = new RegExp("^-?[0-9]+?(.[0-9]+)?$");
  return pattern.test(value);
}
