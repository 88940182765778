// --------------------------------------------------------------
// Created On: 2021-12-14
// Author: Zachary Thomas
//
// Last Modified: 2023-12-27
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

export const SET_AUTHORIZER_TOKEN = "SET_AUTHORIZER_TOKEN";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_ALERTS = "SET_ALERTS";
export const TOGGLE_ALERT = "TOGGLE_ALERT";
export const TOGGLE_ALL_ALERTS = "TOGGLE_ALL_ALERTS";
export const DELETE_ALERT = "DELETE_ALERT";
export const DELETE_ALL_ALERTS = "DELETE_ALL_ALERTS";
export const SET_DEVICE_ATTRIBUTES = "SET_DEVICE_ATTRIBUTES";
export const TOGGLE_DEVICE_ATTRIBUTE = "TOGGLE_DEVICE_ATTRIBUTE";

// Stores an authorizer token that is given when logging in.
export function setAuthorizerToken(token: string): SetAuthorizerTokenAction {
  return {
    type: SET_AUTHORIZER_TOKEN,
    payload: {
      token: token,
    },
  };
}

// Stores an access token that is given when logging in.
export function setAccessToken(token: string): SetAccessTokenAction {
  return {
    type: SET_ACCESS_TOKEN,
    payload: {
      token: token,
    },
  };
}

// Stores current user data after a user has logged in.
export function setCurrentUser(
  userId: number,
  userName: string,
  emailAddress: string,
  companyId: number,
  companyName: string,
  isCompanyHidden: boolean,
  permissions: string[],
  isPackager: boolean
): SetCurrentUserAction {
  return {
    type: SET_CURRENT_USER,
    payload: {
      userId: userId,
      userName: userName,
      emailAddress: emailAddress,
      companyId: companyId,
      companyName: companyName,
      isCompanyHidden: isCompanyHidden,
      permissions: permissions,
      isPackager: isPackager,
    },
  };
}

// Set the alerts that are shown to the user with a notification bell in the navigation bar.
export function setAlerts(alerts: AlertMessage[]): SetAlertsAction {
  return {
    type: SET_ALERTS,
    payload: {
      alerts: alerts,
    },
  };
}

// Toggle an alert so it read or unread.
export function toggleAlert(alertMessageId: number): ToggleAlertAction {
  return {
    type: TOGGLE_ALERT,
    payload: {
      alertMessageId: alertMessageId,
    },
  };
}

// Toggle all alerts so that they are read or unread.
export function toggleAllAlerts(): ToggleAllAlertsAction {
  return {
    type: TOGGLE_ALL_ALERTS,
    payload: null,
  };
}

// Delete an alert.
export function deleteAlert(alertMessageId: number): DeleteAlertAction {
  return {
    type: DELETE_ALERT,
    payload: {
      alertMessageId: alertMessageId,
    },
  };
}

// Delete all alerts.
export function deleteAllAlerts(): DeleteAllAlertsAction {
  return {
    type: DELETE_ALL_ALERTS,
    payload: null,
  };
}

// Store the current device's list of valid attributes and whether the attribute is selected for graphing.
export function setDeviceAttributes(attributes: DeviceAttribute[]): SetDeviceAttributesAction {
  return {
    type: SET_DEVICE_ATTRIBUTES,
    payload: {
      attributes: attributes,
    },
  };
}

// Toggle a single device attribute's selected for graphing setting.
export function toggleDeviceAttribute(code: string): ToggleDeviceAttributeAction {
  return {
    type: TOGGLE_DEVICE_ATTRIBUTE,
    payload: {
      code: code,
    },
  };
}

interface AlertMessage {
  alertMessageId: number;
  alertCode: string;
  date: string;
  message: string;
  unread: boolean;
  urgency: string;
}
