// --------------------------------------------------------------
// Created On: 2022-09-20
// Author: Zachary Thomas
//
// Last Modified: 2023-12-26
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";

// Explains what a rented asset is.
export default function HelpRental(): Component {
  return (
    <div>
      <p className="px-3 my-2">
        Assets can be rented to other accounts to give the rental account the ability to view asset data, remotely
        operate, or set alert thresholds. Assets that are rented to other companies cannot be deleted or modified by the
        renting company.
      </p>

      <p className="px-3 mb-3">
        To rent an asset to another company you must know the email address of at least one user who belongs to the
        receiving company.
      </p>
    </div>
  );
}
