// --------------------------------------------------------------
// Created On: 2021-11-20
// Author: Zachary Thomas
//
// Last Modified: 2024-06-26
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./MapAssetAlertBanner.module.scss";

// Displays warnings about potential issues with asset data.
export default function MapAssetAlertBanner(props: Props): Component {
  const noAssets = noAssetsInGroup();
  const assetIsMissingLocation = assetIsMissingLocationData();

  // Check if there are no assets in the current asset group.
  function noAssetsInGroup(): boolean {
    return props.assets.length === 0;
  }

  // Checks if any asset is missing location data.
  function assetIsMissingLocationData(): boolean {
    return props.assets.some((asset) => asset.longitude === null || asset.latitude === null);
  }

  return (
    <div className={styles.body}>
      {noAssets && props.errorMessage.length === 0 && (
        <div className={`${styles.assetWarning} d-none d-md-inline-block alert alert-warning`}>
          <span>There are no assets in this asset group.</span>
        </div>
      )}

      {/* Currently hiding this message due to customer requesting not to see it. */}
      {/* There is a question of if this makes the device appear to have an issue */}
      {/* when the reality is that the device is outside of cellular range. */}
      {assetIsMissingLocation && !noAssets && props.errorMessage.length === 0 && (
        <div className={`${styles.assetWarning} d-none alert alert-warning`}>
          <span>Some of the assets in this asset group do not</span>
          <br />
          <span>have location data to display.</span>
        </div>
      )}

      {props.errorMessage.length > 0 && (
        <div className={styles.assetWarningContainer}>
          <div className={`${styles.assetWarning} alert alert-danger`}>
            <span>{props.errorMessage}</span>
          </div>
        </div>
      )}
    </div>
  );
}

MapAssetAlertBanner.propTypes = {
  assets: PropTypes.array.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

interface Props {
  assets: LocationAsset[];
  errorMessage: string;
}

interface LocationAsset {
  assetId: number;
  latitude: number | null;
  longitude: number | null;
}
