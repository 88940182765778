// --------------------------------------------------------------
// Created On: 2021-10-01
// Author: Zachary Thomas
//
// Last Modified: 2024-01-15
// Modified By: Zachary Thomas
//
// Copyright 2023 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import validateNickname from "../../../../utilities/validateNickname";
import styles from "./AssociatedAssetItem.module.scss";

// A single associated asset in a threshold management modal.
export default function AssociatedAssetItem(props: Props): Component {
  return (
    <Fragment>
      <div className={`${styles.body} px-4 py-2`}>
        {props.name} {validateNickname(props.nickname, props.name) ? `(${props.nickname})` : ""}{" "}
        {props.isMigrating ? <i className="fa fa-fw fa-exchange" /> : null}
      </div>
    </Fragment>
  );
}

AssociatedAssetItem.propTypes = {
  assetId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  nickname: PropTypes.string,
  isMigrating: PropTypes.bool,
};

interface Props {
  assetId: number;
  name: string;
  nickname: string;
  isMigrating: boolean;
}
