// --------------------------------------------------------------
// Created On: 2022-07-25
// Author: Zachary Thomas
//
// Last Modified: 2024-03-12
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Convert ISO 8601 string to a timestamp (number of milliseconds since the Unix Epoch).
export default function isoToTimestamp(iso: string): number {
  if (!iso.includes("Z") && !iso.includes("+")) {
    iso += "Z";
  }
  const date = new Date(iso);
  return date.getTime();
}
