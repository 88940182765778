// --------------------------------------------------------------
// Created On: 2023-02-03
// Author: Zachary Thomas
//
// Last Modified: 2024-04-17
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import PropTypes from "prop-types";
import { API, MAX_ASSETGROUP_DEPTH } from "../../../../constants/miscellaneous";
import apiRequest from "../../../../utilities/api/apiRequest";
import AssetgroupDiagram from "../AssetgroupDiagram/AssetgroupDiagram";
import Spinner from "../../../../components/Spinner/Spinner";
import TabList from "../../../../components/TabList/TabList";
import AssociatedAssetgroupItem from "./AssociatedAssetgroupItem/AssociatedAssetgroupItem";
import AssociationModal from "../../../../components/AssociationModal/AssociationModal";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../../redux/selectors";
import styles from "./AssetgroupTab.module.scss";

// Tab for associating asset groups with asset groups.
export default function AssetgroupTab(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [showAssociation, setShowAssociation] = useState(false);
  const [associationErrorMessage, setAssociationErrorMessage] = useState<string>("");
  const currentUser = useSelector(getCurrentUser);

  // Given a list of selected asset groups, get the complete nested data for each from the API and associate it.
  async function associateAssetgroups(assetgroups: SimpleAssetgroup[]): Promise<void> {
    const apiRequests: Promise<[Response, ResponseBody]>[] = [];

    setLoading(true);
    assetgroups.forEach((assetgroup) => {
      const apiRequestPromise = apiRequest(
        `${API}/company/${currentUser.companyId}/assetgroup/${assetgroup.assetgroupId}/asset`,
        "GET",
        null
      ) as unknown;
      apiRequests.push(apiRequestPromise as Promise<[Response, ResponseBody]>);
    });

    // Wait for all asset group API requests to resolve.
    const resolvedPromises = await Promise.all(apiRequests);
    setLoading(false);

    // Process each API request that have resolved.
    let apiErrorMessage = "";
    const tempAssetgroups: Assetgroup[] = [];

    resolvedPromises.forEach(([response, responseBody], i) => {
      if (response.ok && responseBody && assetgroups.length > i) {
        tempAssetgroups.push({
          assetgroupId: assetgroups[i].assetgroupId,
          name: assetgroups[i].name,
          isDefault: false,
          assets: responseBody.assets,
          assetgroups: responseBody.assetgroups,
          usergroups: [],
        });
      } else {
        apiErrorMessage = "Internal server error. Unable to get asset group information.";
      }
    });

    // Check if there was an error while processing API responses.
    if (apiErrorMessage.length > 0) {
      setAssociationErrorMessage(apiErrorMessage);
    } else {
      // Make sure none of the asset groups form an infinite loop or are deeper than the required limit.
      const assetgroupsWithMatchError: string[] = [];
      const assetgroupsWithDepthError: string[] = [];
      tempAssetgroups.forEach((assetgroup) => {
        const [childDepth, childFound] = getAssetgroupDepth(assetgroup, props.assetgroupId);
        if (childFound) {
          assetgroupsWithMatchError.push(assetgroup.name);
        } else if (childDepth + 1 > MAX_ASSETGROUP_DEPTH) {
          assetgroupsWithDepthError.push(assetgroup.name);
        }
      });

      // Only continue with association if there were no errors.
      if (assetgroupsWithMatchError.length > 0) {
        setAssociationErrorMessage(
          `The following asset groups already include the current assetgroup as a child: ${assetgroupsWithMatchError
            .toString()
            .replace(",", ", ")}`
        );
      } else if (assetgroupsWithDepthError.length > 0) {
        setAssociationErrorMessage(
          `The following asset groups will cause the max asset group depth of ${MAX_ASSETGROUP_DEPTH} to be exceeded: ${assetgroupsWithDepthError
            .toString()
            .replace(",", ", ")}`
        );
      } else {
        props.onChangeAssetgroups(tempAssetgroups);
        setShowAssociation(false);
        setAssociationErrorMessage("");
      }
    }
  }

  // Get the depth of an asset group and return information on whether another asset group exists inside of it.
  function getAssetgroupDepth(assetgroup: Assetgroup, assetgroupId: number): [number, boolean] {
    let assetgroupIdFound = false;
    let depth = 1;
    for (const childAssetgroup of assetgroup.assetgroups) {
      if (childAssetgroup.assetgroupId === assetgroupId) {
        assetgroupIdFound = true;
      }
      const [childDepth, childFound] = getAssetgroupDepth(childAssetgroup, assetgroupId);
      if (childFound) {
        assetgroupIdFound = true;
      }
      if (depth < childDepth + 1) {
        depth = childDepth + 1;
      }
    }
    return [depth, assetgroupIdFound];
  }

  return (
    <div>
      <Spinner loading={loading} />

      {/* Interactive asset group diagram. */}
      {!showAssociation && (
        <AssetgroupDiagram
          assetgroup={{
            assetgroupId: props.assetgroupId,
            name: props.assetgroupName,
            assets: props.selectedAssets,
            assetgroups: props.selectedAssetgroups,
            usergroups: [],
            isDefault: false,
          }}
          onClick={(selectedId, selectedType) => props.onRedirect(selectedId, selectedType)}
          display="assets"
        />
      )}

      {/* Association menu tabs.*/}
      <div className={`${styles.body} my-4`}>
        <div className="row mx-2">
          <TabList
            tabs={props.tabs}
            selectedTabId={props.tabId}
            onSelect={(tabId: string) => props.onClickTab(tabId)}
          />

          {/* Button and other general content. */}
          <div className={`${styles.tabsBar} py-3 text-center`}>
            <button
              data-test="assetgroup-modal-manage-asset-group-associations-button"
              className={`${styles.associationButton} btn btn-light my-2`}
              disabled={props.disabled}
              onClick={() => setShowAssociation(true)}
            >
              Manage Asset Group Associations
            </button>
          </div>

          {/* List of associated asset groups. */}
          <div className={`${styles.tabsContainer} p-0`}>
            {props.selectedAssetgroups.map((assetgroup: Assetgroup) => (
              <AssociatedAssetgroupItem
                key={assetgroup.assetgroupId}
                assetgroupId={assetgroup.assetgroupId}
                name={assetgroup.name}
              />
            ))}
          </div>
        </div>
      </div>

      <AssociationModal
        data-test="assetgroup-modal-manage-asset-group-associations-button"
        showModal={showAssociation}
        title="Manage Asset Group Associations"
        type="assetgroup"
        associatedItemsTitle="Associated Asset Groups"
        unassociatedItemsTitle="Unassociated Asset Groups"
        items={props.assetgroups}
        associatedItems={props.selectedAssetgroups}
        itemIdKey="assetgroupId"
        parentName={props.assetgroupName}
        disabled={props.disabled}
        onClose={() => setShowAssociation(false)}
        onChange={(associations) => associateAssetgroups(associations)}
        errorMessage={associationErrorMessage}
      />
    </div>
  );
}

AssetgroupTab.propTypes = {
  tabId: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  assetgroupId: PropTypes.number.isRequired,
  assetgroupName: PropTypes.string.isRequired,
  assets: PropTypes.array.isRequired,
  assetgroups: PropTypes.array.isRequired,
  selectedAssets: PropTypes.array.isRequired,
  selectedAssetgroups: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  onRedirect: PropTypes.func.isRequired,
  onClickTab: PropTypes.func.isRequired,
  onChangeAssetgroups: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

interface Props {
  tabId: string;
  tabs: ManagementTab[];
  assetgroupId: number;
  assetgroupName: string;
  assets: SimpleAsset[];
  assetgroups: SimpleAssetgroup[];
  selectedAssets: SimpleAsset[];
  selectedAssetgroups: Assetgroup[];
  disabled: boolean;
  onRedirect: (selectedId: number, selectedType: string) => void;
  onClickTab: (tabId: string) => void;
  onChangeAssetgroups: (assetgroups: Assetgroup[]) => void;
  onError: (errorMessage: string) => void;
}

interface ResponseBody {
  assets: Asset[];
  assetgroups: Assetgroup[];
}
