// --------------------------------------------------------------
// Created On: 2021-05-14
// Author: Zachary Thomas
//
// Last Modified: 2024-05-14
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import styles from "./NavbarAssetLink.module.scss";

// A single clickable link on a navbar that corresponds to an asset.
export default function NavbarAssetLink(props: Props): Component {
  const { assetId } = useParams<Params>();

  return (
    <li
      className={`${styles.item} mx-3 ${
        props.targetPage.replace("*", assetId || "0") === props.currentPage ? styles.active : ""
      }`}
    >
      <Link className={styles.link} to={props.targetPage.replace("*", assetId || "0")}>
        <span>{props.name}</span>
      </Link>
    </li>
  );
}

NavbarAssetLink.propTypes = {
  name: PropTypes.string.isRequired,
  targetPage: PropTypes.string.isRequired,
  currentPage: PropTypes.string.isRequired,
};

interface Props {
  name: string;
  targetPage: string;
  currentPage: string;
}

type Params = {
  assetId: string;
};
