// --------------------------------------------------------------
// Created On: 2024-07-02
// Author: Zachary Thomas
//
// Last Modified: 2024-10-03
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Constants related to polling data periodically.
// Note: Delay is being added to max inactivity time to account for guaranteed inactivity that is applied to the user
// that happens during each initial polling check. This cycle is required to dramatically reduce the number
// of re-renders that have to be processed.
export const DASHBOARD_POLLING_DELAY_SECONDS = 60;
export const DASHBOARD_MAX_INACTIVE_USER_SECONDS = 600 + DASHBOARD_POLLING_DELAY_SECONDS;
export const ASSET_DETAILS_POLLING_DELAY_SECONDS = 5;
export const ASSET_DETAILS_MAX_INACTIVE_USER_SECONDS = 120 + ASSET_DETAILS_POLLING_DELAY_SECONDS;
