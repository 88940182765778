// --------------------------------------------------------------
// Created On: 2023-02-22
// Author: Zachary Thomas
//
// Last Modified: 2023-02-22
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./PaginationControl.module.scss";

// An interactive page selection control.
export default function PaginationControl(props: Props): Component {
  const [totalPages, setTotalPages] = useState<number>(0);

  // Find the total number of pages.
  useEffect(() => {
    setTotalPages(Math.ceil(props.totalCount / props.maxPerPage));
  }, [props.totalCount, props.maxPerPage]);

  return props.totalCount > props.maxPerPage ? (
    <nav aria-label="Search result pages.">
      <ul className="pagination pagination-lg justify-content-center my-4">
        {props.pageNumber > 1 && (
          <li className="page-item" onClick={() => props.onClick(1)}>
            <button className={`${styles.pageButton} page-link`}>&lt;&lt;</button>
          </li>
        )}

        {props.pageNumber > 1 && (
          <li className="page-item" onClick={() => props.onClick(props.pageNumber - 1)}>
            <button className={`${styles.pageButton} page-link`}>&lt;</button>
          </li>
        )}

        {props.pageNumber - 2 >= 1 && (
          <li
            className="page-item d-none d-lg-inline-block"
            onClick={() => props.onClick(props.pageNumber - 2)}
          >
            <button className={`${styles.pageButton} page-link`}>{props.pageNumber - 2}</button>
          </li>
        )}

        {props.pageNumber - 1 >= 1 && (
          <li
            className="page-item d-none d-md-inline-block"
            onClick={() => props.onClick(props.pageNumber - 1)}
          >
            <button className={`${styles.pageButton} page-link`}>{props.pageNumber - 1}</button>
          </li>
        )}

        <li className="page-item">
          <button className={`${styles.active} page-link`}>{props.pageNumber}</button>
        </li>

        {props.pageNumber + 1 <= totalPages && (
          <li
            className="page-item d-none d-md-inline-block"
            onClick={() => props.onClick(props.pageNumber + 1)}
          >
            <button className={`${styles.pageButton} page-link`}>{props.pageNumber + 1}</button>
          </li>
        )}

        {props.pageNumber + 2 <= totalPages && (
          <li
            className="page-item d-none d-lg-inline-block"
            onClick={() => props.onClick(props.pageNumber + 2)}
          >
            <button className={`${styles.pageButton} page-link`}>{props.pageNumber + 2}</button>
          </li>
        )}

        {props.pageNumber < totalPages && (
          <li className="page-item" onClick={() => props.onClick(props.pageNumber + 1)}>
            <button className={`${styles.pageButton} page-link`}>&gt;</button>
          </li>
        )}

        {props.pageNumber < totalPages && (
          <li className="page-item" onClick={() => props.onClick(totalPages)}>
            <button className={`${styles.pageButton} page-link`}>&gt;&gt;</button>
          </li>
        )}
      </ul>
    </nav>
  ) : null;
}

PaginationControl.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  maxPerPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

interface Props {
  pageNumber: number;
  maxPerPage: number;
  totalCount: number;
  onClick: (pageNumber: number) => void;
}
