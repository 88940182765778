// --------------------------------------------------------------
// Created On: 2024-09-10
// Author: Zachary Thomas
//
// Last Modified: 2024-09-16
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import {
  MAX_HIGHLIGHT_ATTRIBUTES,
  MAX_CARDS,
  MAX_CARD_ATTRIBUTES,
  MAX_GAUGES,
  MAX_REMOTE_OPERATION_ATTRIBUTES,
} from "../../constants/templates";
import styles from "./HelpTemplate.module.scss";

// Explains what a template is.
export default function HelpTemplate(): Component {
  return (
    <div>
      <p className="px-3 my-2">
        Templates allow you to customize how asset information is displayed when viewing an individual asset. Assets
        that were never explicitly assigned a template and assets that have had their previous template deleted will all
        use the &apos;Default Template&apos;.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Highlight Attributes:</b> When viewing an asset&apos;s data page, these attributes will appear at the top of
        the page. There is a limit of {MAX_HIGHLIGHT_ATTRIBUTES} highlight attributes.
      </p>
      <img className={styles.image} src="/images/template_highlight_example.png" alt="Highlight attributes example." />

      <p className="px-3 mt-3 mb-2">
        <b>Cards:</b> When viewing an asset&apos;s data page, these cards will show general data about the asset beneath
        the highlight attributes. You can either fill out your own custom attributes per card, or use a dynamic card
        that auto populates based on the state of the current asset. There is a limit of {MAX_CARDS} cards, and
        {MAX_CARD_ATTRIBUTES} attributes per card.
      </p>
      <img className={styles.image} src="/images/template_card_example.png" alt="Card example." />

      <p className="px-3 mt-3 mb-2">
        <b>Remote Operation Gauges:</b> When viewing an asset&apos;s remote operation page, these gauges will show the
        current value of an attribute with a bar showing the current position between the defined min and max. There is
        a limit of {MAX_GAUGES} gauges.
      </p>
      <img className={styles.image} src="/images/template_gauge_example.png" alt="Gauge example." />

      <p className="px-3 mt-3 mb-2">
        <b>Remote Operation Attributes:</b> When viewing an asset&apos;s remote operation page, these attributes will
        appear in a list on the page. There is a limit of {MAX_REMOTE_OPERATION_ATTRIBUTES} remote operation attributes.
      </p>
      <img
        className={styles.image}
        src="/images/template_operation_example.png"
        alt="Remote operation attributes example."
      />
    </div>
  );
}
