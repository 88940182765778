// --------------------------------------------------------------
// Created On: 2023-11-27
// Author: Zachary Thomas
//
// Last Modified: 2023-11-30
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./CreateGeofenceThreshold.module.scss";

// Panel for creating a new geo-fence threshold.
export default function CreateGeofenceThreshold(props: Props): Component {
  return (
    <div className={styles.body}>
      <span>Alert on asset {props.mode}ing geo-fence</span>
      <button className="btn btn-success m-3" type="button" onClick={() => props.onAddThreshold()}>
        <i className="fa fa-fw fa-plus fa-xs" />
      </button>
    </div>
  );
}

CreateGeofenceThreshold.propTypes = {
  mode: PropTypes.string.isRequired,
  onAddThreshold: PropTypes.func.isRequired,
};

interface Props {
  mode: "exit" | "enter";
  onAddThreshold: () => void;
}
