// --------------------------------------------------------------
// Created On: 2021-09-29
// Author: Zachary Thomas
//
// Last Modified: 2023-12-28
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import Modal from "../Modal/Modal";
import ModalHeader from "../ModalHeader/ModalHeader";
import ModalBody from "../ModalBody/ModalBody";
import ModalFooter from "../ModalFooter/ModalFooter";
import PropTypes from "prop-types";
import styles from "./SaveChangesModal.module.scss";

// Modal for making a decision about saving changes.
export default function SaveChangesModal(props: Props): Component {
  return (
    <Modal
      show={props.showModal}
      onHide={() => props.onClose()}
      backdropClassName={`${styles.modal} ${styles.backdrop}`}
      style={{ zIndex: "var(--super-modal-z-index)" }}
      animation
      centered
    >
      <ModalHeader>
        <h5>{props.title}</h5>
      </ModalHeader>
      <ModalBody>
        <p>{props.content}</p>
      </ModalBody>
      <ModalFooter>
        {props.onSave !== undefined && (
          <button
            type="button"
            className={`${styles.button} btn btn-primary`}
            data-dismiss="modal"
            onClick={() => {
              if (props.onSave !== undefined) {
                props.onSave();
              }
            }}
          >
            <span className="d-none d-md-inline">Save Changes</span>
            <span className="d-inline d-md-none">Save</span>
          </button>
        )}

        <button
          type="button"
          className={`${styles.button} btn btn-danger`}
          data-dismiss="modal"
          onClick={() => props.onNoSave()}
        >
          <span className="d-none d-md-inline">Don&#39;t Save Changes</span>
          <span className="d-inline d-md-none">Don&#39;t Save</span>
        </button>

        <button type="button" className={`${styles.button} btn btn-secondary`} onClick={() => props.onClose()}>
          Cancel
        </button>
      </ModalFooter>
    </Modal>
  );
}

SaveChangesModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onNoSave: PropTypes.func.isRequired,
  onSave: PropTypes.func,
};

interface Props {
  showModal: boolean;
  title: string;
  content: string;
  onClose: () => void;
  onNoSave: () => void;
  onSave?: () => void;
}
