// --------------------------------------------------------------
// Created On: 2024-07-02
// Author: Zachary Thomas
//
// Last Modified: 2024-07-02
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import { useEffect, useState } from "react";
import { MS_PER_SECOND } from "../constants/miscellaneous";

// This hook returns the number of seconds since the user was last active.
// Update frequency can be defined to reduce the number of times that this hook will trigger an update.
// For example if update frequency is equal to 5, then this hook updates at 0, 5, 10, 15, etc. seconds.
// If update frequency is not defined, it defaults to updating every second.
export default function useLastActive(updateFrequencySeconds: number = 1): number {
  const [secondsSinceLastActivity, setSecondsSinceLastActivity] = useState<number>(0);

  // Listen for changes in mouse movement or screen touches.
  useEffect(() => {
    function handleResetSecondsSinceLastActivity(): void {
      setSecondsSinceLastActivity(0);
    }

    window.addEventListener("mousemove", handleResetSecondsSinceLastActivity);
    window.addEventListener("touchstart", handleResetSecondsSinceLastActivity);
    window.addEventListener("scroll", handleResetSecondsSinceLastActivity);

    return () => {
      window.removeEventListener("mousemove", handleResetSecondsSinceLastActivity);
      window.removeEventListener("touchstart", handleResetSecondsSinceLastActivity);
      window.removeEventListener("scroll", handleResetSecondsSinceLastActivity);
    };
  }, []);

  // Increment seconds since last activity every second.
  useEffect(() => {
    const intervalId = setInterval(
      () => setSecondsSinceLastActivity((prev) => prev + Math.abs(updateFrequencySeconds)),
      MS_PER_SECOND * Math.abs(updateFrequencySeconds)
    );

    return () => {
      clearInterval(intervalId);
    };
  }, [updateFrequencySeconds]);

  return secondsSinceLastActivity;
}
