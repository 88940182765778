// --------------------------------------------------------------
// Created On: 2023-03-03
// Author: Zachary Thomas
//
// Last Modified: 2024-04-18
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Given highlighting rules and an a value. Return the appropriate color or null if none exists.
export default function calculateHighlightColor(
  highlightingRules: ViewHighlightingRule[],
  value: number
): string | null {
  let appliedColor = null;

  highlightingRules.forEach((highlightingRule) => {
    switch (highlightingRule.valueType) {
      case "NUMBER": {
        switch (highlightingRule.comparator) {
          case "<>": {
            const values = highlightingRule.value.split(",");
            if (values.length === 2 && value >= parseFloat(values[0]) && value <= parseFloat(values[1])) {
              appliedColor = highlightingRule.colorHexCode;
            }
            break;
          }
          case ">":
            if (value > parseFloat(highlightingRule.value)) {
              appliedColor = highlightingRule.colorHexCode;
            }
            break;
          case "<":
            if (value < parseFloat(highlightingRule.value)) {
              appliedColor = highlightingRule.colorHexCode;
            }
            break;
          case "=":
            if (value === parseFloat(highlightingRule.value)) {
              appliedColor = highlightingRule.colorHexCode;
            }
            break;
        }
        break;
      }
      case "MEDIAN": {
        switch (highlightingRule.comparator) {
          case ">":
            if (value > parseFloat(highlightingRule.value)) {
              appliedColor = highlightingRule.colorHexCode;
            }
            break;
          case "<":
            if (value < parseFloat(highlightingRule.value)) {
              appliedColor = highlightingRule.colorHexCode;
            }
            break;
          case "=":
            if (value === parseFloat(highlightingRule.value)) {
              appliedColor = highlightingRule.colorHexCode;
            }
            break;
        }
        break;
      }
      case "MEAN": {
        switch (highlightingRule.comparator) {
          case ">":
            if (value > parseFloat(highlightingRule.value)) {
              appliedColor = highlightingRule.colorHexCode;
            }
            break;
          case "<":
            if (value < parseFloat(highlightingRule.value)) {
              appliedColor = highlightingRule.colorHexCode;
            }
            break;
          case "=":
            if (value === parseFloat(highlightingRule.value)) {
              appliedColor = highlightingRule.colorHexCode;
            }
            break;
        }
        break;
      }
      case "STANDARD_DEVIATION": {
        const values = highlightingRule.value.split(",");
        switch (highlightingRule.comparator) {
          case ">": {
            if (values.length === 2 && (value < parseFloat(values[0]) || value > parseFloat(values[1]))) {
              appliedColor = highlightingRule.colorHexCode;
            }
            break;
          }
          case "<":
            if (values.length === 2 && value >= parseFloat(values[0]) && value <= parseFloat(values[1])) {
              appliedColor = highlightingRule.colorHexCode;
            }
            break;
        }
        break;
      }
      case "MAX": {
        if (value === parseFloat(highlightingRule.value)) {
          appliedColor = highlightingRule.colorHexCode;
        }
        break;
      }
      case "MIN": {
        if (value === parseFloat(highlightingRule.value)) {
          appliedColor = highlightingRule.colorHexCode;
        }
        break;
      }
    }
  });

  return appliedColor;
}

interface ViewHighlightingRule {
  comparator: string;
  valueType: string;
  value: string;
  colorHexCode: string;
}
