// --------------------------------------------------------------
// Created On: 2023-08-31
// Author: Zachary Thomas
//
// Last Modified: 2023-08-31
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Check if the current value is a boolean string (ex: "true", "false").
export default function isValueBooleanString(
  value: string | number | boolean | undefined | null
): boolean {
  return typeof value === "string" && ["true", "false"].includes(value.toLowerCase());
}
