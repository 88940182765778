// --------------------------------------------------------------
// Created On: 2024-07-09
// Author: Zachary Thomas
//
// Last Modified: 2024-08-22
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import { VIEW_HIGHLIGHT_COLORS, MAX_MAP_HIGHLIGHTING_RULES_PER_COLOR } from "../../../../constants/miscellaneous";
import ColorPickerMapMarker from "../../../../components/ColorPickerMapMarker/ColorPickerMapMarker";
import MapHighlightingRuleForm from "./MapHighlightingRuleForm/MapHighlightingRuleForm";
import { useDraggable } from "@dnd-kit/core";
import styles from "./MapHighlightingColorForm.module.scss";

// Form for assigning map highlighting colors.
export default function MapHighlightingColorForm(props: Props): Component {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.formId,
  });

  return (
    <div
      data-test="map-highlighting-color-form"
      className={`${styles.body} container m-0 py-0`}
      ref={setNodeRef}
      style={
        transform
          ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
            }
          : undefined
      }
    >
      <div className="row my-0 py-0">
        {/* This div handles the drag event. */}
        <div className={`${styles.draggable} col-auto p-0 m-0`} {...attributes} {...listeners} />
        <div className="col pe-1">
          <div className="row align-items-center my-0 mx-1">
            <div className={`${styles.title} col-auto px-0 mx-0 py-2`}>
              Rule #{props.formIndex + 1}: Highlight asset map marker{" "}
            </div>
            <div className="col-auto px-0 mx-2 py-2">
              <ColorPickerMapMarker
                colors={VIEW_HIGHLIGHT_COLORS}
                selectedColor={props.colorHexCode}
                disabled={props.disabled}
                onChange={(colorHexCode) => props.onChangeColor(colorHexCode)}
              />
            </div>
            {props.mapHighlightingRules.map((mapHighlightingRule, i) => (
              <MapHighlightingRuleForm
                key={mapHighlightingRule.formId}
                formIndex={i}
                disabled={props.disabled}
                name={mapHighlightingRule.name}
                attributeId={mapHighlightingRule.attributeId}
                comparator={mapHighlightingRule.comparator}
                value={mapHighlightingRule.value}
                valueType={mapHighlightingRule.valueType}
                formId={mapHighlightingRule.formId}
                allowDelete={props.mapHighlightingRules.length > 1}
                attributes={props.attributes}
                onChange={(name, nameChanged, comparator, valueType, value) =>
                  props.onChangeRule(mapHighlightingRule.formId, name, nameChanged, comparator, valueType, value)
                }
                onDelete={() => props.onDeleteRule(mapHighlightingRule.formId)}
              />
            ))}
            <div className="col-12 mx-0 px-0">
              <button
                data-test="attribute-map-add-condition-button"
                type="button"
                className={`${styles.button} btn btn-success my-2`}
                disabled={props.disabled || props.mapHighlightingRules.length >= MAX_MAP_HIGHLIGHTING_RULES_PER_COLOR}
                onClick={() => props.onAddRule()}
              >
                Add Condition
              </button>
              <button
                data-test="attribute-delete-rule-button"
                type="button"
                className={`${styles.button} btn btn-danger my-2 float-end`}
                disabled={props.disabled}
                onClick={() => props.onDeleteColor()}
              >
                Delete Rule
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

MapHighlightingColorForm.propTypes = {
  formId: PropTypes.number.isRequired,
  formIndex: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  attributes: PropTypes.array.isRequired,
  colorHexCode: PropTypes.string.isRequired,
  mapHighlightingRules: PropTypes.array.isRequired,
  onChangeColor: PropTypes.func.isRequired,
  onChangeRule: PropTypes.func.isRequired,
  onDeleteColor: PropTypes.func.isRequired,
  onDeleteRule: PropTypes.func.isRequired,
};

interface Props {
  formId: number;
  formIndex: number;
  disabled?: boolean;
  attributes: ViewAttribute[];
  colorHexCode: string;
  mapHighlightingRules: MapHighlightingRule[];
  onChangeColor: (colorHexCode: string) => void;
  onChangeRule: (
    formId: number,
    name: string,
    nameChanged: boolean,
    comparator: ViewComparator,
    valueType: ViewValueType,
    value: string
  ) => void;
  onDeleteColor: () => void;
  onDeleteRule: (formId: number) => void;
  onAddRule: () => void;
}

interface MapHighlightingRule {
  formId: number;
  attributeId: number;
  name: string;
  comparator: ViewComparator;
  value: string;
  valueType: ViewValueType;
}

interface ViewAttribute {
  attributeId: number;
  name: string;
  code: string;
  units: string | null;
  isBoolean: boolean;
  isHighlightable: boolean;
}

type ViewValueType = "NUMBER" | "MEDIAN" | "MEAN" | "STANDARD_DEVIATION" | "MAX" | "MIN";

type ViewComparator = "=" | "!=" | "<" | ">" | "<>";
