// --------------------------------------------------------------
// Created On: 2021-10-26
// Author: Zachary Thomas
//
// Last Modified: 2024-11-22
// Modified By: KC Willard
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import apiRequest from "../../utilities/api/apiRequest";
import getApiError from "../../utilities/api/getApiError";
import { API, MIN_FULL_NAME_LENGTH, MAX_FULL_NAME_LENGTH } from "../../constants/miscellaneous";
import Spinner from "../../components/Spinner/Spinner";
import ReviewContent from "../../components/ReviewContent/ReviewContent";
import useApi from "../../hooks/useApi";
import Card from "../../components/Card/Card";
import TextBlurb from "../../components/TextBlurb/TextBlurb";
import { useParams, Link } from "react-router-dom";
import styles from "./ReviewInvitePage.module.scss";

// Page for users to review a company invite sent by an admin.
export default function ReviewInvitePage(): Component {
  const [loadingUserInfo, setLoadingUserInfo] = useState<boolean>(false);
  const [loadingInviteAccept, setLoadingInviteAccept] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState<boolean>(false);
  const [callingCode, setCallingCode] = useState<string>("1");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [userExistsInUserPool, setUserExistsInUserPool] = useState<boolean>(false);
  const [userExistsInApplication, setUserExistsInApplication] = useState<boolean>(false);
  const { emailAddress, companyCode, companyName } = useParams();
  const inviteDescription =
    `Upon acceptance an account will be created for '${emailAddress}', a member of the company account` +
    ` '${companyName}'. You will receive an email message containing instructions on how to log into your` +
    ` new account.`;

  // Check if the user already exists on page load.
  useApi(
    () => {
      setLoadingUserInfo(true);
      return true;
    },
    {
      url: `${API}/registration/user-exists`,
      method: "POST",
      body: { emailAddress: emailAddress, applicationCode: "RPM" },
    },
    async (response: Response, responseBody: UserExistsResponseBody) => {
      if (response.ok && responseBody) {
        setErrorMessage("");
        setUserExistsInUserPool(responseBody.userExistsInUserPool);
        setUserExistsInApplication(responseBody.userExistsInApplication);
      } else if (!response.ok) {
        setErrorMessage(await getApiError(response, "Unable to get user information."));
      }
      setLoadingUserInfo(false);
    },
    [emailAddress]
  );

  // Accept an invite as a new user.
  async function acceptAccountInvite(
    name: string,
    emailAddress: string | undefined,
    companyCode: string | undefined,
    userExistsInUserPool: boolean
  ): Promise<void> {
    if (isValid()) {
      let newPhoneNumber = null;
      let newCallingCode = null;

      if (
        phoneNumber !== null &&
        phoneNumber !== undefined &&
        callingCode !== undefined &&
        callingCode !== null &&
        phoneNumber.length > 5
      ) {
        newPhoneNumber = phoneNumber;
        newCallingCode = callingCode;
      }

      const requestBody = {
        name: userExistsInUserPool ? null : name,
        emailAddress: emailAddress,
        companyCode: companyCode,
        phoneNumber: userExistsInUserPool ? null : newPhoneNumber,
        phoneNumberCountryCode: userExistsInUserPool ? null : newCallingCode,
      };

      // Make API call.
      setLoadingInviteAccept(true);
      const [response] = (await apiRequest(`${API}/registration/company/user/invite/accept`, "PUT", requestBody)) as [
        Response,
        ResponseBody
      ];
      setLoadingInviteAccept(false);

      if (response.ok) {
        setErrorMessage("");
        if (userExistsInUserPool) {
          setSuccessMessage(
            `The account '${emailAddress}' has been created successfully.` +
              ` Please click 'Return to Login' to login to your RPM² account.`
          );
        } else {
          setSuccessMessage(
            `The account '${emailAddress}' has been created successfully.` +
              ` You will receive an email shortly with information about how to log in to your new account.`
          );
        }
      } else {
        setSuccessMessage("");
        setErrorMessage(await getApiError(response, "Unable to create account."));
      }
    }
  }

  // Check if form data is valid.
  function isValid(): boolean {
    if (
      (!userExistsInUserPool && name.length < MIN_FULL_NAME_LENGTH) ||
      (!userExistsInUserPool && name.length > MAX_FULL_NAME_LENGTH)
    ) {
      setErrorMessage(`Name must be between ${MIN_FULL_NAME_LENGTH} and ${MAX_FULL_NAME_LENGTH} characters long.`);
      return false;
    } else if (!privacyPolicyAccepted) {
      setErrorMessage(`You must agree to the privacy policy to create an account.`);
      return false;
    } else if (emailAddress === undefined) {
      setErrorMessage("Internal server error. Email address is not found.");
      return false;
    } else if (companyCode === undefined) {
      setErrorMessage("Internal server error. Company account information is not found.");
      return false;
    } else {
      return true;
    }
  }

  return (
    <div className="p-4">
      <Spinner loading={loadingUserInfo || loadingInviteAccept} />
      {!loadingUserInfo && !userExistsInApplication && (
        <ReviewContent
          title="User Registration Form"
          completedForms={[
            { key: "Email", value: emailAddress || "" },
            { key: "Company", value: companyName || "" },
          ]}
          content={inviteDescription}
          successMessage={successMessage}
          errorMessage={errorMessage}
          allowReject={false}
          requireName={!userExistsInUserPool} // If user already exists, don't ask for name.
          name={name}
          onChangeName={(name) => setName(name)}
          phoneNumber={userExistsInUserPool ? undefined : phoneNumber} // If user already exists, don't ask for phone.
          callingCode={userExistsInUserPool ? undefined : callingCode} // If user already exists, don't ask for phone.
          onChangePhoneNumber={(phoneNumber) => setPhoneNumber(phoneNumber)}
          onChangeCallingCode={(callingCode) => setCallingCode(callingCode)}
          requirePrivacyPolicy={true}
          privacyPolicy={privacyPolicyAccepted}
          onChangePrivacyPolicy={(status) => setPrivacyPolicyAccepted(status)}
          onReject={() => {
            /* Do nothing. */
          }}
          onAccept={() => acceptAccountInvite(name, emailAddress, companyCode, userExistsInUserPool)}
        >
          {successMessage.length > 0 && (
            <Link to="/login" className="router-link">
              <div className="text-center">
                <button type="submit" className="btn btn-primary">
                  Return to Login
                </button>
              </div>
            </Link>
          )}
        </ReviewContent>
      )}
      {userExistsInApplication && (
        <div className={styles.alreadyRegisteredWrapper}>
          <Card title="Already Registered">
            <div className="my-5">
              <TextBlurb
                title="Registration Complete"
                paragraph="You previously completed the registration process and this invite is no longer valid."
                icon="check-circle"
              />
            </div>
          </Card>
        </div>
      )}
    </div>
  );
}

interface ResponseBody {
  message: string;
}

interface UserExistsResponseBody {
  userExistsInUserPool: boolean;
  userExistsInApplication: boolean;
}
