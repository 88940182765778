// --------------------------------------------------------------
// Created On: 2023-10-2
// Author: Zachary Thomas
//
// Last Modified: 2024-05-21
// Modified By: Zachary Thomas
//
// Copyright 2023 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import React from "react";
import PropTypes from "prop-types";
import IconTooltip from "../../../../components/IconTooltip/IconTooltip";
import isoIsValid from "../../../../utilities/time/isoIsValid";
import formatDateLocal from "../../../../utilities/time/formatDateLocal";
import styles from "./DeviceStatusValue.module.scss";

// A row that shows the value and description for a specific type of device status.
export default function DeviceStatusValue(props: Props): Component {
  return (
    <tr className={styles.body}>
      <td>
        <label className={styles.label}>{props.name}</label>
      </td>
      <td>
        {isoIsValid(props.value.split(".")[0]) ? formatDateLocal(props.value) : props.value}
        {props.name === "RSSI" && <span> dBm</span>}
      </td>
      <td>
        <IconTooltip
          id={`status-row-${props.name}`}
          icon="info-circle"
          message={props.description}
          color="var(--info-tooltip)"
        />
      </td>
    </tr>
  );
}

DeviceStatusValue.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

interface Props {
  name: string;
  description: string;
  value: string;
}
