// --------------------------------------------------------------
// Created On: 2023-03-06
// Author: Zachary Thomas
//
// Last Modified: 2024-08-05
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import calculateStandardDeviation from "./calculateStandardDeviation";

// Given a highlighting rule and an an array of values. Return the appropriate dynamic value for that rule.
export default function calculateHighlightRuleValue(highlightingRule: HighlightingRule, dataPoints: number[]): string {
  const dynamicValueTypes = ["MEDIAN", "MEAN", "STANDARD_DEVIATION", "MAX", "MIN"];
  if (dynamicValueTypes.includes(highlightingRule.valueType) && dataPoints.length > 0) {
    let value: number | string = 0;
    switch (highlightingRule.valueType) {
      case "MEDIAN": {
        if (dataPoints.length % 2) {
          value = dataPoints[(dataPoints.length + 1) / 2 - 1];
        } else {
          value = (dataPoints[dataPoints.length / 2 - 1] + dataPoints[dataPoints.length / 2]) / 2;
        }
        break;
      }
      case "MEAN": {
        value = dataPoints.reduce((a, b) => a + b) / dataPoints.length;
        break;
      }
      case "STANDARD_DEVIATION": {
        if (dataPoints.length > 1) {
          let standardDeviationLevel = parseInt(highlightingRule.value, 10);
          if (isNaN(standardDeviationLevel)) {
            standardDeviationLevel = 1;
          }
          const mean = dataPoints.reduce((a, b) => a + b) / dataPoints.length;
          const standardDeviation = calculateStandardDeviation(dataPoints);
          const min = mean - standardDeviation * standardDeviationLevel;
          const max = mean + standardDeviation * standardDeviationLevel;
          value = `${min},${max}`;
        }
        break;
      }
      case "MAX": {
        value = dataPoints[dataPoints.length - 1];
        break;
      }
      case "MIN": {
        value = dataPoints[0];
        break;
      }
    }
    return String(value);
  } else {
    return highlightingRule.value;
  }
}

interface HighlightingRule {
  comparator: string;
  valueType: string;
  value: string;
}
