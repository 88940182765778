// --------------------------------------------------------------
// Created On: 2021-11-23
// Author: Zachary Thomas
//
// Last Modified: 2024-05-01
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import React from "react";
import PropTypes from "prop-types";
import styles from "./PrivacyPolicy.module.scss";

// Privacy policy agreement.
export default function PrivacyPolicy(props: Props): Component {
  return (
    <div>
      <div className="mx-2 mb-4">
        <div className="row align-items-center">
          {/* Privacy policy agreement checkbox. */}
          <div className="col-auto px-2">
            <input
              data-test="privacy-policy-check-box"
              className="form-check-input form-check-big"
              type="checkbox"
              id="privacy-policy-checkbox"
              checked={props.status}
              onChange={(e) => props.onChange(e.target.checked)}
            />
          </div>

          {/* Privacy policy agreement label and link. */}
          <div className="col">
            <label className={`${styles.label} mb-0`}>
              By checking this box you are agreeing and consenting to our {props.updated ? "updated " : ""}
              <a href="/eula-text" target="_blank" rel="noopener noreferrer">
                EULA
              </a>{" "}
              and{" "}
              <a href="/privacy-policy-text" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

PrivacyPolicy.propTypes = {
  status: PropTypes.bool.isRequired,
  updated: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

interface Props {
  status: boolean;
  updated?: boolean;
  onChange: (accepted: boolean) => void;
}
