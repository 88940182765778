// --------------------------------------------------------------
// Created On: 2023-02-09
// Author: Zachary Thomas
//
// Last Modified: 2024-04-18
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, useRef } from "react";
import {
  SCHEDULE_TYPE_DAILY,
  SCHEDULE_TYPE_WEEKLY,
  SCHEDULE_TYPE_MONTHLY,
  SCHEDULE_TYPE_MONTHLY_BY_WEEK,
  SCHEDULE_TYPE_YEARLY,
  MAX_DAYS_IN_A_MONTH,
  DAYS_OF_THE_WEEK,
  WEEKS_OF_THE_MONTH,
  MONTHS_OF_THE_YEAR,
} from "../../../../constants/miscellaneous";
import formatTitleCase from "../../../../utilities/formatTitleCase";
import getDayPostfix from "../../../../utilities/time/getDayPostfix";
import timeInputToUserReadable from "../../../../utilities/time/timeInputToUserReadable";
import PropTypes from "prop-types";
import IconTooltip from "../../../../components/IconTooltip/IconTooltip";
import WeekDaySelector from "./WeekDaySelector/WeekDaySelector";
import styles from "./ScheduleForm.module.scss";

// Form for scheduling when report emails should be sent.
export default function ScheduleForm(props: Props): Component {
  const [scheduleType, setScheduleType] = useState<string>(SCHEDULE_TYPE_DAILY);
  const [time, setTime] = useState<string>("09:00");
  const [dayOfWeek, setDayOfWeek] = useState<DayOfWeek>("MONDAY");
  const [daysOfWeek, setDaysOfWeek] = useState<DayOfWeek[]>([]);
  const [dayNumber, setDayNumber] = useState<string>("1");
  const [weekOfMonth, setWeekOfMonth] = useState<WeekOfMonth>("FIRST");
  const [monthOfYear, setMonthOfYear] = useState<MonthOfYear>("JANUARY");
  const [showByWeek, setShowByWeek] = useState<boolean>(false);
  const timeInput = useRef(null);

  // If schedule settings change in the above component, update them here as well.
  useEffect(() => {
    if (props.scheduleSettings.length > 0) {
      const scheduleSetting = props.scheduleSettings[0];
      const scheduleType = scheduleSetting.type;

      // Perform actions based on the schedule type.
      switch (scheduleType) {
        case SCHEDULE_TYPE_DAILY: {
          // The format for daily records is "10:30" (hour, minute).
          if (isTime(scheduleSetting.value)) {
            safeTimeUpdate(scheduleSetting.value);
            setScheduleType(scheduleType);
          } else {
            resetScheduleToDefault();
          }
          break;
        }
        case SCHEDULE_TYPE_WEEKLY: {
          // The format for weekly records is multiple records of this format "MONDAY:10:30" (day, hour, minute).
          const daysOfWeek: DayOfWeek[] = [];
          let validRecords = true;
          props.scheduleSettings.forEach((scheduleSetting) => {
            const timeSegments = scheduleSetting.value.split(":");
            if (
              scheduleSetting.type === SCHEDULE_TYPE_WEEKLY &&
              timeSegments.length === 3 &&
              isDayOfWeek(timeSegments[0]) &&
              isTime(`${timeSegments[1]}:${timeSegments[2]}`)
            ) {
              daysOfWeek.push(timeSegments[0] as DayOfWeek);
              safeTimeUpdate(`${timeSegments[1]}:${timeSegments[2]}`);
              setScheduleType(scheduleType);
            } else {
              validRecords = false;
            }
          });
          if (validRecords) {
            setDaysOfWeek(daysOfWeek);
          } else {
            resetScheduleToDefault();
          }
          break;
        }
        case SCHEDULE_TYPE_MONTHLY: {
          // The format for monthly records is "15:10:30" (day, hour, minute).
          const timeSegments = scheduleSetting.value.split(":");
          if (
            timeSegments.length === 3 &&
            isDayNumber(timeSegments[0]) &&
            isTime(`${timeSegments[1]}:${timeSegments[2]}`)
          ) {
            setDayNumber(String(parseInt(timeSegments[0], 10)));
            safeTimeUpdate(`${timeSegments[1]}:${timeSegments[2]}`);
            setScheduleType(scheduleType);
            setShowByWeek(false);
          } else {
            resetScheduleToDefault();
          }
          break;
        }
        case SCHEDULE_TYPE_MONTHLY_BY_WEEK: {
          // The format for monthly records by week is "SECOND:TUESDAY:10:30" (week, day, hour, minute).
          const timeSegments = scheduleSetting.value.split(":");
          if (
            timeSegments.length === 4 &&
            isWeekOfMonth(timeSegments[0]) &&
            isDayOfWeek(timeSegments[1]) &&
            isTime(`${timeSegments[2]}:${timeSegments[3]}`)
          ) {
            setWeekOfMonth(timeSegments[0] as WeekOfMonth);
            setDayOfWeek(timeSegments[1] as DayOfWeek);
            safeTimeUpdate(`${timeSegments[2]}:${timeSegments[3]}`);
            setScheduleType(SCHEDULE_TYPE_MONTHLY);
            setShowByWeek(true);
          } else {
            resetScheduleToDefault();
          }
          break;
        }
        case SCHEDULE_TYPE_YEARLY: {
          // The format for yearly records is "JANUARY:17:10:30" (month, day, hour, minute).
          const timeSegments = scheduleSetting.value.split(":");
          if (
            timeSegments.length === 4 &&
            isMonthOfYear(timeSegments[0]) &&
            isDayNumber(timeSegments[1]) &&
            isTime(`${timeSegments[2]}:${timeSegments[3]}`)
          ) {
            setMonthOfYear(timeSegments[0] as MonthOfYear);
            setDayNumber(String(parseInt(timeSegments[1], 10)));
            safeTimeUpdate(`${timeSegments[2]}:${timeSegments[3]}`);
            setScheduleType(scheduleType);
          } else {
            resetScheduleToDefault();
          }
          break;
        }
        default:
          resetScheduleToDefault();
      }
    } else {
      setScheduleType(SCHEDULE_TYPE_WEEKLY);
    }
  }, [JSON.stringify(props.scheduleSettings)]);

  // Export schedule settings.
  function exportSettings(field: validExportType, value: string | DayOfWeek[] | boolean): void {
    let tempScheduleType = scheduleType;
    let tempTime = time;
    let tempDayNumber = dayNumber;
    let tempWeekOfMonth = weekOfMonth;
    let tempDayOfWeek = dayOfWeek;
    let tempMonthOfYear = monthOfYear;
    let tempDaysOfWeek = daysOfWeek;
    let tempShowByWeek = showByWeek;

    // Modify a specific field for the API object.
    switch (field) {
      case "scheduleType":
        tempScheduleType = value as string;
        break;
      case "time":
        // Due to weird behavior with time inputs and cursors, this component must control time changes.
        setTime(value as string);
        tempTime = value as string;
        break;
      case "dayNumber":
        tempDayNumber = value as string;
        break;
      case "weekOfMonth":
        tempWeekOfMonth = value as WeekOfMonth;
        break;
      case "dayOfWeek":
        tempDayOfWeek = value as DayOfWeek;
        break;
      case "monthOfYear":
        tempMonthOfYear = value as MonthOfYear;
        break;
      case "daysOfWeek":
        tempDaysOfWeek = value as DayOfWeek[];
        break;
      case "showByWeek":
        tempShowByWeek = value as boolean;
        break;
      default:
      /* Do nothing. */
    }

    // Make sure day number is always two characters in length.
    if (tempDayNumber.length === 1) {
      tempDayNumber = `0${tempDayNumber}`;
    }

    // Generate the report schedule objects that will eventually be passed to the API.
    switch (tempScheduleType) {
      case SCHEDULE_TYPE_DAILY: {
        // The format for daily records is "10:30" (hour, minute).
        props.onChange([{ reportScheduleId: 1, type: tempScheduleType, value: tempTime }]);
        break;
      }
      case SCHEDULE_TYPE_WEEKLY: {
        // The format for weekly records is multiple records of this format "MONDAY:10:30" (day, hour, minute).
        const reportSchedules: ReportSchedule[] = [];
        tempDaysOfWeek.forEach((dayOfWeek, i) => {
          reportSchedules.push({
            reportScheduleId: i + 1,
            type: tempScheduleType,
            value: `${dayOfWeek}:${tempTime}`,
          });
        });
        props.onChange(reportSchedules);
        break;
      }
      case SCHEDULE_TYPE_MONTHLY: {
        // The format for monthly records by week is "SECOND:TUESDAY:10:30" (week, day, hour, minute).
        // The format for monthly records is "15:10:30" (day, hour, minute).
        if (tempShowByWeek) {
          props.onChange([
            {
              reportScheduleId: 1,
              type: SCHEDULE_TYPE_MONTHLY_BY_WEEK,
              value: `${tempWeekOfMonth}:${tempDayOfWeek}:${tempTime}`,
            },
          ]);
        } else {
          props.onChange([
            {
              reportScheduleId: 1,
              type: SCHEDULE_TYPE_MONTHLY,
              value: `${tempDayNumber}:${tempTime}`,
            },
          ]);
        }
        break;
      }
      case SCHEDULE_TYPE_YEARLY: {
        // The format for yearly records is "JANUARY:17:10:30" (month, day, hour, minute).
        props.onChange([
          {
            reportScheduleId: 1,
            type: tempScheduleType,
            value: `${tempMonthOfYear}:${tempDayNumber}:${tempTime}`,
          },
        ]);
        break;
      }
      default:
      /* Do nothing. */
    }
  }

  // Check if a string is a specific time ("09:10").
  function isTime(value: string): boolean {
    const timePattern = /^[0-9][0-9]:[0-9][0-9]$/;
    return timePattern.test(value);
  }

  // Check if a string is a specific day number ("05").
  function isDayNumber(value: string): boolean {
    const dayPattern = /^[0-9][0-9]$/;
    return dayPattern.test(value);
  }

  // Check if a string is a week of a month.
  function isWeekOfMonth(value: string): boolean {
    return WEEKS_OF_THE_MONTH.includes(value);
  }

  // Check if a string is a day of the week.
  function isDayOfWeek(value: string): boolean {
    return DAYS_OF_THE_WEEK.includes(value);
  }

  // Check if a string is a month.
  function isMonthOfYear(value: string): boolean {
    return MONTHS_OF_THE_YEAR.includes(value);
  }

  // Reset all schedule settings to default.
  function resetScheduleToDefault(): void {
    setScheduleType(SCHEDULE_TYPE_DAILY);
    safeTimeUpdate("09:00");
    setDayOfWeek("MONDAY");
    setDaysOfWeek([]);
    setDayNumber("1");
    setMonthOfYear("JANUARY");
    setShowByWeek(false);
    setWeekOfMonth("FIRST");
    props.onChange([{ reportScheduleId: 1, type: SCHEDULE_TYPE_DAILY, value: "09:00" }]);
  }

  // Converts an array of days of the week into a human readable string.
  function getReadableDaysOfWeek(daysOfWeek: DayOfWeek[]): string {
    if (daysOfWeek.length === 7) {
      return "day";
    } else {
      const daySorter = {
        SUNDAY: 1,
        MONDAY: 2,
        TUESDAY: 3,
        WEDNESDAY: 4,
        THURSDAY: 5,
        FRIDAY: 6,
        SATURDAY: 7,
      };
      const sortedDaysOfTheWeek = daysOfWeek.sort((a, b) => daySorter[a] - daySorter[b]);
      let readableDaysOfTheWeek = formatTitleCase(sortedDaysOfTheWeek.join(", "));
      const lastCommaIndex = readableDaysOfTheWeek.lastIndexOf(",");
      if (lastCommaIndex >= 0) {
        const daysOfTheWeekCharacters = readableDaysOfTheWeek.split("");
        daysOfTheWeekCharacters.splice(lastCommaIndex, 1, " and");
        readableDaysOfTheWeek = daysOfTheWeekCharacters.join("");
      }
      return readableDaysOfTheWeek;
    }
  }

  // Update the day of the month or the day of the year.
  function updateDayNumber(day: string): void {
    const parsedDay = parseInt(day, 10);
    if (!isNaN(parsedDay) && parsedDay > 0) {
      if (parsedDay <= MAX_DAYS_IN_A_MONTH) {
        exportSettings("dayNumber", String(parsedDay));
      } else {
        exportSettings("dayNumber", String(MAX_DAYS_IN_A_MONTH));
      }
    } else {
      setDayNumber("");
    }
  }

  // Only update the time input if it is currently not selected. The time input will misplace the cursor if updated while active.
  function safeTimeUpdate(time: string): void {
    if (document.activeElement !== timeInput.current) {
      setTime(time);
    }
  }

  return (
    <div className={styles.border}>
      <div className="mx-1 mb-2">
        <label className={styles.title}>
          <span>Schedule&nbsp;</span>
          <IconTooltip
            id="schedule-tooltip"
            icon="info-circle"
            message="The schedule will determine when to send out the report to each listed
          email address."
            color="var(--info-tooltip)"
          />
        </label>
      </div>

      {/* Select if we want to send reports daily, weekly, monthly, or yearly. */}
      <div className="row mx-3 mb-2">
        <div className="col-0 col-md-3 d-none d-md-block text-end">Send every</div>
        <div className="col-12 col-md-9">
          <select
            data-test="schedule-form-select"
            className="form-select"
            value={scheduleType}
            disabled={props.disabled}
            onChange={(e) => exportSettings("scheduleType", e.target.value)}
          >
            <option value={SCHEDULE_TYPE_DAILY}>Day</option>
            <option value={SCHEDULE_TYPE_WEEKLY}>Week</option>
            <option value={SCHEDULE_TYPE_MONTHLY}>Month</option>
            <option value={SCHEDULE_TYPE_YEARLY}>Year</option>
          </select>
        </div>
      </div>

      {/* Custom selection options based on the schedule type. */}
      {scheduleType === SCHEDULE_TYPE_WEEKLY && (
        <div className="row mx-3 mb-2">
          <div className="col-0 col-md-3" />
          <div className="col-12 col-md-9">
            <WeekDaySelector
              selectedDaysOfWeek={daysOfWeek}
              disabled={props.disabled}
              onChange={(daysOfWeek) => exportSettings("daysOfWeek", daysOfWeek)}
            />
          </div>
        </div>
      )}

      {scheduleType === SCHEDULE_TYPE_MONTHLY && (
        <div className="row align-items-center mx-3 mb-2">
          <div className="col-0 col-md-3 d-none d-md-block text-end">On day</div>
          <div className="col-12 col-md-9">
            <input
              className="form-control d-inline"
              type="number"
              value={dayNumber}
              min={1}
              max={31}
              disabled={props.disabled || showByWeek}
              onChange={(e) => updateDayNumber(e.target.value)}
            />
          </div>
        </div>
      )}

      {scheduleType === SCHEDULE_TYPE_YEARLY && (
        <div className="row mx-3 mb-2">
          <div className="col-0 col-md-3 d-none d-md-block text-end">On</div>
          <div className="col-8 col-md-6">
            <select
              className="form-select"
              value={monthOfYear}
              disabled={props.disabled}
              onChange={(e) => exportSettings("monthOfYear", e.target.value as MonthOfYear)}
            >
              <option value="JANUARY">January</option>
              <option value="FEBRUARY">February</option>
              <option value="MARCH">March</option>
              <option value="APRIL">April</option>
              <option value="JUNE">June</option>
              <option value="JULY">July</option>
              <option value="AUGUST">August</option>
              <option value="OCTOBER">October</option>
              <option value="NOVEMBER">November</option>
              <option value="DECEMBER">December</option>
            </select>
          </div>
          <div className="col-4 col-md-3">
            <input
              className="form-control mx-auto"
              type="number"
              value={dayNumber}
              min={1}
              max={31}
              onChange={(e) => updateDayNumber(e.target.value)}
            />
          </div>
        </div>
      )}

      {/* Select the time that we would like to send the reports. */}
      <div className="row mx-3">
        <div className="col-0 col-md-3 d-none d-md-block text-end">At</div>
        <div className="col-12 col-md-9">
          <input
            data-test='schedule-form-time-input'
            className="form-control mx-auto"
            type="time"
            value={time}
            ref={timeInput}
            disabled={props.disabled}
            onChange={(e) => exportSettings("time", e.target.value)}
          />
        </div>
      </div>

      {/* Show human readable schedule message. */}
      <div className="row mx-3 my-3">
        <div className="col-0 col-md-3" />
        {scheduleType === SCHEDULE_TYPE_DAILY && (
          <div className="col-12 col-md-9">
            A report will be sent every day at {timeInputToUserReadable(time)}.
          </div>
        )}
        {scheduleType === SCHEDULE_TYPE_WEEKLY && daysOfWeek.length > 0 && (
          <div className="col-12 col-md-9">
            A report will be sent every {getReadableDaysOfWeek(daysOfWeek)} at{" "}
            {timeInputToUserReadable(time)}.
          </div>
        )}
        {scheduleType === SCHEDULE_TYPE_MONTHLY && !showByWeek && (
          <div className="col-12 col-md-9">
            A report will be sent every {`${dayNumber}${getDayPostfix(dayNumber)}`} day of the month
            at {timeInputToUserReadable(time)}.
          </div>
        )}
        {scheduleType === SCHEDULE_TYPE_MONTHLY && showByWeek && (
          <div className="col-12 col-md-9">
            A report will be sent every {weekOfMonth.toLowerCase()} {formatTitleCase(dayOfWeek)} of
            the month at {timeInputToUserReadable(time)}.
          </div>
        )}
        {scheduleType === SCHEDULE_TYPE_YEARLY && (
          <div className="col-12 col-md-9">
            A report will be sent every year on the
            {` ${dayNumber}${getDayPostfix(dayNumber)} `}
            of {formatTitleCase(monthOfYear)} at {timeInputToUserReadable(time)}.
          </div>
        )}
      </div>
    </div>
  );
}

ScheduleForm.propTypes = {
  scheduleSettings: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

interface Props {
  scheduleSettings: ReportSchedule[];
  disabled?: boolean;
  onChange: (scheduleSettings: ReportSchedule[]) => void;
}

type validExportType =
  | "scheduleType"
  | "time"
  | "dayNumber"
  | "weekOfMonth"
  | "dayOfWeek"
  | "monthOfYear"
  | "daysOfWeek"
  | "showByWeek";
