// --------------------------------------------------------------
// Created On: 2021-09-01
// Author: Zachary Thomas
//
// Last Modified: 2023-12-28
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import SuccessStatusIcon from "../SuccessStatusIcon/SuccessStatusIcon";

// Display an assets diagnosis status (wireless connection, modbus, sensors, etc...).
export default function DisplayDiagnosisStatus(props: Props): Component {
  const anAnalogSensorIsDisconnected = props.analogSensors.some((analogSensor) => !analogSensor.connected);
  return (props.deviceShouldReport || props.controllerShouldReport || props.analogSensors.length > 0) &&
    !props.loading ? (
    <div className="my-3">
      {props.deviceShouldReport && (
        <div>
          Monitoring device established wireless connection:{" "}
          <SuccessStatusIcon loading={false} successful={props.deviceConnected} />
        </div>
      )}
      {props.controllerShouldReport && (
        <div>
          Monitoring device properly connected to controller:{" "}
          <SuccessStatusIcon loading={false} successful={props.controllerConnected} />
        </div>
      )}
      {props.analogSensors.length > 0 && (
        <Fragment>
          <div>
            Analog sensors connected to monitoring device:{" "}
            <SuccessStatusIcon loading={false} successful={!anAnalogSensorIsDisconnected} />
          </div>
          <div className="mx-3">
            {props.analogSensors.map((analogSensor) => (
              <div key={analogSensor.position}>
                Analog sensor #{analogSensor.position} connected:{" "}
                <SuccessStatusIcon loading={false} successful={analogSensor.connected} />
              </div>
            ))}
          </div>
        </Fragment>
      )}
    </div>
  ) : (
    <div>Monitoring device does not support wireless diagnosis.</div>
  );
}

DisplayDiagnosisStatus.propTypes = {
  loading: PropTypes.bool.isRequired,
  deviceShouldReport: PropTypes.bool.isRequired,
  deviceConnected: PropTypes.bool.isRequired,
  controllerShouldReport: PropTypes.bool.isRequired,
  controllerConnected: PropTypes.bool.isRequired,
  analogSensors: PropTypes.array.isRequired,
};

interface Props {
  loading: boolean;
  deviceShouldReport: boolean;
  deviceConnected: boolean;
  controllerShouldReport: boolean;
  controllerConnected: boolean;
  analogSensors: AnalogSensor[];
}

interface AnalogSensor {
  position: number;
  connected: boolean;
}
