// --------------------------------------------------------------
// Created On: 2023-10-12
// Author: Zachary Thomas
//
// Last Modified: 2023-10-12
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import { MS_PER_DAY } from "../../constants/miscellaneous";

// Get the current time in UTC (plus an optional offset) and return an ISO string.
export default function nowToUtcIso(daysOffset: number): string {
  const currentDate = new Date();
  const utcCurrentDate = new Date(currentDate.getTime() - daysOffset * MS_PER_DAY);
  return utcCurrentDate.toISOString().split(".")[0];
}
