// --------------------------------------------------------------
// Created On: 2022-05-24
// Author: Zachary Thomas
//
// Last Modified: 2023-08-23
// Modified By: Zachary Thomas
//
// Copyright 2022 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./InviteResults.module.scss";

// List of invited users with a status label.
export default function InviteResults(props: Props): Component {
  // Convert the array of invitation responses to a string of emails.
  function getEmailAddresses(invitationResponses: InvitationResponse[]): string {
    let emailAddresses = "";
    invitationResponses.forEach((invitationResponse, i) => {
      if (i === invitationResponses.length - 1) {
        emailAddresses += invitationResponse.emailAddress;
      } else {
        emailAddresses += `${invitationResponse.emailAddress}, `;
      }
    });
    return emailAddresses;
  }

  return (
    <div className="m-2">
      <label style={{ color: props.color }}>{props.title}</label>

      <div className={`${styles.body} p-3`} style={{ borderColor: props.color }}>
        {getEmailAddresses(props.invitationResponses)}
      </div>
    </div>
  );
}

InviteResults.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  invitationResponses: PropTypes.array.isRequired,
};

interface InvitationResponse {
  userId: number;
  emailAddress: string;
}

interface Props {
  title: string;
  color: string;
  invitationResponses: InvitationResponse[];
}
