// --------------------------------------------------------------
// Created On: 2023-06-19
// Author: Zachary Thomas
//
// Last Modified: 2023-11-29
// Modified By: Lilly Kane
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";

// A select for a type of analog sensor and its application (what it reports).
export default function AnalogSensorSelect(props: Props): Component {
  // Returns an analog sensor ID if it exists, otherwise an empty string.
  function getAnalogSensorId(attributeCode: string): string {
    if (
      props.analogSensorMap[attributeCode] !== undefined &&
      props.analogSensorMap[attributeCode] !== null &&
      props.analogSensorMap[attributeCode].analogSensorId !== undefined
    ) {
      return String(props.analogSensorMap[attributeCode].analogSensorId);
    } else {
      return "";
    }
  }

  // Returns a list of all of the applications supported by a selected analog sensor.
  function getAnalogSensorApplications(attributeCode: string): AnalogApplication[] {
    if (
      props.analogSensorMap[attributeCode] !== undefined &&
      props.analogSensorMap[attributeCode] !== null &&
      props.analogSensorMap[attributeCode].analogSensorId !== undefined
    ) {
      const selectedSensor = props.analogSensors.find(
        (analogSensor) =>
          props.analogSensorMap[attributeCode].analogSensorId === analogSensor.analogSensorId
      );
      if (selectedSensor !== undefined) {
        return selectedSensor.applications;
      }
    }

    return [];
  }

  // Returns an analog sensor's currently selected attribute ID, otherwise an empty string.
  function getSelectedAnalogSensorAttributeId(attributeCode: string): string {
    if (
      props.analogSensorMap[attributeCode] !== undefined &&
      props.analogSensorMap[attributeCode] !== null &&
      props.analogSensorMap[attributeCode].mappedAttributeId !== undefined
    ) {
      return String(props.analogSensorMap[attributeCode].mappedAttributeId);
    } else {
      return "";
    }
  }

  return (
    <div data-test="analog-sensor-select-container">
      <label className="my-3 me-2">
        <span>Analog Sensor #{props.sensorNumber}</span>
      </label>
      <select
        data-test="analog-sensor-type-selection"
        className="form-select"
        value={getAnalogSensorId(props.sourceAttribute)}
        disabled={props.disabled}
        onChange={(e) =>
          props.onChange(
            e.target.value,
            "",
            props.sourceAttribute,
            props.sourceConnectedAttribute,
            props.sourceModeAttribute,
            props.sourceMinAttribute,
            props.sourceSpanAttribute
          )
        }
      >
        <option value="">None</option>
        {props.analogSensors.map((analogSensor) => (
          <option key={analogSensor.analogSensorId} value={analogSensor.analogSensorId}>
            {analogSensor.name}
          </option>
        ))}
      </select>

      {getAnalogSensorApplications(props.sourceAttribute).length > 0 && (
        <select
          data-test="analog-sensor-detail-selection"
          className="form-select mt-2"
          value={getSelectedAnalogSensorAttributeId(props.sourceAttribute)}
          disabled={props.disabled}
          onChange={(e) =>
            props.onChange(
              getAnalogSensorId(props.sourceAttribute),
              e.target.value,
              props.sourceAttribute,
              props.sourceConnectedAttribute,
              props.sourceModeAttribute,
              props.sourceMinAttribute,
              props.sourceSpanAttribute
            )
          }
        >
          {getAnalogSensorApplications(props.sourceAttribute).map((attribute) => (
            <option key={attribute.attributeId} value={attribute.attributeId}>
              Sensor reports {attribute.name.toLowerCase()}
            </option>
          ))}
        </select>
      )}
    </div>
  );
}

AnalogSensorSelect.propTypes = {
  sensorNumber: PropTypes.number.isRequired,
  sourceAttribute: PropTypes.string.isRequired,
  sourceConnectedAttribute: PropTypes.string.isRequired,
  sourceModeAttribute: PropTypes.string.isRequired,
  sourceMinAttribute: PropTypes.string.isRequired,
  sourceSpanAttribute: PropTypes.string.isRequired,
  analogSensors: PropTypes.array.isRequired,
  analogSensorMap: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

interface Props {
  sensorNumber: number;
  analogSensors: AnalogSensor[];
  sourceAttribute: string;
  sourceConnectedAttribute: string;
  sourceModeAttribute: string;
  sourceMinAttribute: string;
  sourceSpanAttribute: string;
  analogSensorMap: AnalogSensorMap;
  disabled: boolean;
  onChange: (
    analogSensorId: string,
    attributeId: string,
    attributeCode: string,
    attributeConnectedCode: string,
    sourceModeAttribute: string,
    sourceMinAttribute: string,
    sourceSpanAttribute: string
  ) => void;
}

interface AnalogSensor {
  analogSensorId: number;
  name: string;
  applications: AnalogApplication[];
}

interface AnalogApplication {
  attributeId: number;
  attributeConnectedId: number;
  name: string;
}

interface AnalogSensorMap {
  [key: string]: AnalogSensorSelection;
}

interface AnalogSensorSelection {
  analogSensorId: number;
  attributeCode: string;
  attributeConnectedCode: string;
  attributeModeCode: string;
  attributeMinCode: string;
  attributeSpanCode: string;
  mappedAttributeId: number;
  mappedAttributeConnectedId: number;
}
