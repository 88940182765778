// --------------------------------------------------------------
// Created On: 2021-05-14
// Author: Zachary Thomas
//
// Last Modified: 2024-11-05
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

export const RPM_BRAND = "RPM";
export const PUMPSENTRI_BRAND = "PUMPSENTRI";
export const CONTROLSINC_BRAND = "CONTROLSINC";

// Store information related to the current brand based on environment variable.
let brandUrl: string;
let supportEmail: string;
let supportPhone: string;
let supportOverride: string | null;
switch (import.meta.env.VITE_BRAND) {
  case PUMPSENTRI_BRAND:
    brandUrl = "pumpsentri2";
    supportEmail = "copilot@cornellpump.com";
    supportPhone = "(503)-653-0330";
    supportOverride = "please reach out to Sunbelt Rentals inside sales support.";
    break;
  case CONTROLSINC_BRAND:
    brandUrl = "ci-connect";
    supportEmail = "copilot@cornellpump.com";
    supportPhone = "(503)-653-0330";
    supportOverride = null;
    break;
  default:
    brandUrl = "rpm-squared";
    supportEmail = "copilot@cornellpump.com";
    supportPhone = "(503)-653-0330";
    supportOverride = null;
}

// Use dev or production URLs based on if the environment variable is set for dev or prod.
if (import.meta.env.VITE_ENV === "production") {
  brandUrl = `${brandUrl}.com`;
} else {
  brandUrl = `${brandUrl}-dev.com`;
}

let apiHeaders: Headers = {
  "Content-Type": "application/json",
};
// Note that import.meta.env.MODE shows if we are running locally (development) or are using a build (production).
// If we are developing locally, we manually pass in the origin so that lambdas that care about the domain get an origin.
if (import.meta.env.MODE === "development") {
  let localDomain = "https://rpm-squared-dev.com";
  if (import.meta.env.VITE_ENV === "production") {
    localDomain = "https://rpm-squared.com";
  }
  apiHeaders = {
    "Content-Type": "application/json",
    "Local-Origin": localDomain,
  };
}

// Miscellaneous constants.
export const TESTING_NEW_FEATURE = false;
export const API = `https://api.${brandUrl}`;
export const DOMAIN = `https://${brandUrl}`;
export const APP_BRAND = import.meta.env.VITE_BRAND;
export const API_HEADERS = apiHeaders;
export const CUSTOMER_SERVICE_EMAIL = supportEmail;
export const CUSTOMER_SERVICE_PHONE = supportPhone;
export const CUSTOMER_SERVICE_OVERRIDE_MESSAGE = supportOverride;
export const COPILOT_DEVICE_TYPE = "COPILOT";
export const COPILOT_MANUAL = {
  resourceId: 0,
  name: "Cornell Co-Pilot User Manual",
  endpoint: "manuals/Manual_CoPilot.pdf",
};
export const COPILOT_PMG_DEVICE_TYPE = "COPILOTPMG";
export const PMG_MANUAL = {
  resourceId: 0,
  name: "Co-Pilot PMG Data Sheet",
  endpoint: "manuals/DS_Co-Pilot PANEL MOUNTED GATEWAY 1.pdf",
};
export const COPILOT_EDGE_DEVICE_TYPE = "COPILOTEDGE";
export const PULSE_DEVICE_TYPE = "PULSEV1";
export const PULSE_MANUAL = {
  resourceId: 0,
  name: "Pulse Data Sheet",
  endpoint: "manuals/Pulse_Data_Sheet.pdf",
};
export const SEEKER_C_DEVICE_TYPE = "SEEKERC";
export const SEEKER_SOLAR_DEVICE_TYPE = "SEEKERSOLAR";
export const SEEKER_MANUAL = {
  resourceId: 0,
  name: "Seeker Pamphlet",
  endpoint: "manuals/RPM2_Seeker_pamphlet.pdf",
};
export const PRIVACY_POLICY = "/legal/Privacy_Policy.pdf";
export const EULA = "/legal/EULA.pdf";
export const RPM_COMPANY = "RPM Squared";
export const CONTROLLER_APP_VFD = "VFD";
export const GATEWAY_TIMEOUT_STATUS = 504;
export const MAX_SAME_ATTRIBUTE_COUNT_FOR_ALERT = 2;
export const MIN_LATITUDE = -90;
export const MAX_LATITUDE = 90;
export const MIN_LONGITUDE = -180;
export const MAX_LONGITUDE = 180;
export const MAX_ASSETGROUP_ASSETS_TO_GRAPH = 250;
export const MAX_ASSETGROUP_DEPTH = 4;
export const MAX_DIAGRAM_DEPTH = 5;
export const MAX_DIAGRAM_BREADTH = 10;
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 90;
export const MIN_GROUP_NAME_LENGTH = 1;
export const MAX_GROUP_NAME_LENGTH = 30;
export const MIN_ASSET_NAME_LENGTH = 1;
export const MAX_ASSET_NAME_LENGTH = 30;
export const MIN_ASSET_FIELDS_LENGTH = 1;
export const MAX_ASSET_FIELDS_LENGTH = 50;
export const MIN_LOCATION_NAME_LENGTH = 1;
export const MAX_LOCATION_NAME_LENGTH = 50;
export const MIN_LOCATION_ADDRESS_LENGTH = 0;
export const MAX_LOCATION_ADDRESS_LENGTH = 100;
export const MAX_MINUTES_FOR_ACCURATE_READING = 20;
export const MIN_MAINTENANCE_LENGTH = 1;
export const MAX_MAINTENANCE_LENGTH = 100;
export const MIN_MAINTENANCE_NOTES_LENGTH = 0;
export const MAX_MAINTENANCE_NOTES_LENGTH = 1000;
export const MIN_FULL_NAME_LENGTH = 5;
export const MAX_FULL_NAME_LENGTH = 50;
export const MAX_TELEPHONE_NUMBER_LENGTH = 20;
export const MIN_COMPANY_NAME_LENGTH = 1;
export const MAX_COMPANY_NAME_LENGTH = 100;
export const MIN_ALERT_MESSAGE_LENGTH = 5;
export const MAX_ALERT_MESSAGE_LENGTH = 1000;
export const MIN_SAFE_ASSET_CELSIUS = -40;
export const MAX_SAFE_ASSET_CELSIUS = 100;
export const MIN_SAFE_VIBRATION_MM = 0;
export const MAX_SAFE_VIBRATION_MM = 3;
export const MAX_ASSOCIATED_ASSETS = 100;
export const MAX_ASSOCIATED_USERS = 100;
export const MAX_ASSOCIATED_ASSETGROUPS = 100;
export const MAX_ASSOCIATED_USERGROUPS = 100;
export const MIN_GEOFENCE_POINTS = 3;
export const MAX_GEOFENCE_POINTS = 50;
export const MAX_NAVBAR_ALERTS = 5;
export const MAX_NAVBAR_ALERT_DAYS = 14;
export const MAX_REPORT_NAME_LENGTH = 50;
export const MAX_ROLE_NAME_LENGTH = 50;
export const MAX_ROLE_DESCRIPTION_LENGTH = 100;
export const MAX_REPORT_ATTRIBUTES = 15;
export const MAX_VIEW_NAME_LENGTH = 100;
export const MAX_VIEW_ATTRIBUTES = 5;
export const MAX_VIEW_ATTRIBUTE_HIGHLIGHTING_RULES = 5;
export const MAX_RESULTS_PER_PAGE = 50;
export const CONTROLLER_RPM_MIN = 0;
export const CONTROLLER_RPM_MAX = 5000;
export const SHOW_TOAST_MILLISECONDS = 5000;
export const CHECK_ALERTS_MILLISECONDS = 30000;
export const VALID_EMAIL_REGULAR_EXPRESSION = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const VALID_PASSWORD_NUMBER_REGULAR_EXPRESSION = /^(?=.*[1-9]).+$/;
export const VALID_PASSWORD_LOWERCASE_REGULAR_EXPRESSION = /^(?=.*[a-z]).+$/;
export const VALID_PASSWORD_UPPERCASE_REGULAR_EXPRESSION = /^(?=.*[A-Z]).+$/;
export const VALID_PASSWORD_SPECIAL_REGULAR_EXPRESSION = /^(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`]).+$/;
export const MIN_EMAIL_LENGTH = 5;
export const MAX_EMAIL_LENGTH = 320;
export const GEOFENCE_BUFFER_PERCENT = 0.1;
export const ALERT_TEMPLATE_GEOFENCE = "GEOFENCE";
export const ALERT_EMERGENCY = "EMERGENCY";
export const ALERT_HIGH = "HIGH";
export const ALERT_MEDIUM = "MEDIUM";
export const ALERT_LOW = "LOW";
export const ALERT_INFO = "INFO";
export const DATA_STARTING_DATE_OFFSET = -1;
export const DATA_ENDING_DATE_OFFSET = 0;
export const LOCATION_STARTING_DATE_OFFSET = -7;
export const LOCATION_ENDING_DATE_OFFSET = 0;
export const LOCATION_MIN_GPS_DISTANCE_BETWEEN_POINTS_KM = 0.03;
export const GRAPH_Y_AXIS_MAX_BUFFER_PERCENT = 0.1;
export const ASSETGROUP_ASSET_TAB: ManagementTab = {
  tabId: "asset",
  name: "Associated Assets",
  shortName: "Assets",
  breakPoints: "col-6 col-md",
};
export const ASSETGROUP_ASSETGROUP_TAB: ManagementTab = {
  tabId: "assetgroup",
  name: "Child Asset Groups",
  shortName: "Asset Groups",
  breakPoints: "col-6 col-md",
};
export const ASSETGROUP_USERGROUP_TAB: ManagementTab = {
  tabId: "usergroup",
  name: "Associated User Groups",
  shortName: "User Groups",
  breakPoints: "col-6 col-md",
};
export const ASSETGROUP_GEOFENCE_TAB: ManagementTab = {
  tabId: "geofence",
  name: "Geo-fence",
  shortName: "Geo-fence",
  breakPoints: "col-6 col-md",
};
export const SCHEDULE_TYPE_DAILY = "DAILY";
export const SCHEDULE_TYPE_WEEKLY = "WEEKLY";
export const SCHEDULE_TYPE_MONTHLY = "MONTHLY";
export const SCHEDULE_TYPE_MONTHLY_BY_WEEK = "MONTHLY_BY_WEEK";
export const SCHEDULE_TYPE_YEARLY = "YEARLY";
export const DAYS_OF_THE_WEEK = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
export const WEEKS_OF_THE_MONTH = ["FIRST", "SECOND", "THIRD", "FOURTH"];
export const MONTHS_OF_THE_YEAR = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
];
export const TIMEZONE_UTC = "Etc/UTC";
export const ASSETGROUP_ASSETGROUP_TAB_ID = 2;
export const ASSETGROUP_USERGROUP_TAB_ID = 3;
export const ASSETGROUP_GEOFENCE_TAB_ID = 4;
export const MS_PER_SECOND = 1000;
export const MS_PER_MINUTE = 60 * 1000;
export const MS_PER_HOUR = 60 * 60 * 1000;
export const MS_PER_DAY = 24 * 60 * 60 * 1000;
export const MS_PER_MONTH = 30 * 24 * 60 * 60 * 1000;
export const MS_PER_YEAR = 365 * 24 * 60 * 60 * 1000;
export const MINUTES_PER_HOUR = 60;
export const MINUTES_IN_A_DAY = 1440;
export const MAX_DAYS_IN_A_MONTH = 31;
export const NAVBAR_HOVER_DROPDOWN_DELAY = 100;
export const SECONDS_BETWEEN_DEVICE_READ_QUERIES = 3;
export const SECONDS_BETWEEN_OPERATION_READ_QUERIES = 10;
export const SECONDS_BETWEEN_DEVICE_PENDING_STATUS_QUERIES = 5;
export const MAX_QUERIES_FOR_DEVICE_READ = 30;
export const SECONDS_TO_WAIT_TO_RETURN_DEVICE_DATA = 10;
export const MAX_QUERIES_AFTER_REMOTE_OPERATION = 8;
export const REPORT_MODE_CURRENT = "CURRENT";
export const REPORT_MODE_SERIES = "SERIES";
export const REPORT_MODE_DIFFERENCE = "DIFFERENCE";
export const REPORT_ASSET_ALL = "ALL";
export const REPORT_ASSET_BY_GROUP = "GROUP";
export const GEOFENCE_TYPE_STATIC = "STATIC";
export const GEOFENCE_TYPE_DYNAMIC = "DYNAMIC";
export const PUBLIC_PAGES = [
  "/login",
  "/register",
  "/join-company",
  "/change-temporary-password",
  "/change-temp-password",
  "/forgot-password",
  "/accept-invite",
  "/privacy-policy-text",
  "/eula-text",
  "/privacy-policy-viewer",
  "/eula-viewer",
  "/mobile-app-landing",
  "/mobile-app-loading",
  "/mobile-app-eula",
  "/policy-review",
];
export const DASHBOARD_MAX_LONG_ATTRIBUTE_NAME_LENGTH = 20;
export const DASHBOARD_MAX_SHORT_ATTRIBUTE_NAME_LENGTH = 10;
export const MAX_NUMBER_OF_GRAPHING_ATTRIBUTES = 4;
export const MAX_ATTRIBUTE_ALIAS_LENGTH = 30;
export const CREATE_MODE = "CREATE_MODE";
export const EDIT_MODE = "EDIT_MODE";
export const VIEW_HIGHLIGHT_COLORS = [
  "#e8352e",
  "#fb6251",
  "#feb7b3",
  "#ff8300",
  "#ffb900",
  "#fce656",
  "#4fb35f",
  "#54e26a",
  "#7ae48a",
  "#db33fe",
  "#dc7def",
  "#f8a4df",
];
export const VIEW_INITIAL_HIGHLIGHT_COLOR = "#ffb900";
export const MAX_MAP_HIGHLIGHTING_COLORS = 7;
export const MAX_MAP_HIGHLIGHTING_RULES_PER_COLOR = 5;
export const PRODUCT_MANUFACTURERS = [
  {
    name: "Atlas Copco",
    code: "ATLASCOPCO",
  },
  {
    name: "BBA Pumps",
    code: "BBAPUMPS",
  },
  {
    name: "Cornell Pump Company",
    code: "CORNELL",
  },
  {
    name: "Global Pumps",
    code: "GLOBALPUMPS",
  },
  {
    name: "Gorman-Rupp",
    code: "GORMANRUPP",
  },
  {
    name: "Grundfos",
    code: "GRUNDFOS",
  },
  {
    name: "HydraTech",
    code: "HYDRATECH",
  },
  {
    name: "Myers-Seth Pumps",
    code: "MSP",
  },
  {
    name: "Pioneer Pump",
    code: "PIONEER",
  },
  {
    name: "Premier Pump",
    code: "PREMIER",
  },
  {
    name: "Primax",
    code: "PRIMAX",
  },
  {
    name: "PVR",
    code: "PVR",
  },
  {
    name: "Sykes Group",
    code: "SYKES",
  },
  {
    name: "Vaughan Chopper Pumps",
    code: "VAUGHAN",
  },
];
export const MIN_VALUE_FOR_UNSIGNED_REGISTER = 0;
export const MAX_VALUE_FOR_UNSIGNED_REGISTER = 65535;
export const MIN_VALUE_FOR_SIGNED_REGISTER = -32768;
export const MAX_VALUE_FOR_SIGNED_REGISTER = 32767;

interface Headers {
  [key: string]: string;
}
