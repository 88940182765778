// --------------------------------------------------------------
// Created On: 2021-06-03
// Author: Zachary Thomas
//
// Last Modified: 2024-09-17
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import formatDateShortLocal from "../../../../utilities/time/formatDateShortLocal";
import {
  DTC_1_COUNT_ATTRIBUTE,
  DTC_2_COUNT_ATTRIBUTE,
  DTC_3_COUNT_ATTRIBUTE,
  DTC_4_COUNT_ATTRIBUTE,
  DTC_5_COUNT_ATTRIBUTE,
  DTC_1_CODE_ATTRIBUTE,
  DTC_2_CODE_ATTRIBUTE,
  DTC_3_CODE_ATTRIBUTE,
  DTC_4_CODE_ATTRIBUTE,
  DTC_5_CODE_ATTRIBUTE,
  DTC_1_DESCRIPTION_ATTRIBUTE,
  DTC_2_DESCRIPTION_ATTRIBUTE,
  DTC_3_DESCRIPTION_ATTRIBUTE,
  DTC_4_DESCRIPTION_ATTRIBUTE,
  DTC_5_DESCRIPTION_ATTRIBUTE,
} from "../../../../constants/attributes";
import styles from "./ActiveDtcCardBody.module.scss";

// Specially rendered body for dynamic active DTC cards.
export default function ActiveDtcCardBody(props: Props): Component {
  const activeDtcs = useMemo(() => getActiveDtcs(props.attributes), [JSON.stringify(props.attributes)]);

  // Get all active DTCs.
  function getActiveDtcs(attributes: Attribute[]): ActiveDtc[] {
    const activeDtcs: ActiveDtc[] = [];
    addActiveDtcToArray(activeDtcs, attributes, [
      DTC_1_CODE_ATTRIBUTE,
      DTC_1_DESCRIPTION_ATTRIBUTE,
      DTC_1_COUNT_ATTRIBUTE,
    ]);
    addActiveDtcToArray(activeDtcs, attributes, [
      DTC_2_CODE_ATTRIBUTE,
      DTC_2_DESCRIPTION_ATTRIBUTE,
      DTC_2_COUNT_ATTRIBUTE,
    ]);
    addActiveDtcToArray(activeDtcs, attributes, [
      DTC_3_CODE_ATTRIBUTE,
      DTC_3_DESCRIPTION_ATTRIBUTE,
      DTC_3_COUNT_ATTRIBUTE,
    ]);
    addActiveDtcToArray(activeDtcs, attributes, [
      DTC_4_CODE_ATTRIBUTE,
      DTC_4_DESCRIPTION_ATTRIBUTE,
      DTC_4_COUNT_ATTRIBUTE,
    ]);
    addActiveDtcToArray(activeDtcs, attributes, [
      DTC_5_CODE_ATTRIBUTE,
      DTC_5_DESCRIPTION_ATTRIBUTE,
      DTC_5_COUNT_ATTRIBUTE,
    ]);
    return activeDtcs;
  }

  // Checks if the current DTC is active, if it is it will add the new active DTC to the activeDtcs array in place.
  function addActiveDtcToArray(
    activeDtcs: ActiveDtc[],
    attributes: Attribute[],
    dtcAttributeTuple: [string, string, string]
  ): void {
    const [dtcCodeAttributeCode, dtcDescriptionAttributeCode, dtcCountAttributeCode] = dtcAttributeTuple;
    const dtcCountAttribute = attributes.find((attribute) => attribute.attributeCode === dtcCountAttributeCode);
    // Make sure that the DTC has a count greater than 0, otherwise the DTC is not active.
    if (
      dtcCountAttribute !== undefined &&
      dtcCountAttribute.currentValue !== null &&
      dtcCountAttribute.currentValue !== "0"
    ) {
      const dtcCodeAttribute = attributes.find((attribute) => attribute.attributeCode === dtcCodeAttributeCode);
      let codeValue = "UNKNOWN";
      if (dtcCodeAttribute !== undefined && dtcCodeAttribute.currentValue !== null) {
        codeValue = dtcCodeAttribute.currentValue;
      }
      const dtcDescriptionAttribute = attributes.find(
        (attribute) => attribute.attributeCode === dtcDescriptionAttributeCode
      );
      let descriptionValue = "UNKNOWN";
      if (dtcDescriptionAttribute !== undefined && dtcDescriptionAttribute.currentValue !== null) {
        descriptionValue = dtcDescriptionAttribute.currentValue;
      }
      const activeDtc = {
        code: codeValue,
        description: descriptionValue,
        count: dtcCountAttribute.currentValue,
        dateUtc: dtcCountAttribute.currentValueUtc,
      };
      activeDtcs.push(activeDtc);
    }
  }

  if (activeDtcs.length > 0) {
    return (
      <table data-test="active-dtcs-body" className={`${styles.table} table mb-0 pb-0`}>
        <thead>
          <tr>
            <th className={styles.th}>Code</th>
            <th className={styles.th}>Description</th>
            <th className={styles.th}>Time</th>
            <th className={styles.th}>Count</th>
          </tr>
        </thead>
        <tbody>
          {activeDtcs.map((activeDtc, i) => (
            <tr key={i}>
              <td>{activeDtc.code}</td>
              <td>{activeDtc.description}</td>
              <td>
                {activeDtc === null || activeDtc.dateUtc === null ? "N/A" : formatDateShortLocal(activeDtc.dateUtc)}
              </td>
              <td>{activeDtc === null || activeDtc.count === null ? "N/A" : activeDtc.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  } else {
    return <div className={styles.cardMessage}>No Active DTCs found.</div>;
  }
}

ActiveDtcCardBody.propTypes = {
  attributes: PropTypes.array.isRequired,
};

interface Props {
  attributes: Attribute[];
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  connectedRegAttributeId: number | null;
  connectedAttribute?: Attribute | null;
  unitShortName: string | null;
  unitLongName: string | null;
  icon: string;
  currentValue: string | null;
  currentValueUtc: string | null;
  isHistorical: boolean;
  isGraphable: boolean;
  isStale: boolean;
}

interface ActiveDtc {
  code: string;
  description: string;
  count: string | null;
  dateUtc: string | null;
}
