// --------------------------------------------------------------
// Created On: 2023-07-11
// Author: Zachary Thomas
//
// Last Modified: 2023-07-11
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Return whether the variable is an object that is not an array or null.
export default function isRealObject(variable: unknown): boolean {
  return typeof variable === "object" && !Array.isArray(variable) && variable !== null;
}
