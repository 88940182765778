// --------------------------------------------------------------
// Created On: 2021-10-01
// Author: Zachary Thomas
//
// Last Modified: 2024-01-29
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./AssociatedAssetgroupItem.module.scss";

// A single associated asset group in a threshold management modal.
export default function AssociatedAssetgroupItem(props: Props): Component {
  return (
    <Fragment>
      <div className={`${styles.body} px-4 py-2`}>{props.name}</div>
    </Fragment>
  );
}

AssociatedAssetgroupItem.propTypes = {
  assetgroupId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

interface Props {
  assetgroupId: number;
  name: string;
}
