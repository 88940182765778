// --------------------------------------------------------------
// Created On: 2023-02-19
// Author: Zachary Thomas
//
// Last Modified: 2024-02-02
// Modified By: Zachary Thomas
//
// Copyright 2023 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import userIsSuperAdmin from "../../../../utilities/userIsSuperAdmin";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser } from "../../../../redux/selectors";
import { setCurrentUser } from "../../../../redux/actions";
import { Link, useNavigate } from "react-router-dom";

// A single found asset in an asset list.
export default function SearchAssetListRow(props: Props): Component {
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Go to asset page.
  function goToAsset(assetId: number, companyId: number, companyName: string): void {
    // If we are a super admin, switch to the specified company.
    if (userIsSuperAdmin()) {
      dispatch(
        setCurrentUser(
          currentUser.userId,
          currentUser.userName,
          currentUser.emailAddress,
          companyId,
          companyName,
          false,
          currentUser.permissions,
          currentUser.isPackager
        )
      );
    }
    // Go to the selected assets details page.
    navigate(`/asset/${assetId}/data`);
  }

  return (
    <tr className="selectable-container" onClick={() => goToAsset(props.assetId, props.companyId, props.companyName)}>
      {props.crossCompany && (
        <td>
          <Link to={`/asset/${props.assetId}/data`} className="router-link">
            {props.companyName}
          </Link>
        </td>
      )}
      <td>
        <Link to={`/asset/${props.assetId}/data`} className="router-link">
          {props.name}
        </Link>
      </td>
      <td>
        <Link to={`/asset/${props.assetId}/data`} className="router-link">
          {props.nickname}
        </Link>
      </td>
      <td className="d-none d-xl-table-cell">
        <Link to={`/asset/${props.assetId}/data`} className="router-link">
          {props.productType}
        </Link>
      </td>
      <td className="d-none d-lg-table-cell">
        <Link to={`/asset/${props.assetId}/data`} className="router-link">
          {props.productModel}
        </Link>
      </td>
      <td className="d-none d-md-table-cell">
        <Link to={`/asset/${props.assetId}/data`} className="router-link">
          {props.productIdentifier}
        </Link>
      </td>
    </tr>
  );
}

SearchAssetListRow.propTypes = {
  assetId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  crossCompany: PropTypes.bool.isRequired,
};

interface Props {
  assetId: number;
  name: string;
  nickname: string;
  productType: string;
  productModel: string;
  productIdentifier: string;
  companyId: number;
  companyName: string;
  crossCompany: boolean;
}
