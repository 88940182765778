// --------------------------------------------------------------
// Created On: 2024-03-28
// Author: Zachary Thomas
//
// Last Modified: 2024-08-05
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

// This is an input field that accepts phone numbers.
export default function PhoneNumberInput(props: Props): Component {
  // Update the international calling code and the base phone number.
  function handlePhoneNumber(phone: string, meta: Meta): void {
    const callingCode = meta.country.dialCode;
    props.onChangeCallingCode(callingCode);
    if (phone.startsWith(`+${callingCode}`)) {
      const phoneNumberWithoutCallingCode = phone.slice(1 + callingCode.length);
      props.onChangePhoneNumber(phoneNumberWithoutCallingCode);
    }
  }

  return (
    <div>
      <label className={`${props.labelClassName}`}>{props.labelText}</label>
      <PhoneInput
        placeholder="Enter phone number..."
        value={`+${props.callingCode}${props.phoneNumber}`}
        defaultCountry="us"
        disableDialCodeAndPrefix
        onChange={(phone, meta) => handlePhoneNumber(phone, meta)}
      />
    </div>
  );
}

PhoneNumberInput.propTypes = {
  labelClassName: PropTypes.string,
  phoneNumber: PropTypes.string.isRequired,
  callingCode: PropTypes.string.isRequired,
  onChangePhoneNumber: PropTypes.func.isRequired,
  onChangeCallingCode: PropTypes.func.isRequired,
  labelText: PropTypes.string.isRequired,
};

interface Props {
  labelText: string;
  labelClassName?: string;
  phoneNumber: string;
  callingCode: string;
  onChangePhoneNumber: (phoneNumber: string) => void;
  onChangeCallingCode: (callingCode: string) => void;
}

interface Meta {
  country: Country;
}

interface Country {
  dialCode: string;
  name: string;
}
