// --------------------------------------------------------------
// Created On: 2023-02-21
// Author: Zachary Thomas
//
// Last Modified: 2024-06-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useReducer } from "react";
import Spinner from "../../components/Spinner/Spinner";
import Error500Page from "../Error500Page/Error500Page";
import deepCopy from "../../utilities/deepCopy";
import useApi from "../../hooks/useApi";
import ResourceList from "../../components/ResourceList/ResourceList";
import ViewModal from "./ViewModal/ViewModal";
import { API } from "../../constants/miscellaneous";
import { VIEW_TYPES } from "../../constants/reducerActions";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import styles from "./ManageViewsPage.module.scss";
import ViewListRow from "./ViewListRow/ViewListRow";
import HelpView from "../../components/HelpView/HelpView";

// Page for creating, editing, and deleting views.
export default function ManageViewsPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [attributes, setAttributes] = useState<ViewAttribute[]>([]);
  const [views, dispatch] = useReducer(viewReducer, []);
  const currentUser = useSelector(getCurrentUser);

  // Get a list of views from the API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/view/map`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        dispatch({
          type: VIEW_TYPES.SET_VIEWS,
          payload: {
            views: responseBody.views,
          },
        });
        setAttributes(responseBody.attributes);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // View reducer.
  function viewReducer(state: View[], action: Action): View[] {
    switch (action.type) {
      case VIEW_TYPES.SET_VIEWS: {
        if (action.payload.views !== undefined) {
          return action.payload.views;
        } else {
          return [];
        }
      }

      case VIEW_TYPES.CREATE_VIEW: {
        let stateDeepCopy = deepCopy(state);
        const newView = action.payload.view;
        if (newView !== undefined) {
          // Check if the view already exists before creating them.
          const viewExists = stateDeepCopy.some((view) => view.viewId === newView.viewId);
          if (!viewExists) {
            stateDeepCopy.push(newView);
            stateDeepCopy = sortViews(stateDeepCopy);
          }
        }
        return stateDeepCopy;
      }

      case VIEW_TYPES.UPDATE_VIEW: {
        let stateDeepCopy = deepCopy(state);
        const updatedView = action.payload.view;
        if (updatedView !== undefined) {
          // Find the index of the view.
          const viewIndex = stateDeepCopy.findIndex((view) => view.viewId === updatedView.viewId);

          // Don't continue if we couldn't find the view.
          if (viewIndex === -1) {
            return stateDeepCopy;
          }

          // Replace the current view with the updated one.
          stateDeepCopy.splice(viewIndex, 1, updatedView);

          // Sort the views.
          stateDeepCopy = sortViews(stateDeepCopy);
        }
        return stateDeepCopy;
      }

      case VIEW_TYPES.DELETE_VIEW: {
        const stateDeepCopy = deepCopy(state);
        const viewId = action.payload.viewId;

        if (viewId !== undefined) {
          // Find the index of the view.
          const viewIndex = stateDeepCopy.findIndex((view) => view.viewId === viewId);

          // Don't continue if we couldn't find the view.
          if (viewIndex === -1) {
            return stateDeepCopy;
          }

          // Delete the current view.
          stateDeepCopy.splice(viewIndex, 1);
        }
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  // Sort views.
  function sortViews(views: View[]): View[] {
    return views.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      <div className="row">
        {/* View management. */}
        <div className={`${styles.list} col-12 col-md mx-0`}>
          <ResourceList
            resourceNameSingular="View"
            resourceNamePlural="Views"
            resourceArticle="a"
            headerButtonLabel="Create View"
            headerButtonLabelSmall="Create"
            headerButtonUserPermissions={[[]]}
            resourceIdKey="viewId"
            resourcePriorityKey="shared"
            resources={views}
            resourceRow={ViewListRow}
            helpModal={<HelpView />}
            filterKeys={["name"]}
            loading={loading}
            onClickHeaderButton={() => setSelectedId(0)}
            onSelect={(selectedId) => setSelectedId(selectedId)}
          />

          {/* View modal for editing views. */}
          {selectedId >= 0 && (
            <ViewModal
              isCreatingNewRecord={selectedId === 0}
              viewId={selectedId}
              attributes={attributes}
              onClose={() => setSelectedId(-1)}
              onAction={(action) => dispatch(action)}
            />
          )}

          <datalist id="datalist-attributes">
            {attributes.map((attribute) => (
              <option key={attribute.attributeId} value={attribute.name} />
            ))}
          </datalist>
        </div>
      </div>
    </div>
  );
}

interface ResponseBody {
  views: View[];
  attributes: ViewAttribute[];
}

interface ViewAttribute {
  attributeId: number;
  name: string;
  code: string;
  units: string | null;
  isBoolean: boolean;
  isHighlightable: boolean;
}

interface View {
  viewId: number;
  name: string;
  shared: boolean;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  views?: View[];
  view?: View;
  viewId?: number;
}
