// --------------------------------------------------------------
// Created On: 2023-03-13
// Author: Zachary Thomas
//
// Last Modified: 2024-03-12
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import { MS_PER_MINUTE } from "../../constants/miscellaneous";

// Convert a local timezone ISO 8601 string to UTC ISO 8601.
export default function isoLocalToIsoUtc(iso: string): string {
  if (!iso.includes("Z") && !iso.includes("+")) {
    iso += "Z";
  }
  const currentDate = new Date(iso);
  const timeZoneOffset = currentDate.getTimezoneOffset();
  const localCurrentDate = new Date(currentDate.getTime() + timeZoneOffset * MS_PER_MINUTE);
  return localCurrentDate.toISOString();
}
