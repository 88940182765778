// --------------------------------------------------------------
// Created On: 2023-02-23
// Author: Zachary Thomas
//
// Last Modified: 2023-03-06
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Return the standard deviation given an array of numbers.
export default function calculateStandardDeviation(numbers: number[]): number {
  const n = numbers.length;
  const mean = numbers.reduce((a, b) => a + b) / n;
  return Math.sqrt(numbers.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
}
