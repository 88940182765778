// --------------------------------------------------------------
// Created On: 2023-12-11
// Author: Zachary Thomas
//
// Last Modified: 2024-03-06
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./RentingAssetListRow.module.scss";

// A single asset that is being rented by the current company.
export default function RentingAssetListRow(props: Props): Component {
  return (
    <div className="p-2" data-test="rental-list-row">
      <span>Asset &apos;</span>

      <b>{props.name}</b>

      <span>{props.isPendingApproval ? "' is being sent from company '" : "' is rented from company '"}</span>

      <b>{props.renterCompanyName}</b>

      <span>&apos;</span>

      {props.isPendingApproval && (
        <span data-test="pending-rental-marker" className={styles.tag}>
          Pending
        </span>
      )}
    </div>
  );
}

RentingAssetListRow.propTypes = {
  assetId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  renterCompanyName: PropTypes.string.isRequired,
  isPendingApproval: PropTypes.bool.isRequired,
};

interface Props {
  assetId: number;
  name: string;
  renterCompanyName: string;
  isPendingApproval: boolean;
}
