// --------------------------------------------------------------
// Created On: 2024-08-23
// Author: KC Willard
//
// Last Modified: 2024-09-10
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./Breadcrumbs.module.scss";

// A series of interactive breadcrumbs that supports navigation and highlights the current section.
export default function Breadcrumbs(props: Props) {
  return (
    <p className={styles.body}>
      {props.breadcrumbs.map((breadcrumb, i) => (
        <Fragment key={i}>
          <span
            className={`${styles.breadcrumb} ${
              props.activePageNumber === breadcrumb.pageNumber ? styles.selectedBreadcrumb : ""
            }`}
            onClick={() => props.onClick(breadcrumb.pageNumber)}
          >
            {breadcrumb.title}
          </span>
          {i + 1 < props.breadcrumbs.length && <span className="mx-2">&gt;</span>}
        </Fragment>
      ))}
    </p>
  );
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  activePageNumber: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

interface Props {
  breadcrumbs: Breadcrumb[];
  activePageNumber: number;
  onClick: (pageNumber: number) => void;
}

interface Breadcrumb {
  title: string;
  pageNumber: number;
}
