// --------------------------------------------------------------
// Created On: 2022-05-16
// Author: Zachary Thomas
//
// Last Modified: 2023-05-01
// Modified By: Zachary Thomas
//
// Copyright 2022 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import AlertOverview from "./AlertOverview/AlertOverview";
import AlertTable from "./AlertTable/AlertTable";
import useApi from "../../hooks/useApi";
import Error500Page from "../Error500Page/Error500Page";
import { API } from "../../constants/miscellaneous";
import { useDispatch } from "react-redux";
import { setAlerts } from "../../redux/actions";

// Page that displays a list of all of the alerts for the current user.
export default function AlertPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const dispatch = useDispatch();

  // Get alert data from API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/alertmessage/all`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        dispatch(setAlerts(responseBody.alertMessages));
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
        console.error("Internal server error. Unable to retrieve alert messages.");
      }
      setLoading(false);
    },
    []
  );

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      <AlertOverview />

      <AlertTable loading={loading} />
    </div>
  );
}

interface AlertMessage {
  alertMessageId: number;
  alertCode: string;
  date: string;
  message: string;
  unread: boolean;
  urgency: string;
}

interface ResponseBody {
  alertMessages: AlertMessage[];
}
