// --------------------------------------------------------------
// Created On: 2021-05-14
// Author: Zachary Thomas
//
// Last Modified: 2023-03-12
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./TextBlurb.module.scss";

// Title, paragraph, and icon text.
export default function TextBlurb(props: Props): Component {
  return (
    <div className={`${styles.body} mx-auto`}>
      <h3>{props.title}</h3>
      <p className="mb-3 mx-auto">{props.paragraph}</p>
      <i className={`fa fa-fw fa-${props.icon} fa-3x`} />
    </div>
  );
}

TextBlurb.propTypes = {
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

interface Props {
  title: string;
  paragraph: string;
  icon: string;
}
