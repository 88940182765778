// --------------------------------------------------------------
// Created On: 2023-08-30
// Author: Zachary Thomas
//
// Last Modified: 2023-09-08
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./LightIcon.module.scss";

// Light icon that is on/off and green/red depending on the props passed to it.
export default function LightIcon(props: Props): Component {
  // Get the correct light color style.
  function getLightStyle(): string {
    if (props.isOn && props.color === "green") {
      return styles.isGreen;
    } else if (props.isOn && props.color === "red") {
      return styles.isRed;
    } else {
      return styles.isOff;
    }
  }

  return (
    <span className={styles.iconStack}>
      <i className={`${styles.iconStackTop} ${getLightStyle()} fa fa-fw fa-life-saver`} />
      <i className={`${styles.iconStackBottom} ${getLightStyle()} fa fa-fw fa-circle`} />
    </span>
  );
}

LightIcon.propTypes = {
  isOn: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
};

interface Props {
  isOn: boolean;
  color: "green" | "red";
}
