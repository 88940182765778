// --------------------------------------------------------------
// Created On: 2023-11-27
// Author: Zachary Thomas
//
// Last Modified: 2024-02-22
// Modified By: Zachary Thomas
//
// Copyright 2023 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import useApi from "../../../hooks/useApi";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import GeofenceThresholdControl from "./GeofenceThresholdControl/GeofenceThresholdControl";
import CreateGeofenceThreshold from "./CreateGeofenceThreshold/CreateGeofenceThreshold";
import Spinner from "../../../components/Spinner/Spinner";
import Error from "../../../components/Error/Error";
import apiRequest from "../../../utilities/api/apiRequest";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import AlertEscalationModal from "../AlertEscalationModal/AlertEscalationModal";
import PropTypes from "prop-types";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import AlertThresholdModifyLogList from "../AlertThresholdModifyLogList/AlertThresholdModifyLogList";
import { API } from "../../../constants/miscellaneous";
import styles from "./GeofenceThresholdsModal.module.scss";

// Modal for updating geo-fence threshold information.
export default function GeofenceThresholdsModal(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirmExit, setShowConfirmExit] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [modificationLogs, setModificationLogs] = useState<ModificationLog[]>([]);
  const [enterThresholdExists, setEnterThresholdExists] = useState<boolean>(false);
  const [enterThresholdId, setEnterThresholdId] = useState<number>(0);
  const [enterAction, setEnterAction] = useState<"create" | "update" | "delete" | null>(null);
  const [enterAlertIsEnabled, setEnterAlertIsEnabled] = useState<boolean>(false);
  const [enterRetryCount, setEnterRetryCount] = useState<number>(0);
  const [enterTimeoutInterval, setEnterTimeoutInterval] = useState<number>(1);
  const [enterUsers, setEnterUsers] = useState<AssignedUser[]>([]);
  const [exitThresholdExists, setExitThresholdExists] = useState<boolean>(false);
  const [exitThresholdId, setExitThresholdId] = useState<number>(0);
  const [exitAction, setExitAction] = useState<"create" | "update" | "delete" | null>(null);
  const [exitAlertIsEnabled, setExitAlertIsEnabled] = useState<boolean>(false);
  const [exitRetryCount, setExitRetryCount] = useState<number>(0);
  const [exitTimeoutInterval, setExitTimeoutInterval] = useState<number>(1);
  const [exitUsers, setExitUsers] = useState<AssignedUser[]>([]);
  const [selectedUserEscalation, setSelectedUserEscalation] = useState<"enter" | "exit" | null>(null);
  const currentUser = useSelector(getCurrentUser);

  // Get alert threshold mapping data from API.
  useApi(
    () => {
      if (props.assetgroupId > 0 && props.showModal) {
        setLoading(true);
        return true;
      } else {
        setLoading(false);
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/assetgroup/${props.assetgroupId}/assetgroupgeofencethreshold`,
    },
    async (response: Response, responseBody: GetResponseBody) => {
      if (response.ok && responseBody) {
        setModificationLogs(responseBody.userModificationLogs);

        // Get asset enters geo-fence alert threshold information.
        const enterThreshold = responseBody.alertThresholds.find(
          (alertThreshold) => alertThreshold.alertOnAssetExit === false
        );
        if (enterThreshold !== undefined) {
          setEnterThresholdId(enterThreshold.assetgroupGeofenceThresholdId);
          setEnterThresholdExists(true);
          setEnterAction(null);
          setEnterAlertIsEnabled(true);
          setEnterRetryCount(enterThreshold.escalationRetryCount);
          setEnterTimeoutInterval(enterThreshold.acknowledgementTimeoutIntervals);
          setEnterUsers(enterThreshold.users);
        } else {
          setEnterThresholdId(0);
          setEnterThresholdExists(false);
          setEnterAction(null);
          setEnterAlertIsEnabled(false);
          setEnterRetryCount(0);
          setEnterTimeoutInterval(1);
          setEnterUsers([]);
        }

        // Get asset exits geo-fence alert threshold information.
        const exitThreshold = responseBody.alertThresholds.find(
          (alertThreshold) => alertThreshold.alertOnAssetExit === true
        );
        if (exitThreshold !== undefined) {
          setExitThresholdId(exitThreshold.assetgroupGeofenceThresholdId);
          setExitThresholdExists(true);
          setExitAction(null);
          setExitAlertIsEnabled(true);
          setExitRetryCount(exitThreshold.escalationRetryCount);
          setExitTimeoutInterval(exitThreshold.acknowledgementTimeoutIntervals);
          setExitUsers(exitThreshold.users);
        } else {
          setExitThresholdId(0);
          setExitThresholdExists(false);
          setExitAction(null);
          setExitAlertIsEnabled(false);
          setExitRetryCount(0);
          setExitTimeoutInterval(1);
          setExitUsers([]);
        }

        setErrorMessage("");
      } else {
        setErrorMessage("Internal server error. Unable to get geo-fence thresholds.");
      }
      setLoading(false);
    },
    [props.assetgroupId, props.showModal]
  );

  // Create an alert threshold.
  // The CRUD action we will perform is based on if an alert threshold already existed before we opened the modal.
  function createAlertThreshold(mode: "enter" | "exit"): void {
    if (mode === "enter") {
      if (enterThresholdExists) {
        setEnterAction("update");
      } else {
        setEnterAction("create");
      }
      setEnterAlertIsEnabled(true);
      setEnterRetryCount(0);
      setEnterTimeoutInterval(1);
      setEnterUsers([]);
    } else if (mode === "exit") {
      if (exitThresholdExists) {
        setExitAction("update");
      } else {
        setExitAction("create");
      }
      setExitAlertIsEnabled(true);
      setExitRetryCount(0);
      setExitTimeoutInterval(1);
      setExitUsers([]);
    }
  }

  // Remove an alert threshold.
  // The CRUD action we will perform is based on if an alert threshold already existed before we opened the modal.
  function removeAlertThreshold(mode: "enter" | "exit"): void {
    if (mode === "enter") {
      if (enterThresholdExists) {
        setEnterAction("delete");
      } else {
        setEnterAction(null);
      }
      setEnterAlertIsEnabled(false);
      setEnterRetryCount(0);
      setEnterTimeoutInterval(1);
      setEnterUsers([]);
    } else if (mode === "exit") {
      if (exitThresholdExists) {
        setExitAction("delete");
      } else {
        setExitAction(null);
      }
      setExitAlertIsEnabled(false);
      setExitRetryCount(0);
      setExitTimeoutInterval(1);
      setExitUsers([]);
    }
  }

  // Update assigned users, timeout interval, and retry count for an alert threshold.
  // The CRUD action we will perform is based on if an alert threshold already existed before we opened the modal.
  function updateThresholdSettings(
    users: AssignedUser[],
    timeoutInterval: number,
    retryCount: number,
    mode: "enter" | "exit"
  ): void {
    setSelectedUserEscalation(null);
    if (mode === "enter") {
      if (enterThresholdExists) {
        setEnterAction("update");
      } else {
        setEnterAction("create");
      }
      setEnterUsers(users);
      setEnterTimeoutInterval(timeoutInterval);
      setEnterRetryCount(retryCount);
    } else if (mode === "exit") {
      if (exitThresholdExists) {
        setExitAction("update");
      } else {
        setExitAction("create");
      }
      setExitUsers(users);
      setExitTimeoutInterval(timeoutInterval);
      setExitRetryCount(retryCount);
    }
  }

  // Checks if the alert thresholds are valid.
  function thresholdsAreValid(): boolean {
    if ((enterAlertIsEnabled && enterUsers.length === 0) || (exitAlertIsEnabled && exitUsers.length === 0)) {
      setErrorMessage(`Each alert threshold must have at least one user to deliver alerts to.`);
      return false;
    } else {
      return true;
    }
  }

  // Save changes. The API calls required to do this are based on the calculated action for both enter and exit alert thresholds.
  async function saveChanges(): Promise<void> {
    if (thresholdsAreValid()) {
      let errorOccurred = false;

      setLoading(true);
      // Perform the specified action for the enter threshold.
      const enterRequestBody = {
        alertOnAssetExit: false,
        escalationRetryCount: enterRetryCount,
        acknowledgementTimeoutIntervals: enterTimeoutInterval,
        users: enterUsers,
      };

      if (enterAction === "create") {
        const [response] = (await apiRequest(
          `${API}/company/${currentUser.companyId}/assetgroup/${props.assetgroupId}/assetgroupgeofencethreshold`,
          "POST",
          enterRequestBody
        )) as [Response, ResponseBody];

        if (!response.ok) {
          errorOccurred = true;
          setErrorMessage("Internal server error. Unable to update alert thresholds.");
        }
      } else if (enterAction === "update") {
        const [response] = (await apiRequest(
          `${API}/company/${currentUser.companyId}/assetgroup/${props.assetgroupId}/assetgroupgeofencethreshold/${enterThresholdId}`,
          "PUT",
          enterRequestBody
        )) as [Response, ResponseBody];

        if (!response.ok) {
          errorOccurred = true;
          setErrorMessage("Internal server error. Unable to update alert thresholds.");
        }
      } else if (enterAction === "delete") {
        const [response] = (await apiRequest(
          `${API}/company/${currentUser.companyId}/assetgroup/${props.assetgroupId}/assetgroupgeofencethreshold/${enterThresholdId}`,
          "DELETE",
          null
        )) as [Response, ResponseBody];

        if (!response.ok) {
          errorOccurred = true;
          setErrorMessage("Internal server error. Unable to update alert thresholds.");
        }
      }

      // Perform the specified action for the exit threshold.
      const exitRequestBody = {
        alertOnAssetExit: true,
        escalationRetryCount: exitRetryCount,
        acknowledgementTimeoutIntervals: exitTimeoutInterval,
        users: exitUsers,
      };

      if (exitAction === "create") {
        const [response] = (await apiRequest(
          `${API}/company/${currentUser.companyId}/assetgroup/${props.assetgroupId}/assetgroupgeofencethreshold`,
          "POST",
          exitRequestBody
        )) as [Response, ResponseBody];

        if (!response.ok) {
          errorOccurred = true;
          setErrorMessage("Internal server error. Unable to update alert thresholds.");
        }
      } else if (exitAction === "update") {
        const [response] = (await apiRequest(
          `${API}/company/${currentUser.companyId}/assetgroup/${props.assetgroupId}/assetgroupgeofencethreshold/${exitThresholdId}`,
          "PUT",
          exitRequestBody
        )) as [Response, ResponseBody];

        if (!response.ok) {
          errorOccurred = true;
          setErrorMessage("Internal server error. Unable to update alert thresholds.");
        }
      } else if (exitAction === "delete") {
        const [response] = (await apiRequest(
          `${API}/company/${currentUser.companyId}/assetgroup/${props.assetgroupId}/assetgroupgeofencethreshold/${exitThresholdId}`,
          "DELETE",
          null
        )) as [Response, ResponseBody];

        if (!response.ok) {
          errorOccurred = true;
          setErrorMessage("Internal server error. Unable to update alert thresholds.");
        }
      }

      setLoading(false);

      if (!errorOccurred) {
        discardChanges();
      }
    }
  }

  // Exit modal if no changes have been made. Otherwise prompt user.
  function exitModal(): void {
    if (enterAction === null && exitAction === null) {
      discardChanges();
    } else {
      setShowConfirmExit(true);
    }
  }

  // Exit without saving changes.
  function discardChanges(): void {
    props.onClose();
    setShowConfirmExit(false);
    setEnterAction(null);
    setEnterAlertIsEnabled(false);
    setEnterRetryCount(0);
    setEnterTimeoutInterval(1);
    setEnterUsers([]);
    setExitAction(null);
    setExitAlertIsEnabled(false);
    setExitRetryCount(0);
    setExitTimeoutInterval(1);
    setExitUsers([]);
    setErrorMessage("");
  }

  return (
    <Fragment>
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="xl"
        animation
        centered
      >
        <ModalHeader>
          <h5 className="font-weight-bold">{props.name} Thresholds</h5>
        </ModalHeader>

        <ModalBody>
          {enterAlertIsEnabled ? (
            <GeofenceThresholdControl
              mode="enter"
              onRemoveThreshold={() => removeAlertThreshold("enter")}
              onSelectUsers={() => setSelectedUserEscalation("enter")}
            />
          ) : (
            <CreateGeofenceThreshold mode="enter" onAddThreshold={() => createAlertThreshold("enter")} />
          )}

          {exitAlertIsEnabled ? (
            <GeofenceThresholdControl
              mode="exit"
              onRemoveThreshold={() => removeAlertThreshold("exit")}
              onSelectUsers={() => setSelectedUserEscalation("exit")}
            />
          ) : (
            <CreateGeofenceThreshold mode="exit" onAddThreshold={() => createAlertThreshold("exit")} />
          )}

          <AlertThresholdModifyLogList modificationLogs={modificationLogs} />

          <Error message={errorMessage} />
        </ModalBody>

        <ModalFooter>
          <button className="btn btn-primary" type="button" onClick={() => saveChanges()}>
            Update Thresholds
          </button>

          <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>

      <AlertEscalationModal
        showModal={selectedUserEscalation === "enter"}
        unassignedUsers={props.unassignedUsers}
        assignedUsers={enterUsers}
        retryCount={enterRetryCount}
        timeoutInterval={enterTimeoutInterval}
        isRental={false}
        onChange={(users, timeoutInterval, retryCount) =>
          updateThresholdSettings(users, timeoutInterval, retryCount, "enter")
        }
        onClose={() => setSelectedUserEscalation(null)}
      />

      <AlertEscalationModal
        showModal={selectedUserEscalation === "exit"}
        unassignedUsers={props.unassignedUsers}
        assignedUsers={exitUsers}
        retryCount={exitRetryCount}
        timeoutInterval={exitTimeoutInterval}
        isRental={false}
        onChange={(users, timeoutInterval, retryCount) =>
          updateThresholdSettings(users, timeoutInterval, retryCount, "exit")
        }
        onClose={() => setSelectedUserEscalation(null)}
      />

      <SaveChangesModal
        showModal={props.showModal && showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => discardChanges()}
      />
    </Fragment>
  );
}

GeofenceThresholdsModal.propTypes = {
  assetgroupId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  unassignedUsers: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

interface Props {
  assetgroupId: number;
  name: string;
  showModal: boolean;
  unassignedUsers: UnassignedUser[];
  onClose: () => void;
}

interface GetResponseBody {
  alertThresholds: Threshold[];
  userModificationLogs: ModificationLog[];
}

interface ModificationLog {
  username: string;
  emailAddress: string;
  lastModifiedUtc: string;
  alertOnAssetExit: boolean;
}

interface ResponseBody {
  message: string;
}

interface Threshold {
  assetgroupGeofenceThresholdId: number;
  alertOnAssetExit: boolean;
  escalationRetryCount: number;
  acknowledgementTimeoutIntervals: number;
  users: AssignedUser[];
}

interface UnassignedUser {
  userId: number;
  emailAddress: string;
  name: string;
  hasPhoneNumber: boolean;
}

interface AssignedUser {
  userId: number;
  emailAddress: string;
  name: string;
  includeEmail: boolean;
  includeText: boolean;
  includeCall: boolean;
  escalationLevel: number;
  hasPhoneNumber: boolean;
}
