// --------------------------------------------------------------
// Created On: 2023-02-17
// Author: Zachary Thomas
//
// Last Modified: 2024-04-23
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import { PRIVACY_POLICY, EULA } from "../../constants/miscellaneous";
import Card from "../../components/Card/Card";
import styles from "./PolicyPage.module.scss";
import DangerousHtmlContent from "./DangerousHtmlContent/DangerousHtmlContent";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Page for viewing policy PDFs.
export default function PolicyPage(props: Props): Component {
  const alternateDisplayUrl = getAlternateDisplayUrl(props.policyType, props.displayType);
  const alternatePolicyUrl = getAlternatePolicyUrl(props.policyType, props.displayType);

  // Get the correct URL for the alternate display option.
  function getAlternateDisplayUrl(policyType: "EULA" | "Privacy Policy", displayType: "text" | "pdf"): string {
    if (policyType === "EULA" && displayType === "text") {
      return "/eula-viewer";
    } else if (policyType === "Privacy Policy" && displayType === "text") {
      return "/privacy-policy-viewer";
    } else if (policyType === "EULA" && displayType === "pdf") {
      return "/eula-text";
    } else {
      return "/privacy-policy-text";
    }
  }

  // Get the correct URL for the alternate policy type.
  function getAlternatePolicyUrl(policyType: "EULA" | "Privacy Policy", displayType: "text" | "pdf"): string {
    if (policyType === "EULA" && displayType === "text") {
      return "/privacy-policy-text";
    } else if (policyType === "Privacy Policy" && displayType === "text") {
      return "/eula-text";
    } else if (policyType === "EULA" && displayType === "pdf") {
      return "/privacy-policy-viewer";
    } else {
      return "/eula-viewer";
    }
  }

  return (
    <div className={`${styles.body} p-4`} data-test="policy-card">
      <Card title={props.policyType} fullWidth>
        <div className={`${styles.buttonHeader} pt-1 pb-4`}>
          <Link className="m-2" to={alternateDisplayUrl}>
            <button className="btn btn-primary" data-test="switch-legal-display-button">
              {props.displayType === "text" ? "Switch to PDF Viewer" : "Switch to Plain Text"}
            </button>
          </Link>
          <Link className="m-2" to={alternatePolicyUrl}>
            <button className="btn btn-primary" data-test="switch-legal-policy-button">
              {props.policyType === "EULA" ? "View Privacy Policy" : "View EULA"}
            </button>
          </Link>
        </div>

        {props.displayType === "pdf" ? (
          <div className={styles.cardInterior}>
            <object
              data={props.policyType === "EULA" ? EULA : PRIVACY_POLICY}
              type="application/pdf"
              width="100%"
              height="100%"
            >
              <p>PDF URL: &qt;{props.policyType === "EULA" ? EULA : PRIVACY_POLICY}&qt;</p>
            </object>
          </div>
        ) : (
          <Fragment>
            {props.policyType === "EULA" ? (
              <DangerousHtmlContent fileUrl="/legal/EULA.html" />
            ) : (
              <DangerousHtmlContent fileUrl="/legal/Privacy_Policy.html" />
            )}
          </Fragment>
        )}
      </Card>
    </div>
  );
}

PolicyPage.propTypes = {
  policyType: PropTypes.string.isRequired,
  displayType: PropTypes.string.isRequired,
};

interface Props {
  policyType: "EULA" | "Privacy Policy";
  displayType: "text" | "pdf";
}
