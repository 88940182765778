// --------------------------------------------------------------
// Created On: 2021-11-08
// Author: Zachary Thomas
//
// Last Modified: 2024-09-10
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useState } from "react";
import useApi from "../../../hooks/useApi";
import PropTypes from "prop-types";
import {
  API,
  CREATE_MODE,
  CUSTOMER_SERVICE_OVERRIDE_MESSAGE,
  CUSTOMER_SERVICE_PHONE,
  CUSTOMER_SERVICE_EMAIL,
} from "../../../constants/miscellaneous";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import Spinner from "../../../components/Spinner/Spinner";
import InfoModal from "../../../components/InfoModal/InfoModal";
import HelpAsset from "../../../components/HelpAsset/HelpAsset";
import AssetModal from "../../../components/AssetModal/AssetModal";
import userHasPermission from "../../../utilities/userHasPermission";
import { CREATE_ASSETS_PERMISSION } from "../../../constants/permissions";
import styles from "./EmptyDashboardHelp.module.scss";

// Overlay and message for users who visit an empty data dashboard.
export default function EmptyDashboardHelp(props: Props): Component {
  const currentUser = useSelector(getCurrentUser);
  const [loading, setLoading] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [controllers, setControllers] = useState<Controller[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);
  const [productManufacturers, setProductManufacturers] = useState<string[]>([]);
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [analogSensors, setAnalogSensors] = useState<AnalogSensor[]>([]);
  const [digitalSensors, setDigitalSensors] = useState<DigitalSensor[]>([]);
  const [monitoringDevices, setMonitoringDevices] = useState<SimpleMonitoringDevice[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);

  // Get asset supporting data.
  useApi(
    () => {
      if (userHasPermission([[CREATE_ASSETS_PERMISSION]])) {
        setLoading(true);
        return true;
      } else {
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/device`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        // Add the "None" controller to the controller list.
        const noController = {
          controllerId: 0,
          controllerModel: null,
          manufacturerCompanyCode: null,
          name: "",
        };
        setControllers([noController, ...responseBody.controllers]);
        setDeviceTypes(responseBody.deviceTypes);
        setProductManufacturers(responseBody.productManufacturers);
        setProductTypes(responseBody.productTypes);
        setMonitoringDevices(responseBody.monitoringDevices);
        setAnalogSensors(responseBody.analogSensors);
        setDigitalSensors(responseBody.digitalSensors);
        setTemplates(responseBody.templates);
      }
      setLoading(false);
    },
    []
  );

  return (
    <Fragment>
      <Spinner loading={loading} />

      <div className={styles.overlay} />

      <div data-test="empty-dashboard-popup" className={styles.body}>
        {userHasPermission([[CREATE_ASSETS_PERMISSION]]) ? (
          <div>
            <p>You do not have any assets. Would you like to create one?</p>

            <div>
              <InfoModal
                showModal={showInfoModal}
                title="What is an Asset?"
                darkBackground={false}
                linkMessage="( What is an asset? )"
                onClick={() => {
                  setShowCreateModal(false);
                  setShowInfoModal(true);
                }}
                onClose={() => setShowInfoModal(false)}
              >
                <HelpAsset />
              </InfoModal>
            </div>

            <button
              className="btn btn-primary mt-5 mb-2"
              onClick={() => {
                setShowCreateModal(true);
                setShowInfoModal(false);
              }}
            >
              Create Asset
            </button>

            <AssetModal
              assetId={0}
              mode={CREATE_MODE}
              showModal={showCreateModal}
              deviceTypes={deviceTypes}
              controllers={controllers}
              productTypes={productTypes}
              productManufacturers={productManufacturers}
              monitoringDevices={monitoringDevices}
              analogSensors={analogSensors}
              digitalSensors={digitalSensors}
              templates={templates}
              onClose={() => setShowCreateModal(false)}
              onAction={() => props.onCreateAsset()}
            />
          </div>
        ) : (
          `It doesn't look like you have permission to view any assets yet.` +
          ` If you think this is an error, please contact one of the '${currentUser.companyName}'` +
          ` administrators. If you are unable to contact an administrator, ` +
          `${
            CUSTOMER_SERVICE_OVERRIDE_MESSAGE === null
              ? `please contact customer service by email at ${CUSTOMER_SERVICE_EMAIL}` +
                ` or by phone at ${CUSTOMER_SERVICE_PHONE}.`
              : `${CUSTOMER_SERVICE_OVERRIDE_MESSAGE}`
          }`
        )}
      </div>
    </Fragment>
  );
}

EmptyDashboardHelp.propTypes = {
  onCreateAsset: PropTypes.func.isRequired,
};

interface Props {
  onCreateAsset: () => void;
}

interface ResponseBody {
  controllers: Controller[];
  deviceTypes: DeviceType[];
  productManufacturers: string[];
  productTypes: ProductType[];
  monitoringDevices: SimpleMonitoringDevice[];
  analogSensors: AnalogSensor[];
  digitalSensors: DigitalSensor[];
  templates: Template[];
}

interface Template {
  assetTemplateId: number;
  name: string;
  isImmutable: boolean;
}

interface DeviceType {
  deviceTypeId: number;
  model: string;
  name: string;
}

interface Controller {
  controllerId: number;
  controllerModel: string | null;
  manufacturerCompanyCode: string | null;
  name: string;
}

interface AnalogSensor {
  analogSensorId: number;
  name: string;
  applications: AnalogApplication[];
}

interface AnalogApplication {
  attributeId: number;
  attributeConnectedId: number;
  name: string;
}

interface DigitalSensor {
  digitalSensorId: number;
  name: string;
  applications: DigitalApplication[];
}

interface DigitalApplication {
  attributeStateId: number;
  attributeFlowId: number;
  attributeAccumulationId: number;
  reportingDescription: string;
}
