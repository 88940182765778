// --------------------------------------------------------------
// Created On: 2022-07-26
// Author: Zachary Thomas
//
// Last Modified: 2022-07-26
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Validate a nickname by ensuring that it is a string that does not match the primary name.
export default function validateNickname(
  nickname: string | null | undefined,
  name: string | null | undefined
): boolean {
  return nickname !== null && nickname !== undefined && nickname.length > 0 && nickname !== name;
}
