// --------------------------------------------------------------
// Created On: 2022-09-22
// Author: Zachary Thomas
//
// Last Modified: 2022-09-22
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Check if the given string is valid ISO 8601 (ex: 2022-05-10T10:30:00).
export default function isoIsValid(iso: string): boolean {
  const dateRegexp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  const match = iso.match(dateRegexp);
  return match !== null;
}
