// --------------------------------------------------------------
// Created On: 2023-02-27
// Author: Zachary Thomas
//
// Last Modified: 2024-07-21
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, Fragment } from "react";
import Modal from "../Modal/Modal";
import PropTypes from "prop-types";
import styles from "./ColorPickerMapMarker.module.scss";

// Interactive color picker. Allows a selection of one color from an array of colors.
// The interactive selector shown as a map marker.
export default function ColorPickerMapMarker(props: Props): Component {
  const [showModal, setShowModal] = useState<boolean>(false);

  // Select a color.
  function selectColor(colorHexCode: string): void {
    setShowModal(false);
    props.onChange(colorHexCode);
  }

  return (
    <Fragment>
      <div className={styles.markerWrapper}>
        <img
          src={`/mapMarkers/mapMarkerGearActive_${props.selectedColor.slice(1)}.png`}
          alt="Color selector for map marker"
          className={styles.marker}
          onClick={() => {
            props.disabled ? setShowModal(false) : setShowModal(true);
          }}
        />
      </div>
      <Modal
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--super-modal-z-index)" }}
        show={showModal}
        onHide={() => setShowModal(false)}
        size="sm"
        centered
      >
        <div className="m-3">
          <div className="row">
            {props.colors.map((color) => (
              <div className="col-4" key={color}>
                <div
                  className={`${styles.selectableColor} ${styles.option}`}
                  style={{ backgroundColor: color }}
                  onClick={() => selectColor(color)}
                />
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

ColorPickerMapMarker.propTypes = {
  colors: PropTypes.array.isRequired,
  selectedColor: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

interface Props {
  colors: string[];
  selectedColor: string;
  disabled?: boolean;
  onChange: (selectedColor: string) => void;
}
