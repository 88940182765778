// --------------------------------------------------------------
// Created On: 2021-11-18
// Author: Zachary Thomas
//
// Last Modified: 2024-08-05
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import Card from "../Card/Card";
import Error from "../Error/Error";
import Success from "../Success/Success";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import useAutoAnimate from "../../hooks/useAutoAnimate";
import PropTypes from "prop-types";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput";
import styles from "./ReviewContent.module.scss";

// A card for reviewing content.
export default function ReviewContent(props: Props): Component {
  const [animationParent] = useAutoAnimate<HTMLDivElement>();

  return (
    <div className={`${styles.container} mx-auto`}>
      <Card title={props.title}>
        <div className="mx-4 py-3" ref={animationParent}>
          {props.successMessage.length === 0 && (
            <Fragment>
              <div className="mb-4">
                {/* Input for entering the current persons name. */}
                {props.requireName && (
                  <div className="form-group mb-3">
                    <label className="mt-2">Name</label>
                    <input
                      data-test="review-content-name-input"
                      className="form-control"
                      value={props.name}
                      onChange={(e) => props.onChangeName(e.target.value)}
                    />
                  </div>
                )}

                {/* Phone number input. */}
                {props.phoneNumber !== undefined && props.callingCode !== undefined && props.callingCode !== null && (
                  <div className="form-group my-3">
                    <PhoneNumberInput
                      data-test="review-content-phone-number-input"
                      phoneNumber={props.phoneNumber || ""}
                      callingCode={props.callingCode}
                      labelText="Phone Number (optional)"
                      onChangePhoneNumber={(phoneNumber) => props.onChangePhoneNumber(phoneNumber)}
                      onChangeCallingCode={(callingCode) => props.onChangeCallingCode(callingCode)}
                    />
                  </div>
                )}

                {/* Forms that show some pre-filled data. */}
                {props.completedForms.map((completedForm, i) => (
                  <div className="form-group my-3" key={i}>
                    <label>{completedForm.key}</label>
                    <input value={completedForm.value} className="form-control" disabled />
                  </div>
                ))}

                {/* Privacy policy agreement. */}
                {props.requirePrivacyPolicy && (
                  <PrivacyPolicy
                    status={props.privacyPolicy}
                    onChange={(status) => props.onChangePrivacyPolicy(status)}
                  />
                )}
              </div>

              {/* Main content body. */}
              <p className={`${styles.body} mt-3 mb-5`}>{props.content}</p>
            </Fragment>
          )}

          <div>
            <Success message={props.successMessage} />
            <Error message={props.errorMessage} />
          </div>

          {/* Buttons for accepting or rejecting a request. */}
          {props.successMessage.length === 0 && (
            <div className="row mt-5 mb-3">
              {props.allowReject && (
                <div className="col text-center">
                  <button
                    type="button"
                    className={`${styles.reviewBtn} btn btn-danger mx-auto`}
                    onClick={() => props.onReject()}
                  >
                    Reject
                  </button>
                </div>
              )}

              <div className="col text-center">
                <button
                  data-test="review-content-accept-button"
                  type="button"
                  className={`${styles.reviewBtn} btn btn-success mx-auto`}
                  onClick={() => props.onAccept()}
                >
                  Accept
                </button>
              </div>
            </div>
          )}

          {props.children}
        </div>
      </Card>
    </div>
  );
}

ReviewContent.propTypes = {
  title: PropTypes.string.isRequired,
  completedForms: PropTypes.array.isRequired,
  content: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  allowReject: PropTypes.bool.isRequired,
  requireName: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
  callingCode: PropTypes.string,
  requirePrivacyPolicy: PropTypes.bool.isRequired,
  privacyPolicy: PropTypes.bool.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangePhoneNumber: PropTypes.func.isRequired,
  onChangeCallingCode: PropTypes.func.isRequired,
  onChangePrivacyPolicy: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  children: PropTypes.any,
};

interface Props {
  title: string;
  completedForms: CompletedForm[];
  content: string;
  successMessage: string;
  errorMessage: string;
  allowReject: boolean;
  requireName: boolean;
  name: string;
  phoneNumber?: string | null;
  callingCode?: string | null;
  requirePrivacyPolicy: boolean;
  privacyPolicy: boolean;
  onChangeName: (name: string) => void;
  onChangePhoneNumber: (phoneNumber: string) => void;
  onChangeCallingCode: (callingCode: string) => void;
  onChangePrivacyPolicy: (status: boolean) => void;
  onReject: () => void;
  onAccept: () => void;
  children?: Component | Component[] | boolean;
}

interface CompletedForm {
  key: string;
  value: string;
}
