// --------------------------------------------------------------
// Created On: 2023-08-16
// Author: Zachary Thomas
//
// Last Modified: 2024-04-22
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import React from "react";
import PropTypes from "prop-types";
import IconTooltip from "../../../../components/IconTooltip/IconTooltip";
import styles from "./PermissionCheckbox.module.scss";

// Permission checkbox that allows a user to select a permission for creating or updating roles.
export default function PermissionCheckbox(props: Props): Component {
  return (
    <div className={styles.body}>
      <input
        data-test="permission-checkbox-checkbox"
        className="form-check-input form-check-big"
        type="checkbox"
        checked={props.checked}
        disabled={props.immutable}
        onChange={() => props.onChange(props.permissionId)}
      />
      <label
        data-test="permission-checkbox-label"
        className={`${styles.label} mx-2`} 
        onClick={() => props.onChange(props.permissionId)}
      >
        {props.name}
      </label>
      <IconTooltip
        id={`permission-checkbox-${props.permissionId}`}
        icon="info-circle"
        message={props.description}
        color="var(--info-tooltip)"
      />
    </div>
  );
}

PermissionCheckbox.propTypes = {
  permissionId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  immutable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

interface Props {
  permissionId: number;
  name: string;
  description: string;
  checked: boolean;
  immutable: boolean;
  onChange: (permissionId: number) => void;
}
