// --------------------------------------------------------------
// Created On: 2021-12-31
// Author: Zachary Thomas
//
// Last Modified: 2022-06-09
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import degreesToRadians from "./conversions/degreesToRadians";

// Find the distance between GPS points in kilometers.
export default function distanceGpsCoordinates(
  latA: number,
  lngA: number,
  latB: number,
  lngB: number
): number {
  const earthRadiusKm = 6371;

  const dLat = degreesToRadians(latB - latA);
  const dLng = degreesToRadians(lngB - lngA);

  latA = degreesToRadians(latA);
  latB = degreesToRadians(latB);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(latA) * Math.cos(latB);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadiusKm * c;
}
