// --------------------------------------------------------------
// Created On: 2022-09-01
// Author: Zachary Thomas
//
// Last Modified: 2022-09-01
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import { MS_PER_MINUTE } from "../../constants/miscellaneous";

// Convert ISO 8601 string to a date object with the local time offset of the current user.
export default function isoToLocalDate(iso: string): Date {
  const currentDate = new Date(iso);
  const timeZoneOffset = currentDate.getTimezoneOffset();
  const localCurrentDate = new Date(currentDate.getTime() - timeZoneOffset * MS_PER_MINUTE);
  return localCurrentDate;
}
