// --------------------------------------------------------------
// Created On: 2022-09-20
// Author: Zachary Thomas
//
// Last Modified: 2022-09-20
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";

// Explains what a user group is.
export default function HelpUsergroup(): Component {
  return (
    <div>
      <p className="px-3 my-3">A user group is a named collection of users.</p>

      <p className="px-3 mt-3 mb-2">
        <b>Associating user groups and asset groups:</b> When a user group is associated with an
        asset group, all users in the user group will have access to all assets in the asset group.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>
          Role: A user group can be given a role. When a role is assigned to a group, each user in
          that group gains the permissions that are granted by that role.
        </b>
      </p>
    </div>
  );
}
