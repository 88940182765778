// --------------------------------------------------------------
// Created On: 2024-04-11
// Author: Zachary Thomas
//
// Last Modified: 2024-04-16
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// This component displays HTML content. The HTML file should always be a static locally stored file.
export default function DangerousHtmlContent(props: Props): Component {
  const [htmlContent, setHtmlContent] = useState<string | null>(null);
  useEffect(() => {
    fetchHtmlContent(props.fileUrl);
  }, [props.fileUrl]);

  // Get the local HTML file content.
  async function fetchHtmlContent(htmlUrl: string): Promise<void> {
    let htmlContentString = await (await fetch(htmlUrl)).text();
    // Enforce some styling on incoming HTML.
    htmlContentString = htmlContentString.replace(
      "<div class=WordSection1>",
      "<div class=WordSection1 style='text-align: left;padding:1rem;'>"
    );
    setHtmlContent(htmlContentString);
  }

  return (
    <div>
      {/*
      HTML injection can make an application vulnerable to XSS attacks.
      The time when this is most dangerous is when the HTML is grabbed from a third party
      or when the HTML can be modified by a user.

      When using this component we should only ever be grabbing static files from our public directory.
    */}
      {htmlContent !== null && (
        <div
          className="text-left"
          data-test="dangerous-html-content"
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      )}
    </div>
  );
}

DangerousHtmlContent.propTypes = {
  fileUrl: PropTypes.string.isRequired,
};

interface Props {
  fileUrl: string;
}
