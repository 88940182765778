// --------------------------------------------------------------
// Created On: 2021-09-01
// Author: Zachary Thomas
//
// Last Modified: 2024-10-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import Card from "../../../components/Card/Card";
import { CONFIGURE_CONTROLLERS_PERMISSION } from "../../../constants/permissions";
import userHasPermission from "../../../utilities/userHasPermission";

// A card for letting users know that a controller isn't supported.
export default function ControllerNotSupportedCard(props: Props): Component {
  // Get the specific reason that a controller cannot be configured.
  function getControllerConfigurationMessage(operationIsAllowed: boolean, userHasPermission: boolean): string {
    if (!operationIsAllowed && !userHasPermission) {
      return (
        "You do not have the permission to remotely operate this asset " +
        "and you do not have the permission required to remotely modify controller configurations."
      );
    } else if (!operationIsAllowed) {
      return "You do not have the permission to remotely operate this asset.";
    } else if (!userHasPermission) {
      return "You do not have the permission required to remotely modify controller configurations.";
    } else {
      return "This asset is not associated with a controller that supports remote configuration.";
    }
  }

  return (
    <Card title={`${props.assetName} Controller Configuration`} isLeftAligned>
      {props.loading ? (
        <div className="my-5 py-5" />
      ) : (
        <p className="h4 text-center py-5 px-4">
          {getControllerConfigurationMessage(
            props.operationIsAllowed,
            userHasPermission([[CONFIGURE_CONTROLLERS_PERMISSION]])
          )}
        </p>
      )}
    </Card>
  );
}

ControllerNotSupportedCard.propTypes = {
  assetName: PropTypes.string.isRequired,
  operationIsAllowed: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

interface Props {
  assetName: string;
  operationIsAllowed: boolean;
  loading: boolean;
}
