// --------------------------------------------------------------
// Created On: 2023-01-27
// Author: Zachary Thomas
//
// Last Modified: 2023-02-08
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import { MAX_REPORT_ATTRIBUTES } from "../../constants/miscellaneous";

// Explains what a report is.
export default function HelpReport(): Component {
  return (
    <div>
      <p className="px-3 my-2">Reports can be setup to send emails with asset data periodically.</p>
      <p className="px-3 mt-3 mb-2">
        <b>Email Addresses:</b> This is a list of the email addresses that will receive this report.
      </p>
      <p className="px-3 mt-3 mb-2">
        <b>Schedule:</b> The schedule will determine when to send out the report to each listed
        email address.
      </p>
      <p className="px-3 mt-3 mb-2">
        <b>Attributes:</b> The attributes selected will determine what data to show for each asset
        on the report. A report is limited to containing at most {`${MAX_REPORT_ATTRIBUTES} `}
        attributes.
      </p>
      <p className="px-3 mt-3 mb-2">
        <b>Asset Groups:</b> The email that is sent out will include details about all assets in any
        included asset groups.
      </p>
      <p className="px-3 mt-3 mb-2">
        <b>Content:</b> Report emails will contain a CSV file that shows all of the data requested
        in a report. For reports that cover 10 or fewer assets, an image of a graph will also be
        included for each attribute.
      </p>
    </div>
  );
}
