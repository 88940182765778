// --------------------------------------------------------------
// Created On: 2023-02-09
// Author: Zachary Thomas
//
// Last Modified: 2023-02-09
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Given a day return the postfix for the date (ex: 3'rd', 2'nd, ...).
export default function getDayPostfix(date: number | string): string {
  let dateNumber = 1;
  if (typeof date === "string") {
    dateNumber = parseInt(date, 10);
  } else {
    dateNumber = date;
  }

  if (isNaN(dateNumber)) {
    return "";
  }

  if (dateNumber > 3 && dateNumber < 21) return "th";
  switch (dateNumber % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
