// --------------------------------------------------------------
// Created On: 2024-09-16
// Author: Zachary Thomas
//
// Last Modified: 2024-09-16
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Encodes all possible special characters for a URI.
export default function encodeCompleteUri(selectedString: string): string {
  let encodedString = encodeURIComponent(selectedString);
  encodedString = encodedString.replace("*", "%2A");
  encodedString = encodedString.replace("/", "%2F");
  encodedString = encodedString.replace("+", "%2B");
  encodedString = encodedString.replace("!", "%21");
  return encodedString;
}
