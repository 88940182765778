// --------------------------------------------------------------
// Created On: 2022-09-20
// Author: Zachary Thomas
//
// Last Modified: 2023-12-26
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";

// Explains what an asset transfer is.
export default function HelpTransfer(): Component {
  return (
    <div>
      <p className="px-3 my-2">
        Complete ownership of an asset and any attached monitoring device can be transferred to another company. Once
        the transfer is accepted by the receiving company, this action cannot be reversed.
      </p>

      <p className="px-3 mb-3">
        To transfer an asset to another company you must know the email address of at least one user who belongs to the
        receiving company.
      </p>
    </div>
  );
}
