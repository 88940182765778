// --------------------------------------------------------------
// Created On: 2021-06-08
// Author: Zachary Thomas
//
// Last Modified: 2024-05-16
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LoginForm from "./LoginForm/LoginForm";
import Spinner from "../../components/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import apiRequest from "../../utilities/api/apiRequest";
import getApiError from "../../utilities/api/getApiError";
import { API } from "../../constants/miscellaneous";
import { useDispatch } from "react-redux";
import { setAuthorizerToken, setAccessToken, setCurrentUser } from "../../redux/actions";
import EulaModal from "../../components/EulaModal/EulaModal";
import styles from "./LoginPage.module.scss";

// Page for users to login to their account.
export default function LoginPage(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showEulaAgreement, setShowEulaAgreement] = useState<boolean>(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState<boolean>(false);
  const [hasAccessToken, setHasAccessToken] = useState<boolean>(false);
  const [pendingToken, setPendingToken] = useState<string | null>(null);
  const [pendingAccessToken, setPendingAccessToken] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // On initial page load, if there is an authorization error, update the error message.
  useEffect(() => {
    if (props.authorizationError) {
      setErrorMessage("An action you performed was unable to be authorized. Please login to continue.");
    } else if (props.policyError) {
      setErrorMessage("EULA and privacy policy must be accepted to be allowed to login.");
    } else {
      setErrorMessage("");
    }
  }, [props.authorizationError, props.policyError]);

  // Clear login data when directed to this page.
  useEffect(() => {
    dispatch(setAuthorizerToken(""));
    dispatch(setAccessToken(""));
    dispatch(setCurrentUser(0, "", "", 0, "", false, [], false));
  }, []);

  // Don't allow user to login if they decline the EULA.
  function declineEula(): void {
    dispatch(setAuthorizerToken(""));
    dispatch(setAccessToken(""));
    dispatch(setCurrentUser(0, "", "", 0, "", false, [], false));
    setErrorMessage("EULA and privacy policy must be accepted to be allowed to login.");
    setShowEulaAgreement(false);
  }

  // Accept the current EULA.
  async function acceptEula(): Promise<void> {
    setLoading(true);
    const [response, responseBody] = (await apiRequest(`${API}/accept/eula`, "POST", null)) as [
      Response,
      EulaResponseBody
    ];
    setLoading(false);

    setShowEulaAgreement(false);
    if (response.ok && responseBody) {
      if (pendingToken !== null) {
        dispatch(setAuthorizerToken(pendingToken));
      }
      if (pendingAccessToken !== null) {
        dispatch(setAccessToken(pendingAccessToken));
      }
      loginRedirect(newPasswordRequired, hasAccessToken);
    } else {
      setErrorMessage("Internal server error. Unable to accept EULA and privacy policy, please try again later.");
    }
  }

  // Attempts to login a user.
  async function login(emailAddress: string, password: string): Promise<void> {
    if (emailAddress.length === 0) {
      setErrorMessage("Please enter an email address.");
    } else if (password.length === 0) {
      setErrorMessage("Please enter a password.");
    } else {
      const requestBody = {
        username: emailAddress,
        password: password,
      };

      setLoading(true);
      const [response, responseBody] = (await apiRequest(`${API}/registration/authenticate`, "POST", requestBody)) as [
        Response,
        ResponseBody
      ];
      setLoading(false);

      if (response.ok && responseBody) {
        dispatch(
          setCurrentUser(
            responseBody.userId,
            responseBody.userName,
            responseBody.emailAddress,
            responseBody.companyId,
            responseBody.companyName,
            responseBody.isCompanyHidden,
            responseBody.permissions,
            responseBody.isPackager
          )
        );

        dispatch(setAuthorizerToken(responseBody.token));
        dispatch(setAccessToken(responseBody.accessToken));

        if (responseBody.agreedToEula || responseBody.newPasswordRequired) {
          loginRedirect(responseBody.newPasswordRequired, responseBody.accessToken !== null);
        } else {
          setPendingToken(responseBody.token);
          setPendingAccessToken(responseBody.accessToken);
          setNewPasswordRequired(responseBody.newPasswordRequired);
          setHasAccessToken(responseBody.accessToken !== null);
          setShowEulaAgreement(true);
        }
      } else {
        setErrorMessage(await getApiError(response, "Unable to complete login."));
      }
    }
  }

  // Handle redirecting after login in.
  function loginRedirect(newPasswordRequired: boolean, hasAccessToken: boolean): void {
    if (newPasswordRequired) {
      // Redirect to a different page depending on if the user has to
      // reset a temporary password, or an email token.
      if (hasAccessToken) {
        navigate("/change-temp-password");
      } else {
        navigate("/change-temporary-password");
      }
    } else {
      // If we used a link before being asked to login, redirect to the link.
      if (props.redirectUrl.length > 0) {
        props.onRedirect();
        navigate(props.redirectUrl);
      } else {
        navigate("/");
      }
    }
  }

  return (
    <div className={`${styles.body} p-4`}>
      <Spinner loading={loading} />

      <EulaModal
        showModal={showEulaAgreement}
        onClose={() => declineEula()}
        onAccept={() => acceptEula()}
        onDecline={() => declineEula()}
      />

      <LoginForm
        errorMessage={errorMessage}
        isForgotPasswordRedirect={props.isForgotPasswordRedirect}
        onSubmit={(emailAddress, password) => login(emailAddress, password)}
      />
    </div>
  );
}

LoginPage.propTypes = {
  authorizationError: PropTypes.bool.isRequired,
  policyError: PropTypes.bool.isRequired,
  isForgotPasswordRedirect: PropTypes.bool.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  onRedirect: PropTypes.func.isRequired,
};

interface Props {
  authorizationError: boolean;
  policyError: boolean;
  isForgotPasswordRedirect: boolean;
  redirectUrl: string;
  onRedirect: () => void;
}

interface ResponseBody {
  token: string;
  accessToken: string;
  newPasswordRequired: boolean;
  userId: number;
  userName: string;
  emailAddress: string;
  companyId: number;
  companyName: string;
  isCompanyHidden: boolean;
  permissions: string[];
  isPackager: boolean;
  agreedToEula: boolean;
}

interface EulaResponseBody {
  message: string;
}
