// --------------------------------------------------------------
// Created On: 2022-09-07
// Author: Zachary Thomas
//
// Last Modified: 2023-12-20
// Modified By: Lilly Kane
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./PopulateControl.module.scss";

// Message and toggle option for auto-populate for new resources.
export default function PopulateControl(props: Props): Component {
  return props.active ? (
    <div className={`alert alert-primary ${styles.body}`}>
      <div className={styles.text}>
        <span>
          {`New ${props.resourceType}s will be automatically added to this ${props.resourceType} group when they are created.`}
        </span>
        <button
          data-test="populate-control-toggle"
          className={`btn btn-primary ${styles.button}`}
          disabled={props.disabled}
          onClick={() => props.onChange()}
        >
          Remove as Default
        </button>
      </div>
    </div>
  ) : (
    <div className={`alert alert-secondary ${styles.body}`}>
      <div className={styles.text}>
        <span>
          {`Set as default to automatically add new ${props.resourceType}s to this ${props.resourceType} group when they are created.`}
        </span>
        <button
          data-test="populate-control-toggle"
          className={`btn btn-secondary ${styles.button}`}
          disabled={props.disabled}
          onClick={() => props.onChange()}
        >
          Set as Default
        </button>
      </div>
    </div>
  );
}

PopulateControl.propTypes = {
  resourceType: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

interface Props {
  resourceType: string;
  active: boolean;
  disabled: boolean;
  onChange: () => void;
}
