// --------------------------------------------------------------
// Created On: 2024-08-30
// Author: Zachary Thomas
//
// Last Modified: 2024-10-22
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./Accordion.module.scss";

// Collapsible accordion for showing and hiding content.
export default function Accordion(props: Props): Component {
  return (
    <div className={`${styles.accordion} ${props.className} accordion`} id={`accordion-${props.id}`}>
      <div className={`${styles.accordionItem} accordion-item`}>
        <h2 className={`${styles.accordionHeader} accordion-header`} id={`accordion-header-${props.id}`}>
          <button
            className={`${styles.accordionButton} accordion-button ${props.startsOpen ? "" : "collapsed"}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse-${props.id}`}
            aria-expanded={props.startsOpen ? "true" : "false"}
            aria-controls={`collapse-${props.id}`}
          >
            {props.title}
          </button>
        </h2>
        <div
          id={`collapse-${props.id}`}
          className={`${styles.accordionCollapse} accordion-collapse collapse ${props.startsOpen ? "show" : ""}`}
          data-bs-parent={`#accordion-${props.id}`}
        >
          <div className={`${styles.accordionBody} accordion-body`}>{props.children}</div>
        </div>
      </div>
    </div>
  );
}

Accordion.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  startsOpen: PropTypes.bool,
  children: PropTypes.any,
};

interface Props {
  id: string;
  title: string;
  className?: string;
  startsOpen?: boolean;
  children: Component | Component[];
}
