// --------------------------------------------------------------
// Created On: 2023-02-21
// Author: Zachary Thomas
//
// Last Modified: 2024-08-22
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { MAX_VIEW_ATTRIBUTE_HIGHLIGHTING_RULES } from "../../../../constants/miscellaneous";
import HighlightingRuleForm from "../HighlightingRuleForm/HighlightingRuleForm";
import DroppableArea from "../../../../components/DroppableArea/DroppableArea";
import { Active, DndContext, Over } from "@dnd-kit/core";
import styles from "./ViewAttributeForm.module.scss";

// Form for assigning attributes to a view.
export default function ViewAttributeForm(props: Props): Component {
  const selectedAttribute = useMemo(
    () => getAttribute(props.name, props.attributes),
    [props.name, JSON.stringify(props.attributes)]
  );
  const units = selectedAttribute !== null && selectedAttribute.units !== null ? selectedAttribute.units : "";
  const attributeIsBoolean = selectedAttribute !== null ? selectedAttribute.isBoolean : false;
  const attributeIsHighlightable = selectedAttribute !== null ? selectedAttribute.isHighlightable : false;

  // Returns the named attribute, if it exists.
  function getAttribute(name: string, attributes: ViewAttribute[]): ViewAttribute | null {
    const selectedAttribute = attributes.find((attribute) => attribute.name.toLowerCase() === name.toLowerCase());
    if (selectedAttribute === undefined) {
      return null;
    } else {
      return selectedAttribute;
    }
  }

  return (
    <div data-test="view-attribute-block" className={`${styles.body} my-3 mx-1`}>
      <div className="row align-items-center">
        {/* Attribute number. */}
        <div className="col-2 col-lg-3 col-xl-2">
          <label className={`${styles.title} d-none d-md-inline`}>Attribute #{props.formIndex + 1}:</label>
          <label className={`${styles.title} d-inline d-md-none`}>Attr #{props.formIndex + 1}:</label>
        </div>

        {/* Input field for selecting attribute. */}
        <div className="col-5 col-lg-6 col-xl-8">
          <input
            data-test="attribute-select-input"
            className="form-control mx-auto"
            type="text"
            list="datalist-attributes"
            autoComplete="off"
            value={props.name}
            disabled={props.disabled}
            onChange={(e) => props.onChangeAttribute(e.target.value)}
          />
        </div>

        {/* Button for deleting this attribute. */}
        <div className="col-5 col-lg-3 col-xl-2">
          <button
            data-test="delete-attribute-button"
            type="button"
            className={`${styles.button} btn btn-danger float-end`}
            disabled={props.disabled}
            onClick={() => props.onDeleteAttribute()}
          >
            <i className="fa fa-fw fa-times fa-xs" />
          </button>

          {/* Button for adding a highlighting rule to this attribute. */}
          <button
            data-test="attribute-add-rule-button"
            type="button"
            className={`${styles.button} btn btn-success me-3 float-end`}
            disabled={
              props.disabled ||
              props.highlightingRules.length >= MAX_VIEW_ATTRIBUTE_HIGHLIGHTING_RULES ||
              !attributeIsHighlightable
            }
            onClick={() => props.onAddRule()}
          >
            <span className="d-none d-sm-inline">Add Rule</span>
            <i className="d-inline d-sm-none fa fa-fw fa-plus fa-xs" />
          </button>
        </div>
      </div>

      {/* Highlighting rules. */}
      {props.highlightingRules.length > 0 && (
        <DndContext onDragEnd={(e) => props.onDragEnd(e.over, e.active)}>
          <DroppableArea id={0} disabled={props.disabled} />
          {props.highlightingRules.map((highlightingRule) => (
            <Fragment key={highlightingRule.formId}>
              <HighlightingRuleForm
                formId={highlightingRule.formId}
                comparator={highlightingRule.comparator}
                valueType={highlightingRule.valueType}
                value={highlightingRule.value}
                colorHexCode={highlightingRule.colorHexCode}
                units={units}
                isBoolean={attributeIsBoolean}
                disabled={props.disabled}
                onChange={(comparator, valueType, value, colorHexCode) =>
                  props.onChangeRule(highlightingRule.formId, comparator, valueType, value, colorHexCode)
                }
                onDelete={() => props.onDeleteRule(highlightingRule.formId)}
              />
              <DroppableArea id={highlightingRule.formId} disabled={props.disabled} />
            </Fragment>
          ))}
        </DndContext>
      )}
    </div>
  );
}

ViewAttributeForm.propTypes = {
  formIndex: PropTypes.number.isRequired,
  formId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  attributes: PropTypes.array.isRequired,
  highlightingRules: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onChangeAttribute: PropTypes.func.isRequired,
  onDeleteAttribute: PropTypes.func.isRequired,
  onAddRule: PropTypes.func.isRequired,
  onChangeRule: PropTypes.func.isRequired,
  onDeleteRule: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
};

interface Props {
  formIndex: number;
  formId: number;
  name: string;
  attributes: ViewAttribute[];
  highlightingRules: HighlightingRule[];
  disabled?: boolean;
  onChangeAttribute: (name: string) => void;
  onDeleteAttribute: () => void;
  onAddRule: () => void;
  onChangeRule: (
    formId: number,
    comparator: ViewComparator,
    valueType: ViewValueType,
    value: string,
    colorHexCode: string
  ) => void;
  onDeleteRule: (formId: number) => void;
  onDragEnd: (over: Over | null, active: Active) => void;
}

interface ViewAttribute {
  attributeId: number;
  name: string;
  code: string;
  units: string | null;
  isBoolean: boolean;
  isHighlightable: boolean;
}

interface HighlightingRule {
  formId: number;
  comparator: ViewComparator;
  valueType: ViewValueType;
  value: string;
  colorHexCode: string;
}

type ViewValueType = "NUMBER" | "MEDIAN" | "MEAN" | "STANDARD_DEVIATION" | "MAX" | "MIN";

type ViewComparator = "=" | "!=" | "<" | ">" | "<>";
