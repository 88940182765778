// --------------------------------------------------------------
// Created On: 2024-08-06
// Author: Dimitra Weinstein
//
// Last Modified: 2024-08-21
// Modified By: Dimitra Weinstein
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./AttributeInformationRow.module.scss";

// Table row containing attribute information.
export default function AttributeInformationRow(props: Props): Component {
    return (
        <tr data-test="attribute-info-row" className={styles.body}>
            <td>{props.name}</td>
            <td>{props.description}</td>
        </tr>
    )
}

AttributeInformationRow.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
}

interface Props {
    name: string;
    description: string;
}
