// --------------------------------------------------------------
// Created On: 2022-06-16
// Author: Zachary Thomas
//
// Last Modified: 2024-08-26
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import TitleBar from "../../../components/TitleBar/TitleBar";
import stringIsValidNumber from "../../../utilities/stringIsValidNumber";
import styles from "./LocationDateSelector.module.scss";

// Dropdown for selecting a start date, end date, and frequency.
export default function LocationDateSelector(props: Props): Component {
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);

  // Get the asset's current location data if it is valid.
  useEffect(() => {
    let assetLatitude: number | null = null;
    let assetLongitude: number | null = null;
    if (props.currentLocation !== null) {
      const locationValues = props.currentLocation.split(",");
      if (
        locationValues.length === 2 &&
        stringIsValidNumber(locationValues[0]) &&
        stringIsValidNumber(locationValues[1])
      ) {
        assetLatitude = parseFloat(locationValues[0]);
        assetLongitude = parseFloat(locationValues[1]);
      }
    }
    setLatitude(assetLatitude);
    setLongitude(assetLongitude);
  }, [JSON.stringify(props.currentLocation)]);

  return (
    <div className={`${styles.body} p-4 w-100`}>
      <div className="row">
        <div className={`${styles.options} col-8 col-xl-3 mb-3`}>
          <div className="shadow-sm">
            <div className="row">
              <div className="col">
                <TitleBar title="Date Range" />
              </div>
            </div>
            <div className="row">
              <div className="col-6 pe-0">
                <input
                  data-test="start-date-range-input"
                  className={`${styles.options} form-control`}
                  type="date"
                  value={props.start}
                  onChange={(e) => props.onChangeStart(e.target.value)}
                />
              </div>
              <div className="col-6 ps-0">
                <input
                  data-test="end-date-range-input"
                  className={`${styles.options} form-control`}
                  type="date"
                  value={props.end}
                  onChange={(e) => props.onChangeEnd(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-4 col-xl-3">
          <Fragment>
            <div className={styles.fullFrequency}>
              <div className="shadow-sm">
                <TitleBar title="Minimum Frequency" />
              </div>
              <select
                className={`${styles.options} form-control`}
                value={props.frequencyMinutes}
                onChange={(e) => props.onChangeFrequencyMinutes(parseInt(e.target.value, 10))}
              >
                <option value="0">None</option>
                <option value="10">10 Minutes</option>
                <option value="15">15 Minutes</option>
                <option value="30">30 Minutes</option>
                <option value="60">1 Hour</option>
                <option value="360">6 Hours</option>
                <option value="720">12 Hours</option>
                <option value="1440">24 Hours</option>
                <option value="10080">1 Week</option>
              </select>
            </div>

            <div className={styles.smallFrequency}>
              <div className="shadow-sm">
                <TitleBar title="Interval" />
              </div>
              <select
                className={`${styles.options} form-control`}
                value={props.frequencyMinutes}
                onChange={(e) => props.onChangeFrequencyMinutes(parseInt(e.target.value, 10))}
              >
                <option value="0">None</option>
                <option value="10">10min</option>
                <option value="15">15min</option>
                <option value="30">30min</option>
                <option value="60">1hr</option>
                <option value="360">6hr</option>
                <option value="720">12hr</option>
                <option value="1440">24hr</option>
                <option value="10080">1wk</option>
              </select>
            </div>
          </Fragment>
        </div>

        {latitude !== null && longitude !== null && (
          <div className="col-12 col-xl-6">
            <a
              href={`https://www.google.com/maps?saddr=My+Location&daddr=${latitude},${longitude}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className={`${styles.directionsButton} float-end`} type="button">
                <span className="d-none d-md-inline">Get Directions to Asset</span>
                <i className="d-inline d-md-none fa fa-fw fa-car fa-xs" />
              </button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

LocationDateSelector.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  frequencyMinutes: PropTypes.number.isRequired,
  currentLocation: PropTypes.string,
  onChangeStart: PropTypes.func.isRequired,
  onChangeEnd: PropTypes.func.isRequired,
  onChangeFrequencyMinutes: PropTypes.func.isRequired,
};

interface Props {
  start: string;
  end: string;
  frequencyMinutes: number;
  currentLocation: string | null;
  onChangeStart: (start: string) => void;
  onChangeEnd: (start: string) => void;
  onChangeFrequencyMinutes: (minutes: number) => void;
}
