// --------------------------------------------------------------
// Created On: 2023-02-21
// Author: Zachary Thomas
//
// Last Modified: 2024-09-13
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import { Active, Over, useDraggable } from "@dnd-kit/core";
import styles from "./TemplateAttribute.module.scss";

// Form for a single template attribute.
export default function TemplateAttribute(props: Props): Component {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.formId,
  });

  return (
    <div className="row align-items-center">
      <div className="col-auto ge-0 pe-0">{props.formIndex + 1}.</div>
      <div className="col g-0">
        <div
          data-test="template-attribute-container"
          className="container m-0 py-0"
          ref={setNodeRef}
          style={
            transform
              ? {
                  transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
                }
              : undefined
          }
        >
          <div className={`${styles.body} row p-0 m-0`}>
            <div className={`${styles.draggable} col-auto p-0 m-0`} {...attributes} {...listeners} />
            <div className="col py-2 pe-2">
              <div className="row align-items-center">
                {/* Attribute number. */}
                <div className="col-auto">
                  <label className={styles.title}>Attribute</label>
                </div>

                {/* Input field for selecting attribute. */}
                <div className="col">
                  <input
                    data-test="template-attribute-select-input"
                    className="form-control mx-auto w-100"
                    type="text"
                    list="datalist-attributes"
                    autoComplete="off"
                    value={props.name}
                    disabled={props.disabled}
                    onChange={(e) => props.onChange(e.target.value)}
                  />
                </div>

                {/* Button for deleting this attribute. */}
                <div className="col-auto">
                  <button
                    data-test="delete-attribute-button"
                    type="button"
                    className={`${styles.button} btn btn-danger float-end`}
                    disabled={props.disabled}
                    onClick={() => props.onDelete()}
                  >
                    <i className="fa fa-fw fa-times fa-xs" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TemplateAttribute.propTypes = {
  formIndex: PropTypes.number.isRequired,
  formId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
};

interface Props {
  formIndex: number;
  formId: number;
  name: string;
  disabled?: boolean;
  onChange: (name: string) => void;
  onDelete: () => void;
  onDragEnd: (over: Over | null, active: Active) => void;
}
