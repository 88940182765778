// --------------------------------------------------------------
// Created On: 2023-08-19
// Author: Zachary Thomas
//
// Last Modified: 2023-08-28
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import { SUPER_ADMIN_PERMISSION } from "../constants/permissions";
import store from "../redux/store";

// Checks if the current user is a super admin.
export default function userIsSuperAdmin(): boolean {
  const storeState = store.getState();

  if (
    storeState !== undefined &&
    storeState.currentUser !== undefined &&
    storeState.currentUser.permissions !== undefined
  ) {
    const currentUserPermissions = storeState.currentUser.permissions;
    return currentUserPermissions.includes(SUPER_ADMIN_PERMISSION);
  }

  return false;
}
