// --------------------------------------------------------------
// Created On: 2021-09-30
// Author: Zachary Thomas
//
// Last Modified: 2024-05-07
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import validateNickname from "../../../utilities/validateNickname";
import styles from "./AssetListRow.module.scss";

// A single asset in an asset list.
export default function AssetListRow(props: Props): Component {
  return (
    <div className="p-2" data-test="asset-list-row">
      {props.name} {validateNickname(props.nickname, props.name) ? `(${props.nickname})` : ""}
      {props.isMigrating === true && (
        <span data-test="migrating-asset-marker" className={`${styles.tag} ${styles.migrationTag}`}>
          Migrating
        </span>
      )}
      {props.lastConfigSuccessful === false && (
        <span data-test="failed-config-asset-marker" className={`${styles.tag} ${styles.failedConfigTag}`}>
          Failed Config
        </span>
      )}
      {props.isRented === true && (
        <span data-test="rented-asset-marker" className={`${styles.tag} ${styles.rentalTag}`}>
          Rental
        </span>
      )}
    </div>
  );
}

AssetListRow.propTypes = {
  assetId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
  isRented: PropTypes.bool,
  isMigrating: PropTypes.bool,
  lastConfigSuccessful: PropTypes.bool,
};

interface Props {
  assetId: number;
  name: string;
  nickname: string;
  isRented?: boolean;
  isMigrating?: boolean;
  lastConfigSuccessful?: boolean;
}
