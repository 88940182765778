// --------------------------------------------------------------
// Created On: 2023-08-18
// Author: Zachary Thomas
//
// Last Modified: 2023-08-18
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Constants related to responsive breakpoints for screen sizes.
export const BREAKPOINT_SMALL = 576;
export const BREAKPOINT_MEDIUM = 768;
export const BREAKPOINT_LARGE = 992;
export const BREAKPOINT_EXTRA_LARGE = 1200;
export const BREAKPOINT_EXTRA_EXTRA_LARGE = 1400;
