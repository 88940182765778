// --------------------------------------------------------------
// Created On: 2022-09-20
// Author: Zachary Thomas
//
// Last Modified: 2022-09-20
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";

// Explains what an asset group is.
export default function HelpAssetgroup(): Component {
  return (
    <div>
      <p className="px-3">
        An asset group supports organizing assets into collections. Asset groups can also be used to
        control who can access specific assets, and they can be used with geo-fences for alerting
        and for keeping a live inventory of a physical location.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Associated Assets:</b> Each associated asset appears as part of the asset group.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Associated Child Asset Groups:</b> Associating other asset groups with the current asset
        group allows a chain of asset access. For example if Group A has is a parent of Group B,
        then Group A has access to all assets associated with Group A and Group B.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Associated User Groups:</b> All users in an associated user group have access to all
        associated assets. When making the association you can also decide if the users will be able
        to simply view data, or if they will be granted the ability to operate the asset remotely.
      </p>

      <p className="px-3 mt-3 mb-3">
        <b>Geo-fence:</b> A geo-fence can be created around all of the assets in the current asset
        group. When an asset is detected outside of the geo-fence, an alert will be sent to all
        users in associated user groups. Alternatively geo-fences can be setup to handle the
        associations of assets in relation to a physical location, allowing users to create groups
        that act as a live inventory of a location.
      </p>
    </div>
  );
}
