// --------------------------------------------------------------
// Created On: 2023-12-11
// Author: Zachary Thomas
//
// Last Modified: 2024-03-06
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";

// A single asset that is being received by the current company in a transfer.
export default function IncomingTransferAssetListRow(props: Props): Component {
  return (
    <div className="p-2" data-test="incoming-transfer-row">
      <span>Asset &apos;</span>

      <b>{props.name}</b>

      <span>&apos; is being transferred from company &apos;</span>

      <b>{props.companyName}</b>

      <span>&apos;</span>
    </div>
  );
}

IncomingTransferAssetListRow.propTypes = {
  assetId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
};

interface Props {
  assetId: number;
  name: string;
  companyName: string;
}
