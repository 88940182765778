// --------------------------------------------------------------
// Created On: 2021-10-05
// Author: Zachary Thomas
//
// Last Modified: 2024-11-07
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useState, useEffect } from "react";
import NavigationDrawerCollection from "./NavigationDrawerCollection/NavigationDrawerCollection";
import NavigationDrawerLink from "./NavigationDrawerLink/NavigationDrawerLink";
import SearchBar from "../SearchBar/SearchBar";
import { useNavigate } from "react-router-dom";
import { RPM_COMPANY, PUBLIC_PAGES } from "../../constants/miscellaneous";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import PropTypes from "prop-types";
import userHasPermission from "../../utilities/userHasPermission";
import {
  ALIAS_ATTRIBUTES_PERMISSION,
  CREATE_ASSETS_PERMISSION,
  CREATE_ASSET_GROUPS_PERMISSION,
  CREATE_ASSET_RENTALS_PERMISSION,
  CREATE_REPORTS_PERMISSION,
  CREATE_ROLES_PERMISSION,
  CREATE_USER_GROUPS_PERMISSION,
  DELETE_ASSETS_PERMISSION,
  DELETE_ASSET_GROUPS_PERMISSION,
  REVOKE_ASSET_RENTALS_PERMISSION,
  RETURN_ASSET_RENTALS_PERMISSION,
  DELETE_REPORTS_PERMISSION,
  DELETE_ROLES_PERMISSION,
  DELETE_USERS_PERMISSION,
  DELETE_USER_GROUPS_PERMISSION,
  INVITE_USERS_PERMISSION,
  MANAGE_ALERT_THRESHOLDS_PERMISSION,
  UPDATE_ASSETS_PERMISSION,
  UPDATE_ASSET_GROUPS_PERMISSION,
  UPDATE_ASSET_RENTALS_PERMISSION,
  UPDATE_REPORTS_PERMISSION,
  UPDATE_ROLES_PERMISSION,
  UPDATE_USERS_PERMISSION,
  UPDATE_USER_GROUPS_PERMISSION,
  VIEW_DEVICE_STATUSES_PERMISSION,
  VIEW_SUBSCRIPTIONS_PERMISSION,
  ACCEPT_ASSET_RENTALS_PERMISSION,
  DECLINE_ASSET_RENTALS_PERMISSION,
  TRANSFER_ASSETS_PERMISSION,
  ACCEPT_ASSET_TRANSFERS_PERMISSION,
  DECLINE_ASSET_TRANSFERS_PERMISSION,
  CREATE_TEMPLATES_PERMISSION,
  UPDATE_TEMPLATES_PERMISSION,
  DELETE_TEMPLATES_PERMISSION,
  CONFIGURE_CONTROLLERS_PERMISSION,
} from "../../constants/permissions";
import encodeCompleteUri from "../../utilities/encodeCompleteUri";
import styles from "./NavigationDrawer.module.scss";

// Navigation drawer for navigating pages on a mobile device.
export default function NavigationDrawer(props: Props): Component {
  const currentPageIsPublic = PUBLIC_PAGES.includes(props.currentPage);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [inCompany, setInCompany] = useState<boolean>(true);
  const currentUser = useSelector(getCurrentUser);
  const navigate = useNavigate();

  // Keep track of the status of the current user for routing purposes.
  useEffect(() => {
    setInCompany(currentUser.companyName !== RPM_COMPANY);
  }, [JSON.stringify(currentUser)]);

  // Clear the search query and navigate to the results page.
  function goToResults(resultsAddress: string): void {
    setSearchQuery("");
    navigate(resultsAddress);
  }

  return (
    <div>
      <div
        className={`${styles.overlay} ${props.showNavigationDrawer ? styles.visible : ""}`}
        onClick={() => props.onCloseNavigationDrawer()}
      />
      <nav className={`${styles.drawer} ${props.showNavigationDrawer ? styles.visible : ""}`} data-test="nav-drawer">
        {props.currentPage === "/alert" && (
          <NavigationDrawerCollection name="Alerts" icon="bell" targetPage="/alert" currentPage={props.currentPage}>
            <NavigationDrawerLink
              name="Overview"
              targetPage="/alert/overview"
              onClick={() => props.onCloseNavigationDrawer()}
            />
          </NavigationDrawerCollection>
        )}

        {props.currentPage === "/search" && (
          <NavigationDrawerCollection name="Search" icon="search" targetPage="/search" currentPage={props.currentPage}>
            <NavigationDrawerLink name="Assets" targetPage="/search" onClick={() => props.onCloseNavigationDrawer()} />
          </NavigationDrawerCollection>
        )}

        {props.currentPage === "/account" && (
          <NavigationDrawerCollection
            name="Account Settings"
            icon="user"
            targetPage="/account"
            currentPage={props.currentPage}
          >
            <Fragment>
              <NavigationDrawerLink
                name="Account Info"
                targetPage="/account/user-info"
                onClick={() => props.onCloseNavigationDrawer()}
              />
              <NavigationDrawerLink
                name="Change Password"
                targetPage="/account/password"
                onClick={() => props.onCloseNavigationDrawer()}
              />
            </Fragment>
          </NavigationDrawerCollection>
        )}

        {inCompany ? (
          <Fragment>
            {!currentUser.isPackager && !currentPageIsPublic && (
              <NavigationDrawerCollection
                name="Data Dashboard"
                icon="dashboard"
                targetPage="/dashboard"
                currentPage={props.currentPage}
              >
                <Fragment>
                  <NavigationDrawerLink
                    name="Asset Map"
                    targetPage="/dashboard/map"
                    onClick={() => props.onCloseNavigationDrawer()}
                  />
                  <NavigationDrawerLink
                    name="Asset List"
                    targetPage="/dashboard/list"
                    onClick={() => props.onCloseNavigationDrawer()}
                  />
                  <NavigationDrawerLink
                    name="Asset Graph"
                    targetPage="/dashboard/graph"
                    onClick={() => props.onCloseNavigationDrawer()}
                  />
                </Fragment>
              </NavigationDrawerCollection>
            )}
          </Fragment>
        ) : (
          <NavigationDrawerCollection
            name="RPM Dashboard"
            icon="gear"
            targetPage="/dashboard"
            currentPage={props.currentPage}
          >
            <NavigationDrawerLink
              name="Companies"
              targetPage="/dashboard/companies"
              onClick={() => props.onCloseNavigationDrawer()}
            />
            <NavigationDrawerLink
              name="Chatty Assets"
              targetPage="/assets/chatty"
              onClick={() => props.onCloseNavigationDrawer()}
            />
          </NavigationDrawerCollection>
        )}

        {inCompany && !currentPageIsPublic && (
          <Fragment>
            {props.currentPage === "/asset" && (
              <NavigationDrawerCollection
                name="Asset Details"
                icon="cog"
                targetPage="/asset"
                currentPage={props.currentPage}
              >
                <Fragment>
                  <NavigationDrawerLink
                    name="Data"
                    targetPage="/asset/*/data"
                    onClick={() => props.onCloseNavigationDrawer()}
                    linksToAsset
                  />
                  <NavigationDrawerLink
                    name="Graph"
                    targetPage="/asset/*/graph"
                    onClick={() => props.onCloseNavigationDrawer()}
                    linksToAsset
                  />
                  <NavigationDrawerLink
                    name="Map"
                    targetPage="/asset/*/map"
                    onClick={() => props.onCloseNavigationDrawer()}
                    linksToAsset
                  />
                  <NavigationDrawerLink
                    name="Remote Operation"
                    targetPage="/asset/*/operation"
                    onClick={() => props.onCloseNavigationDrawer()}
                    linksToAsset
                  />
                  {userHasPermission([[CONFIGURE_CONTROLLERS_PERMISSION]]) && (
                    <NavigationDrawerLink
                      name="Controller"
                      targetPage="/asset/*/controller"
                      onClick={() => props.onCloseNavigationDrawer()}
                      linksToAsset
                    />
                  )}
                  <NavigationDrawerLink
                    name="Documents"
                    targetPage="/asset/*/documents"
                    onClick={() => props.onCloseNavigationDrawer()}
                    linksToAsset
                  />
                  <NavigationDrawerLink
                    name="Maintenance Logs"
                    targetPage="/asset/*/logs"
                    onClick={() => props.onCloseNavigationDrawer()}
                    linksToAsset
                  />
                  <NavigationDrawerLink
                    name="Alerts"
                    targetPage="/asset/*/alerts"
                    onClick={() => props.onCloseNavigationDrawer()}
                    linksToAsset
                  />
                </Fragment>
              </NavigationDrawerCollection>
            )}

            <NavigationDrawerCollection
              name="Manage Resources"
              icon="cube"
              targetPage="/manage"
              currentPage={props.currentPage}
            >
              <Fragment>
                {userHasPermission([
                  [CREATE_ASSETS_PERMISSION],
                  [UPDATE_ASSETS_PERMISSION],
                  [DELETE_ASSETS_PERMISSION],
                  [CREATE_ASSET_GROUPS_PERMISSION],
                  [UPDATE_ASSET_GROUPS_PERMISSION],
                  [DELETE_ASSET_GROUPS_PERMISSION],
                ]) && (
                  <NavigationDrawerLink
                    name="Assets"
                    targetPage="/manage/assets"
                    onClick={() => props.onCloseNavigationDrawer()}
                  />
                )}

                {userHasPermission([
                  [CREATE_TEMPLATES_PERMISSION],
                  [UPDATE_TEMPLATES_PERMISSION],
                  [DELETE_TEMPLATES_PERMISSION],
                ]) && (
                  <NavigationDrawerLink
                    name="Templates"
                    targetPage="/manage/templates"
                    onClick={() => props.onCloseNavigationDrawer()}
                  />
                )}

                {userHasPermission([
                  [INVITE_USERS_PERMISSION],
                  [UPDATE_USERS_PERMISSION],
                  [DELETE_USERS_PERMISSION],
                  [CREATE_USER_GROUPS_PERMISSION],
                  [UPDATE_USER_GROUPS_PERMISSION],
                  [DELETE_USER_GROUPS_PERMISSION],
                ]) && (
                  <NavigationDrawerLink
                    name="Users"
                    targetPage="/manage/users"
                    onClick={() => props.onCloseNavigationDrawer()}
                  />
                )}

                {userHasPermission([
                  [CREATE_ROLES_PERMISSION],
                  [UPDATE_ROLES_PERMISSION],
                  [DELETE_ROLES_PERMISSION],
                ]) && (
                  <NavigationDrawerLink
                    name="Roles"
                    targetPage="/manage/roles"
                    onClick={() => props.onCloseNavigationDrawer()}
                  />
                )}

                {userHasPermission([
                  [CREATE_REPORTS_PERMISSION],
                  [UPDATE_REPORTS_PERMISSION],
                  [DELETE_REPORTS_PERMISSION],
                ]) && (
                  <NavigationDrawerLink
                    name="Reports"
                    targetPage="/manage/reports"
                    onClick={() => props.onCloseNavigationDrawer()}
                  />
                )}

                {userHasPermission([
                  [CREATE_ASSET_RENTALS_PERMISSION],
                  [UPDATE_ASSET_RENTALS_PERMISSION],
                  [REVOKE_ASSET_RENTALS_PERMISSION],
                  [RETURN_ASSET_RENTALS_PERMISSION],
                  [ACCEPT_ASSET_RENTALS_PERMISSION],
                  [DECLINE_ASSET_RENTALS_PERMISSION],
                ]) && (
                  <NavigationDrawerLink
                    name="Rentals"
                    targetPage="/manage/rentals"
                    onClick={() => props.onCloseNavigationDrawer()}
                  />
                )}

                {userHasPermission([
                  [TRANSFER_ASSETS_PERMISSION],
                  [ACCEPT_ASSET_TRANSFERS_PERMISSION],
                  [DECLINE_ASSET_TRANSFERS_PERMISSION],
                ]) && (
                  <NavigationDrawerLink
                    name="Transfers"
                    targetPage="/manage/transfers"
                    onClick={() => props.onCloseNavigationDrawer()}
                  />
                )}

                <NavigationDrawerLink
                  name="Views"
                  targetPage="/manage/views"
                  onClick={() => props.onCloseNavigationDrawer()}
                />
              </Fragment>
            </NavigationDrawerCollection>

            {userHasPermission([[VIEW_SUBSCRIPTIONS_PERMISSION], [VIEW_DEVICE_STATUSES_PERMISSION]]) && (
              <NavigationDrawerCollection
                name="Monitoring Devices"
                icon="binoculars"
                targetPage="/monitoring-devices"
                currentPage={props.currentPage}
              >
                <Fragment>
                  {userHasPermission([[VIEW_SUBSCRIPTIONS_PERMISSION]]) && (
                    <NavigationDrawerLink
                      name="Subscriptions"
                      targetPage="/monitoring-devices/subscriptions"
                      onClick={() => props.onCloseNavigationDrawer()}
                    />
                  )}

                  {userHasPermission([[VIEW_DEVICE_STATUSES_PERMISSION]]) && (
                    <NavigationDrawerLink
                      name="Statuses"
                      targetPage="/monitoring-devices/statuses"
                      onClick={() => props.onCloseNavigationDrawer()}
                    />
                  )}
                </Fragment>
              </NavigationDrawerCollection>
            )}

            <NavigationDrawerCollection
              name="Data Processing"
              icon="database"
              targetPage="/data-processing"
              currentPage={props.currentPage}
            >
              <Fragment>
                {userHasPermission([[ALIAS_ATTRIBUTES_PERMISSION]]) && (
                  <NavigationDrawerLink
                    name="Attribute Aliases"
                    targetPage="/data-processing/attributes"
                    onClick={() => props.onCloseNavigationDrawer()}
                  />
                )}

                <NavigationDrawerLink
                  name="Attribute Information"
                  targetPage="/data-processing/attribute-info"
                  onClick={() => props.onCloseNavigationDrawer()}
                />

                {userHasPermission([[MANAGE_ALERT_THRESHOLDS_PERMISSION]]) && (
                  <NavigationDrawerLink
                    name="Alert Thresholds"
                    targetPage="/data-processing/alert-thresholds"
                    onClick={() => props.onCloseNavigationDrawer()}
                  />
                )}
              </Fragment>
            </NavigationDrawerCollection>
          </Fragment>
        )}

        {currentPageIsPublic && (
          <Fragment>
            <NavigationDrawerCollection
              name="Account"
              icon="sign-in"
              targetPage={["/login", "/register", "/forgot-password"]}
              currentPage={props.currentPage}
            >
              <Fragment>
                <NavigationDrawerLink
                  name="Login"
                  targetPage="/login"
                  onClick={() => props.onCloseNavigationDrawer()}
                />
                <NavigationDrawerLink
                  name="Create Account"
                  targetPage="/register"
                  onClick={() => props.onCloseNavigationDrawer()}
                />
                <NavigationDrawerLink
                  name="Reset Password"
                  targetPage="/forgot-password"
                  onClick={() => props.onCloseNavigationDrawer()}
                />
              </Fragment>
            </NavigationDrawerCollection>
            <NavigationDrawerCollection
              name="Legal"
              icon="gavel"
              targetPage={["/eula-text", "/eula-viewer", "/privacy-policy-text", "/privacy-policy-viewer"]}
              currentPage={props.currentPage}
            >
              <Fragment>
                <NavigationDrawerLink
                  name="EULA Text"
                  targetPage="/eula-text"
                  onClick={() => props.onCloseNavigationDrawer()}
                />
                <NavigationDrawerLink
                  name="EULA PDF Viewer"
                  targetPage="/eula-viewer"
                  onClick={() => props.onCloseNavigationDrawer()}
                />
                <NavigationDrawerLink
                  name="Privacy Policy Text"
                  targetPage="/privacy-policy-text"
                  onClick={() => props.onCloseNavigationDrawer()}
                />
                <NavigationDrawerLink
                  name="Privacy Policy PDF Viewer"
                  targetPage="/privacy-policy-viewer"
                  onClick={() => props.onCloseNavigationDrawer()}
                />
              </Fragment>
            </NavigationDrawerCollection>
          </Fragment>
        )}

        {currentUser.companyName !== RPM_COMPANY && !currentUser.isPackager && !currentPageIsPublic && (
          <div className="mx-3 my-4">
            <SearchBar
              prompt="Search assets..."
              location="NAVIGATION_DRAWER"
              searchQuery={searchQuery}
              onChange={(searchQuery) => setSearchQuery(searchQuery)}
              onSubmit={() => goToResults(`/search/${encodeCompleteUri(searchQuery)}`)}
            />
          </div>
        )}
      </nav>
    </div>
  );
}

NavigationDrawer.propTypes = {
  currentPage: PropTypes.string.isRequired,
  showNavigationDrawer: PropTypes.bool.isRequired,
  onCloseNavigationDrawer: PropTypes.func.isRequired,
};

interface Props {
  currentPage: string;
  showNavigationDrawer: boolean;
  onCloseNavigationDrawer: () => void;
}
