// --------------------------------------------------------------
// Created On: 2022-01-20
// Author: Zachary Thomas
//
// Last Modified: 2023-11-17
// Modified By: Lilly Kane
//
// Copyright 2022 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import formatTitleCase from "../../../../utilities/formatTitleCase";
import styles from "./AlertCount.module.scss";

// Displays a count and a description of a specific type of alert.
export default function AlertCount(props: Props): Component {
  return (
    <div
      data-test={`alert-count-${props.type}`}
      className={`${styles.body} col-12 col-md-6 col-xl d-flex py-3 my-3 justify-content-center ${
        props.leftBorder ? styles.left : ""
      } ${props.rightBorder ? styles.right : ""}`}
    >
      <div className={styles.wrapper}>
        <p className={styles.count}>{props.count}</p>
        <span
          className={styles.overview}
          style={{
            color: `var(--alert-${props.type})`,
          }}
        >
          {`${formatTitleCase(props.type)} Alert${props.count === 1 ? "" : "s"}`}
        </span>
      </div>
    </div>
  );
}

AlertCount.propTypes = {
  type: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  leftBorder: PropTypes.bool.isRequired,
  rightBorder: PropTypes.bool.isRequired,
};

interface Props {
  type: string;
  count: number;
  leftBorder: boolean;
  rightBorder: boolean;
}
