// --------------------------------------------------------------
// Created On: 2021-09-01
// Author: Zachary Thomas
//
// Last Modified: 2024-10-21
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./RadioButton.module.scss";

// Generic radio button with label.
export default function RadioButton(props: Props): Component {
  return (
    <div className={`${styles.body} ${props.className} form-check`} onClick={() => props.onClick()}>
      <input
        className="form-check-input"
        type="radio"
        id={props.radioId}
        checked={props.checked}
        onChange={() => props.onClick()}
      />
      <label className="form-check-label" onClick={() => props.onClick()} htmlFor={props.radioId}>
        {props.label}
      </label>
    </div>
  );
}

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  radioId: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

interface Props {
  label: string;
  checked: boolean;
  className?: string;
  radioId?: string;
  onClick: () => void;
}
