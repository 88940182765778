// --------------------------------------------------------------
// Created On: 2023-02-21
// Author: Zachary Thomas
//
// Last Modified: 2023-12-05
// Modified By: Lilly Kane
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./ViewListRow.module.scss";

// A single view in a list of views.
export default function ViewListRow(props: Props): Component {
  return (
    <div className="p-2">
      <span data-test="view-name">{props.name}</span>
      {props.shared ? (
        <span data-test="view-is-shared-tag" className={styles.tag}>
          Shared
        </span>
      ) : (
        <span data-test="unshared-view-marker" />
      )}
    </div>
  );
}

ViewListRow.propTypes = {
  viewId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  shared: PropTypes.bool.isRequired,
};

interface Props {
  viewId: number;
  name: string;
  shared: boolean;
}
