// --------------------------------------------------------------
// Created On: 2021-09-21
// Author: Zachary Thomas
//
// Last Modified: 2023-12-20
// Modified By: Lilly Kane
//
// Copyright 2021 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes, { InferProps } from "prop-types";

// Generic modal header.
export default function ModalHeader(props: Props): Component {
  return (
    <Modal.Header data-test="modal-header" className={props.className || undefined}>
      {props.children}
    </Modal.Header>
  );
}

ModalHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

type Props = InferProps<typeof ModalHeader.propTypes>;
