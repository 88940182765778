// --------------------------------------------------------------
// Created On: 2021-05-14
// Author: Zachary Thomas
//
// Last Modified: 2023-03-03
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import TitleBar from "../../../components/TitleBar/TitleBar";
import styles from "./GroupSelector.module.scss";

// Dropdown for selecting an asset group that returns the index of the passed in array.
export default function GroupSelector(props: Props): Component {
  // Save the asset group selection to local storage, so that the users selection can be kept between page changes.
  function selectAssetgroup(assetgroupId: number): void {
    localStorage.setItem("selectedAssetgroupId", String(assetgroupId));
    props.onSelectAssetgroup(assetgroupId);
  }

  // Save the view selection to local storage, so that the users selection can be kept between page changes.
  function selectView(viewId: number): void {
    localStorage.setItem("selectedViewId", String(viewId));
    props.onSelectView(viewId);
  }

  return (
    <div className={props.absolutelyPositioned ? styles.absolutelyPosition : ""}>
      <div className="p-4 w-100">
        <div className="row">
          {/* Assetgroup select. */}
          <div className="col-6 col-lg-5">
            <div className="shadow-sm">
              <TitleBar title="Select Asset Group" />
              <select
                data-test="assetgroup-groupselector"
                className={`${styles.selectorSelect} form-select`}
                value={props.assetgroupId}
                onChange={(e) => selectAssetgroup(parseInt(e.target.value, 10))}
              >
                <option value="0">All Available Assets</option>
                {props.assetgroups.map((assetgroup) => (
                  <option value={assetgroup.assetgroupId} key={assetgroup.assetgroupId}>
                    {assetgroup.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* View select. */}
          <div className="col-6 col-lg-5">
            <div className="shadow-sm">
              <TitleBar title="Select View" />
              <select
                data-test="view-groupselector"
                className={`${styles.selectorSelect} form-select`}
                value={props.viewId}
                onChange={(e) => selectView(parseInt(e.target.value, 10))}
              >
                {props.views.length === 0 && <option value="0">None</option>}
                {props.views.map((view) => (
                  <option value={view.viewId} key={view.viewId}>
                    {view.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Asset counter. */}
          <div className="d-none d-lg-block col-lg-2">
            <div className="table-responsive shadow-sm">
              <table className={`${styles.table} table mb-0 pb-0`}>
                <thead className={styles.thead}>
                  <tr>
                    <th className={styles.th}>
                      <span className="d-none d-xxl-block">Asset Count</span>
                      <span className="d-block d-xxl-none">Count</span>
                    </th>
                  </tr>
                </thead>
                <tbody className={styles.tbody}>
                  <tr>
                    <td className={styles.td} data-test="groupselector-asset-count">
                      {`${props.assetCount} asset${props.assetCount !== 1 ? "s" : ""
                        }`}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

GroupSelector.propTypes = {
  assetCount: PropTypes.number.isRequired,
  assetgroups: PropTypes.array.isRequired,
  views: PropTypes.array.isRequired,
  absolutelyPositioned: PropTypes.bool.isRequired,
  assetgroupId: PropTypes.number.isRequired,
  viewId: PropTypes.number.isRequired,
  onSelectAssetgroup: PropTypes.func.isRequired,
  onSelectView: PropTypes.func.isRequired,
};

interface Props {
  assetCount: number;
  assetgroups: DashboardAssetgroup[];
  views: DashboardView[];
  absolutelyPositioned: boolean;
  assetgroupId: number;
  viewId: number;
  onSelectAssetgroup: (assetgroupId: number) => void;
  onSelectView: (viewId: number) => void;
}

interface DashboardAssetgroup {
  assetgroupId: number;
  name: string;
}

interface DashboardView {
  viewId: number;
  name: string;
  shared: boolean;
}
