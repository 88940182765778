// --------------------------------------------------------------
// Created On: 2023-08-14
// Author: Zachary Thomas
//
// Last Modified: 2024-03-08
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./RoleListRow.module.scss";

// A single role in a list of roles.
export default function RoleListRow(props: Props): Component {
  return (
    <div className="p-2" data-test="role-list-row">
      <span>
        {props.name} {props.immutable && <span className={styles.tag}>Immutable</span>}
      </span>
    </div>
  );
}

RoleListRow.propTypes = {
  roleId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  immutable: PropTypes.bool.isRequired,
};

interface Props {
  roleId: number;
  name: string;
  immutable: boolean;
}
