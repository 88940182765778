// --------------------------------------------------------------
// Created On: 2021-05-14
// Author: Zachary Thomas
//
// Last Modified: 2024-09-10
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment } from "react";
import { API, MS_PER_SECOND } from "../../constants/miscellaneous";
import stringIsValidNumber from "../../utilities/stringIsValidNumber";
import { DASHBOARD_POLLING_DELAY_SECONDS, DASHBOARD_MAX_INACTIVE_USER_SECONDS } from "../../constants/polling";
import calculateMapHighlightColor from "../../utilities/calculateMapHighlightColor";
import useApi from "../../hooks/useApi";
import getApiError from "../../utilities/api/getApiError";
import Spinner from "../../components/Spinner/Spinner";
import Error500Page from "../Error500Page/Error500Page";
import Error404Page from "../Error404Page/Error404Page";
import EmptyDashboardHelp from "./EmptyDashboardHelp/EmptyDashboardHelp";
import GroupMap from "./GroupMap/GroupMap";
import GroupSelector from "./GroupSelector/GroupSelector";
import GroupListPage from "./GroupListPage/GroupListPage";
import GroupGraphPage from "./GroupGraphPage/GroupGraphPage";
import { Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import useLastActive from "../../hooks/useLastActive";
import calculateHighlightRuleValue from "../../utilities/calculateHighlightRuleValue";
import deepCopy from "../../utilities/deepCopy";
import styles from "./DashboardUserPage.module.scss";

// Page for viewing data about assets that belong to asset groups.
export default function DashboardUserPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [assetgroups, setAssetgroups] = useState<DashboardAssetgroup[]>([]);
  const [views, setViews] = useState<DashboardView[]>([]);
  const [newAssetCreated, setNewAssetCreated] = useState<boolean>(false);
  const [mapAssets, setMapAssets] = useState<MapAsset[]>([]);
  const [viewAssets, setViewAssets] = useState<ViewAsset[]>([]);
  const [viewAttributes, setViewAttributes] = useState<ViewAttribute[]>([]);
  const [geofenceEnabled, setGeofenceEnabled] = useState<boolean>(false);
  const [geofencePoints, setGeofencePoints] = useState<GeofencePoint[]>([]);
  const [assetgroupId, setAssetgroupId] = useState<number>(0);
  const [viewId, setViewId] = useState<number>(0);
  const [mapHighlightingColors, setMapHighlightingColors] = useState<MapHighlightingColor[]>([]);
  const [listIsLoaded, setListIsLoaded] = useState<boolean>(false);
  const [mapPollCounter, setMapPollCounter] = useState<number>(0);
  const [listPollCounter, setListPollCounter] = useState<number>(0);
  const [timerCounter, setTimerCounter] = useState<number>(0);
  const currentUser = useSelector(getCurrentUser);
  const [userPreviouslyInactive, setUserPreviouslyInactive] = useState<boolean>(false);
  const [attributeDetailsByAttributeId, setAttributeDetailsByAttributeId] = useState<AttributeDetailsByAttributeId>({});
  const userInactiveSeconds = useLastActive(DASHBOARD_POLLING_DELAY_SECONDS);
  const location = useLocation();
  const isViewingMap = location.pathname.includes("map");

  // Get the list of asset groups and views from the API for use on all sub-pages.
  useApi(
    () => {
      setLoading(true);
      setAssetgroups([]);
      setViews([]);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/view`,
    },
    async (response: Response, responseBody: ListResponseBody) => {
      if (response.ok && responseBody) {
        // If there is a saved selection for assetgroup and view, attempt to apply it.
        let assetgroupId: number | string | null = localStorage.getItem("selectedAssetgroupId");
        let viewId: number | string | null = localStorage.getItem("selectedViewId");

        if (assetgroupId !== null) {
          assetgroupId = parseInt(assetgroupId, 10);
          if (listIncludesAssetGroupId(responseBody.assetgroups, assetgroupId)) {
            setAssetgroupId(assetgroupId);
          }
        }

        let viewFound = false;
        if (viewId !== null) {
          viewId = parseInt(viewId, 10);
          if (listIncludesViewId(responseBody.views, viewId)) {
            viewFound = true;
            setViewId(viewId);
          }
        }

        // If we didn't have a view previously selected, load the first view returned.
        if (!viewFound && responseBody.views.length > 0) {
          setViewId(responseBody.views[0].viewId);
        }

        // Finish getting the list of assetgroups and views.
        setAssetgroups(responseBody.assetgroups);
        setViews(responseBody.views);
        setListIsLoaded(true);
        setFailedToLoad(false);
        setErrorMessage("");
      } else {
        setListIsLoaded(false);
        setFailedToLoad(true);
        setErrorMessage(await getApiError(response, "Failed to load asset groups."));
      }
    },
    [newAssetCreated]
  );

  // If the current page is the map page, get the currently selected asset group information.
  useApi(
    () => {
      if (isViewingMap && listIsLoaded && listIncludesAssetGroupId(assetgroups, assetgroupId)) {
        if (mapPollCounter === 0) {
          setLoading(true);
        }
        setListPollCounter(0);
        return true;
      } else {
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/assetgroup/${assetgroupId}/view/${viewId}/location`,
    },
    async (response: Response, responseBody: MapResponseBody) => {
      if (response.ok && responseBody) {
        // Get all dynamic highlighting rules for the current assets.
        const dynamicMapHighlightingColors = calculateDynamicMapRuleValues(mapHighlightingColors, responseBody.assets);
        // Apply highlighting rules for map asset markers.
        const newAssets: MapAsset[] = [];
        responseBody.assets.forEach((asset) => {
          const newValueByAttributeId: ValueByAttributeId = {};
          for (const key in asset.valueByAttributeId) {
            newValueByAttributeId[key] = {
              value: asset.valueByAttributeId[key],
              triggeredHighlightColor: false,
            };
          }
          const newAsset: MapAsset = { ...asset, valueByAttributeId: newValueByAttributeId, highlightColorCode: null };
          newAsset.valueByAttributeId = newValueByAttributeId;
          [newAsset.highlightColorCode, newAsset.valueByAttributeId] = calculateMapHighlightColor(
            dynamicMapHighlightingColors,
            newAsset.valueByAttributeId
          );
          newAssets.push(newAsset);
        });
        setMapAssets(newAssets);
        setGeofenceEnabled(responseBody.geofenceEnabled);
        setGeofencePoints(responseBody.geofencePoints);
        setFailedToLoad(false);
        setErrorMessage("");
      } else {
        setFailedToLoad(true);
        setErrorMessage(await getApiError(response, "Failed to load assets."));
      }
      setLoading(false);
    },
    [
      newAssetCreated,
      assetgroupId,
      isViewingMap,
      listIsLoaded,
      JSON.stringify(assetgroups),
      JSON.stringify(attributeDetailsByAttributeId),
      JSON.stringify(mapHighlightingColors),
      mapPollCounter,
    ]
  );

  // If the current page is the map page, get the currently selected map view highlighting rules.
  // This API doesn't need to poll since it is just getting the rules, not the values.
  useApi(
    () => {
      if (isViewingMap && listIsLoaded && listIncludesViewId(views, viewId)) {
        if (mapPollCounter === 0) {
          setLoading(true);
        }
        setListPollCounter(0);
        return true;
      } else {
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/view/${viewId}/map-highlight`,
    },
    async (response: Response, responseBody: MapHighlightResponseBody) => {
      if (response.ok && responseBody) {
        setAttributeDetailsByAttributeId(responseBody.attributeDetailsByAttributeId);
        setMapHighlightingColors(responseBody.mapHighlightingColors);
        setFailedToLoad(false);
        setErrorMessage("");
      } else {
        setFailedToLoad(true);
        setErrorMessage(await getApiError(response, "Failed to load view information."));
      }
      setLoading(false);
    },
    [viewId, isViewingMap, listIsLoaded, listIncludesViewId(views, viewId), JSON.stringify(views)]
  );

  // If the current page is a list or graph page, then get the data for the asset group and attribute highlighting rules.
  useApi(
    () => {
      if (!isViewingMap && listIncludesAssetGroupId(assetgroups, assetgroupId) && listIncludesViewId(views, viewId)) {
        if (listPollCounter === 0) {
          setLoading(true);
        }
        setMapPollCounter(0);
        return true;
      } else {
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/view/${viewId}/assetgroup/${assetgroupId}/`,
    },
    async (response: Response, responseBody: ViewResponseBody) => {
      if (response.ok && responseBody) {
        setViewAssets(responseBody.assets);
        setViewAttributes(responseBody.attributes);
        setFailedToLoad(false);
        setErrorMessage("");
      } else {
        setFailedToLoad(false);
        setErrorMessage(await getApiError(response, "Failed to load assets."));
      }
      setLoading(false);
    },
    [
      newAssetCreated,
      assetgroupId,
      viewId,
      isViewingMap,
      JSON.stringify(views),
      JSON.stringify(assetgroups),
      listPollCounter,
    ]
  );

  // Periodically poll data to give users a live feed.
  useEffect(() => {
    const newTimerId = setTimeout(() => {
      setTimerCounter((prev) => prev + 1);
      // Only poll data if the user is active.
      console.log(
        `Looking to see if data should be polled` +
          ` (inactive for ${userInactiveSeconds} seconds of ${DASHBOARD_MAX_INACTIVE_USER_SECONDS} second limit).`
      );
      if (userInactiveSeconds < DASHBOARD_MAX_INACTIVE_USER_SECONDS) {
        // Check if the user should be getting map data.
        if (isViewingMap && listIsLoaded && listIncludesAssetGroupId(assetgroups, assetgroupId)) {
          console.log("Polling map data.");
          setMapPollCounter((prev) => prev + 1);
        }
        // Check if the user should be getting list / graph data.
        else if (
          !isViewingMap &&
          listIncludesAssetGroupId(assetgroups, assetgroupId) &&
          listIncludesViewId(views, viewId)
        ) {
          console.log("Polling list / graph data.");
          setListPollCounter((prev) => prev + 1);
        } else {
          console.log("Data was not ready to be polled.");
        }
        setUserPreviouslyInactive(false);
      } else {
        console.log("User is inactive. Data will not be polled.");
        setUserPreviouslyInactive(true);
      }
    }, DASHBOARD_POLLING_DELAY_SECONDS * MS_PER_SECOND);
    return () => {
      clearTimeout(newTimerId);
    };
  }, [
    timerCounter,
    isViewingMap,
    listIsLoaded,
    JSON.stringify(assetgroups),
    assetgroupId,
    JSON.stringify(views),
    viewId,
  ]);

  // If the user returns to the page after being inactive, trigger polling data immediately instead of waiting for timer.
  useEffect(() => {
    if (userPreviouslyInactive && userInactiveSeconds === 0) {
      // Check if the user should be getting map data.
      if (isViewingMap && listIsLoaded && listIncludesAssetGroupId(assetgroups, assetgroupId)) {
        console.log("Polling map data.");
        setMapPollCounter((prev) => prev + 1);
      }
      // Check if the user should be getting list / graph data.
      else if (
        !isViewingMap &&
        listIncludesAssetGroupId(assetgroups, assetgroupId) &&
        listIncludesViewId(views, viewId)
      ) {
        console.log("Polling list / graph data.");
        setListPollCounter((prev) => prev + 1);
      } else {
        console.log("Data was not ready to be polled.");
      }
    }
  }, [userInactiveSeconds, userPreviouslyInactive, isViewingMap, listIsLoaded]);

  // Returns whether the current asset group list includes a specific asset group ID.
  function listIncludesAssetGroupId(assetgroups: DashboardAssetgroup[], assetgroupId: number): boolean {
    return assetgroupId === 0 || assetgroups.some((assetgroup) => assetgroup.assetgroupId === assetgroupId);
  }

  // Returns whether the current view list includes a specific view ID.
  function listIncludesViewId(views: DashboardView[], viewId: number): boolean {
    return views.some((view) => view.viewId === viewId);
  }

  // Calculate map highlighting values for dynamic rules that require viewing a set of data.
  function calculateDynamicMapRuleValues(
    highlightingColors: MapHighlightingColor[],
    assets: UnmodifiedMapAsset[]
  ): MapHighlightingColor[] {
    const highlightColorsDeepCopy = deepCopy(highlightingColors);

    // Don't bother calculating anything if there are no highlighting colors.
    if (highlightingColors.length === 0) {
      return highlightColorsDeepCopy;
    }

    highlightColorsDeepCopy.forEach((highlightingColor) => {
      // Get data points from each asset for the current attribute.
      highlightingColor.mapHighlightingRules.forEach((mapHighlightingRule) => {
        const dataPoints: number[] = [];
        assets.forEach((asset) => {
          if (stringIsValidNumber(asset.valueByAttributeId[mapHighlightingRule.attributeId])) {
            dataPoints.push(parseFloat(asset.valueByAttributeId[mapHighlightingRule.attributeId]));
          }
        });
        dataPoints.sort((a, b) => a - b);

        // Use data points to calculate dynamic highlighting rules.
        mapHighlightingRule.value = calculateHighlightRuleValue(mapHighlightingRule, dataPoints);
      });
    });

    return highlightColorsDeepCopy;
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className={styles.body}>
      <Spinner loading={loading} />

      <Routes>
        {/* Page for viewing assets on a map. */}
        <Route
          path="/map"
          element={
            <Fragment>
              <GroupSelector
                assetCount={mapAssets.length}
                assetgroups={assetgroups}
                views={views}
                absolutelyPositioned={true}
                assetgroupId={assetgroupId}
                viewId={viewId}
                onSelectAssetgroup={(assetgroupId) => {
                  setAssetgroupId(assetgroupId);
                  setMapPollCounter(0);
                }}
                onSelectView={(viewId) => {
                  setViewId(viewId);
                  setMapPollCounter(0);
                }}
              />
              <GroupMap
                loading={loading}
                assets={mapAssets}
                geofenceEnabled={geofenceEnabled}
                geofencePoints={geofencePoints}
                attributeDetailsByAttributeId={attributeDetailsByAttributeId}
                errorMessage={errorMessage}
                preventMapReposition={mapPollCounter > 0}
              />
            </Fragment>
          }
        />

        {/* Page for viewing assets in a list. */}
        <Route
          path="/list"
          element={
            <Fragment>
              <GroupSelector
                assetCount={viewAssets.length}
                assetgroups={assetgroups}
                views={views}
                absolutelyPositioned={false}
                assetgroupId={assetgroupId}
                viewId={viewId}
                onSelectAssetgroup={(assetgroupId) => {
                  setAssetgroupId(assetgroupId);
                  setListPollCounter(0);
                }}
                onSelectView={(viewId) => {
                  setViewId(viewId);
                  setListPollCounter(0);
                }}
              />
              <GroupListPage
                assets={viewAssets}
                attributes={viewAttributes}
                viewId={viewId}
                loading={loading}
                errorMessage={errorMessage}
              />
            </Fragment>
          }
        />

        {/* Page for viewing assets on a graph. */}
        <Route
          path="/graph"
          element={
            <Fragment>
              <GroupSelector
                assetCount={viewAssets.length}
                assetgroups={assetgroups}
                views={views}
                absolutelyPositioned={false}
                assetgroupId={assetgroupId}
                viewId={viewId}
                onSelectAssetgroup={(assetgroupId) => {
                  setAssetgroupId(assetgroupId);
                  setListPollCounter(0);
                }}
                onSelectView={(viewId) => {
                  setViewId(viewId);
                  setListPollCounter(0);
                }}
              />
              <GroupGraphPage
                assets={viewAssets}
                attributes={viewAttributes}
                viewId={viewId}
                loading={loading}
                errorMessage={errorMessage}
              />
            </Fragment>
          }
        />

        <Route path="*" element={<Error404Page />} />
      </Routes>

      {/* Display a special message for users with no assets. Admins also get a prompt to create a new asset. */}
      {!loading && assetgroups.length <= 1 && mapAssets.length === 0 && viewAssets.length === 0 && (
        <EmptyDashboardHelp onCreateAsset={() => setNewAssetCreated(true)} />
      )}
    </div>
  );
}

interface ListResponseBody {
  assetgroups: DashboardAssetgroup[];
  views: DashboardView[];
}

interface DashboardView {
  viewId: number;
  name: string;
  shared: boolean;
}

interface DashboardAssetgroup {
  assetgroupId: number;
  name: string;
}

interface MapResponseBody {
  geofencePoints: GeofencePoint[];
  geofenceEnabled: boolean;
  assets: UnmodifiedMapAsset[];
}

interface GeofencePoint {
  geofencePointId: number;
  latitude: number;
  longitude: number;
  draggable?: boolean;
}

interface UnmodifiedMapAsset {
  assetId: number;
  name: string;
  nickname: string;
  deviceType: string | null;
  deviceIdentifier: string | null;
  latitude: number | null;
  longitude: number | null;
  lastComm: string | null;
  valueByAttributeId: UnmodifiedValueByAttributeId;
}

interface UnmodifiedValueByAttributeId {
  [key: string]: string;
}

interface MapAsset {
  assetId: number;
  name: string;
  nickname: string;
  deviceType: string | null;
  deviceIdentifier: string | null;
  latitude: number | null;
  longitude: number | null;
  lastComm: string | null;
  highlightColorCode: string | null;
  valueByAttributeId: ValueByAttributeId;
}

interface ValueByAttributeId {
  [key: string]: MapAttribute;
}

interface MapAttribute {
  value: string;
  triggeredHighlightColor: boolean;
}

interface ViewResponseBody {
  viewId: number;
  name: string;
  shared: boolean;
  attributes: ViewAttribute[];
  assets: ViewAsset[];
}

interface MapHighlightResponseBody {
  mapHighlightingColors: MapHighlightingColor[];
  attributeDetailsByAttributeId: AttributeDetailsByAttributeId;
}

interface MapHighlightingColor {
  colorHexCode: string;
  viewMapHighlightColorId: number;
  mapHighlightingRules: MapHighlightingRule[];
}

interface MapHighlightingRule {
  attributeId: number;
  comparator: ViewComparator;
  value: string;
  valueType: ViewValueType;
}

interface ViewAsset {
  assetId: number;
  name: string;
  nickname: string;
  deviceType: string;
  deviceIdentifier: string;
  attributes: ViewAssetAttribute[];
  lastComm: string | null;
}

interface ViewAssetAttribute {
  attributeId: number;
  value: string;
}

interface ViewAttribute {
  attributeId: number;
  name: string;
  units: string;
  highlightingRules: ViewHighlightingRule[];
  isGraphable: boolean;
}

interface ViewHighlightingRule {
  comparator: ViewComparator;
  valueType: ViewValueType;
  value: string;
  colorHexCode: string;
}

interface AttributeDetailsByAttributeId {
  [key: string]: AttributeDetails;
}

interface AttributeDetails {
  name: string;
  units: string | null;
}

type ViewValueType = "NUMBER" | "MEDIAN" | "MEAN" | "STANDARD_DEVIATION" | "MAX" | "MIN";

type ViewComparator = "=" | "!=" | "<" | ">" | "<>";
