// --------------------------------------------------------------
// Created On: 2022-07-27
// Author: Zachary Thomas
//
// Last Modified: 2024-03-06
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./RentOutAssetListRow.module.scss";

// A single rented out asset in a list of rented out assets.
export default function RentOutAssetListRow(props: Props): Component {
  return (
    <div className="p-2" data-test="rentout-list-row">
      <span>Asset &apos;</span>

      <b>{props.name}</b>

      <span>&apos; is rented to &apos;</span>

      <b>{props.renteeCompanyName}</b>

      <span>&apos;</span>

      {props.isPendingApproval && (
        <span data-test="pending-rental-marker" className={styles.tag}>
          Pending
        </span>
      )}
    </div>
  );
}

RentOutAssetListRow.propTypes = {
  assetId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  renteeCompanyName: PropTypes.string.isRequired,
  isPendingApproval: PropTypes.bool.isRequired,
};

interface Props {
  assetId: number;
  name: string;
  renteeCompanyName: string;
  isPendingApproval: boolean;
}
