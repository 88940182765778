// --------------------------------------------------------------
// Created On: 2021-06-01
// Author: Zachary Thomas
//
// Last Modified: 2024-12-03
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import IconTooltip from "../IconTooltip/IconTooltip";
import PropTypes from "prop-types";
import translateManufacturer from "../../utilities/translateManufacturer";
import {
  API,
  COPILOT_DEVICE_TYPE,
  COPILOT_PMG_DEVICE_TYPE,
  COPILOT_EDGE_DEVICE_TYPE,
} from "../../constants/miscellaneous";
import useApi from "../../hooks/useApi";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import SampleDeviceDataButton from "../SampleDeviceDataButton/SampleDeviceDataButton";
import styles from "./AssetDetails.module.scss";
import ValidateAssetButton from "../ValidateAssetButton/ValidateAssetButton";

// Panel that displays information about the current asset.
export default function AssetDetails(props: Props): Component {
  const [associatedAssetgroups, setAssociatedAssetgroups] = useState<AssociatedAssetgroup[]>([]);
  const currentUser = useSelector(getCurrentUser);
  const deviceCanSample = checkIfDeviceCanSample();

  // Get the associated assetgroups for this asset if 'showAssociatedAssetgroups' is true.
  useApi(
    () => {
      if (props.assetId !== undefined && props.showAssociatedAssetgroups !== undefined) {
        return props.showAssociatedAssetgroups;
      } else {
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/${props.assetId}/assetgroup`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setAssociatedAssetgroups(responseBody.assetgroups);
      } else {
        setAssociatedAssetgroups([]);
      }
    },
    [props.assetId, props.showAssociatedAssetgroups]
  );

  // Returns the list of associated asset groups as a comma separated string.
  function getAssetgroupList(assetgroups: AssociatedAssetgroup[]): string {
    const names: string[] = [];
    assetgroups.forEach((assetgroup) => names.push(assetgroup.name));
    return names.join(", ");
  }

  // Check if the current device supports sampling.
  function checkIfDeviceCanSample(): boolean {
    return (
      [COPILOT_DEVICE_TYPE, COPILOT_PMG_DEVICE_TYPE, COPILOT_EDGE_DEVICE_TYPE].includes(props.deviceType || "") &&
      props.deviceId !== null &&
      props.deviceId !== undefined
    );
  }

  return (
    <div className="px-3 pt-4 pb-3" data-test="asset-details-container">
      <div className="row justify-content-center">
        <div className="table-responsive col-12 col-xl-8">
          <table className={`${styles.table} table mb-0 pb-0`}>
            <thead className={styles.thead}>
              <tr>
                <th className={styles.th}>Asset Details</th>
                <th className={styles.th}></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Name</td>
                <td data-test="asset-details-name">
                  <span className="me-2">{props.name}</span>
                  {props.isRented && (
                    <span>
                      {" "}
                      <IconTooltip
                        id="rental"
                        icon="file-text"
                        message="This asset is a rental. Your ability to modify this asset is limited."
                        color="var(--alert-info)"
                      />
                    </span>
                  )}
                </td>
              </tr>

              <tr>
                <td>Nickname</td>
                {(props.nickname !== null && props.nickname !== undefined && props.nickname.length > 0) ||
                props.loading ? (
                  <td data-test="asset-details-nickname">{props.nickname}</td>
                ) : (
                  <td data-test="asset-details-nickname">None</td>
                )}
              </tr>

              {props.productType !== null && props.productType !== undefined && (
                <tr>
                  <td>Product Type</td>
                  <td data-test="asset-details-type">{props.productType}</td>
                </tr>
              )}

              <tr>
                <td>Product Manufacturer</td>
                <td data-test="asset-details-manufacturer">
                  {props.productManufacturer !== null ? translateManufacturer(props.productManufacturer) : "None"}
                </td>
              </tr>

              <tr>
                <td>Product Part Number</td>
                <td data-test="asset-details-part-number">
                  {props.productModel !== null ? props.productModel : "None"}
                </td>
              </tr>

              <tr>
                <td>Product Serial Number</td>
                <td data-test="asset-details-serial-number">
                  {props.productIdentifier !== null ? props.productIdentifier : "None"}
                </td>
              </tr>

              <tr>
                <td>Controller</td>
                <td data-test="asset-details-controller">
                  {props.controllerModel !== null ? props.controllerModel : "None"}
                </td>
              </tr>

              <tr>
                <td>
                  <span className="d-none d-sm-inline">Monitoring Device</span>
                  <span className="d-inline d-sm-none">Device</span>
                </td>
                <td data-test="asset-details-device">
                  <span className="me-2">
                    {props.deviceTypeName !== null ? `${props.deviceTypeName} ${props.deviceIdentifier}` : "None"}
                  </span>
                  {props.deviceType !== null && deviceCanSample && props.allowDeviceRead && (
                    <SampleDeviceDataButton
                      deviceId={props.deviceId || 0}
                      className="me-2"
                      onDeviceRead={() => {
                        props.onDeviceRead !== undefined ? props.onDeviceRead() : "";
                      }}
                    />
                  )}
                  {props.deviceType !== null && props.allowAssetValidate && (
                    <ValidateAssetButton assetId={props.assetId || null} />
                  )}
                </td>
              </tr>

              {props.showAssociatedAssetgroups && associatedAssetgroups.length > 0 && (
                <tr>
                  <td>
                    Asset Groups{" "}
                    <IconTooltip
                      id="asset-group-association-tooltip"
                      icon="info-circle"
                      message={
                        "Lists the asset groups that this asset is directly associated with. " +
                        "This does not include asset groups that are parents of the directly associated asset groups."
                      }
                      color="var(--info-tooltip)"
                    />
                  </td>
                  <td>
                    <span className="me-2">{getAssetgroupList(associatedAssetgroups)}</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

AssetDetails.propTypes = {
  name: PropTypes.string.isRequired,
  nickname: PropTypes.string,
  productType: PropTypes.string,
  productModel: PropTypes.string.isRequired,
  productIdentifier: PropTypes.string.isRequired,
  productManufacturer: PropTypes.string.isRequired,
  deviceId: PropTypes.number,
  deviceType: PropTypes.string,
  deviceTypeName: PropTypes.string,
  deviceIdentifier: PropTypes.string,
  controllerModel: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  isRented: PropTypes.bool.isRequired,
  assetId: PropTypes.number,
  showAssociatedAssetgroups: PropTypes.bool,
  allowDeviceRead: PropTypes.bool,
  allowAssetValidate: PropTypes.bool,
  onDeviceRead: PropTypes.func,
};

interface Props {
  name: string;
  nickname: string | null;
  productType?: string;
  productModel: string;
  productIdentifier: string;
  productManufacturer: string;
  deviceType: string | null;
  deviceTypeName: string | null;
  deviceId?: number | null;
  deviceIdentifier: string | null;
  controllerModel: string | null;
  loading: boolean;
  isRented: boolean;
  assetId?: number;
  showAssociatedAssetgroups?: boolean;
  allowDeviceRead?: boolean;
  allowAssetValidate?: boolean;
  onDeviceRead?: () => void;
}

interface ResponseBody {
  assetgroups: AssociatedAssetgroup[];
}

interface AssociatedAssetgroup {
  assetgroupId: number;
  name: string;
}
