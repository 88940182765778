// --------------------------------------------------------------
// Created On: 2022-09-01
// Author: Zachary Thomas
//
// Last Modified: 2022-09-01
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Convert ISO 8601 string to a date object.
export default function isoToDate(iso: string): Date {
  const date = new Date(iso);
  return date;
}
