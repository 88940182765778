// --------------------------------------------------------------
// Created On: 2023-11-27
// Author: Zachary Thomas
//
// Last Modified: 2023-12-01
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";

// Explains what a geo-fence alert threshold is.
export default function HelpAlertThresholdGeofence(): Component {
  return (
    <div>
      <p className="px-3">
        Each asset group can have a geo-fence. This menu allows you to decide how alerting is
        handled when an asset enters or leaves an active geo-fence.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Alert on asset entering geo-fence:</b> This setting triggers an alert whenever an asset
        enters an active geo-fence.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Alert on asset exiting geo-fence:</b> This setting triggers an alert whenever an asset
        leaves an active geo-fence.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Subscribed users:</b> These are the users that are subscribed to the current alert
        threshold. You can decide how long after the initial alert each user will receive a message
        and The forms of communication used for contacting those users. If a user acknowledges an
        alert, then no further messages will be sent out for that current alert. This functionality
        supports the creation of alert escalation chains.
      </p>
    </div>
  );
}
