// --------------------------------------------------------------
// Created On: 2022-10-24
// Author: Zachary Thomas
//
// Last Modified: 2023-02-23
// Modified By: Zachary Thomas
//
// Copyright 2022 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./SortedTableHeader.module.scss";

// A clickable header column for sorting a column of results.
export default function SortedTableHeader(props: Props): Component {
  return (
    <th className={`${styles.body} ${props.className}`} onClick={() => props.onClick(props.index)}>
      <span className={props.selectedIndex === props.index ? styles.selected : styles.unselected}>
        {props.name}
      </span>

      <span className="ms-3">
        {props.selectedIndex === props.index ? (
          <Fragment>
            {props.selectedAscending ? (
              <span className={styles.selected}>&#9650;</span>
            ) : (
              <span className={styles.selected}>&#9660;</span>
            )}
          </Fragment>
        ) : (
          <span className={styles.unselected}>&#9660;</span>
        )}
      </span>
    </th>
  );
}

SortedTableHeader.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  selectedAscending: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

interface Props {
  name: string;
  index: number;
  selectedIndex: number;
  selectedAscending: boolean;
  className?: string;
  onClick: (index: number) => void;
}
