// --------------------------------------------------------------
// Created On: 2023-02-03
// Author: Zachary Thomas
//
// Last Modified: 2024-09-09
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import PropTypes from "prop-types";
import { GEOFENCE_TYPE_DYNAMIC } from "../../../../constants/miscellaneous";
import Warning from "../../../../components/Warning/Warning";
import AssetgroupDiagram from "../AssetgroupDiagram/AssetgroupDiagram";
import TabList from "../../../../components/TabList/TabList";
import AssociatedAssetItem from "./AssociatedAssetItem/AssociatedAssetItem";
import AssociationModal from "../../../../components/AssociationModal/AssociationModal";
import styles from "./AssetTab.module.scss";

// Tab for associating assets with asset groups.
export default function AssetTab(props: Props): Component {
  const [showAssociation, setShowAssociation] = useState(false);

  // Associate a number of assets.
  function associateAssets(assets: SimpleAsset[]): void {
    props.onChangeAssets(assets);
    setShowAssociation(false);
  }

  return (
    <div>
      {/* Interactive asset group diagram. */}
      {!showAssociation && (
        <AssetgroupDiagram
          assetgroup={{
            assetgroupId: props.assetgroupId,
            name: props.assetgroupName,
            assets: props.selectedAssets,
            assetgroups: props.selectedAssetgroups,
            usergroups: [],
            isDefault: false,
          }}
          onClick={(selectedId, selectedType) => props.onRedirect(selectedId, selectedType)}
          display="assets"
        />
      )}

      {/* Association menu tabs.*/}
      <div className={`${styles.body} my-4`}>
        <div className="row mx-2">
          <TabList
            tabs={props.tabs}
            selectedTabId={props.tabId}
            onSelect={(tabId: string) => props.onClickTab(tabId)}
          />

          {/* Button and other general content. */}
          <div className={`${styles.tabsBar} py-3 text-center`}>
            <button
              data-test="assetgroup-modal-manage-asset-associations-button"
              className={`${styles.associationButton} btn btn-light my-2`}
              disabled={props.disabled || props.geofenceType === GEOFENCE_TYPE_DYNAMIC}
              onClick={() => setShowAssociation(true)}
            >
              Manage Asset Associations
            </button>

            {props.geofenceType === GEOFENCE_TYPE_DYNAMIC && (
              <div className="my-3">
                <Warning
                  message={
                    "This asset group currently has a dynamic geo-fence. You will not be able to manually manage asset" +
                    " associations while this is the case. Switching to a basic geo-fence will reenable manual asset associations."
                  }
                />
              </div>
            )}
          </div>

          {/* List of associated assets. */}
          <div className={`${styles.tabsContainer} p-0`}>
            {props.selectedAssets.map((asset) => (
              <AssociatedAssetItem
                key={asset.assetId}
                assetId={asset.assetId}
                name={asset.name}
                nickname={asset.nickname}
              />
            ))}
          </div>
        </div>
      </div>

      <AssociationModal
        showModal={showAssociation}
        title="Manage Asset Associations"
        type="asset"
        associatedItemsTitle="Associated Assets"
        unassociatedItemsTitle="Unassociated Assets"
        items={props.assets}
        associatedItems={props.selectedAssets}
        itemIdKey="assetId"
        disabled={props.disabled}
        onClose={() => setShowAssociation(false)}
        onChange={(associations) => associateAssets(associations)}
      />
    </div>
  );
}

AssetTab.propTypes = {
  tabId: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  assetgroupId: PropTypes.number.isRequired,
  assetgroupName: PropTypes.string.isRequired,
  assets: PropTypes.array.isRequired,
  assetgroups: PropTypes.array.isRequired,
  selectedAssets: PropTypes.array.isRequired,
  selectedAssetgroups: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  onRedirect: PropTypes.func.isRequired,
  onClickTab: PropTypes.func.isRequired,
  onChangeAssets: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

interface Props {
  tabId: string;
  tabs: ManagementTab[];
  geofenceType: string;
  assetgroupId: number;
  assetgroupName: string;
  assets: SimpleAsset[];
  assetgroups: SimpleAssetgroup[];
  selectedAssets: SimpleAsset[];
  selectedAssetgroups: Assetgroup[];
  disabled: boolean;
  onRedirect: (selectedId: number, selectedType: string) => void;
  onClickTab: (tabId: string) => void;
  onChangeAssets: (assets: SimpleAsset[]) => void;
  onError: (errorMessage: string) => void;
}
