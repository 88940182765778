// --------------------------------------------------------------
// Created On: 2024-12-03
// Author: Zachary Thomas
//
// Last Modified: 2024-12-03
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import Modal from "../../Modal/Modal";
import ModalHeader from "../../ModalHeader/ModalHeader";
import ModalBody from "../../ModalBody/ModalBody";
import ModalFooter from "../../ModalFooter/ModalFooter";
import Error from "../../Error/Error";
import Spinner from "../../Spinner/Spinner";
import InlineEllipsisSpinner from "../../InlineEllipsisSpinner/InlineEllipsisSpinner";
import DisplayDiagnosisStatus from "../../DisplayDiagnosisStatus/DisplayDiagnosisStatus";
import { API } from "../../../constants/miscellaneous";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import useApi from "../../../hooks/useApi";
import styles from "./ValidateAssetModal.module.scss";

// Modal for validating that an asset is configured correctly.
export default function ValidateAssetModal(props: Props): Component {
  const currentUser = useSelector(getCurrentUser);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [deviceShouldReport, setDeviceShouldReport] = useState<boolean>(false);
  const [deviceConnected, setDeviceConnected] = useState<boolean>(false);
  const [controllerShouldReport, setControllerShouldReport] = useState<boolean>(false);
  const [controllerConnected, setControllerConnected] = useState<boolean>(false);
  const [analogSensors, setAnalogSensors] = useState<AnalogSensor[]>([]);
  const [diagnosisPerformed, setDiagnosisPerformed] = useState<boolean>(false);
  const [configurationTestCount, setConfigurationTestCount] = useState<number>(0);
  const configurationIsBad =
    (deviceShouldReport && !deviceConnected) ||
    (controllerShouldReport && !controllerConnected) ||
    analogSensors.some((analogSensor) => !analogSensor.connected);

  // If we have found an asset, then validate connection information.
  useApi(
    () => {
      if (props.assetId !== null) {
        setLoading(true);
        return true;
      } else {
        setLoading(false);
        return false;
      }
    },
    {
      method: "POST",
      url: `${API}/company/${currentUser.companyId}/asset/${props.assetId === null ? 0 : props.assetId}/validate`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setDeviceShouldReport(responseBody.deviceShouldReport);
        setDeviceConnected(responseBody.deviceConnected);
        setControllerShouldReport(responseBody.controllerShouldReport);
        setControllerConnected(responseBody.controllerConnected);
        setAnalogSensors(responseBody.analogSensors);
        setDiagnosisPerformed(true);
        setErrorMessage("");
      } else {
        setErrorMessage("Internal server error. Unable to validate the status of the asset.");
        setDiagnosisPerformed(false);
      }
      setLoading(false);
    },
    [props.assetId, configurationTestCount]
  );

  return (
    <div>
      <Spinner loading={loading} />

      <Modal
        show={true}
        onHide={() => props.onClose()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="lg"
        centered
        animation
      >
        <ModalHeader>
          <h5 className="font-weight-bold">Validate Asset</h5>
        </ModalHeader>

        <ModalBody>
          {loading && (
            <div>
              <div className="text-center">
                Checking Asset Configuration <InlineEllipsisSpinner loading={true} />
              </div>
            </div>
          )}

          {props.assetId !== null && !loading && diagnosisPerformed && (
            <div className="row justify-content-center px-5">
              <div className="col-12 col-xl-8">
                <DisplayDiagnosisStatus
                  loading={false}
                  deviceShouldReport={deviceShouldReport}
                  deviceConnected={deviceConnected}
                  controllerShouldReport={controllerShouldReport}
                  controllerConnected={controllerConnected}
                  analogSensors={analogSensors}
                />
              </div>
            </div>
          )}

          {props.assetId !== null && !loading && configurationIsBad && (
            <div className="text-center">
              <button
                className="btn btn-primary text-center mx-auto my-2"
                data-test="retry-asset-diagnosis-button"
                type="button"
                onClick={() => setConfigurationTestCount((prev) => prev + 1)}
              >
                Retry Validation Check
              </button>
            </div>
          )}

          {errorMessage.length > 0 && (
            <div className="m-3">
              <Error message={errorMessage} />
            </div>
          )}
        </ModalBody>

        <ModalFooter className={styles.footer}>
          <button className={`${styles.btn} btn btn-secondary`} type="button" onClick={() => props.onClose()}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

ValidateAssetModal.propTypes = {
  assetId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

interface Props {
  assetId: number | null;
  onClose: () => void;
}

interface ResponseBody {
  deviceShouldReport: boolean;
  deviceConnected: boolean;
  controllerShouldReport: boolean;
  controllerConnected: boolean;
  analogSensors: AnalogSensor[];
}

interface AnalogSensor {
  position: number;
  connected: boolean;
}
