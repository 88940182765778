// --------------------------------------------------------------
// Created On: 2023-02-17
// Author: Zachary Thomas
//
// Last Modified: 2024-04-23
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import { API } from "../../constants/miscellaneous";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import apiRequest from "../../utilities/api/apiRequest";
import EulaModal from "../../components/EulaModal/EulaModal";

// Page used by mobile apps to accept or reject policies.
export default function MobilePolicyConfirmationPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  // Accept the current EULA and privacy policy.
  async function acceptPolicies(): Promise<void> {
    setLoading(true);
    await apiRequest(`${API}/accept/eula`, "POST", null);
    setLoading(false);
    navigate("/");
  }

  return (
    <div className="p-4" data-test="mobile-policy-confirmation-page">
      <Spinner loading={loading} />

      <EulaModal
        showModal={true}
        onClose={() => navigate("/login/policy-rejected")}
        onAccept={() => acceptPolicies()}
        onDecline={() => navigate("/login/policy-rejected")}
      />
    </div>
  );
}
