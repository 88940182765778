// --------------------------------------------------------------
// Created On: 2021-06-09
// Author: Zachary Thomas
//
// Last Modified: 2021-06-09
// Modified By: Zachary Thomas
//
// Copyright 2021 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./Success.module.scss";

// Success message.
export default function Success(props: Props): Component {
  return props.message.length > 0 ? (
    <div className={`${styles.alert} alert alert-success`} role="alert">
      {props.message}
    </div>
  ) : null;
}

Success.propTypes = {
  message: PropTypes.string.isRequired,
};

interface Props {
  message: string;
}
