// --------------------------------------------------------------
// Created On: 2023-03-03
// Author: Zachary Thomas
//
// Last Modified: 2024-07-19
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import deepCopy from "./deepCopy";
import stringIsValidNumber from "./stringIsValidNumber";

// Given map highlighting colors and a single asset's array of attribute values, return the applied highlighting color
// and the attribute array with the ones that triggered the highlight marked.
export default function calculateMapHighlightColor(
  mapHighlightingColors: MapHighlightingColor[],
  valueByAttributeId: ValueByAttributeId
): [string | null, ValueByAttributeId] {
  const valueByAttributeIdDeepCopy = deepCopy(valueByAttributeId);
  const mapHighlightingColorsDeepCopy = deepCopy(mapHighlightingColors);
  const reversedMapHighlightingColors = mapHighlightingColorsDeepCopy.reverse();

  // Keep track of the highest priority rule that passes (only the highest priority color is used).
  let topPassingColorIndex = -1;
  reversedMapHighlightingColors.forEach((mapHighlightingColor, i) => {
    // Keep track of how many rules for a single color pass (all have to pass to highlight).
    let passingRuleCount = 0;
    for (const mapHighlightingRule of mapHighlightingColor.mapHighlightingRules) {
      // Get the attribute that the rule applies to.
      const selectedAttribute = valueByAttributeIdDeepCopy[mapHighlightingRule.attributeId];
      // If there isn't a value to check, then return early.
      if (selectedAttribute === undefined || selectedAttribute.value === null) {
        break;
      }

      // Convert string value to number.
      let stringValue = selectedAttribute.value;
      if (stringValue.toLowerCase() === "true") {
        stringValue = "1";
      } else if (stringValue.toLowerCase() === "false") {
        stringValue = "0";
      }

      let value = null;
      if (stringIsValidNumber(stringValue)) {
        value = parseFloat(stringValue);
      } else {
        break;
      }

      switch (mapHighlightingRule.valueType) {
        case "NUMBER": {
          switch (mapHighlightingRule.comparator) {
            case "<>": {
              const values = mapHighlightingRule.value.split(",");
              if (values.length === 2 && value >= parseFloat(values[0]) && value <= parseFloat(values[1])) {
                passingRuleCount++;
              }
              break;
            }
            case ">":
              if (value > parseFloat(mapHighlightingRule.value)) {
                passingRuleCount++;
              }
              break;
            case "<":
              if (value < parseFloat(mapHighlightingRule.value)) {
                passingRuleCount++;
              }
              break;
            case "=":
              if (value === parseFloat(mapHighlightingRule.value)) {
                passingRuleCount++;
              }
              break;
          }
          break;
        }
        case "MEDIAN": {
          switch (mapHighlightingRule.comparator) {
            case ">":
              if (value > parseFloat(mapHighlightingRule.value)) {
                passingRuleCount++;
              }
              break;
            case "<":
              if (value < parseFloat(mapHighlightingRule.value)) {
                passingRuleCount++;
              }
              break;
            case "=":
              if (value === parseFloat(mapHighlightingRule.value)) {
                passingRuleCount++;
              }
              break;
          }
          break;
        }
        case "MEAN": {
          switch (mapHighlightingRule.comparator) {
            case ">":
              if (value > parseFloat(mapHighlightingRule.value)) {
                passingRuleCount++;
              }
              break;
            case "<":
              if (value < parseFloat(mapHighlightingRule.value)) {
                passingRuleCount++;
              }
              break;
            case "=":
              if (value === parseFloat(mapHighlightingRule.value)) {
                passingRuleCount++;
              }
              break;
          }
          break;
        }
        case "STANDARD_DEVIATION": {
          const values = mapHighlightingRule.value.split(",");
          switch (mapHighlightingRule.comparator) {
            case ">": {
              if (values.length === 2 && (value < parseFloat(values[0]) || value > parseFloat(values[1]))) {
                passingRuleCount++;
              }
              break;
            }
            case "<":
              if (values.length === 2 && value >= parseFloat(values[0]) && value <= parseFloat(values[1])) {
                passingRuleCount++;
              }
              break;
          }
          break;
        }
        case "MAX": {
          if (value === parseFloat(mapHighlightingRule.value)) {
            passingRuleCount++;
          }
          break;
        }
        case "MIN": {
          if (value === parseFloat(mapHighlightingRule.value)) {
            passingRuleCount++;
          }
          break;
        }
      }
    }

    // Check if all the rules passed, if so update the top passing color index.
    if (passingRuleCount === mapHighlightingColor.mapHighlightingRules.length) {
      topPassingColorIndex = i;
    }
    passingRuleCount = 0;
  });

  // Figure out what color to return and update the attribute object to reflect the
  // attributes that caused the highlighting rule to be applied.
  let appliedColor = null;
  if (topPassingColorIndex > -1) {
    appliedColor = reversedMapHighlightingColors[topPassingColorIndex].colorHexCode;
    reversedMapHighlightingColors[topPassingColorIndex].mapHighlightingRules.forEach((mapHighlightingRule) => {
      const selectedAttribute = valueByAttributeIdDeepCopy[mapHighlightingRule.attributeId];
      if (selectedAttribute !== undefined) {
        selectedAttribute.triggeredHighlightColor = true;
      }
    });
  }

  return [appliedColor, valueByAttributeIdDeepCopy];
}

interface MapHighlightingColor {
  colorHexCode: string;
  viewMapHighlightColorId: number;
  mapHighlightingRules: MapHighlightingRule[];
}

interface MapHighlightingRule {
  attributeId: number;
  comparator: ViewComparator;
  value: string;
  valueType: ViewValueType;
}

interface ValueByAttributeId {
  [key: string]: MapAttribute;
}

interface MapAttribute {
  value: string;
  triggeredHighlightColor: boolean;
}

type ViewValueType = "NUMBER" | "MEDIAN" | "MEAN" | "STANDARD_DEVIATION" | "MAX" | "MIN";

type ViewComparator = "=" | "!=" | "<" | ">" | "<>";
