// --------------------------------------------------------------
// Created On: 2021-10-27
// Author: Zachary Thomas
//
// Last Modified: 2024-05-14
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import styles from "./NavigationDrawerLink.module.scss";

// A single selectable page link.
export default function NavigationDrawerLink(props: Props): Component {
  const [assetId, setAssetId] = useState<string>("0");
  const location = useLocation();

  // Update the asset ID based on the current location.
  useEffect(() => {
    const pathSubdirectories = location.pathname.split("/");
    if (pathSubdirectories.length > 2) {
      setAssetId(pathSubdirectories[2]);
    }
  }, [location.pathname]);

  return (
    <Link
      className={`${styles.body} ${styles.navigationLink}`}
      to={props.linksToAsset ? props.targetPage.replace("*", assetId) : props.targetPage}
      onClick={() => props.onClick()}
    >
      <div
        data-test="nav-drawer-link"
        className={`${styles.link} p-3 ${
          props.linksToAsset
            ? props.targetPage.replace("*", assetId) === location.pathname
              ? styles.selected
              : ""
            : props.targetPage === location.pathname
            ? styles.selected
            : ""
        }`}
      >
        {props.name}
      </div>
    </Link>
  );
}

NavigationDrawerLink.propTypes = {
  name: PropTypes.string.isRequired,
  targetPage: PropTypes.string.isRequired,
  linksToAsset: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

interface Props {
  name: string;
  targetPage: string;
  linksToAsset?: boolean;
  onClick: () => void;
}
