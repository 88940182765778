// --------------------------------------------------------------
// Created On: 2022-05-26
// Author: Zachary Thomas
//
// Last Modified: 2024-09-15
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import formatDateShortLocal from "../../../../utilities/time/formatDateShortLocal";
import formatDateLocal from "../../../../utilities/time/formatDateLocal";
import PropTypes from "prop-types";
import isoIsValid from "../../../../utilities/time/isoIsValid";
import styles from "./DataPoint.module.scss";

// Represents a data point in a stylized format.
export default function DataPoint(props: Props): Component {
  return (
    <div
      data-test="data-point"
      className={`${styles.body} ${props.isHistorical ? styles.historical : ""} ${
        props.isStale ? styles.staleData : ""
      }`}
      onClick={() => props.onClick()}
    >
      <div className="row justify-content-center px-2">
        <div className="col-auto my-auto">
          <span className="fa-stack fa-lg">
            <i className={`${styles.iconBackground} fa fa-fw fa-circle fa-stack-1x`} />
            <i className={`${styles.icon} fa fa-fw fa-${props.icon} fa-stack-1x`} />
          </span>
        </div>

        <div className="col my-auto ps-0 pe-3">
          <div className={styles.value}>
            <span className="me-1">
              {typeof props.value === "boolean" && <span>{props.value ? "True" : "False"}</span>}

              {typeof props.value === "string" && isoIsValid(props.value) && (
                <span>{formatDateLocal(props.value)}</span>
              )}

              {typeof props.value === "string" && !isoIsValid(props.value) && <span>{props.value}</span>}

              {typeof props.value === "number" && <span>{props.value}</span>}
            </span>
            <span className={styles.subtle}>{props.unit}</span>
            <span className={styles.subtle}>
              {props.mostRecentUtc !== undefined && props.mostRecentUtc !== null && typeof props.value !== "boolean"
                ? ` on ${formatDateShortLocal(props.mostRecentUtc)}`
                : ""}
            </span>
          </div>

          <div className={styles.name}>
            <span className={styles.subtle}>{props.name}</span>
            <span className={styles.subtle} />
          </div>
        </div>
      </div>
    </div>
  );
}

DataPoint.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  unit: PropTypes.string,
  mostRecentUtc: PropTypes.string,
  isHistorical: PropTypes.bool.isRequired,
  isStale: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

interface Props {
  icon: string;
  name: string;
  code: string;
  value?: string | number | boolean | null;
  unit?: string;
  mostRecentUtc?: string | null;
  isHistorical: boolean;
  isGraphable: boolean;
  isStale: boolean;
  onClick: () => void;
}
