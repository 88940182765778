// --------------------------------------------------------------
// Created On: 2023-02-09
// Author: Zachary Thomas
//
// Last Modified: 2023-02-09
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Convert HTML input time ("08:20", "20:15", ...) to a human readable string.
export default function timeInputToUserReadable(time: string): string {
  const times = time.split(":");
  if (times.length === 2) {
    const hours = parseInt(times[0], 10);
    const minutes = times[1];
    if (hours === 12) {
      return `12:${minutes} PM`;
    } else if (hours === 0 || hours === 24) {
      return `12:${minutes} AM`;
    } else if (hours > 12) {
      return `${hours - 12}:${minutes} PM`;
    } else if (hours < 12) {
      return `${hours}:${minutes} AM`;
    } else {
      return "invalid time";
    }
  } else {
    return "invalid time";
  }
}
