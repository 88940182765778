// --------------------------------------------------------------
// Created On: 2021-10-12
// Author: Zachary Thomas
//
// Last Modified: 2024-01-02
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser } from "../../../../redux/selectors";
import { setCurrentUser } from "../../../../redux/actions";

// A single company in a company list.
export default function CompanyListItem(props: Props): Component {
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  // Visit the select company as an admin.
  function visitCompany(companyId: number, companyName: string, isPackager: boolean): void {
    dispatch(
      setCurrentUser(
        currentUser.userId,
        currentUser.userName,
        currentUser.emailAddress,
        companyId,
        companyName,
        false,
        currentUser.permissions,
        isPackager
      )
    );
  }

  return (
    <div className="company-list-item p-2" onClick={() => visitCompany(props.companyId, props.name, props.isPackager)}>
      {props.name}
    </div>
  );
}

CompanyListItem.propTypes = {
  companyId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isPackager: PropTypes.bool.isRequired,
};

interface Props {
  companyId: number;
  name: string;
  isPackager: boolean;
}
