// --------------------------------------------------------------
// Created On: 2021-06-07
// Author: Zachary Thomas
//
// Last Modified: 2024-01-26
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import Modal from "../Modal/Modal";
import ModalHeader from "../ModalHeader/ModalHeader";
import ModalBody from "../ModalBody/ModalBody";
import ModalFooter from "../ModalFooter/ModalFooter";
import PropTypes from "prop-types";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import styles from "./EulaModal.module.scss";

// Modal for agreeing or disagreeing to a new EULA.
export default function EulaModal(props: Props): Component {
  const [agreedToPolicy, setAgreedToPolicy] = useState<boolean>(false);

  // If the modal is closed, clear the policy agreement.
  useEffect(() => {
    if (!props.showModal) {
      setAgreedToPolicy(false);
    }
  }, [props.showModal]);

  return (
    <div data-test="confirmation-modal">
      <Modal
        show={props.showModal}
        onHide={() => props.onClose()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--super-modal-z-index)" }}
        animation
        centered
      >
        <ModalHeader>
          <h5>End-user License Agreement & Privacy Policy</h5>
        </ModalHeader>
        <ModalBody>
          <div className="my-4">
            <PrivacyPolicy status={agreedToPolicy} updated={true} onChange={(status) => setAgreedToPolicy(status)} />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            data-test="confirm-modal-cancel-button"
            type="button"
            className="btn btn-secondary"
            onClick={() => props.onDecline()}
          >
            Cancel
          </button>
          <button
            data-test="confirm-modal-confirm-button"
            type="button"
            className="btn btn-primary"
            data-dismiss="modal"
            disabled={!agreedToPolicy}
            onClick={() => props.onAccept()}
          >
            Continue
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

EulaModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
};

interface Props {
  showModal: boolean;
  onClose: () => void;
  onAccept: () => void;
  onDecline: () => void;
}
