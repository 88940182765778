// --------------------------------------------------------------
// Created On: 2024-07-21
// Author: Zachary Thomas
//
// Last Modified: 2024-09-13
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import { useDroppable } from "@dnd-kit/core";
import styles from "./DroppableArea.module.scss";

// Location for dropping draggable MapHighlightingColorForm to reorder list.
export default function DroppableArea(props: Props): Component {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
    disabled: props.disabled,
  });
  return (
    <div
      className={`${styles.body} ${isOver ? styles.highlighted : ""} ${props.isSlim ? styles.isSlim : ""}`}
      ref={setNodeRef}
    />
  );
}

DroppableArea.propTypes = {
  id: PropTypes.number.isRequired,
  isSlim: PropTypes.bool,
  disabled: PropTypes.bool,
};

interface Props {
  id: number;
  isSlim?: boolean;
  disabled?: boolean;
}
