// --------------------------------------------------------------
// Created On: 2023-12-28
// Author: Zachary Thomas
//
// Last Modified: 2023-12-28
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./SuccessStatusIcon.module.scss";

// Icon that either represents a loading state, success, or failure.
export default function SuccessStatusIcon(props: Props): Component {
  return props.loading ? (
    <i className={`${styles.loadingIcon} fa fa-fw fa-circle-o-notch fa-spin ${props.className}`} />
  ) : (
    <Fragment>
      {props.successful ? (
        <i className={`${styles.successIcon} fa fa-fw fa-check-circle ${props.className}`} />
      ) : (
        <i className={`${styles.failedIcon} fa fa-fw fa-times-circle ${props.className}`} />
      )}
    </Fragment>
  );
}

SuccessStatusIcon.propTypes = {
  loading: PropTypes.bool.isRequired,
  successful: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

interface Props {
  loading: boolean;
  successful: boolean;
  className?: string;
}
