// --------------------------------------------------------------
// Last Modified: 2024-08-26
// Author: Zachary Thomas
//
// Last Modified: 2024-08-26
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useState } from "react";
import useApi from "../../../hooks/useApi";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Spinner from "../../../components/Spinner/Spinner";
import { API } from "../../../constants/miscellaneous";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import { useParams } from "react-router-dom";
import formatDateLocal from "../../../utilities/time/formatDateLocal";
import PropTypes from "prop-types";
import styles from "./AlertLogModal.module.scss";
import formatDateShortLocal from "../../../utilities/time/formatDateShortLocal";

// Modal viewing delivery history of an alert log.
export default function AlertLogModal(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isAcknowledged, setIsAcknowledged] = useState<boolean>(false);
  const [isTimedOut, setIsTimedOut] = useState<boolean>(false);
  const [createdUtc, setCreatedUtc] = useState<string>("");
  const [deliveries, setDeliveries] = useState<AlertLogDelivery[]>([]);
  const currentUser = useSelector(getCurrentUser);
  const { assetId } = useParams();

  // Get alert log data from API.
  useApi(
    () => {
      if (props.alertLogId > 0) {
        setLoading(true);
        return true;
      } else {
        setLoading(false);
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/${assetId}/alertlog/${props.alertLogId}`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setMessage(responseBody.message);
        setIsAcknowledged(responseBody.isAcknowledged);
        setIsTimedOut(responseBody.isTimedOut);
        setCreatedUtc(responseBody.createdUtc);
        setDeliveries(responseBody.deliveries);
        setErrorMessage("");
      } else {
        setErrorMessage("Internal server error. Alert log details could not be retrieved.");
      }
      setLoading(false);
    },
    [assetId, props.alertLogId]
  );

  // Translates action to past tense.
  function translateAction(action: string): string {
    switch (action) {
      case "send":
        return "was sent";
      case "receive":
        return "received";
      default:
        return "<unknown>";
    }
  }

  return (
    <div>
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => props.onClose()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="xl"
        animation
        centered
      >
        <ModalHeader>
          <h5 className="font-weight-bold">
            <span>Alert Log Details</span>
          </h5>
        </ModalHeader>

        <ModalBody>
          {!loading && (
            <Fragment>
              <div className="m-2">
                <div className="pb-2">Alert Message:&nbsp;{message}</div>
                <div className="pb-2">Alert Acknowledged:&nbsp;{isAcknowledged ? "Yes" : "No"}</div>
                <div className="pb-2">Alert Timed Out:&nbsp;{isTimedOut ? "Yes" : "No"}</div>
                <div className="pb-2">Alert Creation Time:&nbsp;{formatDateLocal(createdUtc)}</div>

                {deliveries.length > 0 && <div className="pt-5">Delivery History</div>}
              </div>

              {deliveries.length > 0 && (
                <div className="m-2">
                  {deliveries.map((delivery) => (
                    <div key={delivery.alertDeliveryLogId} className={styles.delivery}>
                      {delivery.username} ({delivery.emailAddress}){" "}
                      {delivery.isAcknowledged ? "acknowledged" : translateAction(delivery.action)} an alert message (
                      {delivery.method}) at {formatDateShortLocal(delivery.alertUtc)}
                    </div>
                  ))}
                </div>
              )}

              {errorMessage.length > 0 && (
                <div className="row">
                  <div className="col mt-4 mx-2">
                    <Error message={errorMessage} />
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </ModalBody>

        <ModalFooter>
          <button
            className={`${styles.footer} ${styles.btn} btn btn-secondary`}
            type="button"
            onClick={() => props.onClose()}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

AlertLogModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  alertLogId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

interface Props {
  showModal: boolean;
  alertLogId: number;
  onClose: () => void;
}

interface ResponseBody {
  message: string;
  isAcknowledged: boolean;
  isTimedOut: boolean;
  createdUtc: string;
  deliveries: AlertLogDelivery[];
}

interface AlertLogDelivery {
  alertDeliveryLogId: number;
  username: string;
  emailAddress: string;
  isAcknowledged: true;
  action: string;
  method: string;
  alertUtc: string;
}
