// --------------------------------------------------------------
// Created On: 2024-09-12
// Author: Zachary Thomas
//
// Last Modified: 2024-09-15
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import IconTooltip from "../../../../components/IconTooltip/IconTooltip";
import PropTypes from "prop-types";
import { MAX_GAUGES } from "../../../../constants/templates";
import { Active, DndContext, Over } from "@dnd-kit/core";
import DroppableArea from "../../../../components/DroppableArea/DroppableArea";
import Gauge from "./Gauge/Gauge";
import styles from "./GaugePage.module.scss";

// Page in wizard for defining gauges.
export default function GaugePage(props: Props): Component {
  return (
    <div data-test="highlight-attribute-page">
      <div className="mx-1 my-2">
        <label className={styles.title}>
          <span className="ms-3 me-2">Remote Operation Gauges</span>
          <IconTooltip
            id="gauge-attribute-tooltip"
            icon="info-circle"
            message={`When viewing an asset's remote operation page, these gauges will show the current value
                      of an attribute with a bar showing the current position between the defined min and max.
                      There is a limit of ${MAX_GAUGES} gauges.`}
            color="var(--info-tooltip)"
          />
          <span className="me-2" />
          <IconTooltip
            id="gauge-image-tooltip"
            icon="picture-o"
            imageUrl="/images/template_gauge_example.png"
            imageAlt="Gauge example."
            imageMaxWidth={1000}
            color="var(--info-tooltip-image)"
          />
        </label>

        <button
          data-test="add-highlight-attribute-button"
          type="submit"
          className={`${styles.addButton} btn btn-success float-end`}
          onClick={() => props.onCreate()}
          disabled={props.disabled || props.gauges.length >= MAX_GAUGES}
        >
          <span className="d-none d-sm-inline">Add Gauge</span>
          <i className="d-inline d-sm-none fa fa-fw fa-plus fa-xs" />
        </button>
      </div>

      {props.gauges.length > 0 && (
        <DndContext onDragEnd={(e) => props.onDragEnd(e.over, e.active)}>
          <DroppableArea id={0} disabled={props.disabled} isSlim />
          {props.gauges.map((gauge, i) => (
            <Fragment key={gauge.formId}>
              <Gauge
                key={gauge.formId}
                formIndex={i}
                formId={gauge.formId}
                name={gauge.attributeName}
                min={gauge.min}
                max={gauge.max}
                unitShortName={gauge.unitShortName}
                disabled={props.disabled}
                onChange={(name, min, max) => {
                  props.onChange(gauge.formId, name, min, max);
                }}
                onDelete={() => props.onDelete(gauge.formId)}
                onDragEnd={(over, active) => props.onDragEnd(over, active)}
              />
              <DroppableArea id={gauge.formId} disabled={props.disabled} isSlim />
            </Fragment>
          ))}
        </DndContext>
      )}
    </div>
  );
}

GaugePage.propTypes = {
  gauges: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onCreate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
};

interface Props {
  gauges: Gauge[];
  disabled?: boolean;
  onCreate: () => void;
  onChange: (formId: number, name: string, min: string, max: string) => void;
  onDelete: (formId: number) => void;
  onDragEnd: (over: Over | null, active: Active) => void;
}

interface Gauge {
  formId: number;
  regAttributeId: number;
  attributeName: string;
  unitId: number | null;
  unitShortName: string | null;
  unitLongName: string | null;
  min: string | number;
  max: string | number;
}
