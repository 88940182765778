// --------------------------------------------------------------
// Created On: 2021-05-19
// Author: Zachary Thomas
//
// Last Modified: 2023-12-18
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Convert a string to title case.
export default function formatTitleCase(string: string): string {
  const words = string.toLowerCase().split(" ");
  words.forEach((word, i) => {
    let titleCaseSection = "";
    if (word.length === 1) {
      titleCaseSection = word[0].toUpperCase();
    } else if (word.length > 1) {
      titleCaseSection = word[0].toUpperCase() + word.slice(1);
    }
    words[i] = titleCaseSection;
  });
  return words.join(" ");
}
