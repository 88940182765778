// --------------------------------------------------------------
// Created On: 2023-06-23
// Author: Zachary Thomas
//
// Last Modified: 2024-09-30
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { LOW_FLOAT_ENGAGED, HIGH_FLOAT_ENGAGED, ALARM_FLOAT_ENGAGED } from "../../../../constants/attributes";
import styles from "./LiftStationVisual.module.scss";

// A data visualization for a lift station.
export default function LiftStationVisual(props: Props): Component {
  const floatLevel = useMemo(() => getFloatLevel(props.attributes), [JSON.stringify(props.attributes)]);

  // Get the current float level.
  function getFloatLevel(attributes: Attribute[]): FloatLevel {
    const alarmFloatEngagedAttribute = attributes.find((attribute) => attribute.attributeCode === ALARM_FLOAT_ENGAGED);
    const highFloatEngagedAttribute = attributes.find((attribute) => attribute.attributeCode === HIGH_FLOAT_ENGAGED);
    const lowFloatEngagedAttribute = attributes.find((attribute) => attribute.attributeCode === LOW_FLOAT_ENGAGED);
    if (
      alarmFloatEngagedAttribute !== undefined &&
      alarmFloatEngagedAttribute.currentValue !== null &&
      alarmFloatEngagedAttribute.currentValue.toUpperCase() === "TRUE"
    ) {
      return "ALARM";
    } else if (
      highFloatEngagedAttribute !== undefined &&
      highFloatEngagedAttribute.currentValue !== null &&
      highFloatEngagedAttribute.currentValue.toUpperCase() === "TRUE"
    ) {
      return "HIGH";
    } else if (
      lowFloatEngagedAttribute !== undefined &&
      lowFloatEngagedAttribute.currentValue !== null &&
      lowFloatEngagedAttribute.currentValue.toUpperCase() === "TRUE"
    ) {
      return "LOW";
    } else {
      return "EMPTY";
    }
  }

  return (
    <div className="row g-0">
      <div className={`${styles.svgContent} col-12 col-lg-6`}>
        {/* Main lift station image. */}
        {floatLevel === "EMPTY" && (
          <img
            className={styles.base}
            src="/productVisuals/liftStation/liftStation_empty.svg"
            alt="Lift Station Data Visualization"
          />
        )}
        {floatLevel === "LOW" && (
          <img
            className={styles.base}
            src="/productVisuals/liftStation/liftStation_low.svg"
            alt="Lift Station Data Visualization"
          />
        )}
        {floatLevel === "HIGH" && (
          <img
            className={styles.base}
            src="/productVisuals/liftStation/liftStation_high.svg"
            alt="Lift Station Data Visualization"
          />
        )}
        {floatLevel === "ALARM" && (
          <img
            className={styles.base}
            src="/productVisuals/liftStation/liftStation_alarm.svg"
            alt="Lift Station Data Visualization"
          />
        )}
        {/* Hover effects. */}
        <img
          className={props.hoverCode === ALARM_FLOAT_ENGAGED ? styles.hover : styles.hiddenHover}
          src="/productVisuals/liftStation/liftStation_alarmHover.svg"
          alt="Lift Station Data Visualization"
        />
        <img
          className={props.hoverCode === HIGH_FLOAT_ENGAGED ? styles.hover : styles.hiddenHover}
          src="/productVisuals/liftStation/liftStation_highHover.svg"
          alt="Lift Station Data Visualization"
        />
        <img
          className={props.hoverCode === LOW_FLOAT_ENGAGED ? styles.hover : styles.hiddenHover}
          src="/productVisuals/liftStation/liftStation_lowHover.svg"
          alt="Lift Station Data Visualization"
        />
      </div>
    </div>
  );
}

LiftStationVisual.propTypes = {
  attributes: PropTypes.array.isRequired,
  hoverCode: PropTypes.string,
};

interface Props {
  attributes: Attribute[];
  hoverCode: string | null;
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  connectedRegAttributeId: number | null;
  unitShortName: string | null;
  unitLongName: string | null;
  icon: string;
  currentValue: string | null;
  currentValueUtc: string | null;
  isHistorical: boolean;
  isGraphable: boolean;
  isStale: boolean;
}

type FloatLevel = "ALARM" | "HIGH" | "LOW" | "EMPTY";
