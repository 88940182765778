// --------------------------------------------------------------
// Created On: 2023-11-30
// Author: Zachary Thomas
//
// Last Modified: 2024-04-16
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import IconTooltip from "../../../components/IconTooltip/IconTooltip";
import Error from "../../../components/Error/Error";
import PropTypes from "prop-types";
import UserEscalationForm from "./UserEscalationForm/UserEscalationForm";
import deepCopy from "../../../utilities/deepCopy";
import styles from "./AlertEscalationModal.module.scss";

// Modal for selecting escalation/delivery rules for an alert threshold.
export default function AlertEscalationModal(props: Props): Component {
  const [assignedUsers, setAssignedUsers] = useState<FormAssignedUser[]>([]);
  const [timeoutInterval, setTimeoutInterval] = useState<number>(1);
  const [timeoutDelay, setTimeoutDelay] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formId, setFormId] = useState<number>(0);
  const [retryCount, setRetryCount] = useState<number>(0);

  // If the passed in values change, or the modal is closed/opened, reset the settings for subscriptions.
  useEffect(() => {
    let formId = 1;
    const formAssignedUsers: FormAssignedUser[] = [];
    props.assignedUsers.forEach((assignedUser) => {
      const formAssignedUser = {
        formId: formId,
        userCode: `${assignedUser.name} (${assignedUser.emailAddress})`,
        userId: assignedUser.userId,
        emailAddress: assignedUser.emailAddress,
        name: assignedUser.name,
        includeEmail: assignedUser.includeEmail,
        includeText: assignedUser.hasPhoneNumber && assignedUser.includeText,
        includeCall: assignedUser.hasPhoneNumber && assignedUser.includeCall,
        escalationLevel: assignedUser.escalationLevel,
        hasPhoneNumber: assignedUser.hasPhoneNumber,
      };
      formAssignedUsers.push(formAssignedUser);
      formId++;
    });
    setAssignedUsers(formAssignedUsers);
    setFormId(formId);
    setRetryCount(props.retryCount);
    setTimeoutInterval(props.timeoutInterval);
    setErrorMessage("");
  }, [JSON.stringify(props.assignedUsers), props.retryCount, props.timeoutInterval, props.showModal]);

  // Get current timeout delay by comparing timeout interval with user escalation levels.
  function getTimeoutDelay(): number {
    let maxEscalationLevel = 0;
    assignedUsers.forEach((user) => {
      if (user.escalationLevel > maxEscalationLevel) {
        maxEscalationLevel = user.escalationLevel;
      }
    });
    return timeoutInterval - maxEscalationLevel;
  }

  // Updates the timeout interval by using a timeout delay and comparing it to user escalation levels.
  function updateTimeoutInterval(timeoutDelay: number, assignedUsers: AssignedUser[]): void {
    let maxEscalationLevel = 0;
    assignedUsers.forEach((user) => {
      if (user.escalationLevel > maxEscalationLevel) {
        maxEscalationLevel = user.escalationLevel;
      }
    });
    setTimeoutInterval(maxEscalationLevel + timeoutDelay);
    setTimeoutDelay(timeoutDelay);
  }

  // Adds a user who still needs information filled out.
  function addUser(): void {
    const assignedUsersDeepCopy = deepCopy(assignedUsers);
    const newUser = {
      formId: formId,
      userCode: "",
      userId: 0,
      emailAddress: "",
      name: "",
      includeEmail: false,
      includeText: false,
      includeCall: false,
      escalationLevel: 0,
      hasPhoneNumber: false,
    };
    // Keep a unique ID key for unfilled out form fields so that React handles CRUD functionality on elements correctly.
    setFormId((prev) => prev + 1);
    setAssignedUsers([newUser, ...assignedUsersDeepCopy]);
  }

  // Delete a user.
  function deleteUser(userId: number): void {
    const assignedUsersDeepCopy = deepCopy(assignedUsers);
    const userIndex = assignedUsersDeepCopy.findIndex((assignedUser) => assignedUser.userId === userId);
    if (userIndex !== -1) {
      assignedUsersDeepCopy.splice(userIndex, 1);
      setAssignedUsers(assignedUsersDeepCopy);
      updateTimeoutInterval(timeoutDelay, assignedUsersDeepCopy);
    }
  }

  // Update a user.
  function updateUser(
    formId: number,
    user: string,
    includeEmail: boolean,
    includeText: boolean,
    includeCall: boolean,
    escalationLevel: number
  ): void {
    const assignedUsersDeepCopy = deepCopy(assignedUsers);

    // Get the record we are updating and its index.
    const updatedUser = assignedUsersDeepCopy.find((assignedUser) => assignedUser.formId === formId);
    const updatedUserIndex = assignedUsersDeepCopy.findIndex((assignedUser) => assignedUser.formId === formId);

    if (updatedUser !== undefined && updatedUserIndex > -1) {
      // Check if the form currently references a user that exists in the current company, otherwise a valid user isn't entered.
      let userMatchFound = false;

      // Check if the email has manually been entered as the user value (example: "johnDoe@gmail.com").
      let emailAddress = user;
      let userEmailAddressMatch = props.unassignedUsers.find(
        (unassignedUser) => unassignedUser.emailAddress.toLowerCase() === emailAddress.toLowerCase()
      );

      // If it wasn't entered manually, check if the email address has been selected from a list.
      // If it was, then we need to parse it (example: "John Doe (johnDoe@gmail.com)").
      if (userEmailAddressMatch === undefined) {
        const sliceIndex = user.lastIndexOf(" (");
        if (sliceIndex > -1) {
          emailAddress = user.slice(sliceIndex + 2, -1);
          userEmailAddressMatch = props.unassignedUsers.find(
            (unassignedUser) => unassignedUser.emailAddress.toLowerCase() === emailAddress.toLowerCase()
          );
        }
      }

      if (userEmailAddressMatch !== undefined) {
        updatedUser.userId = userEmailAddressMatch.userId;
        updatedUser.name = userEmailAddressMatch.name;
        updatedUser.emailAddress = userEmailAddressMatch.emailAddress;
        updatedUser.hasPhoneNumber = userEmailAddressMatch.hasPhoneNumber;
        userMatchFound = true;
      }

      // If we didn't find a match, give the user a userId of 0 to indicate no match found.
      if (userMatchFound === false) {
        updatedUser.userId = 0;
        updatedUser.name = "";
        updatedUser.emailAddress = "";
      }

      // Update additional settings.
      updatedUser.userCode = user;
      updatedUser.includeEmail = includeEmail;
      updatedUser.includeText = includeText;
      updatedUser.includeCall = includeCall;
      updatedUser.escalationLevel = escalationLevel;

      // Update the record in the list of assigned users.
      assignedUsersDeepCopy.splice(updatedUserIndex, 1, updatedUser);
      setAssignedUsers(assignedUsersDeepCopy);
      updateTimeoutInterval(timeoutDelay, assignedUsersDeepCopy);
    }
  }

  // Checks if the current escalation settings are valid.
  function escalationIsValid(): boolean {
    const invalidUser = assignedUsers.find((assignedUser) => assignedUser.userId < 1);
    if (invalidUser !== undefined) {
      setErrorMessage(`The user '${invalidUser.userCode}' does not exist.`);
      return false;
    } else if (duplicateUserAndDelay()) {
      setErrorMessage("The same user cannot appear twice unless there is a difference in the alert delivery delay.");
      return false;
    } else {
      return true;
    }
  }

  // Check if the same user appears twice with the same delivery delay.
  function duplicateUserAndDelay(): boolean {
    const assignedUserSet = new Set(
      assignedUsers.map((assignedUser) => `${assignedUser.userId}|${assignedUser.escalationLevel}`)
    );
    return assignedUserSet.size < assignedUsers.length;
  }

  // Save changes.
  function saveChanges(): void {
    if (escalationIsValid()) {
      setErrorMessage("");
      props.onChange(assignedUsers, timeoutInterval, retryCount);
    }
  }

  return (
    <Fragment>
      <Modal
        show={props.showModal}
        onHide={() => props.onClose()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--super-modal-z-index)" }}
        size="xl"
        animation
        centered
      >
        <ModalHeader>
          <h5 data-test="alert-threshold-manage-subscribed-users-modal-header" className="font-weight-bold">
            Manage Subscribed Users
          </h5>
        </ModalHeader>

        <ModalBody>
          <div className="mx-2 my-3" data-test="alert-threshold-manage-subscribed-users-modal-body">
            <label className={`${styles.formTitle} mb-3`}>Retry count </label>
            <IconTooltip
              id="retry-count-tooltip"
              icon="info-circle"
              message={
                "If an alert is not acknowledged and the alert condition is still met, " +
                "then we will restart the alert delivery process over again. The number of times that the alert delivery process " +
                "will be retried is based on the retry count."
              }
              color="var(--info-tooltip)"
            />
            <select
              className="form-select mb-4"
              data-test="manage-alert-threshold-retry-count-selector"
              value={String(retryCount)}
              disabled={props.isRental}
              onChange={(e) => setRetryCount(parseInt(e.target.value, 10))}
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </select>

            <label className={`${styles.formTitle} mb-3`}>Timeout delay </label>
            <IconTooltip
              id="timeout-delay-tooltip"
              icon="info-circle"
              message={
                "This value represents how long to wait after sending the final user an alert before treating the alert as " +
                "a timeout that was not acknowledged."
              }
              color="var(--info-tooltip)"
            />
            <select
              data-test="manage-alert-threshold-timeout-delay-selector"
              className="form-select mb-4"
              value={String(getTimeoutDelay())}
              disabled={props.isRental}
              onChange={(e) => updateTimeoutInterval(parseInt(e.target.value, 10), assignedUsers)}
            >
              <option value="1">5 minutes</option>
              <option value="2">10 minutes</option>
              <option value="3">15 minutes</option>
              <option value="4">20 minutes</option>
              <option value="5">25 minutes</option>
              <option value="6">30 minutes</option>
            </select>
          </div>

          <label className={`${styles.formTitle} mb-3`}>Subscribed users </label>
          <IconTooltip
            id="subscribed-users-tooltip"
            icon="info-circle"
            message={
              "These are the users that are subscribed to the current alert threshold. You can decide how long after the " +
              "initial alert each user will receive a message and The forms of communication used for contacting those users. " +
              "If a user acknowledges an alert, then no further messages will be sent out for that current alert. This functionality " +
              "supports the creation of alert escalation chains."
            }
            color="var(--info-tooltip)"
          />
          <button
            data-test="manage-alert-threshold-add-subscribed-user-button"
            className="btn btn-success float-end"
            type="button"
            disabled={props.isRental}
            onClick={() => addUser()}
          >
            Add User
          </button>

          <div data-test="manage-alert-threshold-subscribed-users-container">
            {assignedUsers.map((assignedUser) => (
              <UserEscalationForm
                key={assignedUser.formId}
                user={assignedUser.userCode}
                includeEmail={assignedUser.includeEmail}
                includeText={assignedUser.includeText}
                includeCall={assignedUser.includeCall}
                hasPhoneNumber={assignedUser.hasPhoneNumber}
                escalationLevel={assignedUser.escalationLevel}
                isRental={props.isRental}
                onChange={(user, includeEmail, includeText, includeCall, escalationLevel) =>
                  updateUser(assignedUser.formId, user, includeEmail, includeText, includeCall, escalationLevel)
                }
                onDeleteUser={() => deleteUser(assignedUser.userId)}
              />
            ))}
          </div>

          <Error message={errorMessage} />
        </ModalBody>

        <ModalFooter>
          {props.isRental ? (
            <button
              data-test="alert-threshold-modal-close-button"
              className="btn btn-secondary"
              type="button"
              onClick={() => props.onClose()}
            >
              Close
            </button>
          ) : (
            <Fragment>
              <button
                data-test="alert-threshold-modal-done-button"
                className="btn btn-primary"
                type="button"
                onClick={() => saveChanges()}
              >
                Done
              </button>

              <button
                data-test="alert-threshold-modal-cancel-button"
                className="btn btn-secondary"
                type="button"
                onClick={() => props.onClose()}
              >
                Cancel
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

AlertEscalationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  unassignedUsers: PropTypes.array.isRequired,
  assignedUsers: PropTypes.array.isRequired,
  retryCount: PropTypes.number.isRequired,
  timeoutInterval: PropTypes.number.isRequired,
  isRental: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

interface Props {
  showModal: boolean;
  unassignedUsers: UnassignedUser[];
  assignedUsers: AssignedUser[];
  retryCount: number;
  timeoutInterval: number;
  isRental: boolean;
  onChange: (users: AssignedUser[], timeoutInterval: number, retryCount: number) => void;
  onClose: () => void;
}

interface UnassignedUser {
  userId: number;
  emailAddress: string;
  name: string;
  hasPhoneNumber: boolean;
}

interface AssignedUser {
  userId: number;
  emailAddress: string;
  name: string;
  includeEmail: boolean;
  includeText: boolean;
  includeCall: boolean;
  escalationLevel: number;
  hasPhoneNumber: boolean;
}

interface FormAssignedUser {
  formId: number;
  userCode: string;
  userId: number;
  emailAddress: string;
  name: string;
  includeEmail: boolean;
  includeText: boolean;
  includeCall: boolean;
  escalationLevel: number;
  hasPhoneNumber: boolean;
}
