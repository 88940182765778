// --------------------------------------------------------------
// Created On: 2023-12-19
// Author: Zachary Thomas
//
// Last Modified: 2023-12-28
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import { useMemo } from "react";
import PropTypes from "prop-types";
import SuccessStatusIcon from "../../../components/SuccessStatusIcon/SuccessStatusIcon";
import styles from "./AssetThresholdProgressDisplay.module.scss";

// Displays the current progress of applying thresholds to assets.
export default function AssetThresholdProgressDisplay(props: Props): Component {
  const progressPercentage = useMemo(() => getProgressPercentage(props.assets), [JSON.stringify(props.assets)]);

  // Calculate the current progress percentage as an integer.
  function getProgressPercentage(assets: ProgressAsset[]): number {
    const assetCount = assets.length;
    if (assetCount === 0) {
      return 100;
    } else {
      let completedAssets = 0;
      assets.forEach((asset) => {
        if (!asset.loading) {
          completedAssets++;
        }
      });
      return Math.round((completedAssets / assetCount) * 100);
    }
  }

  return (
    <div>
      <div className="progress my-4">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progressPercentage}%` }}
          aria-valuenow={progressPercentage}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          {progressPercentage}%
        </div>
      </div>

      <div className={`${styles.assetContainer} row mx-2 my-4`}>
        {props.assets.map((asset) => (
          <div
            key={asset.assetId}
            className={`${styles.asset} ${!asset.loading && asset.successful ? styles.success : ""} ${
              !asset.loading && !asset.successful ? styles.failed : ""
            } col-12 col-md-6 col-lg-4`}
          >
            {asset.name}

            <SuccessStatusIcon loading={asset.loading} successful={asset.successful} className="float-end mt-1" />
          </div>
        ))}
      </div>
    </div>
  );
}

AssetThresholdProgressDisplay.propTypes = {
  assets: PropTypes.array.isRequired,
};

interface Props {
  assets: ProgressAsset[];
}

interface ProgressAsset {
  assetId: number;
  name: string;
  loading: boolean;
  successful: boolean;
}
