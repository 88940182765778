// --------------------------------------------------------------
// Created On: 2023-07-20
// Author: Zachary Thomas
//
// Last Modified: 2024-09-17
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { POWER_TO_PANEL_ATTRIBUTE, COMMON_ALARM_ATTRIBUTE } from "../../../constants/attributes";
import formatDateShortLocal from "../../../utilities/time/formatDateShortLocal";
import LightIcon from "../../../components/LightIcon/LightIcon";
import isValueBooleanString from "../../../utilities/isValueBooleanString";
import {
  COPILOT_DEVICE_TYPE,
  API,
  SECONDS_BETWEEN_DEVICE_PENDING_STATUS_QUERIES,
  MS_PER_SECOND,
  COPILOT_PMG_DEVICE_TYPE,
} from "../../../constants/miscellaneous";
import SampleDeviceDataButton from "../../../components/SampleDeviceDataButton/SampleDeviceDataButton";
import apiRequest from "../../../utilities/api/apiRequest";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import nowToUtcIso from "../../../utilities/time/nowToUtcIso";
import styles from "./OperationDetailsPanel.module.scss";

// Panel for viewing operation related details about an asset.
export default function OperationDetailsPanel(props: Props): Component {
  const [deviceIsAwaitingCommand, setDeviceIsAwaitingCommand] = useState<boolean | null>(null);
  const [pendingStatusUtc, setPendingStatusUtc] = useState<string | null>(null);
  const currentUser = useSelector(getCurrentUser);

  // Check if monitoring device is ready to accept new commands every 5 seconds after page load.
  useEffect(() => {
    const timerId = setTimeout(
      () => getAssetPendingCommandStatus(),
      MS_PER_SECOND * SECONDS_BETWEEN_DEVICE_PENDING_STATUS_QUERIES
    );
    return () => {
      clearTimeout(timerId);
    };
  }, [pendingStatusUtc]);

  // Get pending command status for an asset.
  async function getAssetPendingCommandStatus() {
    const [response, responseBody] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/asset/${props.assetId}/operation/pendingcommand`,
      "GET",
      null
    )) as [Response, PendingCommandResponseBody];

    if (response.ok && responseBody) {
      setDeviceIsAwaitingCommand(!responseBody.pendingCommand);
      setPendingStatusUtc(nowToUtcIso(0));
    } else {
      setDeviceIsAwaitingCommand(false);
      setPendingStatusUtc(nowToUtcIso(0));
    }
  }

  // Determines if a light should be shown as on or off based on the attribute code and value.
  function lightIsOn(attributeCode: string, value: string | null): boolean {
    if (value === null) {
      return false;
    } else if (props.staleAttributeCodes.includes(attributeCode)) {
      return false;
    } else if (attributeCode === POWER_TO_PANEL_ATTRIBUTE) {
      return true;
    } else if (isValueBooleanString(value) && value.toLowerCase() === "true") {
      return true;
    } else {
      return false;
    }
  }

  // Determines what color a light should be based on the attribute code and value.
  function lightColor(attributeCode: string, value: string | null): "red" | "green" {
    if (value === null) {
      return "red";
    } else if (
      attributeCode === POWER_TO_PANEL_ATTRIBUTE &&
      isValueBooleanString(value) &&
      value.toLowerCase() === "false"
    ) {
      return "red";
    } else if (
      attributeCode === COMMON_ALARM_ATTRIBUTE &&
      isValueBooleanString(value) &&
      value.toLowerCase() === "true"
    ) {
      return "red";
    } else {
      return "green";
    }
  }

  return (
    <div className={styles.body}>
      <div className={styles.titleBar}>
        <label className={styles.title}>Attributes</label>
        {props.deviceId !== null && props.userCanOperate && (
          <SampleDeviceDataButton
            className="float-end me-3"
            deviceId={props.deviceId}
            onDeviceRead={() => props.onDeviceRead()}
            onIsSamplingChange={(isSampling) => props.onIsSamplingChange(isSampling)}
            smallButton
          />
        )}
      </div>
      <div>
        <table className={`${styles.table} table mb-0 pb-0`}>
          <thead className={styles.thead}>
            <tr>
              <th className={styles.th}>Name</th>
              <th className={styles.th}>Value</th>
              <th className={styles.th} />
              <th className={styles.th}>Time</th>
            </tr>
          </thead>
          <tbody>
            {props.attributes.map((attribute, i) => (
              <tr
                key={i}
                className={
                  props.staleAttributeCodes.includes(attribute.attributeCode) ? styles.staleData : styles.activeData
                }
              >
                <td>{attribute.attributeName}</td>
                <td>
                  {attribute.currentValue === null
                    ? "N/A"
                    : `${attribute.currentValue} ${attribute.unitShortName === null ? "" : attribute.unitShortName}`}
                </td>
                <td>
                  {isValueBooleanString(attribute.currentValue) && (
                    <LightIcon
                      isOn={lightIsOn(attribute.attributeCode, attribute.currentValue)}
                      color={lightColor(attribute.attributeCode, attribute.currentValue)}
                    />
                  )}
                </td>
                <td>{attribute.currentValueUtc === null ? "N/A" : formatDateShortLocal(attribute.currentValueUtc)}</td>
              </tr>
            ))}

            {/* Show Co-Pilot's pending command status. */}
            {[COPILOT_DEVICE_TYPE, COPILOT_PMG_DEVICE_TYPE].includes(props.deviceType || "") &&
              deviceIsAwaitingCommand !== null && (
                <tr className={styles.activeData}>
                  <td>Monitoring Device is Ready</td>
                  <td>{deviceIsAwaitingCommand ? "True" : "False"}</td>
                  <td>
                    <LightIcon isOn={true} color={deviceIsAwaitingCommand ? "green" : "red"} />
                  </td>
                  <td>{pendingStatusUtc === null ? "N/A" : formatDateShortLocal(pendingStatusUtc)}</td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

OperationDetailsPanel.propTypes = {
  assetId: PropTypes.number.isRequired,
  attributes: PropTypes.array.isRequired,
  staleAttributeCodes: PropTypes.array.isRequired,
  deviceId: PropTypes.number,
  deviceType: PropTypes.string,
  queriesRemaining: PropTypes.number.isRequired,
  userCanOperate: PropTypes.bool.isRequired,
  onIsSamplingChange: PropTypes.func.isRequired,
};

interface Props {
  assetId: number;
  attributes: ListAttribute[];
  staleAttributeCodes: string[];
  deviceId: number | null;
  deviceType: string | null;
  queriesRemaining: number;
  userCanOperate: boolean;
  onDeviceRead: () => void;
  onIsSamplingChange: (isSampling: boolean) => void;
}

interface ListAttribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  unitShortName: string;
  currentValue: string | null;
  currentValueUtc: string | null;
}

interface PendingCommandResponseBody {
  pendingCommand: boolean;
}
