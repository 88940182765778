// --------------------------------------------------------------
// Created On: 2024-08-23
// Author: KC Willard
//
// Last Modified: 2024-09-13
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import React from "react";
import { MAX_TEMPLATE_NAME_LENGTH, MAX_TEMPLATE_DESCRIPTION_LENGTH } from "../../../../constants/templates";
import PropTypes from "prop-types";
import styles from "./TemplateNameForm.module.scss";

// Form for naming and adding a description to a template.
export default function TemplateNameForm(props: Props): Component {
  return (
    <div>
      <div className="col mx-3">
        <label className={`${styles.title} mb-3`}>Template Name</label>
        <input
          data-test="template-name-form"
          className="form-control mx-auto"
          type="text"
          maxLength={MAX_TEMPLATE_NAME_LENGTH}
          value={props.name !== null ? props.name : ""}
          disabled={props.disabled}
          onChange={(e) => props.onChangeName(e.target.value)}
        />
      </div>
      <div className="col m-3">
        <label className={`${styles.title} mb-3`}>Template Description</label>
        <textarea
          data-test="template-description-text-area"
          className="form-control mx-auto"
          maxLength={MAX_TEMPLATE_DESCRIPTION_LENGTH}
          value={props.description}
          disabled={props.disabled}
          onChange={(e) => props.onChangeDescription(e.target.value)}
        />
      </div>
    </div>
  );
}

TemplateNameForm.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChangeName: PropTypes.func.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
};

interface Props {
  name: string;
  description: string;
  disabled?: boolean;
  onChangeName: (name: string) => void;
  onChangeDescription: (description: string) => void;
}
