// --------------------------------------------------------------
// Created On: 2022-05-18
// Author: Zachary Thomas
//
// Last Modified: 2024-05-14
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import PropTypes from "prop-types";
import { MAX_PASSWORD_LENGTH } from "../../constants/miscellaneous";
import styles from "./PasswordInput.module.scss";

// Input for storing passwords with visibility toggle button.
export default function PasswordInput(props: Props): Component {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <div className={styles.body}>
      <input
        data-test="password-input"
        type={showPassword ? "text" : "password"}
        className="login-input form-control my-2"
        aria-describedby="Password"
        placeholder="Enter password"
        maxLength={MAX_PASSWORD_LENGTH}
        autoComplete="current-password"
        value={props.password}
        onChange={(e) => props.onChangePassword(e.target.value)}
      />

      <i
        className={`${styles.toggleVisible} fa fa-fw fa-eye${showPassword ? "" : "-slash"}`}
        onClick={() => setShowPassword((prev) => !prev)}
      />
    </div>
  );
}

PasswordInput.propTypes = {
  password: PropTypes.string.isRequired,
  onChangePassword: PropTypes.func.isRequired,
};

interface Props {
  password: string;
  onChangePassword: (password: string) => void;
}
