// --------------------------------------------------------------
// Created On: 2023-11-03
// Author: Zachary Thomas
//
// Last Modified: 2024-08-26
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import formatDateShortLocal from "../../../utilities/time/formatDateShortLocal";
import PropTypes from "prop-types";

// A single alert log row in an alert log list.
export default function AlertLogListRow(props: Props): Component {
  return (
    <div className="p-2">
      {`${props.message} (${formatDateShortLocal(props.createdUtc)}) ${props.isAcknowledged ? " [Acknowledged]" : ""} ${
        props.isTimedOut ? " [Timed Out]" : ""
      }`}
    </div>
  );
}

AlertLogListRow.propTypes = {
  message: PropTypes.string.isRequired,
  isAcknowledged: PropTypes.bool.isRequired,
  isTimedOut: PropTypes.bool.isRequired,
  createdUtc: PropTypes.string.isRequired,
};

interface Props {
  message: string;
  isAcknowledged: boolean;
  isTimedOut: boolean;
  createdUtc: string;
}
