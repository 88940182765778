// --------------------------------------------------------------
// Created On: 2021-05-14
// Author: Zachary Thomas
//
// Last Modified: 2023-10-24
// Modified By: Zachary Thomas
//
// Copyright 2021 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./EllipsisSpinner.module.scss";

// Centered ellipsis spinner that lets the user know something is loading.
export default function EllipsisSpinner(props: Props): Component {
  return props.loading ? (
    <div className={styles.spinnerContainer}>
      <div role="status" className={styles.spinner} />
    </div>
  ) : null;
}

EllipsisSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
};

interface Props {
  loading: boolean;
}
