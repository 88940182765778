// --------------------------------------------------------------
// Created On: 2022-05-02
// Author: Zachary Thomas
//
// Last Modified: 2024-04-22
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import Spinner from "../../components/Spinner/Spinner";

// Very simple page that represents the first place where the mobile app lands when starting the application.
export default function MobileAppLandingPage(): Component {
  return <Spinner loading={true} />;
}
