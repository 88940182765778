// --------------------------------------------------------------
// Created On: 2021-12-31
// Author: Zachary Thomas
//
// Last Modified: 2022-06-09
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Convert degrees to radians.
export default function degreesToRadians(degrees: number): number {
  return (degrees * Math.PI) / 180;
}
