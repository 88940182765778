// --------------------------------------------------------------
// Created On: 2023-03-06
// Author: Zachary Thomas
//
// Last Modified: 2024-09-10
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./AssetListColumnHeader.module.scss";

// Column header that supports sorting and filtering results.
export default function AssetListColumnHeader(props: Props): Component {
  //
  function handleSwitchingNameAndNickname(
    showNickname: boolean | undefined,
    onChangeShowNickname: (() => void) | undefined
  ): void {
    if (showNickname !== undefined) {
      // We want to swap what is currently stored for 'showNickname'.
      let showNicknameString = "true";
      if (showNickname) {
        showNicknameString = "false";
      }
      localStorage.setItem("showNickname", showNicknameString);
      if (onChangeShowNickname !== undefined) {
        onChangeShowNickname();
      }
    }
  }

  return (
    <th className={`${styles.body} ${styles.title}`}>
      {/* Label for printing. */}
      {props.enableNameNicknameSwapping ? (
        <span className={styles.printLabel}>{props.showNickname ? "Nickname" : "Name"}</span>
      ) : (
        <span className={styles.printLabel}>{props.title}</span>
      )}

      {/* Dropdown button. */}
      <div className={`${styles.dropdownContainer} dropdown`}>
        <button
          className={`${styles.dropdown} btn btn-secondary dropdown-toggle ms-2`}
          type="button"
          data-boundary="viewport"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {props.enableNameNicknameSwapping ? (
            <span>{props.showNickname ? "Nickname" : "Name"}</span>
          ) : (
            <span>{props.title}</span>
          )}
          <span className={styles.dropdownArrow}>&nbsp;&#9660;</span>
        </button>

        {/* Drop down options. */}
        <div className={`${styles.dropdownMenu} dropdown-menu dropdown-menu-end`}>
          <button className={`${styles.option} dropdown-item`} onClick={() => props.onSort(true)}>
            {props.sortAscendingText ? props.sortAscendingText : "Sort Smallest to Largest"}
          </button>
          <button className={`${styles.option} dropdown-item`} onClick={() => props.onSort(false)}>
            {props.sortDescendingText ? props.sortDescendingText : "Sort Largest to Smallest"}
          </button>
          {props.enableNameNicknameSwapping && (
            <button
              className={`${styles.option} dropdown-item`}
              onClick={() => handleSwitchingNameAndNickname(props.showNickname, props.onChangeShowNickname)}
            >
              {props.showNickname ? "Switch to displaying 'Name'" : "Switch to displaying 'Nickname'"}
            </button>
          )}
          {props.filterOptions.map((filterOption) => (
            <button
              key={filterOption.title}
              className={`${styles.option} dropdown-item`}
              onClick={(e) => props.onChangeFilter(filterOption.title, !filterOption.checked, e)}
            >
              <i className={`fa fa-fw fa-${filterOption.checked ? "check-square" : "square"} me-3`} />
              <span>{filterOption.title}</span>
            </button>
          ))}
        </div>
      </div>
    </th>
  );
}

AssetListColumnHeader.propTypes = {
  title: PropTypes.string.isRequired,
  filterOptions: PropTypes.array.isRequired,
  sortAscendingText: PropTypes.string,
  sortDescendingText: PropTypes.string,
  enableNameNicknameSwapping: PropTypes.bool,
  showNickname: PropTypes.bool,
  onChangeFilter: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onChangeShowNickname: PropTypes.func,
};

interface Props {
  title: string;
  filterOptions: FilterOption[];
  sortAscendingText?: string;
  sortDescendingText?: string;
  enableNameNicknameSwapping?: boolean;
  showNickname?: boolean;
  onChangeFilter: (title: string, checked: boolean, e: React.MouseEvent<HTMLElement>) => void;
  onSort: (isAscending: boolean) => void;
  onChangeShowNickname?: () => void;
}

interface FilterOption {
  title: string;
  checked: boolean;
}
