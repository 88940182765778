// --------------------------------------------------------------
// Created On: 2022-01-24
// Author: Zachary Thomas
//
// Last Modified: 2022-01-24
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import formatTitleCase from "../../../../../utilities/formatTitleCase";
import {
  MAX_ALERT_MESSAGE_LENGTH,
  ALERT_EMERGENCY,
  ALERT_HIGH,
  ALERT_MEDIUM,
  ALERT_LOW,
  ALERT_INFO,
} from "../../../../../constants/miscellaneous";
import PropTypes from "prop-types";

// Form for sending an alert message.
export default function AlertMessageForm(props: Props): Component {
  return (
    <div className="component-template">
      <div className="form-alert-message mx-2 mt-3">
        <label className="mb-3">Urgency</label>
        <select
          className="form-select"
          value={props.urgency}
          onChange={(e) => props.onChangeUrgency(e.target.value)}
        >
          <option value={ALERT_EMERGENCY}>{formatTitleCase(ALERT_EMERGENCY)}</option>

          <option value={ALERT_HIGH}>{formatTitleCase(ALERT_HIGH)}</option>

          <option value={ALERT_MEDIUM}>{formatTitleCase(ALERT_MEDIUM)}</option>

          <option value={ALERT_LOW}>{formatTitleCase(ALERT_LOW)}</option>

          <option value={ALERT_INFO}>{formatTitleCase(ALERT_INFO)}</option>
        </select>
      </div>

      <div className="form-alert-message mx-2 mt-3">
        <label className="mb-3">Alert Message</label>
        <textarea
          className="form-control"
          rows={3}
          maxLength={MAX_ALERT_MESSAGE_LENGTH}
          value={props.alertMessage}
          onChange={(e) => props.onChangeAlertMessage(e.target.value)}
        />
      </div>
    </div>
  );
}

AlertMessageForm.propTypes = {
  urgency: PropTypes.string.isRequired,
  alertMessage: PropTypes.string.isRequired,
  onChangeUrgency: PropTypes.func.isRequired,
  onChangeAlertMessage: PropTypes.func.isRequired,
};

interface Props {
  urgency: string;
  alertMessage: string;
  onChangeUrgency: (urgency: string) => void;
  onChangeAlertMessage: (alertMessage: string) => void;
}
