// --------------------------------------------------------------
// Created On: 2021-09-01
// Author: Zachary Thomas
//
// Last Modified: 2024-06-24
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";

// Button for remotely changing speed (RPM or Hertz).
export default function SpeedChangeButton(props: Props): Component {
  const hertz = props.rpmValue;
  return (
    <button
      className="btn btn-primary w-100"
      type="button"
      disabled={props.disabled}
      onClick={() => props.onClick(props.rpmValue)}
    >
      {props.showHertz ? (
        <span>{hertz} hertz</span>
      ) : (
        <span>
          {props.rpmValue < 1000 ? <span>&nbsp;</span> : ""}
          {props.rpmValue} RPM
        </span>
      )}
    </button>
  );
}

SpeedChangeButton.propTypes = {
  rpmValue: PropTypes.number.isRequired,
  showHertz: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

interface Props {
  rpmValue: number;
  showHertz: boolean;
  disabled?: boolean;
  onClick: (rpm: number) => void;
}
