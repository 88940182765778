// --------------------------------------------------------------
// Created On: 2021-05-14
// Author: Zachary Thomas
//
// Last Modified: 2024-04-23
// Modified By: Zachary Thomas
//
// Copyright 2022 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.scss";

// General footer.
export default function Footer(): Component {
  const date = new Date();

  return (
    <footer className={`${styles.body} py-1 px-3`}>
      <div className="row">
        <div className="col-auto me-3">
          <Link className={styles.link} data-test="eula-footer-link" to={`/eula-text`}>
            EULA
          </Link>
        </div>
        <div className="col-auto me-3">
          <Link className={styles.link} data-test="privacy-policy-footer-link" to={`/privacy-policy-text`}>
            Privacy Policy
          </Link>
        </div>

        <div className="col-auto col-xs ms-auto">
          <span className={styles.copyright}>
            &#xA9; {date.getFullYear()}, Cornell Pump Company. All rights reserved.
          </span>
        </div>
      </div>
    </footer>
  );
}
