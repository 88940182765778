// --------------------------------------------------------------
// Created On: 2023-01-27
// Author: Zachary Thomas
//
// Last Modified: 2024-03-06
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";

// A single report in a list of reports.
export default function ReportListRow(props: Props): Component {
  return (
    <div className="p-2" data-test="report-list-row">
      <span>{props.name}</span>
    </div>
  );
}

ReportListRow.propTypes = {
  reportId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

interface Props {
  reportId: number;
  name: string;
}
