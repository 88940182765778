// --------------------------------------------------------------
// Created On: 2022-05-11
// Author: Zachary Thomas
//
// Last Modified: 2024-05-10
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import IconTooltip from "../../../components/IconTooltip/IconTooltip";
import { MAX_COMPANY_NAME_LENGTH } from "../../../constants/miscellaneous";

// Form for filling out company information when creating a new account.
export default function CompanyForm(props: Props): Component {
  return (
    <div className="mb-4">
      {props.name.length > 2 && (
        <datalist id="datalist-companies">
          {props.companies.map((company) => (
            <option key={company.companyId} value={company.name} />
          ))}
        </datalist>
      )}

      <div className="mx-2 pb-3">
        <label className="mb-3">
          <span className="font-weight-bold">Company Name</span>
          <IconTooltip
            id="company-tooltip"
            icon="info-circle"
            message="This is the name that other users will see when being invited to your account or when transferring assets across accounts."
            color="var(--info-tooltip)"
          />
        </label>

        <input
          className="form-control mx-auto"
          type="text"
          maxLength={MAX_COMPANY_NAME_LENGTH}
          list="datalist-companies"
          autoComplete="off"
          value={props.name}
          onChange={(e) => props.onChangeName(e.target.value)}
        />
      </div>
    </div>
  );
}

CompanyForm.propTypes = {
  name: PropTypes.string.isRequired,
  onChangeName: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
  companyAlreadyExists: PropTypes.bool.isRequired,
};

interface Props {
  name: string;
  onChangeName: (name: string) => void;
  companies: Company[];
  companyAlreadyExists: boolean;
}

interface Company {
  companyId: number;
  name: string;
  code: string;
}
