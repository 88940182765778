// --------------------------------------------------------------
// Created On: 2023-08-30
// Author: Zachary Thomas
//
// Last Modified: 2023-09-05
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import styles from "./SensorConnectedIcon.module.scss";

// Icon that looks different based on if a sensor is connected or not, with a tooltip.
export default function SensorConnectedIcon(props: Props): Component {
  return props.isConnected !== null ? (
    <span data-tip data-for={`connected-icon-${props.attributeCode}`} className={styles.iconStack}>
      <i
        className={`${props.isConnected ? styles.connected : styles.disconnected} ${
          styles.iconStackBottom
        } fa fa-fw fa-plug`}
      />
      {!props.isConnected && (
        <i className={`${styles.iconStackTop} ${styles.disconnected} fa fa-fw fa-exclamation`} />
      )}
      <ReactTooltip
        id={`connected-icon-${props.attributeCode}`}
        place="top"
        effect="solid"
        className={styles.tooltip}
      >
        {props.isConnected ? "This sensor is currently connected." : "This sensor is not connected"}
      </ReactTooltip>
    </span>
  ) : null;
}

SensorConnectedIcon.propTypes = {
  attributeCode: PropTypes.string.isRequired,
  isConnected: PropTypes.bool,
};

interface Props {
  attributeCode: string;
  isConnected: boolean | null;
}
