// --------------------------------------------------------------
// Created On: 2022-10-31
// Author: Zachary Thomas
//
// Last Modified: 2023-11-17
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import { intervalToDuration } from "date-fns";
import isoToLocalDate from "./isoToLocalDate";

// Gets the amount of time since the specified date as a string describing the time passed.
export default function isoToTimePassed(isoUtcTime: string | null): string {
  if (isoUtcTime === null) {
    return `0 seconds`;
  } else {
    const nowDate = new Date();
    const localDate = isoToLocalDate(isoUtcTime);
    const millisecondsPassed = nowDate.getTime() - localDate.getTime();
    const duration = intervalToDuration({ start: 0, end: millisecondsPassed });
    if (duration.years !== undefined && duration.years > 0) {
      return `${duration.years} year${duration.years > 1 ? "s" : ""}`;
    } else if (duration.months !== undefined && duration.months > 0) {
      return `${duration.months} month${duration.months > 1 ? "s" : ""}`;
    } else if (duration.weeks !== undefined && duration.weeks > 0) {
      return `${duration.weeks} week${duration.weeks > 1 ? "s" : ""}`;
    } else if (duration.days !== undefined && duration.days > 0) {
      return `${duration.days} day${duration.days > 1 ? "s" : ""}`;
    } else if (duration.hours !== undefined && duration.hours > 0) {
      return `${duration.hours} hour${duration.hours > 1 ? "s" : ""}`;
    } else if (duration.minutes !== undefined && duration.minutes > 0) {
      return `${duration.minutes} minute${duration.minutes > 1 ? "s" : ""}`;
    } else if (duration.seconds !== undefined && duration.seconds > 0) {
      return `${duration.seconds} second${duration.seconds > 1 ? "s" : ""}`;
    } else {
      return `0 seconds`;
    }
  }
}
