// --------------------------------------------------------------
// Created On: 2021-05-14
// Author: Zachary Thomas
//
// Last Modified: 2024-04-17
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./InlineEllipsisSpinner.module.scss";

// Inline ellipsis spinner that lets the user know something is loading.
export default function InlineEllipsisSpinner(props: Props): Component {
  return props.loading ? (
    <div className={styles.inlineSpinnerContainer} data-test="inline-ellipsis-spinner">
      <div role="status" className={styles.spinner} />
    </div>
  ) : null;
}

InlineEllipsisSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
};

interface Props {
  loading: boolean;
}
