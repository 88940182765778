// --------------------------------------------------------------
// Created On: 2022-09-01
// Author: Zachary Thomas
//
// Last Modified: 2024-04-11
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Add a number of days (or subtract with a negative number) to an ISO 8601 string.
export default function isoAddDays(iso: string, days: number): string {
  const date = new Date(iso);
  date.setDate(date.getDate() + days);
  return date.toISOString();
}
