// --------------------------------------------------------------
// Created On: 2021-08-26
// Author: Zachary Thomas
//
// Last Modified: 2024-01-31
// Modified By: Zachary Thomas
//
// Copyright 2022 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useEffect } from "react";
import { SHOW_TOAST_MILLISECONDS } from "../../constants/miscellaneous";
import PropTypes from "prop-types";
import styles from "./Toast.module.scss";

// A toast that displays a message.
export default function Toast(props: Props): Component {
  // Hide the toast automatically after it has been shown for some time.
  useEffect(() => {
    if (props.show) {
      const newTimerId = setTimeout(() => props.onClose(), SHOW_TOAST_MILLISECONDS);
      return () => {
        clearTimeout(newTimerId);
      };
    }
  }, [props.show]);

  return props.show ? (
    <div
      className={
        `${styles.body}` +
        ` ${props.type === "error" ? styles.error : ""}` +
        ` ${props.type === "success" ? styles.success : ""}`
      }
      onClick={() => props.onClose()}
      role="alert"
    >
      <div className={styles.header}>
        <strong className="me-auto">{props.title}</strong>
      </div>

      <div className={styles.content}>{props.message}</div>
    </div>
  ) : null;
}

Toast.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["success", "error"]),
  onClose: PropTypes.func.isRequired,
};

interface Props {
  title: string;
  message: string;
  show: boolean;
  type?: "success" | "error";
  onClose: () => void;
}
