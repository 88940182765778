// --------------------------------------------------------------
// Created On: 2023-03-14
// Author: Zachary Thomas
//
// Last Modified: 2023-03-14
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import scheduleTimeConversion from "./scheduleTimeConversion";

// Convert schedule settings from UTC to local time.
export default function scheduleFromUtcToLocal(
  scheduleSettings: ReportSchedule[]
): ReportSchedule[] | null {
  return scheduleTimeConversion(scheduleSettings, true);
}
