// --------------------------------------------------------------
// Created On: 2021-10-01
// Author: Zachary Thomas
//
// Last Modified: 2024-04-16
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./AssociatedAssetgroupItem.module.scss";

// A single child asset group in the asset group modal.
export default function AssociatedAssetgroupItem(props: Props): Component {
  return (
    <Fragment>
      <div
      data-test="assetgroup-associated-asset-group-item"
      className={`${styles.body}px-4 py-2`}
      >
        {props.name}
      </div>
    </Fragment>
  );
}

AssociatedAssetgroupItem.propTypes = {
  assetgroupId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

interface Props {
  assetgroupId: number;
  name: string;
}
