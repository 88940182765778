// --------------------------------------------------------------
// Created On: 2022-09-20
// Author: Zachary Thomas
//
// Last Modified: 2022-09-20
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";

// Explains what a maintenance log is.
export default function HelpMaintenance(): Component {
  return (
    <div>
      <p className="px-3 my-3">
        Maintenance logs are used to keep track of when an asset was serviced. Maintenance logs
        include a description of the service, who performed the service, date, time, cost, and any
        additional notes.
      </p>
    </div>
  );
}
