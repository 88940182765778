// --------------------------------------------------------------
// Created On: 2024-07-24
// Author: Hannah Vaughan
//
// Last Modified: 2024-10-29
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./ControllerConfigurationsTable.module.scss";
import IconTooltip from "../../../../components/IconTooltip/IconTooltip";

// This table displays and allows writing to specific controller configuration registers.
export default function ControllerConfigurationsTable(props: Props): Component {
  // Returns a specific CSS module class for styling based on the value passed in from props.hideHeaderBreakpoint.
  // The reason we cannot do this inline is that Bootstrap V5 does not support 'table-header-group' as a display
  // property and 'table-header-group' is what we will need to display a 'thead' correctly when it should be displayed.
  function getTheadClass(hideHeaderBreakpoint: Breakpoint | undefined): string {
    switch (hideHeaderBreakpoint) {
      case "sm":
        return `${styles.theadSm}`;
      case "md":
        return `${styles.theadMd}`;
      case "lg":
        return `${styles.theadLg}`;
      case "xl":
        return `${styles.theadXl}`;
      case "xxl":
        return `${styles.theadXxl}`;
      default:
        return "";
    }
  }

  return (
    <table className={`${styles.table} table align-middle mb-0 pb-0`}>
      <thead className={getTheadClass(props.hideHeaderBreakpoint)}>
        <tr>
          <th scope="col" className={styles.th}>
            Parameter
          </th>
          <th scope="col" className={styles.th}>
            Value
          </th>
          {props.hasUnits && (
            <th scope="col" className={styles.th}>
              Unit
            </th>
          )}
          <th scope="col" className={styles.th} />
        </tr>
      </thead>
      <tbody>
        {props.controllerConfigurations.map((controllerConfig) => (
          <tr key={controllerConfig.controllerConfigurationId}>
            <td>
              {controllerConfig.name}
              <IconTooltip
                id={`${controllerConfig.controllerConfigurationId}-controller-config-tooltip`}
                icon="info-circle"
                message={controllerConfig.tooltip}
                color="var(--info-tooltip)"
              />
            </td>
            <td className={styles.configurationInput}>
              <input
                type="text"
                className="form-control form-control-sm"
                value={controllerConfig.value === null ? "" : `${controllerConfig.value}`}
                onChange={(e) =>
                  props.onChangeControllerConfig(controllerConfig.controllerConfigurationId, e.target.value)
                }
              />
            </td>
            {props.hasUnits && <td>{controllerConfig.units}</td>}
            <td>
              <button
                className="btn btn-sm btn-primary float-end"
                type="button"
                disabled={controllerConfig.value === controllerConfig.previousValue || props.loading}
                onClick={() => {
                  props.onWriteControllerConfig(
                    controllerConfig.controllerConfigurationId,
                    `${controllerConfig.value}`
                  );
                }}
              >
                Write
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

ControllerConfigurationsTable.propTypes = {
  hasUnits: PropTypes.bool.isRequired,
  controllerConfigurations: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  hideHeaderBreakpoint: PropTypes.string,
  onChangeControllerConfig: PropTypes.func.isRequired,
  onWriteControllerConfig: PropTypes.func.isRequired,
};

interface Props {
  hasUnits: boolean;
  controllerConfigurations: ControllerConfiguration[];
  loading: boolean;
  hideHeaderBreakpoint?: Breakpoint;
  onChangeControllerConfig: (controllerConfigurationId: number, value: string) => void;
  onWriteControllerConfig: (controllerConfigurationId: number, value: string) => void;
}

interface ControllerConfiguration {
  controllerConfigurationId: number;
  name: string;
  tooltip: string;
  startAddress: string;
  units: string | null;
  value: string | number | null;
  previousValue: string | number | null;
}

type Breakpoint = "sm" | "md" | "lg" | "xl" | "xxl";
