// --------------------------------------------------------------
// Created On: 2022-10-25
// Author: Zachary Thomas
//
// Last Modified: 2024-12-05
// Modified By: Dimitra Weinstein
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import userHasPermission from "../../../utilities/userHasPermission";
import { CREATE_ASSETS_PERMISSION } from "../../../constants/permissions";
import styles from "./DeviceSubscriptionRow.module.scss";

// A single row in a table that represents a monitoring device subscription.
export default function DeviceSubscriptionRow(props: Props): Component {
  return (
    <tr className={styles.body} data-test="device-subscription-table-row">
      <td data-test="subscription-device-id">{props.deviceIdentifier}</td>
      <td data-test="subscription-device-type">{props.deviceTypeName}</td>
      <td>
        {props.assetId && props.assetName ? (
          <Link data-test="device-subscription-asset-link" to={`/asset/${props.assetId}/data`}>
            {props.assetName}
          </Link>
        ) : (
          <Fragment>
            {userHasPermission([[CREATE_ASSETS_PERMISSION]]) ? (
              <button
                data-test="create-asset-for-device-button"
                className="btn btn-primary"
                onClick={() => props.onOpenModal(props.deviceIdentifier, props.deviceType)}
              >
                <span className="d-none d-md-inline">Create Asset</span>
                <span className="d-inline d-md-none">Create</span>
              </button>
            ) : (
              <span>N/A</span>
            )}
          </Fragment>
        )}
      </td>
      <td data-test="subscription-type">{props.subscriptionType}</td>
      <td data-test="activation-status">{props.activationStatus === null ? "N/A" : props.activationStatus}</td>
      <td data-test="subscription-number">{props.subscriptionNumber ? props.subscriptionNumber : "No Subscription"}</td>
      <td data-test="subscription-start-date">
        {props.subscriptionStartDate === null ? "N/A" : props.subscriptionStartDate}
      </td>
      <td data-test="subscription-end-date">{props.subscriptionExpiry === null ? "N/A" : props.subscriptionExpiry}</td>
    </tr>
  );
}

DeviceSubscriptionRow.propTypes = {
  deviceIdentifier: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  subscriptionType: PropTypes.string.isRequired,
  activationStatus: PropTypes.string,
  subscriptionNumber: PropTypes.string,
  subscriptionStartDate: PropTypes.string,
  subscriptionExpiry: PropTypes.string,
  assetId: PropTypes.number,
  assetName: PropTypes.string,
  onOpenModal: PropTypes.func.isRequired,
};

interface Props {
  deviceIdentifier: string;
  deviceType: string;
  deviceTypeName: string;
  subscriptionType: string;
  activationStatus: string | null;
  subscriptionNumber?: string | null;
  subscriptionStartDate?: string | null;
  subscriptionExpiry?: string | null;
  assetId?: number;
  assetName?: string;
  onOpenModal: (deviceIdentifier: string, deviceType: string) => void;
}
