// --------------------------------------------------------------
// Created On: 2021-09-30
// Author: Zachary Thomas
//
// Last Modified: 2024-05-01
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";

// A single user in an user list.
export default function UserListRow(props: Props): Component {
  return (
    <div data-test="user-list-row" className="p-2">
      {props.name} ({props.emailAddress})
    </div>
  );
}

UserListRow.propTypes = {
  userId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  emailAddress: PropTypes.string.isRequired,
};

interface Props {
  userId: number;
  name: string;
  emailAddress: string;
}
