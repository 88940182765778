// --------------------------------------------------------------
// Created On: 2023-02-10
// Author: Zachary Thomas
//
// Last Modified: 2023-09-03
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import deepCopy from "../../../../../utilities/deepCopy";
import PropTypes from "prop-types";
import styles from "./WeekDaySelector.module.scss";

// Component for selecting days of the week.
export default function WeekDaySelector(props: Props): Component {
  // Check if a specific day is selected.
  function dayIsSelected(dayOfWeek: DayOfWeek): boolean {
    return props.selectedDaysOfWeek.includes(dayOfWeek);
  }

  // Modify the selection status of a day.
  function clickDay(dayOfWeek: DayOfWeek): void {
    const selectedDaysOfWeekDeepCopy = deepCopy(props.selectedDaysOfWeek);
    const selectedDayIndex = selectedDaysOfWeekDeepCopy.indexOf(dayOfWeek);
    if (selectedDayIndex >= 0) {
      selectedDaysOfWeekDeepCopy.splice(selectedDayIndex, 1);
    } else {
      selectedDaysOfWeekDeepCopy.push(dayOfWeek);
    }
    props.onChange(selectedDaysOfWeekDeepCopy);
  }

  return (
    <div className="row align-items-center my-3">
      <div className="col-3 col-md text-center">
        <div
          className={`${styles.day} ${dayIsSelected("SUNDAY") ? styles.selected : ""}`}
          onClick={() => {
            props.disabled
              ? {
                  /* Do nothing. */
                }
              : clickDay("SUNDAY");
          }}
        >
          S
        </div>
      </div>

      <div className="col-3 col-md text-center">
        <div
          className={`${styles.day} ${dayIsSelected("MONDAY") ? styles.selected : ""}`}
          onClick={() => {
            props.disabled
              ? {
                  /* Do nothing. */
                }
              : clickDay("MONDAY");
          }}
        >
          M
        </div>
      </div>

      <div className="col-3 col-md text-center">
        <div
          className={`${styles.day} ${dayIsSelected("TUESDAY") ? styles.selected : ""}`}
          onClick={() => {
            props.disabled
              ? {
                  /* Do nothing. */
                }
              : clickDay("TUESDAY");
          }}
        >
          T
        </div>
      </div>

      <div className="col-3 col-md text-center">
        <div
          className={`${styles.day} ${dayIsSelected("WEDNESDAY") ? styles.selected : ""}`}
          onClick={() => {
            props.disabled
              ? {
                  /* Do nothing. */
                }
              : clickDay("WEDNESDAY");
          }}
        >
          W
        </div>
      </div>

      <div className="col-3 col-md text-center">
        <div
          className={`${styles.day} ${dayIsSelected("THURSDAY") ? styles.selected : ""}`}
          onClick={() => {
            props.disabled
              ? {
                  /* Do nothing. */
                }
              : clickDay("THURSDAY");
          }}
        >
          T
        </div>
      </div>

      <div className="col-3 col-md text-center">
        <div
          className={`${styles.day} ${dayIsSelected("FRIDAY") ? styles.selected : ""}`}
          onClick={() => {
            props.disabled
              ? {
                  /* Do nothing. */
                }
              : clickDay("FRIDAY");
          }}
        >
          F
        </div>
      </div>

      <div className="col-3 col-md text-center">
        <div
          className={`${styles.day} ${dayIsSelected("SATURDAY") ? styles.selected : ""}`}
          onClick={() => {
            props.disabled
              ? {
                  /* Do nothing. */
                }
              : clickDay("SATURDAY");
          }}
        >
          S
        </div>
      </div>
    </div>
  );
}

WeekDaySelector.propTypes = {
  selectedDaysOfWeek: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

interface Props {
  selectedDaysOfWeek: DayOfWeek[];
  disabled?: boolean;
  onChange: (weekDays: DayOfWeek[]) => void;
}
