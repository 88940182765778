// --------------------------------------------------------------
// Created On: 2021-08-30
// Author: Zachary Thomas
//
// Last Modified: 2024-08-03
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import apiRequest from "../../utilities/api/apiRequest";
import getApiError from "../../utilities/api/getApiError";
import { API } from "../../constants/miscellaneous";
import Spinner from "../../components/Spinner/Spinner";
import ReviewContent from "../../components/ReviewContent/ReviewContent";
import { useParams, Link } from "react-router-dom";

// Page for reviewing request to join company.
export default function ReviewRequestPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { name, emailAddress, companyCode } = useParams();

  const invitationDescription =
    `A user who claims to be named '${name}' is requesting to join your company.` +
    ` If you accept this request, a new account with the email address '${emailAddress}' will be created and an email` +
    ` will be sent to the user letting them know that their account` +
    ` has been created.\n\n` +
    `Would you like to accept this request?`;

  // Accept a new user account.
  async function acceptAccount(
    name: string | undefined,
    emailAddress: string | undefined,
    companyCode: string | undefined
  ): Promise<void> {
    if (isValid()) {
      const requestBody = {
        name: name,
        emailAddress: emailAddress,
        companyCode: companyCode,
      };

      // Make API call.
      setLoading(true);
      const [response] = (await apiRequest(`${API}/registration/company/user/request/accept`, "POST", requestBody)) as [
        Response,
        ResponseBody
      ];
      setLoading(false);

      if (response.ok) {
        setErrorMessage("");
        setSuccessMessage(`The user '${emailAddress}' has been added to the company.`);
      } else {
        setSuccessMessage("");
        setErrorMessage(await getApiError(response, "Unable to register user."));
      }
    }
  }

  // Reject the request for a new account.
  async function rejectAccount(emailAddress: string | undefined, companyCode: string | undefined): Promise<void> {
    if (isValid()) {
      const requestBody = {
        emailAddress: emailAddress,
        companyCode: companyCode,
      };

      // Make API call.
      setLoading(true);
      const [response] = (await apiRequest(`${API}/registration/company/user/request/reject`, "POST", requestBody)) as [
        Response,
        ResponseBody
      ];
      setLoading(false);

      if (response.ok) {
        setErrorMessage("");
        setSuccessMessage(`The user has been sent an email informing them that their request was rejected.`);
      } else {
        setSuccessMessage("");
        setErrorMessage(await getApiError(response, "Unable to reject user."));
      }
    }
  }

  // Check if form data is valid.
  function isValid(): boolean {
    if (name === undefined) {
      setErrorMessage("Internal server error. Username is not found.");
      return false;
    } else if (emailAddress === undefined) {
      setErrorMessage("Internal server error. Email address is not found.");
      return false;
    } else if (companyCode === undefined) {
      setErrorMessage("Internal server error. Company account information is not found.");
      return false;
    } else {
      return true;
    }
  }

  return (
    <div className="p-4">
      <Spinner loading={loading} />

      <ReviewContent
        title="Request to Join Company"
        completedForms={[
          { key: "Name", value: name || "" },
          { key: "Email Address", value: emailAddress || "" },
        ]}
        content={invitationDescription}
        successMessage={successMessage}
        errorMessage={errorMessage}
        allowReject={true}
        requireName={false}
        name=""
        onChangeName={() => {
          /* Do nothing. */
        }}
        onChangePhoneNumber={() => {
          /* Do nothing. */
        }}
        onChangeCallingCode={() => {
          /* Do nothing. */
        }}
        requirePrivacyPolicy={false}
        privacyPolicy={false}
        onChangePrivacyPolicy={() => {
          /* Do nothing. */
        }}
        onReject={() => rejectAccount(emailAddress, companyCode)}
        onAccept={() => acceptAccount(name, emailAddress, companyCode)}
      >
        {successMessage.length > 0 && (
          <Link to="/" className="router-link">
            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                Return to Dashboard
              </button>
            </div>
          </Link>
        )}
      </ReviewContent>
    </div>
  );
}

interface ResponseBody {
  message: string;
}
