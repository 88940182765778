// --------------------------------------------------------------
// Created On: 2023-03-14
// Author: Zachary Thomas
//
// Last Modified: 2024-02-27
// Modified By: Zachary Thomas
//
// Copyright 2023 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import { TIMEZONE_UTC, WEEKS_OF_THE_MONTH } from "../../constants/miscellaneous";
import deepCopy from "../deepCopy";
import {
  nextSunday,
  nextMonday,
  nextTuesday,
  nextWednesday,
  nextThursday,
  nextFriday,
  nextSaturday,
  isSaturday,
  isSunday,
} from "date-fns";
import { formatInTimeZone, zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

// Handles converting schedule time from UTC to local time OR local time to UTC.
// Returns a schedule if it includes a valid date, otherwise returns null.
export default function scheduleTimeConversion(
  scheduleSettings: ReportSchedule[],
  scheduleIsUtc: boolean
): ReportSchedule[] | null {
  try {
    const scheduleSettingsDeepCopy = deepCopy(scheduleSettings);

    // Get current user local time information.
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const now = new Date();
    const nowSegments = now.toISOString().split(/-|:|T/);
    const year = nowSegments[0];
    const month = nowSegments[1];
    const day = nowSegments[2];
    const second = "00";

    scheduleSettingsDeepCopy.forEach((scheduleSetting) => {
      const scheduleTimes = scheduleSetting.value.split(":");
      switch (scheduleSetting.type) {
        case "DAILY": {
          // Daily values are formatted as hours:minutes (ex: 14:30).
          if (scheduleTimes.length === 2) {
            const pattern = "HH:mm";
            let isoDate = `${year}-${month}-${day}T${scheduleTimes[0]}:${scheduleTimes[1]}:${second}`;
            if (scheduleIsUtc) {
              isoDate += "Z";
              scheduleSetting.value = formatInTimeZone(isoDate, userTimeZone, pattern);
            } else {
              isoDate += ".000";
              scheduleSetting.value = formatInTimeZone(isoDate, TIMEZONE_UTC, pattern);
            }
          }
          break;
        }
        case "WEEKLY": {
          // Weekly values are formatted as day-of-week:hours:minutes (ex: MONDAY:14:30).
          const pattern = "EEEE:HH:mm";
          let weekDateFormat = null;
          if (scheduleTimes.length === 3) {
            const weekDay = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
            switch (scheduleTimes[0]) {
              case "SUNDAY":
                weekDateFormat = nextSunday(weekDay).toISOString().split("T")[0];
                break;
              case "MONDAY":
                weekDateFormat = nextMonday(weekDay).toISOString().split("T")[0];
                break;
              case "TUESDAY":
                weekDateFormat = nextTuesday(weekDay).toISOString().split("T")[0];
                break;
              case "WEDNESDAY":
                weekDateFormat = nextWednesday(weekDay).toISOString().split("T")[0];
                break;
              case "THURSDAY":
                weekDateFormat = nextThursday(weekDay).toISOString().split("T")[0];
                break;
              case "FRIDAY":
                weekDateFormat = nextFriday(weekDay).toISOString().split("T")[0];
                break;
              case "SATURDAY":
                weekDateFormat = nextSaturday(weekDay).toISOString().split("T")[0];
                break;
            }

            if (weekDateFormat !== null) {
              let isoDate = `${weekDateFormat}T${scheduleTimes[1]}:${scheduleTimes[2]}:${second}`;
              if (scheduleIsUtc) {
                isoDate += "Z";
                scheduleSetting.value = formatInTimeZone(isoDate, userTimeZone, pattern).toUpperCase();
              } else {
                isoDate += ".000";
                scheduleSetting.value = formatInTimeZone(isoDate, TIMEZONE_UTC, pattern).toUpperCase();
              }
            }
          }
          break;
        }
        case "MONTHLY": {
          // Monthly values are formatted as day:hours:minutes (ex: 15:14:30).
          if (scheduleTimes.length === 3) {
            const pattern = "dd:HH:mm";
            let isoDate = `${year}-${month}-${scheduleTimes[0]}T${scheduleTimes[1]}:${scheduleTimes[2]}:${second}`;
            if (scheduleIsUtc) {
              isoDate += "Z";
              scheduleSetting.value = formatInTimeZone(isoDate, userTimeZone, pattern);
            } else {
              isoDate += ".000";
              scheduleSetting.value = formatInTimeZone(isoDate, TIMEZONE_UTC, pattern);
            }
          }
          break;
        }
        case "MONTHLY_BY_WEEK": {
          // Monthly-by-week values are formatted as week-of-month:day-of-week:hours:minutes (ex: SECOND:TUESDAY:10:30).
          const pattern = "EEEE:HH:mm";
          let weekDateFormat = null;
          if (scheduleTimes.length === 4) {
            const weekDay = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
            switch (scheduleTimes[1]) {
              case "SUNDAY":
                weekDateFormat = nextSunday(weekDay).toISOString().split("T")[0];
                break;
              case "MONDAY":
                weekDateFormat = nextMonday(weekDay).toISOString().split("T")[0];
                break;
              case "TUESDAY":
                weekDateFormat = nextTuesday(weekDay).toISOString().split("T")[0];
                break;
              case "WEDNESDAY":
                weekDateFormat = nextWednesday(weekDay).toISOString().split("T")[0];
                break;
              case "THURSDAY":
                weekDateFormat = nextThursday(weekDay).toISOString().split("T")[0];
                break;
              case "FRIDAY":
                weekDateFormat = nextFriday(weekDay).toISOString().split("T")[0];
                break;
              case "SATURDAY":
                weekDateFormat = nextSaturday(weekDay).toISOString().split("T")[0];
                break;
            }

            if (weekDateFormat !== null) {
              const utcIsoDate = `${weekDateFormat}T${scheduleTimes[2]}:${scheduleTimes[3]}:${second}Z`;
              const localIsoDate = `${weekDateFormat}T${scheduleTimes[2]}:${scheduleTimes[3]}:${second}.000`;
              if (scheduleIsUtc) {
                const value = formatInTimeZone(utcIsoDate, userTimeZone, pattern).toUpperCase();
                const utcDate = utcToZonedTime(utcIsoDate, TIMEZONE_UTC);
                const localDate = utcToZonedTime(utcIsoDate, userTimeZone);
                // Check if the week of the month has changed.
                const weekIndex = WEEKS_OF_THE_MONTH.indexOf(scheduleTimes[0]);
                if (isSaturday(utcDate) && isSunday(localDate)) {
                  if (weekIndex >= WEEKS_OF_THE_MONTH.length - 1) {
                    scheduleSetting.value = `${WEEKS_OF_THE_MONTH[0]}:${value}`;
                  } else {
                    scheduleSetting.value = `${WEEKS_OF_THE_MONTH[weekIndex + 1]}:${value}`;
                  }
                } else if (isSunday(utcDate) && isSaturday(localDate)) {
                  if (weekIndex <= 0) {
                    scheduleSetting.value = `${WEEKS_OF_THE_MONTH[WEEKS_OF_THE_MONTH.length - 1]}:${value}`;
                  } else {
                    scheduleSetting.value = `${WEEKS_OF_THE_MONTH[weekIndex - 1]}:${value}`;
                  }
                } else {
                  scheduleSetting.value = `${scheduleTimes[0]}:${value}`;
                }
              } else {
                const value = formatInTimeZone(localIsoDate, TIMEZONE_UTC, pattern).toUpperCase();
                const localDate = zonedTimeToUtc(localIsoDate, userTimeZone);
                const utcDate = utcToZonedTime(localIsoDate, TIMEZONE_UTC);
                // Check if the week of the month has changed.
                const weekIndex = WEEKS_OF_THE_MONTH.indexOf(scheduleTimes[0]);
                if (isSaturday(localDate) && isSunday(utcDate)) {
                  if (weekIndex >= WEEKS_OF_THE_MONTH.length - 1) {
                    scheduleSetting.value = `${WEEKS_OF_THE_MONTH[0]}:${value}`;
                  } else {
                    scheduleSetting.value = `${WEEKS_OF_THE_MONTH[weekIndex + 1]}:${value}`;
                  }
                } else if (isSunday(localDate) && isSaturday(utcDate)) {
                  if (weekIndex <= 0) {
                    scheduleSetting.value = `${WEEKS_OF_THE_MONTH[WEEKS_OF_THE_MONTH.length - 1]}:${value}`;
                  } else {
                    scheduleSetting.value = `${WEEKS_OF_THE_MONTH[weekIndex - 1]}:${value}`;
                  }
                } else {
                  scheduleSetting.value = `${scheduleTimes[0]}:${value}`;
                }
              }
            }
          }
          break;
        }
        case "YEARLY": {
          // Yearly values are formatted as month:day:hours:minutes (ex: JANUARY:17:10:30).
          if (scheduleTimes.length === 4) {
            let isoMonth = null;
            switch (scheduleTimes[0]) {
              case "JANUARY":
                isoMonth = "01";
                break;
              case "FEBRUARY":
                isoMonth = "02";
                break;
              case "MARCH":
                isoMonth = "03";
                break;
              case "APRIL":
                isoMonth = "04";
                break;
              case "MAY":
                isoMonth = "05";
                break;
              case "JUNE":
                isoMonth = "06";
                break;
              case "JULY":
                isoMonth = "07";
                break;
              case "AUGUST":
                isoMonth = "08";
                break;
              case "SEPTEMBER":
                isoMonth = "09";
                break;
              case "OCTOBER":
                isoMonth = "10";
                break;
              case "NOVEMBER":
                isoMonth = "11";
                break;
              case "DECEMBER":
                isoMonth = "12";
                break;
            }

            if (isoMonth !== null) {
              const pattern = "MMMM:dd:HH:mm";
              let isoDate = `${year}-${isoMonth}-${scheduleTimes[1]}T${scheduleTimes[2]}:${scheduleTimes[3]}:${second}`;
              if (scheduleIsUtc) {
                isoDate += "Z";
                scheduleSetting.value = formatInTimeZone(isoDate, userTimeZone, pattern).toUpperCase();
              } else {
                isoDate += ".000";
                scheduleSetting.value = formatInTimeZone(isoDate, TIMEZONE_UTC, pattern).toUpperCase();
              }
            }
          }
          break;
        }
      }
    });
    return scheduleSettingsDeepCopy;
  } catch (error) {
    return null;
  }
}
