// --------------------------------------------------------------
// Created On: 2023-02-16
// Author: Zachary Thomas
//
// Last Modified: 2023-02-17
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useRef } from "react";
import PropTypes from "prop-types";
import styles from "./SearchBar.module.scss";

// Generic search bar.
export default function SearchBar(props: Props): Component {
  const searchInput = useRef(null);

  // Check if the user pressed enter while focused on the search bar.
  function checkPressEnter(e: React.KeyboardEvent) {
    if (e.key === "Enter" && document.activeElement === searchInput.current) {
      props.onSubmit(props.searchQuery);
    }
  }

  return (
    <div
      className={`${styles.body} ${
        props.location === "NAVIGATION_DRAWER" ? styles.navigationDrawer : ""
      } ${props.location === "NAVBAR" ? styles.navbar : ""} search nav-item`}
      onKeyDown={(e) => checkPressEnter(e)}
    >
      <div className="d-flex" onSubmit={() => props.onSubmit(props.searchQuery)}>
        <input
          type="search"
          className={`${styles.input} form-control`}
          placeholder={props.prompt}
          value={props.searchQuery}
          ref={searchInput}
          onChange={(e) => props.onChange(e.target.value)}
        />
        <button
          type="button"
          className={`${styles.button} btn btn-primary`}
          onClick={() => props.onSubmit(props.searchQuery)}
        >
          <i className="fa fa-fw fa-search" />
        </button>
      </div>
    </div>
  );
}

SearchBar.propTypes = {
  prompt: PropTypes.string.isRequired,
  location: PropTypes.string,
  searchQuery: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

interface Props {
  prompt: string;
  location?: "NAVBAR" | "NAVIGATION_DRAWER";
  searchQuery: string;
  onChange: (searchQuery: string) => void;
  onSubmit: (searchQuery: string) => void;
}
