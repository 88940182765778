// --------------------------------------------------------------
// Created On: 2023-08-29
// Author: Zachary Thomas
//
// Last Modified: 2024-09-11
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import { ENGINE_RUNNING_ATTRIBUTE } from "../../../../constants/attributes";
import PropTypes from "prop-types";
import formatDateShortLocal from "../../../../utilities/time/formatDateShortLocal";
import styles from "./ProductDataTable.module.scss";

// A table with highlighted attributes that are relevant for the highlighted product type.
export default function ProductDataTable(props: Props): Component {
  return (
    <Fragment>
      <div data-test="product-data-table-container" className="col-12 col-lg-6">
        {props.attributes.length > 0 && (
          <div className={styles.tableContent}>
            <table className={`${styles.table} table mb-0 pb-0`}>
              <thead className={styles.thead}>
                <tr>
                  <th className={styles.th}>Name</th>
                  <th className={styles.th}>Value</th>
                  <th className={styles.th}>Time</th>
                </tr>
              </thead>
              <tbody>
                {/* Show attributes. If engine running is returned, hide it if a value is not included. */}
                {props.attributes
                  .filter(
                    (attribute) =>
                      attribute.attributeCode !== ENGINE_RUNNING_ATTRIBUTE || attribute.currentValue !== null
                  )
                  .map((attribute, i) => (
                    <tr
                      key={attribute.regAttributeId}
                      className={attribute.isStale ? styles.staleData : styles.activeData}
                      onMouseOver={() => props.onUpdateHoverList(i, true)}
                      onMouseOut={() => props.onUpdateHoverList(i, false)}
                      onClick={() => props.onSelectAttribute(attribute.regAttributeId)}
                    >
                      <td>{attribute.attributeName}</td>
                      <td>
                        {attribute.currentValue === null
                          ? "N/A"
                          : `${attribute.currentValue} ${
                              attribute.unitShortName === null ? "" : attribute.unitShortName
                            }`}
                      </td>
                      <td>
                        {attribute.currentValueUtc === null ? "N/A" : formatDateShortLocal(attribute.currentValueUtc)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Fragment>
  );
}

ProductDataTable.propTypes = {
  attributes: PropTypes.array.isRequired,
  onUpdateHoverList: PropTypes.func.isRequired,
  onSelectAttribute: PropTypes.func.isRequired,
};

interface Props {
  attributes: Attribute[];
  onUpdateHoverList: (index: number, isHovering: boolean) => void;
  onSelectAttribute: (attributeId: number) => void;
}

interface Attribute {
  regAttributeId: number;
  attributeCode: string;
  attributeName: string;
  connectedRegAttributeId: number | null;
  unitShortName: string | null;
  unitLongName: string | null;
  icon: string;
  currentValue: string | null;
  currentValueUtc: string | null;
  isHistorical: boolean;
  isGraphable: boolean;
  isStale: boolean;
}
