// --------------------------------------------------------------
// Created On: 2021-09-10
// Author: Zachary Thomas
//
// Last Modified: 2024-09-09
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment } from "react";
import {
  API,
  MIN_GROUP_NAME_LENGTH,
  MAX_GROUP_NAME_LENGTH,
  MAX_ASSOCIATED_ASSETGROUPS,
  MAX_ASSOCIATED_USERGROUPS,
  MIN_GEOFENCE_POINTS,
  MAX_GEOFENCE_POINTS,
  GEOFENCE_TYPE_STATIC,
  GEOFENCE_TYPE_DYNAMIC,
  ASSETGROUP_ASSET_TAB,
  ASSETGROUP_ASSETGROUP_TAB,
  ASSETGROUP_USERGROUP_TAB,
  ASSETGROUP_GEOFENCE_TAB,
} from "../../../constants/miscellaneous";
import { ASSETGROUP_TYPES } from "../../../constants/reducerActions";
import useApi from "../../../hooks/useApi";
import apiRequest from "../../../utilities/api/apiRequest";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import getApiError from "../../../utilities/api/getApiError";
import PopulateControl from "../../../components/PopulateControl/PopulateControl";
import Spinner from "../../../components/Spinner/Spinner";
import deepCopy from "../../../utilities/deepCopy";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import AssetTab from "./AssetTab/AssetTab";
import AssetgroupTab from "./AssetgroupTab/AssetgroupTab";
import UsergroupTab from "./UsergroupTab/UsergroupTab";
import GeofenceTab from "./GeofenceTab/GeofenceTab";
import userHasPermission from "../../../utilities/userHasPermission";
import {
  CREATE_ASSET_GROUPS_PERMISSION,
  UPDATE_ASSET_GROUPS_PERMISSION,
  DELETE_ASSET_GROUPS_PERMISSION,
} from "../../../constants/permissions";
import styles from "./AssetgroupModal.module.scss";

// Modal for creating, editing, and deleting asset groups.
export default function AssetgroupModal(props: Props): Component {
  const tabs: ManagementTab[] = [
    ASSETGROUP_ASSET_TAB,
    ASSETGROUP_ASSETGROUP_TAB,
    ASSETGROUP_USERGROUP_TAB,
    ASSETGROUP_GEOFENCE_TAB,
  ];
  const initialAssetgroup: Assetgroup = {
    assetgroupId: 0,
    name: "",
    assets: [],
    assetgroups: [],
    usergroups: [],
    geofenceEnabled: false,
    geofenceType: GEOFENCE_TYPE_STATIC,
    geofencePoints: [],
    isDefault: false,
  };
  const [redirectSelection, setRedirectSelection] = useState<RedirectSelection>({
    selectedId: 0,
    type: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showConfirmExit, setShowConfirmExit] = useState<boolean>(false);
  const [showConfirmRedirect, setShowConfirmRedirect] = useState<boolean>(false);
  const [selectedTabId, setSelectedTabId] = useState<string>("asset");
  const [previousAssetgroup, setPreviousAssetgroup] = useState<Assetgroup>(initialAssetgroup);
  const [name, setName] = useState<string>("");
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [assets, setAssets] = useState<SimpleAsset[]>([]);
  const [assetgroups, setAssetgroups] = useState<Assetgroup[]>([]);
  const [usergroups, setUsergroups] = useState<Usergroup[]>([]);
  const [geofencePoints, setGeofencePoints] = useState<GeofencePoint[]>([]);
  const [geofenceEnabled, setGeofenceEnabled] = useState<boolean>(false);
  const [geofenceType, setGeofenceType] = useState<string>(GEOFENCE_TYPE_STATIC);
  const [queriedAsset, setQueriedAsset] = useState<boolean>(false);
  const [queriedUser, setQueriedUser] = useState<boolean>(false);
  const [queriedGeofence, setQueriedGeofence] = useState<boolean>(false);
  const currentUser = useSelector(getCurrentUser);

  // Get initial asset group data when the modal is first opened.
  useApi(
    () => {
      if (props.showModal && props.assetgroupId > 0) {
        setLoading(true);
        return true;
      } else {
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/assetgroup/${props.assetgroupId}/asset`,
    },
    async (response: Response, responseBody: AssetgroupResponseBody) => {
      if (response.ok && responseBody) {
        setErrorMessage("");
        setAssets(responseBody.assets);
        setAssetgroups(responseBody.assetgroups);
      } else {
        setErrorMessage("Internal server error. Unable to get asset group information.");
      }
      setQueriedAsset(true);
    },
    [props.showModal, props.assetgroupId]
  );

  // Get initial user group data when the modal is first opened.
  useApi(
    () => {
      if (props.showModal && props.assetgroupId > 0) {
        setLoading(true);
        return true;
      } else {
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/assetgroup/${props.assetgroupId}/user`,
    },
    async (response: Response, responseBody: UsergroupResponseBody) => {
      if (response.ok && responseBody) {
        setErrorMessage("");
        setUsergroups(responseBody.usergroups);
      } else {
        setErrorMessage("Internal server error. Unable to get user group information.");
      }
      setQueriedUser(true);
    },
    [props.showModal, props.assetgroupId]
  );

  // Get initial geo-fence data when the modal is first opened.
  useApi(
    () => {
      if (props.showModal && props.assetgroupId > 0) {
        setLoading(true);
        return true;
      } else {
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/assetgroup/${props.assetgroupId}/geofence`,
    },
    async (response: Response, responseBody: GeofenceResponseBody) => {
      if (response.ok && responseBody) {
        setErrorMessage("");
        // Update the geofence point IDs for each point.
        let geofencePointId = 1;
        const pointsDeepCopy = deepCopy(responseBody.points);
        pointsDeepCopy.forEach((point) => {
          point.geofencePointId = geofencePointId;
          geofencePointId++;
        });
        setGeofenceEnabled(responseBody.enabled);
        setGeofenceType(responseBody.type);
        setGeofencePoints(pointsDeepCopy);
      } else {
        setErrorMessage("Internal server error. Unable to get geo-fence information.");
      }
      setQueriedGeofence(true);
    },
    [props.showModal, props.assetgroupId]
  );

  // Assign previous asset group and stop loading if we have queried all of the tabs.
  useEffect(() => {
    if (queriedAsset && queriedUser && queriedGeofence) {
      const previousAssetgroupDeepCopy = deepCopy(previousAssetgroup);
      previousAssetgroupDeepCopy.assets = assets;
      previousAssetgroupDeepCopy.assetgroups = assetgroups;
      previousAssetgroupDeepCopy.usergroups = usergroups;
      previousAssetgroupDeepCopy.geofenceEnabled = geofenceEnabled;
      previousAssetgroupDeepCopy.geofenceType = geofenceType;
      previousAssetgroupDeepCopy.geofencePoints = geofencePoints;
      previousAssetgroupDeepCopy.name = props.name;
      previousAssetgroupDeepCopy.isDefault = props.isDefault;
      setPreviousAssetgroup(previousAssetgroupDeepCopy);
      setLoading(false);
    }
  }, [props.assetgroupId, queriedAsset, queriedUser, queriedGeofence]);

  // Manage updating settings when the selection status changes.
  useEffect(() => {
    if (props.showModal) {
      setName(props.name);
      setIsDefault(props.isDefault);
      const previousAssetgroupDeepCopy = deepCopy(previousAssetgroup);
      previousAssetgroupDeepCopy.name = props.name;
      previousAssetgroupDeepCopy.isDefault = props.isDefault;
      setPreviousAssetgroup(previousAssetgroupDeepCopy);
    } else {
      setPreviousAssetgroup(initialAssetgroup);
      setName("");
      setIsDefault(false);
      setAssets([]);
      setAssetgroups([]);
      setUsergroups([]);
      setGeofencePoints([]);
      setGeofenceEnabled(false);
      setGeofenceType(GEOFENCE_TYPE_STATIC);
      setQueriedUser(false);
      setQueriedGeofence(false);
    }
    setQueriedAsset(false);
  }, [props.showModal, props.assetgroupId, props.name, props.isDefault]);

  // Validate the asset group settings.
  function assetgroupIsValid(): boolean {
    if (name.trim().length < MIN_GROUP_NAME_LENGTH || name.trim().length > MAX_GROUP_NAME_LENGTH) {
      setErrorMessage(
        `The asset group name must be between ${MIN_GROUP_NAME_LENGTH} and ${MAX_GROUP_NAME_LENGTH} characters long.`
      );
      return false;
    } else if (assetgroups.length > MAX_ASSOCIATED_ASSETGROUPS) {
      setErrorMessage(`An asset group is not allowed to have over ${MAX_ASSOCIATED_ASSETGROUPS} child asset groups.`);
      return false;
    } else if (usergroups.length > MAX_ASSOCIATED_USERGROUPS) {
      setErrorMessage(
        `An asset group is not allowed to have over ${MAX_ASSOCIATED_USERGROUPS} associated user groups.`
      );
      return false;
    } else if (geofencePoints.length < MIN_GEOFENCE_POINTS && geofenceEnabled) {
      setErrorMessage(`An enabled geofence must be made of at least ${MIN_GEOFENCE_POINTS} points.`);
      return false;
    } else if (geofencePoints.length > MAX_GEOFENCE_POINTS) {
      setErrorMessage(`An asset group is not allowed to have over ${MAX_GEOFENCE_POINTS} geofence points.`);
      return false;
    } else {
      return true;
    }
  }

  // Create an asset group.
  async function createAssetgroup(close: boolean): Promise<void> {
    if (assetgroupIsValid()) {
      const assetIds: number[] = [];
      assets.forEach((asset) => assetIds.push(asset.assetId));

      const assetgroupIds: number[] = [];
      assetgroups.forEach((assetgroup) => assetgroupIds.push(assetgroup.assetgroupId));

      const tempUsergroups: Usergroup[] = [];
      usergroups.forEach((usergroup) =>
        tempUsergroups.push({
          usergroupId: usergroup.usergroupId,
          name: usergroup.name,
          operateAccessPermission: usergroup.operateAccessPermission,
          isDefault: usergroup.isDefault,
          users: [],
        })
      );

      // Remove form ID from geofence points before creating asset group.
      const cleanedGeofencePoints: CleanGeofencePoint[] = [];
      geofencePoints.forEach((geofencePoint) =>
        cleanedGeofencePoints.push({ longitude: geofencePoint.longitude, latitude: geofencePoint.latitude })
      );

      const requestBody = {
        name: name.trim(),
        description: "",
        assetIds: assetIds,
        assetgroupIds: assetgroupIds,
        usergroups: tempUsergroups,
        geofenceEnabled: geofenceEnabled,
        geofenceType: geofenceType,
        geofencePoints: cleanedGeofencePoints,
        isDefault: isDefault,
      };

      setLoading(true);
      const [response, responseBody] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/assetgroup`,
        "POST",
        requestBody
      )) as [Response, PostResponseBody];
      setLoading(false);

      if (response.ok) {
        const assetgroupDeepCopy = deepCopy(previousAssetgroup);
        assetgroupDeepCopy.assetgroupId = responseBody.assetgroupId;
        assetgroupDeepCopy.name = name.trim();
        assetgroupDeepCopy.isDefault = isDefault;
        assetgroupDeepCopy.geofenceEnabled = geofenceEnabled;
        assetgroupDeepCopy.geofenceType = geofenceType;
        assetgroupDeepCopy.geofencePoints = geofencePoints;
        setPreviousAssetgroup(assetgroupDeepCopy);
        props.onAction({
          type: ASSETGROUP_TYPES.CREATE_ASSETGROUP,
          payload: {
            assetgroup: assetgroupDeepCopy,
          },
        });
        setSelectedTabId(ASSETGROUP_ASSET_TAB.tabId);
        setShowConfirmExit(false);
        setErrorMessage("");

        // If the geo-fence was dynamic, then make an additional API call to update the asset associations.
        if (assetgroupDeepCopy.geofenceType === GEOFENCE_TYPE_DYNAMIC && assetgroupDeepCopy.geofenceEnabled === true) {
          const requestBody = {
            name: name.trim(),
            geofencePoints: cleanedGeofencePoints,
          };
          apiRequest(
            `${API}/company/${currentUser.companyId}/assetgroup/${responseBody.assetgroupId}/populate`,
            "POST",
            requestBody
          );
        }

        if (close) {
          discardChanges();
        }
      } else {
        setErrorMessage(await getApiError(response, "Unable to create asset group."));
      }
    }
  }

  // Edit an asset group.
  async function editAssetgroup(assetgroupId: number, close: boolean): Promise<void> {
    if (assetgroupIsValid()) {
      const assetIds: number[] = [];

      assets.forEach((asset) => assetIds.push(asset.assetId));

      const assetgroupIds: number[] = [];
      assetgroups.forEach((assetgroup) => assetgroupIds.push(assetgroup.assetgroupId));

      // Remove form ID from geofence points before creating asset group.
      const cleanedGeofencePoints: CleanGeofencePoint[] = [];
      geofencePoints.forEach((geofencePoint) =>
        cleanedGeofencePoints.push({ longitude: geofencePoint.longitude, latitude: geofencePoint.latitude })
      );

      const requestBody = {
        assetgroupId: assetgroupId,
        name: name.trim(),
        description: "",
        assetIds: assetIds,
        assetgroupIds: assetgroupIds,
        usergroups: usergroups,
        geofenceEnabled: geofenceEnabled,
        geofenceType: geofenceType,
        geofencePoints: cleanedGeofencePoints,
        isDefault: isDefault,
      };

      setLoading(true);
      const [response] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/assetgroup/${assetgroupId}`,
        "PUT",
        requestBody
      )) as [Response, PutResponseBody];
      setLoading(false);

      if (response.ok) {
        const assetgroupDeepCopy = deepCopy(previousAssetgroup);
        assetgroupDeepCopy.assetgroupId = assetgroupId;
        assetgroupDeepCopy.name = name.trim();
        assetgroupDeepCopy.isDefault = isDefault;
        assetgroupDeepCopy.geofenceEnabled = geofenceEnabled;
        assetgroupDeepCopy.geofenceType = geofenceType;
        assetgroupDeepCopy.geofencePoints = geofencePoints;
        setPreviousAssetgroup(assetgroupDeepCopy);
        props.onAction({
          type: ASSETGROUP_TYPES.UPDATE_ASSETGROUP,
          payload: {
            assetgroup: assetgroupDeepCopy,
          },
        });
        setSelectedTabId(ASSETGROUP_ASSET_TAB.tabId);
        setErrorMessage("");

        // If the geo-fence was dynamic, then make an additional API call to update the asset associations.
        if (assetgroupDeepCopy.geofenceType === GEOFENCE_TYPE_DYNAMIC && assetgroupDeepCopy.geofenceEnabled === true) {
          const requestBody = {
            name: name.trim(),
            geofencePoints: cleanedGeofencePoints,
          };
          apiRequest(
            `${API}/company/${currentUser.companyId}/assetgroup/${assetgroupId}/populate`,
            "POST",
            requestBody
          );
        }

        if (close) {
          discardChanges();
        }
      } else {
        setErrorMessage(await getApiError(response, "Unable to update asset group."));
      }
    }
  }

  // Delete an asset group.
  async function deleteAssetgroup(assetgroupId: number): Promise<void> {
    setLoading(true);
    const [response] = (await apiRequest(
      `${API}/company/${currentUser.companyId}/assetgroup/${assetgroupId}`,
      "DELETE",
      null
    )) as [Response, DeleteResponseBody];
    setLoading(false);

    if (response.ok) {
      discardChanges();
      props.onAction({
        type: ASSETGROUP_TYPES.DELETE_ASSETGROUP,
        payload: {
          assetgroupId: assetgroupId,
        },
      });
    } else {
      setShowConfirmDelete(false);
      setErrorMessage(await getApiError(response, "Unable to delete asset group."));
    }
  }

  // Redirect to another modal if no changes have been made. Otherwise prompt user.
  function redirectModal(selectedId: number, selectedType: string): void {
    // Check to see if the asset group state is the same as the prop.
    if (assetgroupChanged()) {
      // We have unsaved changes, give the user a chance to save them.
      setRedirectSelection({ selectedId: selectedId, type: selectedType });
      setShowConfirmRedirect(true);
    } else {
      // Since there have been no changes we can safely exit.
      redirect(selectedId);
    }
  }

  // Checks if any changes have been made to the current assetgroup.
  function assetgroupChanged(): boolean {
    // Check if the asset group name is the same.
    if (previousAssetgroup.name !== name) {
      return true;
    }

    // Check if the asset group default setting is the same.
    if (previousAssetgroup.isDefault !== isDefault) {
      return true;
    }

    // Check if the assets are the same.
    const beforeAssetIds = previousAssetgroup.assets.map((asset) => asset.assetId);
    const afterAssetIds = assets.map((asset) => asset.assetId);

    beforeAssetIds.sort((a, b) => a - b);
    afterAssetIds.sort((a, b) => a - b);

    if (JSON.stringify(beforeAssetIds) !== JSON.stringify(afterAssetIds)) {
      return true;
    }

    // Check if the asset groups are the same.
    const beforeAssetgroupIds = previousAssetgroup.assetgroups.map((assetgroup) => assetgroup.assetgroupId);
    const afterAssetgroupIds = assetgroups.map((assetgroup) => assetgroup.assetgroupId);

    beforeAssetgroupIds.sort((a, b) => a - b);
    afterAssetgroupIds.sort((a, b) => a - b);

    if (JSON.stringify(beforeAssetgroupIds) !== JSON.stringify(afterAssetgroupIds)) {
      return true;
    }

    // Check if the user groups are the same.
    const beforeUsergroupIds = previousAssetgroup.usergroups.map((usergroup) => usergroup.usergroupId);
    const afterUsergroupIds = usergroups.map((usergroup) => usergroup.usergroupId);

    beforeUsergroupIds.sort((a, b) => a - b);
    afterUsergroupIds.sort((a, b) => a - b);

    if (JSON.stringify(beforeUsergroupIds) !== JSON.stringify(afterUsergroupIds)) {
      return true;
    }

    // Check if geofence enabled setting is the same.
    if (previousAssetgroup.geofenceEnabled !== geofenceEnabled) {
      return true;
    }

    // Check if the geofence type is the same.
    if (previousAssetgroup.geofenceType !== geofenceType) {
      return true;
    }

    // Check if the geofence points are the same.
    if (previousAssetgroup.geofencePoints !== undefined) {
      const beforeGeofencePoints = previousAssetgroup.geofencePoints.map((geofencePoint) => {
        return {
          latitude: geofencePoint.latitude,
          longitude: geofencePoint.longitude,
        };
      });

      const afterGeofencePoints = geofencePoints.map((geofencePoint) => {
        return {
          latitude: geofencePoint.latitude,
          longitude: geofencePoint.longitude,
        };
      });

      if (JSON.stringify(beforeGeofencePoints) !== JSON.stringify(afterGeofencePoints)) {
        return true;
      }
    }

    return false;
  }

  // Redirect to a new modal without saving changes.
  function redirect(selectedId: number): void {
    setSelectedTabId(ASSETGROUP_ASSET_TAB.tabId);
    setShowConfirmRedirect(false);
    setErrorMessage("");
    props.onSelect(selectedId, "assetgroup");
  }

  // Exit modal if no changes have been made. Otherwise prompt user.
  function exitModal(): void {
    // Check to see if the group state is the same as the prop.
    if (assetgroupChanged()) {
      // We have unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    } else {
      // Since there have been no changes we can safely exit.
      discardChanges();
    }
  }

  // Save changes.
  function saveChanges(close: boolean, selectedId: number): void {
    if (props.mode === "create") {
      createAssetgroup(close);
    } else {
      editAssetgroup(props.assetgroupId, close);
    }
    setShowConfirmExit(false);
    setShowConfirmRedirect(false);

    // See if we should be redirected to a new modal.
    if (!close) {
      redirect(selectedId);
    }
  }

  // Exit without saving changes.
  function discardChanges() {
    setShowConfirmExit(false);
    setShowConfirmDelete(false);
    setShowConfirmRedirect(false);
    setSelectedTabId(ASSETGROUP_ASSET_TAB.tabId);
    setPreviousAssetgroup(initialAssetgroup);
    setErrorMessage("");
    props.onClose();
  }

  // Update an existing geo-fence.
  function updateGeofence(enabled: boolean, type: string, points: GeofencePoint[]): void {
    setGeofenceEnabled(enabled);
    setGeofenceType(type);
    setGeofencePoints(points);
  }

  // Select a tab in the modal and clear old error messages.
  function selectTab(tabId: string): void {
    if (selectedTabId !== tabId) {
      setSelectedTabId(tabId);
      setErrorMessage("");
    }
  }

  // Returns whether the current user is allowed to edit the form with their current permissions.
  function formIsEditable(): boolean {
    return (
      (props.mode === "create" && userHasPermission([[CREATE_ASSET_GROUPS_PERMISSION]])) ||
      (props.mode !== "create" && userHasPermission([[UPDATE_ASSET_GROUPS_PERMISSION]]))
    );
  }

  return (
    <div>
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        backdropClassName={styles.backdrop}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="xl"
        animation
      >
        <ModalHeader>
          <h5 className="modal-title font-weight-bold">
            {props.mode === "create" ? <span>Create Asset Group</span> : <span>Edit Asset Group</span>}
          </h5>
        </ModalHeader>

        <ModalBody>
          <div className={styles.body}>
            {/* Asset group name input. */}
            {props.mode === "create" ? (
              <div className="mx-2">
                <label className="mb-3">Asset Group Name</label>
                <input
                  data-test="asset-group-name-input"
                  className="form-control mx-auto mb-4"
                  type="text"
                  maxLength={MAX_GROUP_NAME_LENGTH}
                  value={name}
                  disabled={!formIsEditable()}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            ) : (
              <div className="form-group mx-2">
                <input
                  data-test="asset-group-name-input"
                  className={`${styles.name} form-control px-0 mb-3 mx-auto`}
                  type="text"
                  maxLength={MAX_GROUP_NAME_LENGTH}
                  value={name}
                  disabled={!formIsEditable()}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            )}

            {/* Controls if this asset group will auto-populate new assets. */}
            <PopulateControl
              resourceType="asset"
              active={isDefault || false}
              disabled={!formIsEditable()}
              onChange={() => setIsDefault((prev) => !prev)}
            />

            {/* Tab for associating assets. */}
            {selectedTabId === ASSETGROUP_ASSET_TAB.tabId && props.showModal && (
              <AssetTab
                tabId={ASSETGROUP_ASSET_TAB.tabId}
                assetgroupId={props.assetgroupId}
                assetgroupName={name}
                assets={props.assets}
                assetgroups={props.assetgroups}
                selectedAssets={assets}
                selectedAssetgroups={assetgroups}
                tabs={tabs}
                geofenceType={geofenceType}
                onRedirect={(selectedId, selectedType) => redirectModal(selectedId, selectedType)}
                disabled={!formIsEditable()}
                onClickTab={(tabId) => selectTab(tabId)}
                onChangeAssets={(assets) => setAssets(assets)}
                onError={(errorMessage) => setErrorMessage(errorMessage)}
              />
            )}

            {/* Tab for associating asset groups. */}
            {selectedTabId === ASSETGROUP_ASSETGROUP_TAB.tabId && props.showModal && (
              <AssetgroupTab
                tabId={ASSETGROUP_ASSETGROUP_TAB.tabId}
                assetgroupId={props.assetgroupId}
                assetgroupName={name}
                assets={props.assets}
                assetgroups={props.assetgroups}
                selectedAssets={assets}
                selectedAssetgroups={assetgroups}
                tabs={tabs}
                disabled={!formIsEditable()}
                onRedirect={(selectedId, selectedType) => redirectModal(selectedId, selectedType)}
                onClickTab={(tabId) => selectTab(tabId)}
                onChangeAssetgroups={(assetgroups) => setAssetgroups(assetgroups)}
                onError={(errorMessage) => setErrorMessage(errorMessage)}
              />
            )}

            {/* Tab for associating user groups. */}
            {selectedTabId === ASSETGROUP_USERGROUP_TAB.tabId && props.showModal && (
              <UsergroupTab
                tabId={ASSETGROUP_USERGROUP_TAB.tabId}
                assetgroupId={props.assetgroupId}
                assetgroupName={name}
                usergroups={props.usergroups}
                selectedUsergroups={usergroups}
                tabs={tabs}
                disabled={!formIsEditable()}
                onClickTab={(tabId) => selectTab(tabId)}
                onChangeUsergroups={(usergroups) => setUsergroups(usergroups)}
                onError={(errorMessage) => setErrorMessage(errorMessage)}
              />
            )}

            {selectedTabId === ASSETGROUP_GEOFENCE_TAB.tabId && props.showModal && (
              <GeofenceTab
                tabId={ASSETGROUP_GEOFENCE_TAB.tabId}
                tabs={tabs}
                assetgroupId={props.assetgroupId}
                enabled={geofenceEnabled}
                type={geofenceType}
                points={geofencePoints}
                previousPoints={previousAssetgroup.geofencePoints || []}
                assets={assets}
                assetgroups={assetgroups}
                disabled={!formIsEditable()}
                onClickTab={(tabId) => selectTab(tabId)}
                onChangeGeofence={(enabled: boolean, type: string, points: GeofencePoint[]) =>
                  updateGeofence(enabled, type, points)
                }
                onError={(errorMessage) => setErrorMessage(errorMessage)}
              />
            )}

            {errorMessage.length > 0 && (
              <div className="row">
                <div className="col mt-4 mx-2">
                  <Error message={errorMessage} />
                </div>
              </div>
            )}
          </div>
        </ModalBody>

        <ModalFooter className={styles.footer}>
          {props.mode === "create" ? (
            <Fragment>
              <button
                data-test="assetgroup-modal-create-asset-group-button"
                className={`${styles.button} btn btn-primary`}
                type="button"
                onClick={() => createAssetgroup(true)}
              >
                Create Asset Group
              </button>

              <button
                data-test="assetgroup-modal-cancel-button"
                className={`${styles.button} btn btn-secondary`}
                type="button"
                onClick={() => exitModal()}
              >
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              {userHasPermission([[DELETE_ASSET_GROUPS_PERMISSION]]) && (
                <button
                  data-test="assetgroup-modal-delete-button"
                  className={`${styles.button} btn btn-danger me-auto`}
                  type="button"
                  onClick={() => setShowConfirmDelete(true)}
                >
                  Delete
                </button>
              )}

              {userHasPermission([[UPDATE_ASSET_GROUPS_PERMISSION]]) && (
                <button
                  data-test="assetgroup-modal-save-changes-button"
                  className={`${styles.button} btn btn-primary`}
                  type="button"
                  disabled={loading}
                  onClick={() => editAssetgroup(props.assetgroupId, true)}
                >
                  Save Changes
                </button>
              )}

              <button
                data-test="assetgroup-modal-cancel-button"
                className={`${styles.button} btn btn-secondary`}
                type="button"
                onClick={() => exitModal()}
              >
                Cancel
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      {/* Additional child modals. */}
      {props.showModal && (
        <Fragment>
          <ConfirmModal
            data-test="assetgroup-delete-confirm-modal"
            showModal={props.showModal && showConfirmDelete}
            title={`Delete '${name}'?`}
            content={`Are you sure that you want to delete the asset group '${name}'?`}
            yesText="Delete Asset Group"
            noText="Cancel"
            danger={true}
            onClose={() => setShowConfirmDelete(false)}
            onYes={() => deleteAssetgroup(props.assetgroupId)}
            onNo={() => setShowConfirmDelete(false)}
          />

          <SaveChangesModal
            data-test="assetgroup-save-confirm-modal"
            showModal={props.showModal && showConfirmExit}
            title="Changes have not been saved!"
            content="Are you sure that you want to exit without saving your changes?"
            onClose={() => setShowConfirmExit(false)}
            onSave={() => saveChanges(true, 0)}
            onNoSave={() => discardChanges()}
          />

          <SaveChangesModal
            data-test="assetgroup-delete-confirm-modal"
            showModal={props.showModal && showConfirmRedirect}
            title="Changes have not been saved!"
            content="Are you sure that you want to leave without saving your changes?"
            onClose={() => setShowConfirmRedirect(false)}
            onSave={() => saveChanges(false, redirectSelection.selectedId)}
            onNoSave={() => redirect(redirectSelection.selectedId)}
          />
        </Fragment>
      )}
    </div>
  );
}

AssetgroupModal.propTypes = {
  mode: PropTypes.oneOf(["create", "edit"]).isRequired,
  showModal: PropTypes.bool.isRequired,
  assetgroupId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  assetgroups: PropTypes.array.isRequired,
  assets: PropTypes.array.isRequired,
  usergroups: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

interface Props {
  mode: "create" | "edit";
  showModal: boolean;
  assetgroupId: number;
  name: string;
  isDefault: boolean;
  assetgroups: SimpleAssetgroup[];
  assets: SimpleAsset[];
  usergroups: SimpleUsergroup[];
  onSelect: (selectedId: number, selectedType: string) => void;
  onClose: () => void;
  onAction: (action: Action) => void;
}

interface AssetgroupResponseBody {
  assets: Asset[];
  assetgroups: Assetgroup[];
}

interface UsergroupResponseBody {
  usergroups: Usergroup[];
}

interface SimpleUsergroup {
  usergroupId: number;
  name: string;
  operateAccessPermission: boolean;
}

interface Usergroup {
  usergroupId: number;
  name: string;
  operateAccessPermission: boolean;
  users: User[];
  isDefault: boolean;
}

interface User {
  userId: number;
  name: string;
  emailAddress: string;
  roleId: number;
}

interface GeofenceResponseBody {
  enabled: boolean;
  type: string;
  points: GeofencePoint[];
}

interface GeofencePoint {
  geofencePointId: number;
  latitude: number;
  longitude: number;
  draggable?: boolean;
}

interface CleanGeofencePoint {
  latitude: number;
  longitude: number;
}

interface PostResponseBody {
  assetgroupId: number;
  error?: string;
}

interface PutResponseBody {
  error?: string;
}

interface DeleteResponseBody {
  error?: string;
}

interface RedirectSelection {
  selectedId: number;
  type: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  assetgroups?: SimpleAssetgroup[];
  assetgroup?: SimpleAssetgroup;
  assetgroupId?: number;
}
