// --------------------------------------------------------------
// Created On: 2024-01-19
// Author: Zachary Thomas
//
// Last Modified: 2024-01-19
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

// Run this function for a specified number of milliseconds. This function is best used with 'await'.
export default async function delayMilliseconds(milliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}
