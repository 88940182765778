// --------------------------------------------------------------
// Created On: 2022-09-20
// Author: Zachary Thomas
//
// Last Modified: 2023-12-14
// Modified By: Zachary Thomas
//
// Copyright 2022 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";

// Explains what a rented asset is.
export default function HelpRentalPending(): Component {
  return (
    <div>
      <p className="px-3 my-2">
        These are assets that are being rented to your company. If the request is pending you will have the option to
        accept or reject the rental that is being sent to you. If you accept the request you will have limited access to
        the asset depending on the permissions granted by the providing company. The providing company also has the
        ability to revoke this access at any time.
      </p>
    </div>
  );
}
