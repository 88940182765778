// --------------------------------------------------------------
// Created On: 2022-06-21
// Author: Zachary Thomas
//
// Last Modified: 2024-08-30
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import formatDateShortLocal from "../../../../utilities/time/formatDateShortLocal";
import PropTypes from "prop-types";
import IconTooltip from "../../../../components/IconTooltip/IconTooltip";
import styles from "./HistoryTable.module.scss";

// Table for viewing historical data points.
export default function HistoryTable(props: Props): Component {
  // Returns the local timestamp string from unix timestamp.
  function getLocalTimestampFromUnixTimestamp(unixTimestamp: number): string {
    const date = new Date(unixTimestamp);
    return formatDateShortLocal(date.toISOString().split(".")[0]).replace(",", "");
  }

  return (
    <div className={`${styles.body} my-3`} data-test="data-history-table">
      {props.series.map((singleSeries) => (
        <table key={singleSeries.name} className={`${styles.table} table`}>
          <thead className={styles.thead}>
            <tr>
              <th className={styles.th}>{singleSeries.name}</th>
              <th className={styles.th}>Date</th>
              <th className={styles.th} />
            </tr>
          </thead>
          <tbody>
            {singleSeries.data.map((singleDataPoint, i) => (
              <tr key={i}>
                <td>
                  {singleDataPoint[1]} {singleSeries.unitShortName !== null && singleSeries.unitShortName}{" "}
                  {singleSeries.dataMarkers.some((dataMarker) => dataMarker.dataPointIndex === i) && (
                    <IconTooltip
                      id={`stale-data-tooltip-${i}`}
                      icon="exclamation-triangle"
                      message={"This data was uploaded after network communication was restored."}
                      color="var(--bs-warning)"
                    />
                  )}
                </td>
                <td>{getLocalTimestampFromUnixTimestamp(singleDataPoint[0])}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ))}
    </div>
  );
}

HistoryTable.propTypes = {
  series: PropTypes.array,
};

interface Props {
  series: FlexibleTimeDataSeries[];
}

interface FlexibleTimeDataSeries {
  name: string;
  unitName: string;
  unitShortName: string;
  data: [number, number | string][];
  dataMarkers: DataMarker[];
}

interface DataMarker {
  seriesIndex: number;
  dataPointIndex: number;
}
