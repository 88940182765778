// --------------------------------------------------------------
// Created On: 2024-07-23
// Author: Hannah Vaughan
//
// Last Modified: 2024-11-05
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import { CONFIG_BYTE_ORDERS, CONFIG_DATA_TYPES } from "../../../constants/controllerConfigurations";
import RadioButton from "../../../components/RadioButton/RadioButton";
import PropTypes from "prop-types";
import styles from "./MainConfigurationControls.module.scss";

// Controls used primarily for manually reading or writing to a specific controller register.
export default function MainConfigurationControls(props: Props): Component {
  return (
    <Fragment>
      <div className="container">
        <div className={`${styles.header} row text-align-center py-3`}>
          <div className="col-6 col-lg-8 text-align-center">{props.assetName} Controller Configuration</div>
          <div className="col-6 col-lg-4">
            <button
              type="button"
              className={`${styles.sampleAllButton} btn btn-sm btn-primary float-end`}
              disabled={props.loading}
              onClick={() => props.onClickSampleAllParameters()}
            >
              Sample All Parameters
            </button>
          </div>
        </div>
      </div>
      <div className={`${styles.body} row justify-content-center mx-0 mb-3 w-100`}>
        <div className="col-xl-8 col-lg-7 col-md-7">
          <div className="row">
            <div className={`col text-center fw-bold p-1 ${styles.th}`}>Address</div>
            <div className={`col text-center fw-bold p-1 ${styles.th}`}>Data Type</div>
            <div className={`col text-center fw-bold p-1 ${styles.th}`}>Server ID</div>
            <div className={`col text-center fw-bold p-1 ${styles.th}`}>Byte Order</div>
          </div>
          <div className="row">
            <div className={`col py-2 ${styles.td}`}>
              <input
                type="text"
                className="form-control"
                id="address-input-field"
                value={props.startAddressInput}
                onChange={(e) => props.onChangeStartAddressInput(e.target.value)}
              />
            </div>
            <div className={`col py-2 ${styles.td}`}>
              <select
                className="form-select"
                value={props.dataTypeValue}
                onChange={(e) => props.onChangeDataTypeValue(parseInt(e.target.value))}
              >
                <option value={0} disabled>
                  Select a data type...
                </option>
                {CONFIG_DATA_TYPES.map((configDataType) => (
                  <option key={configDataType.value} value={configDataType.value}>
                    {configDataType.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={`col py-2 ${styles.td}`}>
              <input
                type="text"
                className="form-control"
                id="server-input-field"
                value={props.serverIdInput}
                onChange={(e) => props.onChangeServerIdInput(e.target.value)}
              />
            </div>
            <div className={`col py-2 ${styles.td}`}>
              <select
                className="form-select"
                value={props.byteOrderValue}
                onChange={(e) => props.onChangeByteOrderValue(parseInt(e.target.value))}
              >
                <option value={-1} disabled>
                  Select a byte order...
                </option>
                {CONFIG_BYTE_ORDERS.map((configByteOrder) => (
                  <option key={configByteOrder.value} value={configByteOrder.value}>
                    {configByteOrder.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className={`${styles.table} col`}>
          {/* Column for read/write features. */}
          <div className="row">
            {/* Row to keep all read/write elements horizontal. */}
            <div className="col-auto">
              {/* Column for read/write toggle. */}
              <div className="d-none d-xl-inline-flex pt-2">
                <RadioButton
                  label="Read"
                  className="mx-1"
                  checked={props.registerMode === "READ"}
                  radioId="read-large-radio"
                  onClick={() => props.onChangeRegisterMode(false)} // A false value means the read radio button was checked.
                />
                <RadioButton
                  label="Write"
                  className="mx-1"
                  checked={props.registerMode === "WRITE"}
                  radioId="write-large-radio"
                  onClick={() => props.onChangeRegisterMode(true)} // A true value means the write radio button was checked.
                />
              </div>
              <div className="d-inline-flex d-xl-none flex-column">
                <RadioButton
                  label="Read"
                  className="mx-1"
                  checked={props.registerMode === "READ"}
                  radioId="read-medium-radio"
                  onClick={() => props.onChangeRegisterMode(false)} // A false value means the read radio button was checked.
                />
                <RadioButton
                  label="Write"
                  className="mx-1"
                  checked={props.registerMode === "WRITE"}
                  radioId="write-medium-radio"
                  onClick={() => props.onChangeRegisterMode(true)} // A true value means the write radio button was checked.
                />
              </div>
            </div>
            <div className="col d-inline-flex align-items-center">
              <input
                type="text"
                className="form-control"
                value={props.configValueInput}
                id="read-write-input-field"
                disabled={props.registerMode === "READ"}
                onChange={(e) => props.onChangeConfigValueInput(e.target.value)}
              />
              <span className="d-sm-inline">&nbsp;&nbsp;</span>
              <button
                type="button"
                className="btn btn-primary"
                disabled={props.loading}
                onClick={() => props.onClickReadWriteConfigValue()}
              >
                {props.registerMode === "WRITE" ? "Write" : "Read"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

MainConfigurationControls.propTypes = {
  assetName: PropTypes.string.isRequired,
  registerMode: PropTypes.string.isRequired,
  startAddressInput: PropTypes.string.isRequired,
  dataTypeValue: PropTypes.number.isRequired,
  serverIdInput: PropTypes.string.isRequired,
  byteOrderValue: PropTypes.number.isRequired,
  configValueInput: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onClickSampleAllParameters: PropTypes.func.isRequired,
  onChangeRegisterMode: PropTypes.func.isRequired,
  onChangeStartAddressInput: PropTypes.func.isRequired,
  onChangeDataTypeValue: PropTypes.func.isRequired,
  onChangeServerIdInput: PropTypes.func.isRequired,
  onChangeByteOrderValue: PropTypes.func.isRequired,
  onChangeConfigValueInput: PropTypes.func.isRequired,
  onClickReadWriteConfigValue: PropTypes.func.isRequired,
};

interface Props {
  assetName: string;
  registerMode: RegisterMode;
  startAddressInput: string;
  dataTypeValue: number;
  serverIdInput: string;
  byteOrderValue: number;
  configValueInput: string;
  loading: boolean;
  onClickSampleAllParameters: () => void;
  onChangeRegisterMode: (isWrite: boolean) => void;
  onChangeStartAddressInput: (startAddressInput: string) => void;
  onChangeDataTypeValue: (dataTypeValue: number) => void;
  onChangeServerIdInput: (serverIdInput: string) => void;
  onChangeByteOrderValue: (byteOrderValue: number) => void;
  onChangeConfigValueInput: (configValueInput: string) => void;
  onClickReadWriteConfigValue: () => void;
}

type RegisterMode = "READ" | "WRITE";
