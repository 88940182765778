// --------------------------------------------------------------
// Created On: 2021-09-22
// Author: Zachary Thomas
//
// Last Modified: 2023-09-04
// Modified By: Zachary Thomas
//
// Copyright 2021 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import styles from "./AssociationRole.module.scss";

// Interactive icon that can be clicked to change operation permission.
export default function AssociationRole(props: Props): Component {
  return (
    <div className="col-auto" data-tip data-for={`user-group-${props.usergroupId}`}>
      <ReactTooltip id={`user-group-${props.usergroupId}`} place="top" effect="solid">
        {props.operateAccessPermission
          ? "Remove operation permission from this user group."
          : "Add operation permission to this user group."}
      </ReactTooltip>

      <i
        className={
          `${styles.role} fa fa-fw fa-cog fa-lg` +
          ` ${props.operateAccessPermission ? styles.operate : styles.view}`
        }
        onClick={() => props.onClick()}
      />
    </div>
  );
}

AssociationRole.propTypes = {
  usergroupId: PropTypes.number.isRequired,
  operateAccessPermission: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

interface Props {
  usergroupId: number;
  operateAccessPermission: boolean;
  onClick: () => void;
}
