// --------------------------------------------------------------
// Created On: 2021-10-01
// Author: Zachary Thomas
//
// Last Modified: 2024-04-16
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import validateNickname from "../../../../../utilities/validateNickname";
import styles from "./AssociatedAssetItem.module.scss";

// A single associated asset in the asset group modal.
export default function AssociatedAssetItem(props: Props): Component {
  return (
    <Fragment>
      <div
      data-test="assetgroup-associated-asset-item"
      className={`${styles.body} px-4 py-2`}
      >
        {props.name} {validateNickname(props.nickname, props.name) ? `(${props.nickname})` : ""}
      </div>
    </Fragment>
  );
}

AssociatedAssetItem.propTypes = {
  assetId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  nickname: PropTypes.string,
  productType: PropTypes.string,
  productModel: PropTypes.string,
  productManufacturer: PropTypes.string,
};

interface Props {
  assetId: number;
  name: string;
  nickname?: string;
  productType?: string;
  productModel?: string;
  productManufacturer?: string;
}
