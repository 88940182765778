// --------------------------------------------------------------
// Created On: 2023-11-27
// Author: Zachary Thomas
//
// Last Modified: 2024-01-29
// Modified By: Zachary Thomas
//
// Copyright 2023 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./SelectAssetsControl.module.scss";

// Button for selecting assets.
export default function SelectAssetsControl(props: Props): Component {
  return (
    <div className={styles.body}>
      <span>Select assets to update</span>
      <br />
      <button className="btn btn-primary m-3" type="button" onClick={() => props.onSelect()}>
        Select Assets
      </button>
    </div>
  );
}

SelectAssetsControl.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

interface Props {
  onSelect: () => void;
}
