// --------------------------------------------------------------
// Created On: 2021-12-21
// Author: Zachary Thomas
//
// Last Modified: 2024-05-07
// Modified By: Zachary Thomas
//
// Copyright 2023 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./GeofenceItem.module.scss";

// A single geofence point in a geofence.
export default function GeofenceItem(props: Props): Component {
  const [latitude, setLatitude] = useState<string>(props.latitude.toString());
  const [longitude, setLongitude] = useState<string>(props.longitude.toString());
  const [previousLatitude, setPreviousLatitude] = useState<string>(props.latitude.toString());
  const [previousLongitude, setPreviousLongitude] = useState<string>(props.longitude.toString());

  // If the input fields are not in use, automatically update input fields with changes that are passed down.
  useEffect(() => {
    setLatitude(props.latitude.toString());
    setLongitude(props.longitude.toString());
  }, [props.latitude, props.longitude]);

  // Pass the change to location data up a level if the entered value is valid.
  function updateLocation(latitude: string, longitude: string): void {
    setLatitude(latitude);
    setLongitude(longitude);
    if (
      latitude.length > 0 &&
      longitude.length > 0 &&
      !["0", ".", "-"].includes(latitude[latitude.length - 1]) &&
      !["0", ".", "-"].includes(longitude[longitude.length - 1]) &&
      !Number.isNaN(latitude) &&
      !Number.isNaN(longitude) &&
      (previousLatitude !== latitude || previousLongitude !== longitude)
    ) {
      setPreviousLatitude(latitude);
      setPreviousLongitude(longitude);
      props.onUpdatePoint(props.geofencePointId, parseFloat(latitude), parseFloat(longitude));
    }
  }

  return (
    <div data-test="-assetgroup-geofence-item" className={`${styles.body}px-4 py-2`}>
      <div className="row px-2 align-items-center">
        <div className="col-auto font-weight-bold">{props.geofencePointId}.</div>
        <div className="col">
          Latitude
          <input
            className="form-control"
            value={latitude}
            disabled={props.disabled}
            onChange={(e) => updateLocation(e.target.value, longitude)}
          />
        </div>

        <div className="col">
          Longitude
          <input
            className="form-control"
            value={longitude}
            disabled={props.disabled}
            onChange={(e) => updateLocation(latitude, e.target.value)}
          />
        </div>

        <div className="col-2">
          <button
            type="button"
            className="btn btn-danger float-end"
            disabled={props.disabled}
            onClick={() => props.onDeletePoint(props.geofencePointId)}
          >
            <i className="small-btn-text fa fa-fw fa-times" />
          </button>
        </div>
      </div>
    </div>
  );
}

GeofenceItem.propTypes = {
  geofencePointId: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  onUpdatePoint: PropTypes.func.isRequired,
  onDeletePoint: PropTypes.func.isRequired,
};

interface Props {
  geofencePointId: number;
  latitude: number;
  longitude: number;
  disabled: boolean;
  onUpdatePoint: (geofencePointId: number, latitude: number, longitude: number) => void;
  onDeletePoint: (geofencePointId: number) => void;
}
