// --------------------------------------------------------------
// Created On: 2021-08-30
// Author: Zachary Thomas
//
// Last Modified: 2024-05-31
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment } from "react";
import Card from "../../components/Card/Card";
import Error from "../../components/Error/Error";
import Success from "../../components/Success/Success";
import Spinner from "../../components/Spinner/Spinner";
import CompanyForm from "./CompanyForm/CompanyForm";
import apiRequest from "../../utilities/api/apiRequest";
import PrivacyPolicy from "../../components/PrivacyPolicy/PrivacyPolicy";
import Error500Page from "../Error500Page/Error500Page";
import useApi from "../../hooks/useApi";
import getApiError from "../../utilities/api/getApiError";
import useAutoAnimate from "../../hooks/useAutoAnimate";
import { Link } from "react-router-dom";
import {
  API,
  MIN_FULL_NAME_LENGTH,
  MAX_FULL_NAME_LENGTH,
  MIN_EMAIL_LENGTH,
  MAX_EMAIL_LENGTH,
  VALID_EMAIL_REGULAR_EXPRESSION,
} from "../../constants/miscellaneous";
import styles from "./CreateAccountPage.module.scss";
import AccountInfo from "./AccountInfo/AccountInfo";

// Page for users to create a new account.
export default function CreateAccountPage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [companyAlreadyExists, setCompanyAlreadyExists] = useState<boolean>(false);
  const [requestedAccount, setRequestedAccount] = useState<boolean>(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState<boolean>(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [accountType, setAccountType] = useState<"NEW_ACCOUNT" | "NEW_COMPANY" | "EXISTING_COMPANY">("NEW_ACCOUNT");
  const [animationParent] = useAutoAnimate<HTMLDivElement>();

  // Get a list of the verified companies that names are already in use.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/registration/company`,
    },
    async (response: Response, responseBody: CompanyResponseBody) => {
      if (response.ok && responseBody) {
        setCompanies(responseBody.companies);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // Check if the entered company name already exists.
  useEffect(() => {
    const tempCompanyAlreadyExists = companies.some(
      (company) => company.name.toLowerCase() === companyName.toLowerCase()
    );
    setCompanyAlreadyExists(tempCompanyAlreadyExists);

    if (tempCompanyAlreadyExists) {
      setAccountType("EXISTING_COMPANY");
    } else {
      setAccountType("NEW_COMPANY");
    }
  }, [companyName, JSON.stringify(companies)]);

  // Check if account data is valid.
  function accountIsValid(): boolean {
    if (username.trim().length < MIN_FULL_NAME_LENGTH || username.trim().length > MAX_FULL_NAME_LENGTH) {
      setErrorMessage(`Username must be between ${MIN_FULL_NAME_LENGTH} and ${MAX_FULL_NAME_LENGTH} characters long.`);
      return false;
    } else if (emailAddress.trim().length < MIN_EMAIL_LENGTH || emailAddress.trim().length > MAX_EMAIL_LENGTH) {
      setErrorMessage(`Email must be between ${MIN_EMAIL_LENGTH} and ${MAX_EMAIL_LENGTH} characters long.`);
      return false;
    } else if (!VALID_EMAIL_REGULAR_EXPRESSION.test(emailAddress.toLowerCase())) {
      setErrorMessage("You must enter a valid email address.");
      return false;
    } else if (companyName.length === 0) {
      setErrorMessage(`To create an account you must enter a company name.`);
      return false;
    } else if (!privacyPolicyAccepted) {
      setErrorMessage("You must agree to the EULA and privacy policy to create an account.");
      return false;
    } else {
      return true;
    }
  }

  // Create a new account.
  async function createAccount(): Promise<void> {
    if (accountIsValid()) {
      const updatedCompanyName = companyName;

      const requestBody = {
        name: username.trim(),
        emailAddress: emailAddress.trim(),
        companyName: updatedCompanyName.trim(),
        companyAddress: "",
      };

      setLoading(true);
      const [response] = (await apiRequest(`${API}/registration/company/user`, "POST", requestBody)) as [
        Response,
        UserResponseBody
      ];
      setLoading(false);

      if (response.ok) {
        setRequestedAccount(true);
        setErrorMessage("");

        setSuccessMessage(
          "Your new account has been created." +
            " You will receive an email shortly with a temporary password to access your account."
        );
      } else {
        setSuccessMessage("");
        setErrorMessage(await getApiError(response, "Unable to create account."));
      }
    }
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      <div className="col-12 col-xl-6 offset-xl-3">
        <Card title="Account Information">
          <div className="create-account-form-inner px-5 mb-4" ref={animationParent}>
            {successMessage.length === 0 && (
              <Fragment>
                <div className="mx-2 my-4">
                  <label className="mb-3">
                    <span className="font-weight-bold">Username</span>
                  </label>
                  <input
                    className="form-control mx-auto"
                    type="text"
                    maxLength={MAX_FULL_NAME_LENGTH}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>

                <div className="mx-2 mb-4">
                  <label className="mb-3">
                    <span className="font-weight-bold">Email</span>
                  </label>
                  <input
                    className="form-control mx-auto"
                    type="email"
                    maxLength={MAX_EMAIL_LENGTH}
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </div>

                <CompanyForm
                  name={companyName}
                  onChangeName={(name) => setCompanyName(name)}
                  companies={companies}
                  companyAlreadyExists={companyAlreadyExists}
                />

                <div className="mx-2">
                  <PrivacyPolicy
                    status={privacyPolicyAccepted}
                    onChange={(status) => setPrivacyPolicyAccepted(status)}
                  />
                </div>

                <AccountInfo accountType={accountType} />
              </Fragment>
            )}

            <div className="my-4">
              <Success message={successMessage} />
              <Error message={errorMessage} />
            </div>

            {requestedAccount ? (
              <div className="text-center my-4">
                <Link to="/login" className="router-link">
                  <button type="submit" className={`${styles.createBtn} btn btn-primary`}>
                    Return to Login
                  </button>
                </Link>
              </div>
            ) : (
              <div className="text-center my-4">
                <button
                  type="submit"
                  className={`${styles.createBtn} btn btn-primary`}
                  disabled={accountType === "EXISTING_COMPANY"}
                  onClick={() => createAccount()}
                >
                  {accountType === "NEW_ACCOUNT" && "Create Account"}
                  {(accountType === "NEW_COMPANY" || accountType === "EXISTING_COMPANY") && "Create Company Account"}
                </button>
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}

interface Company {
  companyId: number;
  name: string;
  code: string;
}

interface CompanyResponseBody {
  companies: Company[];
}

interface UserResponseBody {
  message: string;
}
