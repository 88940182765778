// --------------------------------------------------------------
// Created On: 2021-12-14
// Author: Zachary Thomas
//
// Last Modified: 2023-02-07
// Modified By: Zachary Thomas
//
// Copyright 2021 - 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

export function getAuthorizerToken(state: ReduxStore): string {
  return state.authorizerToken;
}

export function getAccessToken(state: ReduxStore): string {
  return state.accessToken;
}

export function getCurrentUser(state: ReduxStore): CurrentUser {
  return state.currentUser;
}

export function getAlerts(state: ReduxStore): AlertMessage[] {
  return state.alerts;
}

export function getDeviceAttributes(state: ReduxStore): DeviceAttribute[] {
  return state.deviceAttributes;
}

export function getSelectedDeviceAttributes(state: ReduxStore): DeviceAttribute[] {
  return state.deviceAttributes.filter((attribute) => attribute.checked);
}

interface AlertMessage {
  alertMessageId: number;
  alertCode: string;
  date: string;
  message: string;
  unread: boolean;
  urgency: string;
}
