// --------------------------------------------------------------
// Created On: 2021-05-14
// Author: Zachary Thomas
//
// Last Modified: 2024-07-03
// Modified By: Jonathon Hicke
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./Error.module.scss";

// Error message.
export default function Error(props: Props): Component {
  return props.message.length > 0 ? (
    <div data-test="error-message" className={`${styles.text} alert alert-danger`} role="alert">
      {props.message}
    </div>
  ) : null;
}

Error.propTypes = {
  message: PropTypes.string.isRequired,
};

interface Props {
  message: string;
}
