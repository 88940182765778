// --------------------------------------------------------------
// Created On: 2022-01-24
// Author: Zachary Thomas
//
// Last Modified: 2024-05-31
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect } from "react";
import Modal from "../../../Modal/Modal";
import ModalHeader from "../../../ModalHeader/ModalHeader";
import ModalBody from "../../../ModalBody/ModalBody";
import ModalFooter from "../../../ModalFooter/ModalFooter";
import Error from "../../../Error/Error";
import Spinner from "../../../Spinner/Spinner";
import AlertMessageForm from "./AlertMessageForm/AlertMessageForm";
import apiRequest from "../../../../utilities/api/apiRequest";
import getApiError from "../../../../utilities/api/getApiError";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../../redux/selectors";
import {
  API,
  MIN_ALERT_MESSAGE_LENGTH,
  MAX_ALERT_MESSAGE_LENGTH,
  ALERT_INFO,
} from "../../../../constants/miscellaneous";
import PropTypes from "prop-types";
import styles from "./AlertMessageModal.module.scss";

// Modal for sending alert messages.
export default function AlertMessageModal(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [urgency, setUrgency] = useState<string>(ALERT_INFO.toUpperCase());
  const [alertMessage, setAlertMessage] = useState<string>("");
  const currentUser = useSelector(getCurrentUser);

  // Clear all data when the modal is opened.
  useEffect(() => {
    if (props.showModal) {
      setAlertMessage("");
    }
  }, [props.showModal]);

  // Validate the alert message settings.
  function alertMessageIsValid(): boolean {
    if (
      alertMessage.trim().length < MIN_ALERT_MESSAGE_LENGTH ||
      alertMessage.trim().length > MAX_ALERT_MESSAGE_LENGTH
    ) {
      setErrorMessage(
        `The alert message must be between ${MIN_ALERT_MESSAGE_LENGTH} and ${MAX_ALERT_MESSAGE_LENGTH} characters long.`
      );
      return false;
    } else {
      return true;
    }
  }

  // Send an alert message.
  async function sendAlertMessage(): Promise<void> {
    if (alertMessageIsValid()) {
      const requestBody = {
        message: alertMessage.trim(),
        urgency: urgency,
        audience: "COMPANY",
        companyId: currentUser.companyId,
        usergroupId: null,
        userId: null,
      };

      setLoading(true);
      const [response] = (await apiRequest(`${API}/alertmessage`, "POST", requestBody)) as [Response, ResponseBody];
      setLoading(false);

      if (response.ok) {
        setErrorMessage("");
        props.onClose();
      } else {
        setErrorMessage(await getApiError(response, "Unable to send alert message."));
      }
    }
  }

  // Exit the modal.
  function exitModal(): void {
    setErrorMessage("");
    props.onClose();
  }

  return (
    <div>
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--super-modal-z-index)" }}
        animation
        centered
      >
        <ModalHeader>
          <h5 className="font-weight-bold">Send Alert Message</h5>
        </ModalHeader>

        <ModalBody>
          <p className="mx-2">Alert messages are sent to all users in your company.</p>

          <AlertMessageForm
            urgency={urgency}
            alertMessage={alertMessage}
            onChangeUrgency={(urgency) => setUrgency(urgency)}
            onChangeAlertMessage={(alertMessage) => setAlertMessage(alertMessage)}
          />

          {errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={errorMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <button className={`${styles.button} btn btn-primary`} type="button" onClick={() => sendAlertMessage()}>
            Send Alert Message
          </button>

          <button className={`${styles.button} btn btn-secondary`} type="button" onClick={() => exitModal()}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

AlertMessageModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

interface Props {
  showModal: boolean;
  onClose: () => void;
}

interface ResponseBody {
  message: string;
}
