// --------------------------------------------------------------
// Created On: 2024-09-14
// Author: Zachary Thomas
//
// Last Modified: 2024-09-16
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Active, Over, useDraggable } from "@dnd-kit/core";
import { MAX_CARD_ATTRIBUTES } from "../../../../../constants/templates";
import { DndContext } from "@dnd-kit/core";
import DroppableArea from "../../../../../components/DroppableArea/DroppableArea";
import TemplateAttribute from "../../TemplateAttribute/TemplateAttribute";
import deepCopy from "../../../../../utilities/deepCopy";
import styles from "./TemplateCard.module.scss";
import DynamicCardInfo from "./DynamicCardInfo/DynamicCardInfo";

// Form for a single template card.
export default function TemplateCard(props: Props): Component {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.formId,
  });

  // Handle changes to the current card.
  function handleCardChange(name: string, cardTypeId: string | number | null, attributes: Attribute[]): void {
    let parsedCardTypeId: number | null = parseInt(String(cardTypeId), 10);
    if (parsedCardTypeId === undefined || isNaN(parsedCardTypeId)) {
      parsedCardTypeId = null;
    }
    props.onChange(name, parsedCardTypeId, attributes);
  }

  // Handles adding an attribute to the current card.
  function handleAddAttribute(name: string, cardTypeId: string | number | null, attributes: Attribute[]): void {
    let attributesDeepCopy = deepCopy(attributes);
    let newAttributeFormId = 1;
    attributesDeepCopy.forEach((attribute) => {
      if (attribute.formId >= newAttributeFormId) {
        newAttributeFormId = attribute.formId + 1;
      }
    });
    const newAttribute = {
      formId: newAttributeFormId,
      regAttributeId: 0,
      attributeName: "",
      unitId: null,
      unitShortName: null,
      unitLongName: null,
    };
    attributesDeepCopy = [...attributesDeepCopy, newAttribute];
    handleCardChange(name, cardTypeId, attributesDeepCopy);
  }

  // Handles updating an attribute on the current card.
  function handleUpdateAttribute(
    formId: number,
    attributeName: string,
    cardName: string,
    cardTypeId: string | number | null,
    attributes: Attribute[],
    attributeMap: AttributesById
  ): void {
    const attributesDeepCopy = deepCopy(attributes);
    const selectedAttribute = attributesDeepCopy.find((attribute) => attribute.formId === formId);
    if (selectedAttribute !== undefined) {
      const attributes = Object.keys(attributeMap).map((key) => attributeMap[key]);
      const lookUpAttribute = attributes.find((attribute) => attribute.attributeName === attributeName);
      if (lookUpAttribute !== undefined) {
        selectedAttribute.regAttributeId = lookUpAttribute.regAttributeId;
      } else {
        selectedAttribute.regAttributeId = 0;
      }
      selectedAttribute.attributeName = attributeName;
      handleCardChange(cardName, cardTypeId, attributesDeepCopy);
    }
  }

  // Handles deleting an attribute on the current card.
  function handleDeleteAttribute(
    formId: number,
    cardName: string,
    cardTypeId: string | number | null,
    attributes: Attribute[]
  ): void {
    const attributesDeepCopy = deepCopy(attributes);
    const selectedAttributeIndex = attributesDeepCopy.findIndex((attribute) => attribute.formId === formId);
    if (selectedAttributeIndex >= 0) {
      attributesDeepCopy.splice(selectedAttributeIndex, 1);
      handleCardChange(cardName, cardTypeId, attributesDeepCopy);
    }
  }

  // Handles changing the order of cards when dragged to a new position.
  function handleAttributeDragEnd(
    over: Over | null,
    active: Active,
    cardName: string,
    cardTypeId: string | number | null,
    attributes: Attribute[]
  ): void {
    if (over !== null) {
      let attributesDeepCopy = deepCopy(attributes);
      const selectedAttributeIndex = attributesDeepCopy.findIndex((attribute) => attribute.formId === active.id);
      const selectedAttribute = attributesDeepCopy.find((attribute) => attribute.formId === active.id);
      if (selectedAttributeIndex > -1 && selectedAttribute !== undefined) {
        // Start by removing the attribute from the array before adding it to its new location.
        attributesDeepCopy.splice(selectedAttributeIndex, 1);
        // Figure out where the attribute gets added back into the array.
        if (over.id === 0) {
          attributesDeepCopy = [selectedAttribute, ...attributesDeepCopy];
          handleCardChange(cardName, cardTypeId, attributesDeepCopy);
        } else {
          const previousAttributeIndex = attributesDeepCopy.findIndex((attribute) => attribute.formId === over.id);
          if (previousAttributeIndex > -1) {
            attributesDeepCopy.splice(previousAttributeIndex + 1, 0, selectedAttribute);
            handleCardChange(cardName, cardTypeId, attributesDeepCopy);
          }
        }
      }
    }
  }

  return (
    <div className="row align-items-center">
      <div className="col-auto ge-0 pe-0">{props.formIndex + 1}.</div>
      <div className="col g-0">
        <div
          data-test="card-attribute-container"
          className="container m-0 py-0"
          ref={setNodeRef}
          style={
            transform
              ? {
                  transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
                }
              : undefined
          }
        >
          <div className={`${styles.body} row p-0 m-0`}>
            <div className={`${styles.draggable} col-6 col-lg-auto p-0 m-0`} {...attributes} {...listeners} />
            <div className="col py-2 pe-2">
              <div className="row align-items-center my-2 my-lg-1">
                {/* Drop down for selecting card type. */}
                <div className="col-auto my-2 my-lg-1">
                  <label className={styles.title}>Card Type</label>
                </div>
                <div className="col-8 col-lg my-2 my-lg-1">
                  <select
                    data-test="card-type-selection"
                    className="form-select"
                    value={props.cardTypeId || ""}
                    disabled={props.disabled}
                    onChange={(e) => handleCardChange(props.name, e.target.value, props.attributes)}
                  >
                    <option value="">Custom Card</option>
                    {props.templateCardTypes.map((templateCardType) => (
                      <option key={templateCardType.templateCardTypeId} value={templateCardType.templateCardTypeId}>
                        Dynamic {templateCardType.name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* Show different content based on if this is a custom card or a dynamic card. */}
                {props.cardTypeId === null ? (
                  <Fragment>
                    <div className="col-auto my-2 my-lg-1">
                      <label className={styles.title}>Name</label>
                    </div>
                    <div className="col-5 col-lg my-2 my-lg-1">
                      <input
                        data-test="card-name-input"
                        className="form-control mx-auto"
                        type="text"
                        value={props.name}
                        disabled={props.disabled}
                        onChange={(e) => handleCardChange(e.target.value, props.cardTypeId, props.attributes)}
                      />
                    </div>
                    <div className="col-auto my-2 my-lg-1">
                      <button
                        data-test="add-card-attribute-button"
                        type="submit"
                        className={`${styles.addButton} btn btn-success float-end`}
                        onClick={() => handleAddAttribute(props.name, props.cardTypeId, props.attributes)}
                        disabled={props.disabled || props.attributes.length >= MAX_CARD_ATTRIBUTES}
                      >
                        <span className="d-none d-sm-inline">Add Attribute</span>
                        <i className="d-inline d-sm-none fa fa-fw fa-plus fa-xs" />
                      </button>
                    </div>
                  </Fragment>
                ) : (
                  <DynamicCardInfo
                    cardTypeId={props.cardTypeId}
                    templateCardTypes={props.templateCardTypes}
                    formId={props.formId}
                  />
                )}
                {/* Button for deleting this card. */}
                <div className="col-auto my-2 my-lg-1">
                  <button
                    data-test="delete-card-button"
                    type="button"
                    className={`${styles.button} btn btn-danger float-end`}
                    disabled={props.disabled}
                    onClick={() => props.onDelete()}
                  >
                    <i className="d-inline fa fa-fw fa-times fa-xs" />
                  </button>
                </div>
              </div>

              {/* Custom attributes for a custom card. */}
              {props.cardTypeId === null && props.attributes.length > 0 && (
                <div className="row align-items-center my-2">
                  <div className="col">
                    <DndContext
                      onDragEnd={(e) =>
                        handleAttributeDragEnd(e.over, e.active, props.name, props.cardTypeId, props.attributes)
                      }
                    >
                      <DroppableArea id={0} disabled={props.disabled} isSlim />
                      {props.attributes.map((attribute, i) => (
                        <Fragment key={attribute.formId}>
                          <TemplateAttribute
                            key={attribute.formId}
                            formIndex={i}
                            formId={attribute.formId}
                            name={attribute.attributeName}
                            disabled={props.disabled}
                            onChange={(name) => {
                              handleUpdateAttribute(
                                attribute.formId,
                                name,
                                props.name,
                                props.cardTypeId,
                                props.attributes,
                                props.attributeMap
                              );
                            }}
                            onDelete={() =>
                              handleDeleteAttribute(attribute.formId, props.name, props.cardTypeId, props.attributes)
                            }
                            onDragEnd={(over, active) =>
                              handleAttributeDragEnd(over, active, props.name, props.cardTypeId, props.attributes)
                            }
                          />
                          <DroppableArea id={attribute.formId} disabled={props.disabled} isSlim />
                        </Fragment>
                      ))}
                    </DndContext>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TemplateCard.propTypes = {
  formIndex: PropTypes.number.isRequired,
  formId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  cardTypeId: PropTypes.number,
  attributeMap: PropTypes.object.isRequired,
  attributes: PropTypes.array.isRequired,
  templateCardTypes: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
};

interface Props {
  formIndex: number;
  formId: number;
  name: string;
  cardTypeId: number | null;
  attributeMap: AttributesById;
  attributes: Attribute[];
  templateCardTypes: TemplateCardType[];
  disabled?: boolean;
  onChange: (name: string, cardTypeId: number | null, attributes: Attribute[]) => void;
  onDelete: () => void;
  onDragEnd: (over: Over | null, active: Active) => void;
}

interface TemplateCardType {
  templateCardTypeId: number;
  name: string;
  description: string;
}

interface Attribute {
  formId: number;
  regAttributeId: number;
  attributeName: string;
  unitId: number | null;
  unitShortName: string | null;
  unitLongName: string | null;
}

interface AttributesById {
  [key: string]: Attribute;
}
