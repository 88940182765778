// --------------------------------------------------------------
// Created On: 2023-11-22
// Author: Zachary Thomas
//
// Last Modified: 2023-12-19
// Modified By: Zachary Thomas
//
// Copyright 2023 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment } from "react";
import styles from "./HelpAlertThresholdAsset.module.scss";
import { MANAGE_MULTIPLE_ALERT_THRESHOLDS_PERMISSION } from "../../constants/permissions";

// Explains what an alert threshold is.
export default function HelpAlertThresholdAsset(): Component {
  return (
    <div>
      <p className="px-3">
        Each asset can have multiple alert thresholds. This section allows you to decide how alerting is handled when an
        asset reports a value that crosses a threshold.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Attributes:</b> Each asset can have alert thresholds set for multiple different attributes. An example of an
        attribute could be &apos;Fuel Level&apos;.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Thresholds:</b> An alert threshold represents some range of values that when reached, should notify someone
        of a potential issue. An example of this could be setting an alert threshold on &apos;Fuel Level&apos; when it
        is less than 10%.
      </p>

      <p className="px-3 mt-3 mb-2">
        <b>Subscribed users:</b> These are the users that are subscribed to the current alert threshold. You can decide
        how long after the initial alert each user will receive a message and The forms of communication used for
        contacting those users. If a user acknowledges an alert, then no further messages will be sent out for that
        current alert. This functionality supports the creation of alert escalation chains.
      </p>

      {MANAGE_MULTIPLE_ALERT_THRESHOLDS_PERMISSION && (
        <Fragment>
          <p className="px-3 mt-3 mb-2">
            <b>Updating multiple assets:</b> When usings the &apos;Update multiple assets&apos; feature to apply alert
            thresholds, all existing alert thresholds for the specified attribute are replaced by the current selection.
            Any active alerts for replaced thresholds will timeout at the time that the change is made. See example
            below:
          </p>

          <div className="row">
            <div className="col-12 col-lg-6">
              <img
                className={styles.image}
                src="/images/multiple_threshold_before.png"
                alt="Example of applying alert thresholds to multiple assets."
              />
            </div>
            <div className="col-12 col-lg-6">
              <img
                className={styles.image}
                src="/images/multiple_threshold_after.png"
                alt="Example of applying alert thresholds to multiple assets."
              />
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}
