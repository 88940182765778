// --------------------------------------------------------------
// Created On: 2023-02-21
// Author: Zachary Thomas
//
// Last Modified: 2024-04-10
// Modified By: Lilly Kane
//
// Copyright 2023 - 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import styles from "./UserEscalationForm.module.scss";

// Form for assigning alert escalation rules for an alert threshold to a specific user.
export default function UserEscalationForm(props: Props): Component {
  return (
    <div className={`${styles.body} my-3 mx-1`} data-test="manage-alert-threshold-single-user-subscription-settings">
      <div className="row align-items-center justify-content-center m-2">
        <div className="col-10 col-lg-11">
          <input
            data-test="manage-alert-threshold-subscribed-user-name-input"
            className="form-control"
            type="text"
            list="datalist-users"
            autoComplete="off"
            value={props.user}
            disabled={props.isRental}
            onChange={(e) =>
              props.onChange(
                e.target.value,
                props.includeEmail,
                props.includeText,
                props.includeCall,
                props.escalationLevel
              )
            }
          />
        </div>
        <div className="col-2 col-lg-1">
          <button
            data-test="manage-alert-threshold-remove-subscribed-user-button"
            type="button"
            className={`${styles.button} btn btn-danger float-end`}
            disabled={props.isRental}
            onClick={() => props.onDeleteUser()}
          >
            <i className="fa fa-fw fa-times fa-xs" />
          </button>
        </div>
      </div>

      <div className="row align-items-center m-2">
        <div className="col-2 text-center">
          <label
            onClick={() =>
              props.onChange(
                props.user,
                !props.includeEmail,
                props.includeText,
                props.includeCall,
                props.escalationLevel
              )
            }
          >
            Email
          </label>
          <br />
          <input
            data-test="manage-alert-threshold-sub-by-email-checkbox"
            className={`form-check-input ${styles.check}`}
            type="checkbox"
            checked={props.includeEmail}
            disabled={props.isRental}
            onChange={() =>
              props.onChange(
                props.user,
                !props.includeEmail,
                props.includeText,
                props.includeCall,
                props.escalationLevel
              )
            }
          />
        </div>

        <div className="col-2 text-center">
          <label
            onClick={() => {
              if (props.hasPhoneNumber) {
                props.onChange(
                  props.user,
                  props.includeEmail,
                  !props.includeText,
                  props.includeCall,
                  props.escalationLevel
                );
              }
            }}
          >
            Text
          </label>
          <br />
          <input
            data-test="manage-alert-threshold-sub-by-text-checkbox"
            className={`form-check-input ${styles.check}`}
            type="checkbox"
            checked={props.includeText}
            disabled={props.isRental || !props.hasPhoneNumber}
            onChange={() =>
              props.onChange(
                props.user,
                props.includeEmail,
                !props.includeText,
                props.includeCall,
                props.escalationLevel
              )
            }
          />
        </div>

        <div className="col-2 text-center">
          <label
            className={styles.checkLabel}
            onClick={() => {
              if (props.hasPhoneNumber) {
                props.onChange(
                  props.user,
                  props.includeEmail,
                  props.includeText,
                  !props.includeCall,
                  props.escalationLevel
                );
              }
            }}
          >
            Call
          </label>
          <br />
          <input
            data-test="manage-alert-threshold-sub-by-phonecall-checkbox"
            className={`form-check-input ${styles.check}`}
            type="checkbox"
            checked={props.includeCall}
            disabled={props.isRental || !props.hasPhoneNumber}
            onChange={() =>
              props.onChange(
                props.user,
                props.includeEmail,
                props.includeText,
                !props.includeCall,
                props.escalationLevel
              )
            }
          />
        </div>

        <div className="col-6 text-center">
          <label className="mb-2">Alert delivery delay</label>
          <select
            data-test="manage-alert-threshold-user-alert-delivery-delay-selector"
            className="form-select"
            value={String(props.escalationLevel)}
            disabled={props.isRental}
            onChange={(e) =>
              props.onChange(
                props.user,
                props.includeEmail,
                props.includeText,
                props.includeCall,
                parseInt(e.target.value, 10)
              )
            }
          >
            <option value="0">No delay</option>
            <option value="1">5 minutes</option>
            <option value="2">10 minutes</option>
            <option value="3">15 minutes</option>
            <option value="4">20 minutes</option>
            <option value="5">25 minutes</option>
            <option value="6">30 minutes</option>
          </select>
        </div>
      </div>
    </div>
  );
}

UserEscalationForm.propTypes = {
  user: PropTypes.string.isRequired,
  includeEmail: PropTypes.bool.isRequired,
  includeText: PropTypes.bool.isRequired,
  includeCall: PropTypes.bool.isRequired,
  hasPhoneNumber: PropTypes.bool.isRequired,
  escalationLevel: PropTypes.number.isRequired,
  isRental: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
};

interface Props {
  user: string;
  includeEmail: boolean;
  includeText: boolean;
  includeCall: boolean;
  hasPhoneNumber: boolean;
  escalationLevel: number;
  isRental: boolean;
  onChange: (
    user: string,
    includeEmail: boolean,
    includeText: boolean,
    includeCall: boolean,
    escalationLevel: number
  ) => void;
  onDeleteUser: () => void;
}
