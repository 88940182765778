// --------------------------------------------------------------
// Created On: 2021-09-01
// Author: Zachary Thomas
//
// Last Modified: 2024-02-28
// Modified By: Zachary Thomas
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React from "react";

// General disclaimer for performing remote operations.
export default function RemoteOperationDisclaimer(): Component {
  return (
    <div>
      <p>Warning: Automatic Engine Start-up System</p>
      <p>
        This panel/machine is equipped with an automatic engine start-up system that may initiate engine activation
        without prior warning. The automatic start-up system operates based on remote commands. As a result:
      </p>
      <ol type="1">
        <li>
          Unexpected Engine Activation: The engine may start or stop unexpectedly without any warning signals or notice.
        </li>
        <li>
          Automatic Operation Programming: Engine controllers, VFDs, and other pump control mechanisms may be programmed
          to start, stop, change speeds, or engage in other behavior based variables including, but not limited to
          timers, external inputs, and internal process variables. Cornell takes no responsibility for configuration or
          programming that affects operation of the pump package.
        </li>
        <li>
          Risk of Injury or Property Damage: Sudden engine activation could pose risks of injury to nearby individuals
          or damage to surrounding property.
        </li>
        <li>Safety Precautions: </li>
        <ol type="a">
          <li>
            Ensure all personnel are clear of the machine&apos;s operational zone before starting or during operation.
          </li>
          <li>
            Establish clear, visible signage or warning systems to signal the machine&apos;s operational status and
            potential hazards to nearby individuals.
          </li>
          <ol type="i">
            <li>
              User acknowledges and agrees that it is solely responsible for providing warning labels, visible warnings,
              and audible warnings to notify the panel/machine operator of an impending start-up.
            </li>
          </ol>

          <li>Maintain a safe distance and exercise caution when the machine is operational.</li>

          <li>
            Always use lockout/tag out procedures prior to performing any service or conﬁguration operations on the
            machine to prevent accidental start-ups or unauthorized access to hazardous zones.
          </li>

          <li>
            Ensure all personnel involved in service or maintenance operations are appropriately trained and equipped
            with the necessary safety gear.
          </li>

          <li>
            Do not conﬁgure operator programmable features while the panel is in &quot;AUTO&quot; mode. The panel should
            be conﬁgured <u>only</u> when the key is in the &quot;Run&quot; position.
          </li>
        </ol>

        <li>
          Emergency Response: In the event of an unexpected engine start-up, immediately follow established safety
          procedures outlined in the user manual or posted on the machine for safe shutdown or evacuation.
        </li>
      </ol>

      <p>
        By operating this panel/machine, users acknowledge and accept the potential risks associated with the automatic
        engine start-up system and assume responsibility for following safety guidelines to prevent injury to damage.
        Cornell Pump shall not be liable for any injury, loss, or damage resulting from the failure to follow
        recommended safety precautions and guidelines.
      </p>

      <p>
        Users expressly waive any claims against Cornell Pump arising from injuries, damages, or losses resulting from
        the failure to adhere to safety precautions. The user assumes full responsibility for adhering to safety
        guidelines during operation.
      </p>
    </div>
  );
}
